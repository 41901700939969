export const getData = (url, authToken, afterAction) => {
  fetch(url, {
    credentials: "same-origin",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
  })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        afterAction(data);
      })
      .catch((error) => console.log(error));
};

export const getList = async (url, authToken, afterAction) => {
  fetch(url, {
    credentials: "same-origin",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
  })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        afterAction(data);
      })
      .catch((error) => console.log(error));
};




export const postData = (url, authToken, body, afterAction, afterActionError=null) => {
  return fetch(url, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
    body: JSON.stringify(body),
  })
      .then((response) => {
        if (response.status != 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        afterAction(data);
      })
      .catch((error) => {
        afterActionError && afterActionError(error)
        console.log(error)
      });
}; 
export const postContactsToMailfold = (url, authToken, afterAction, afterActionError=null) => {
  return fetch(url, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
  })
    .then((response) => {
        console.log(response)
      if (response.status > 204) {
        afterAction("error");
          throw new Error("Not success");
        }
        return response;
      })
    .then((data) => {
      afterAction("success");
      // window.location.reload();
      // alert('Contacts of selected list posted successfully!');
      })
      .catch((error) => {
        afterActionError && afterActionError(error)
        console.log(error)
      });
};

export const postGhl = async(url, authToken,afterAction) => {
  return fetch(url, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
  })
    .then((response) => {
      if (response.status > 204) {
        afterAction("error");
        throw new Error("Not success");
      }
      // Return the JSON response
    })
    .then((data) => {
      // Perform actions with the 'data' received from the response if needed
      afterAction("success"); 
      window.location.reload()
    
      // Reload the page after successful processing if required
      // window.location.reload();
      // alert('Contacts of selected list posted successfully!');
    })
    .catch((error) => {
      console.error("Error:", error);
      // Handle errors if needed
    });
}; 
export const postContacts = (url, authToken,afterAction) => {
  return fetch(url, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
  })
    .then((response) => {
      if (response.status > 204) {
        throw new Error("Not success");
      }
      // Return the JSON response
    })
    .then((data) => {
      // Perform actions with the 'data' received from the response if needed
      afterAction(data)
    
      // Reload the page after successful processing if required
      window.location.reload();
    })
    .catch((error) => {
      console.error("Error:", error);
      // Handle errors if needed
    });
};


export const putData = (url, authToken, body, afterAction) => {
  fetch(url, {
    credentials: "same-origin",
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
    body: JSON.stringify(body),
  })
      .then((response) => {
        if (response.status > 204) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        afterAction(data);
      })
      .catch((error) => console.log(error));
};

export const deleteData = (url, authToken, afterAction) => {
  fetch(url, {
    credentials: "same-origin",
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authToken,
    },
  })
      .then((response) => {
        if (response.status > 204) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        afterAction(data);
      })
      .catch((error) => {
        console.log(error);
      });
};
