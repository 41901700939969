import React, { useEffect, useRef, useState } from 'react';
import Warning from './Warning';

const initialState = {
    holder_name: '',
    number: '',
    expiration_month: '',
    expiration_year: '',
    cvv: ''
}

const styles = {
    error: {
        border: "1px solid rgba(256, 0, 0, 0.8)",
    },
}

function getYears(card_years) {

    const [first, last] = card_years.split('..');
    const result = []
    for (let i = +first; i <= +last; i++) {
        result.push(i)
    }
    return result;
}

function getCardNumber({ current_agency_card, current_user_card }) {
    if (current_agency_card?.last_four_digits) {
        return '*'.repeat(12) + current_agency_card?.last_four_digits
    }
    if (current_user_card?.last_four_digits) {
        return '*'.repeat(12) + current_user_card?.last_four_digits
    }
    return '';
}

const BillingForm = (
    {
        card_years = "",
        current_agency_card,
        current_user_card,
        billing_url = "update_billing?tab=billing",
        ...props
    }
) => {

    const form = useRef(null)
    const [formErrors, setFormErrors] = useState(() => ({}))
    const [fields, setFields] = useState(() => {

        const years = getYears(card_years)
        const [expiration_year] = years
        const [[expiration_month]] = props.months_with_index
        return {
            ...initialState,
            card_years: years,
            nunber: getCardNumber({ current_agency_card, current_user_card }),
            expiration_year,
            expiration_month,
            ...props.card
        }
    })

    const [validations, setValidations] = useState(() => ({
        holder_name: {
            pattern: "^[a-zA-Z\s]*$",
            text: 'Name',
            required: true,
            valid: true,

        },
        number: {
            pattern: "^[0-9\s]+$",
            text: 'Card number',
            required: true,
            valid: true,

        },
        cvv: {
            pattern: "^[0-9]{3,4}$",
            text: 'CVV',
            required: true,
            valid: true,
        }
    }));

    useEffect(() => {
        setFields((prev) => ({ ...prev, number: getCardNumber({ current_agency_card, current_user_card }) }))
    }, [current_agency_card, current_user_card])

    const isFormValid = () => {
        const errors = {};
        const newValidations = { ...validations };
        let isValid = true;
        Object.keys(newValidations).forEach((item) => {
            const { pattern, text, required } = newValidations[item]
            const regex = new RegExp(pattern);
            const result = regex.test(
                item === "number"
                    ? fields[item].replace(/ /g, '')
                    : fields[item]);
            errors[item] = [];
            newValidations[item].valid = true;
            if (!fields[item] && required) {
                errors[item] = [...errors[item], `${text} is required`]
                newValidations[item].valid = false;
                isValid = false;
            }
            if (!result) {
                errors[item] = [...errors[item], `${text} is not valid`]
                newValidations[item].valid = false;
                isValid = false;
            }
        });
        setFormErrors(errors)
        setValidations(newValidations);
        return isValid;
    }

    const getWarning = (data) => {
        if (Array.isArray(data)) {
            return data.join('. ');
        }

        if (data) {
            return data;
        }
        return "";
    }

    const handleChange = ({ target: { id, value } }) => {
        const field = id.replace('card_', "")

        let newValue = value
        if (field === "number") {
            if (!value.includes("**") && fields[field].length < newValue.length) {
                newValue = newValue.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
            }
        }
        setFields(prev => ({ ...prev, [field]: newValue }))
    }

    const authToken = () => { return $('meta[name="csrf-token"]').attr("content"); }

    const onSubmit = (event) => {
        event.preventDefault();
        if (!isFormValid()) {
            console.debug("Form is invalid!")
            return
        }

        const formData = new FormData(form.current);
        formData.set('card[number]',
            formData.get('card[number]')
                .replace('*', '')
                .replace(/\W/gi, '')
        )

        fetch(`${billing_url}`, {
            body: formData,
            method: "POST",
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error("Not success");
                }

                return response.json();
            })
            .then(({ errors = {} }) => {
                setFormErrors(errors)
            })
            .catch(console.debug);
    }
    return (
        <form
            className="edit_billing"
            id="edit_card_2"
            action="/update_billing?tab=billing"
            acceptCharset="UTF-8"
            method="post"
            ref={form}
            onSubmit={onSubmit}
        >
            <input
                type="hidden"
                name="authenticity_token"
                value={authToken()}
            />
            <div className="settings-card-container">
                <h3>
                    Card Editting
                </h3>
                <div className='cardedit-wrapper'>
                    <div className='cardwrapper-input'>
                        <label htmlFor="card_holder_name">Cardholder Name</label>
                        <input
                            id="card_holder_name"
                            placeholder="Jeffrey Pine"
                            style={!validations.holder_name?.valid ? styles.error : {}}
                            type="text"
                            // pattern="^[a-zA-Z\s]*$"
                            title="Name should only contain letters"
                            name="card[holder_name]"
                            value={fields.holder_name}
                            onChange={handleChange}
                        />
                        <Warning data={getWarning(formErrors?.holder_name)} />
                    </div>
                    <div className='cardwrapper-input'>
                        <label htmlFor="card_number" className='card-number-label'>Card Number</label>
                            <input
                                id="card_number"
                                className='card_number'
                                style={!validations.number?.valid ? styles.error : {}}
                                placeholder="0000 0000 0000 0000"
                                // value="************4242"
                                type="text"
                                data-stripe="number"
                                // pattern="^[0-9\s]+$"
                                title="Please enter only numbers"
                                name="card[number]"
                                value={fields.number}
                                onChange={handleChange}
                            />
                            <span className='card_types'>
                                Visa, MasterCard, American Express, Discover, JCB, and Diners Club accepted.
                            </span>
                        <Warning data={getWarning(formErrors?.number)} />
                    </div>
                    <div className='cardwrapper-input'>
                        <div className="settings-card-container-third">
                            <div className='selectwrapper'>
                                <div className="Third first">
                                    <label htmlFor="card_exp_month">Expiration Month</label>
                                    <select
                                        id="expiration_month"
                                        data-stripe="exp_month"
                                        name="card[exp_month]"
                                        value={fields.expiration_month}
                                        onChange={handleChange}
                                    >
                                        {
                                            props.months_with_index.map(
                                                ([month, index]) => (
                                                    <option value={index}>{month}</option>
                                                )
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="Third second">
                                    <label htmlFor="card_exp_year">Expiration Year</label>
                                    <select
                                        id="expiration_year"
                                        data-stripe="exp_year"
                                        name="card[exp_year]"
                                        value={fields.expiration_year}
                                        onChange={handleChange}
                                    >
                                        {
                                            fields.card_years.map(
                                                year => (
                                                    <option value={year}>{year}</option>
                                                ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="Third">
                                <label htmlFor="card_cvv">CVV</label>
                                <input
                                    id="cvv"
                                    placeholder=""
                                    style={!validations.cvv?.valid ? styles.error : {}}
                                    type="text"
                                    data-stripe="cvc"
                                    // pattern="^[0-9]{3,4}$"
                                    //title="Please enter correct value"
                                    name="card[cvv]"
                                    value={fields.cvv}
                                    onChange={handleChange}
                                />
                                <Warning data={getWarning(formErrors?.cvv)} />
                            </div>                          
                        </div>
                    </div>
                    <div className='cardwrapper-input cardwrapper-button'>
                        <input
                            type="submit"
                            name="commit"
                            value="Save Changes"
                            className="Button green-button"
                            //data-disable-with="Save"
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default BillingForm;
