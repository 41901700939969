import React, { useState } from 'react';
import SubscriptionPremiumTypes from './SubscriptionPremiumTypes';
import SubscriptionTable from './SubscriptionTable';
import cn from 'classnames';
import { premiumSubscriptionTypeByName } from './utils';

const NoPremiumForm = ({ data, upgrade }) => {
    const premium = data.current_user?.['premium?'];
    const subscription_name = data.subscription_name
    if (premium) {
        return null;
    }
    const [premiumType, setPremiumType] = useState(() => premiumSubscriptionTypeByName(subscription_name))
    const [isSubmitting, setIsSubmitting] = useState(false)
    const upgradeAccount = () => {
        setIsSubmitting(true)
        upgrade(premiumType).finally(() => setIsSubmitting(false))
    }


    return (
        <>
            <div className="settings-premium-container">
                <div className="premium-description-text-container">
                    <h2>
                        Premium Subscription
                    </h2>
                    <p className="premium-description">
                        Premium Subscription permits you to save your time and automate your mailing.
                    </p>
                    <SubscriptionPremiumTypes
                        premiumType={premiumType}
                        setPremiumType={setPremiumType}
                        centered={false}
                    />
                </div>
                <div className="Clear"></div>
                <div>
                    <button
                        className={cn("green-button green-button-large-font", { disabled: data.new_record || isSubmitting })}
                        onClick={upgradeAccount}
                    >
                        Upgrade to Premium
                    </button>

                    {data.new_record && <p className="premium-description premium-description-last">
                        In order to buy Premium you need to provide your Credit Card data in the <a href="/settings/?tab=billing">Billing tab</a> first!
                    </p>}
                </div>
            </div>
            <SubscriptionTable />
        </>
    )

};

export default NoPremiumForm;
