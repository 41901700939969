import React, { useState, useEffect } from "react";

import { Dropdown, Input, Modal } from "semantic-ui-react";
import _ from "lodash";
import * as Papa from "papaparse";

import CustomListMapping from "../automations/components/CustomListMapping";
import IntegrationPage from "./components/IntegrationPage";
import CustomFieldsPage from "./components/CustomFieldsPage";
import SettingsModal from "./components/SettingsModals";
import XLSX from "xlsx";

import ArrowLeftGreen from "../../../assets/images/arrow-left-green.svg";
import TableImage from "../../../assets/images/table.png";
import { getData } from "../../lib/fetches";

function ContactsSettings(props) {
  const { goBack, page, token, allLists, user } = props;

  const downloadSample = () => {
    window.location.href = `/download_template`;
  };

  const downloadSampleXlsx = () => {
    window.location.href = `/download_template_xlsx`;
  };

  useEffect(() => {}, []);

  const [sampleData, setSampleData] = useState([]);
  const [defaultPositions, setDefaultPositions] = useState({});
  const [listObject, setListObject] = useState({});
  const [listNameChosen, setlistNameChosen] = useState(false);
  const [modalPopUp, setModalPopUp] = useState(false);
  const [listNameError, setListNameError] = useState(false);
  const [listFilled, setListFilled] = useState(false);
  const [listError, setListError] = useState(false);
  const [takenListNameError, setTakenListNameError] = useState(false);
  const [listNameErrorType, setListNameErrorType] = useState("");
  const [fileName, setFileName] = useState("Select a .CSV or .XLSX file");
  const [saveButton, setSaveButton] = useState("Save");
  const [modalContext, setModalContext] = useState("");
  const [file, setFile] = useState("");
  const [listOption, setListOption] = useState("");
  const [name, setName] = useState("");

  const handleDefaultPositions = (newDefaultPositions) => {
    let newSaveDisabled = !isMappingsValid();

    setDefaultPositions(newDefaultPositions);
    setSaveDisabled(newSaveDisabled);
  };

  const openModalPopUp = (boolean, type) => {
    setModalContext(type);
    setModalPopUp(boolean);
  };

  const handleNewListName = (e) => {
    setName(e.target.value);
    if (e.target.value !== "") {
      setlistNameChosen(true);
      setListNameError(false);
      setTakenListNameError(false);
      setListNameErrorType("");
    } else {
      setlistNameChosen(false);
    }
  };

  const saveList = () => {
    setSaveButton("Saving ...");
    const finished = () => {
      setSaveButton("✓");

      setTimeout(() => {
        setSaveButton("Uploaded");
      }, 2000);
    };

    const rejected = (e) => {
      console.log(e);
      setListError(true);
    }

    let formData = new FormData();

    if (!isMappingsValid()) {
      throw new Error("Required Data Missing!");
      return;
    }

    if (listObject.value !== undefined) {
      formData.append("address_book_id", listObject.value);
    }
    formData.append("file", file);
    formData.append("name", name);
    formData.append("mappings", JSON.stringify(defaultPositions));
    fetch(`/process_list`, {
      body: formData,
      method: "POST",
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        if (data.errors && data.errors.name[0]) {
          setTakenListNameError(true);
          setListNameErrorType(data.errors.name[0]);
        } else {
          openModalPopUp(true, "importSuccess");
          setListError(false);
          setListNameError(false);
        }
      })
      .then(finished)
      .catch(rejected);
  };

  const isMappingsValid = () => {
    const presentKeys = Object.keys(defaultPositions);

    const requiredKeys = ["addressLine1", "city", "state", "postalCode"];

    const gotRequiredKeys = requiredKeys.every((k) => k in defaultPositions);

    const gotRequiredNameKeys = (presentKeys.includes("firstName") && presentKeys.includes("lastName")) || presentKeys.includes("businessName");

    return gotRequiredKeys && gotRequiredNameKeys;
  };

  const acceptedFileFormats = () => {
    return [
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ].join(", ");
  };

  const fileOnSelect = (file) => {
    if (!file) return console.log("No file found!");

    const fileExtension = file.name.split(".").pop();
    if (fileExtension === "csv") {
      Papa.parse(file, {
        complete: (results) => {
          setSampleData(results.data);
          setFileName(file.name);
          setFile(file);
        },
      });
    } else if (["xlsx", "xls"].includes(fileExtension)) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let binaryString = e.target.result;
        let workBook = XLSX.read(binaryString, { type: "binary" });
        let first_worksheet = workBook.Sheets[workBook.SheetNames[0]];
        let data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        setSampleData(data);
        setFileName(file.name);
        setFile(file);
      };
      reader.readAsBinaryString(file);
    } else {
      throw new TypeError(`${file.type} File Type not supported.`, file.name);
    }
  };

  const handelSaveList = () => {
    listFilled ? saveList() : setListError(true);
  };

  const handleListOptionsOnSave = (data) => {
    if (data.value !== "new list") {
      setListOption("");
      const listId = data.value;
      const lists = data.options;
      let chosenList = lists.filter((elem) => elem.value === listId);
      setName(chosenList[0].text);
      setListObject(...chosenList);
      setlistNameChosen(true);
      setListNameError(false);
    } else {
      setListOption(data.value);
      setListObject({});
    }
  };

  return (
    <>
      <div className="arrowbackbutton">
        <div className="arrow-back-green" onClick={() => goBack("")}>
          <img src={ArrowLeftGreen} />
          Back to all contacts
        </div>
      </div>
      {page === "import" && (
        <>
          <header className="PageHeader contacts page-settings">
            <div className="contacts-page-small-block">
              <h1 className="contacts-table-title">Import contacts</h1>
              <p className="contacts-page-subtitle">
                Add contacts to your Mailfold database. You can either mail all
                of your contacts or organize them into specific mailing lists.
              </p>
              <p className="contacts-page-subtitle">
                * Required fields: First name & Last name or Full name, Address 1, City, State, Postal code
              </p>
              <div className="contactsettting-buttons">
                <div className="import-breadcrumb">
                  <span onClick={() => downloadSample()}>
                    Download sample CSV template
                  </span>
                  {/* <span>Learn More</span> */}
                </div>
                <div className="import-breadcrumb">
                  <span onClick={() => downloadSampleXlsx()}>
                    Download sample XLSX template
                  </span>
                </div>
              </div>
            </div>
            <div>
              <img src={TableImage} />
            </div>
          </header>
          <div className="contacts-page-main border-top">
            <div className="contacts-page-small-block">
              <h3>
                <strong>Select your CSV or XLSX file below</strong>
              </h3>
              <p className="contacts-page-breadcrumb">
                We’ll add your contacts to your contact database
              </p>
              <div className="contacts-import-cover">
                <input
                  placeholder="Select a File"
                  type="file"
                  key="file"
                  id="fileInput"
                  accept={acceptedFileFormats()}
                  onChange={(e) => {
                    fileOnSelect(e.target.files[0]);
                  }}
                />
                <div className="recipients-block-cover">
                  <div className="recipients-block-input">
                    <label className="recipients-label import">
                      Mailing list file
                    </label>
                    <div className="FilenameDisplay recipents-input">
                      {fileName}
                    </div>
                  </div>
                    <label className="FileInputButton" htmlFor="fileInput">
                      Choose File
                    </label>
                </div>
              </div>
              {file && listError && (
                <div className="contacts-error-flash contacts-error-flash-fields">
                  Something went wrong. Please try again.
                </div>
              )}
              <fieldset className="WideListOptions contacts">
                <If condition={sampleData.length !== 0}>
                  <h4 className="WideListOptions title">Define column names</h4>
                  <CustomListMapping
                    sampleData={sampleData}
                    setDefaultPositions={setDefaultPositions}
                    defaultPositions={defaultPositions}
                    setListFilled={setListFilled}
                  />
                  {/* <input
                    type="submit"
                    name="commit"
                    value={saveButton}
                    className="Button ExitButton recipients"
                    onClick={saveList}
                    disabled={saveDisabled || fileUploaded}
                  /> */}
                </If>
              </fieldset>
            </div>
            <div className="contacts-page-small-block">
              <h3>
                <strong>Add contacts to a mailing list (optional)</strong>
              </h3>
              <p className="contacts-page-breadcrumb">
                You can always add contacts to a list later
              </p>
              <div className="contacts-modal-input-cover import">
                <h4 className="contacts-tab-item-title import ">
                  Belong to List
                </h4>
                <Dropdown
                  className="segment-modal-input import"
                  placeholder="lists"
                  options={allLists}
                  selection
                  onChange={(event, data) => {
                    handleListOptionsOnSave(data);
                  }}
                />
                {listNameError && (
                  <div className="contacts-error-flash">
                    Please choose the list to import contacts !
                  </div>
                )}
                {listNameErrorType !== "" ? (
                  <h4 className="list-error import">
                    {`This name ${listNameErrorType}`}
                  </h4>
                ) : null}
                {listOption === "new list" && (
                  <input
                    className={`segment-modal-input import ${
                      takenListNameError ? "field-error" : null
                    }`}
                    placeholder="List Name"
                    value={name}
                    onChange={(e) => handleNewListName(e)}
                  />
                )}
                <button
                  className={`contacts-page-button ${
                    listNameChosen && file ? "green" : "disabled"
                  }`}
                  onClick={() =>
                    listNameChosen ? handelSaveList() : setListNameError(true)
                  }
                >
                  Import contacts
                </button>
                <p className="contacts-breadcrumb">
                  With a free account, you can’t add more than 5000 contacts. If
                  you want to add more upgrades your account to premium.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {page === "integration" && (
        <IntegrationPage
          openModalPopUp={openModalPopUp}
          user={user}
          token={token}
        />
      )} */}
      {page === "customField" && (
        <CustomFieldsPage openModalPopUp={openModalPopUp} {...props} />
      )}
      <Modal
        open={modalPopUp}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        className="contacts-segment-modal"
        onClose={() => {
          window.location.href = "/contacts";
          setModalPopUp(false);
        }}
      >
        <SettingsModal
          modalContext={modalContext}
          openModalPopUp={openModalPopUp}
          isPremiumUser={user['premium?']}
        />
      </Modal>
    </>
  );
}

export default ContactsSettings;
