import React from 'react'
import PropTypes from 'prop-types'

export default class TemplateForm extends React.Component {
  static propTypes = {
    canvasJson: PropTypes.func,
    fromJson: PropTypes.func,
    setElementPrivacy: PropTypes.func,
    setElementType: PropTypes.func,
    setTemplateName: PropTypes.func
  }

  exportToJson = () => {
    this.refs.templateJson.value = JSON.stringify(this.props.canvasJson())
  }

  loadFromJson = () => {
    if(this.refs.templateJson.value)
      this.props.fromJson(this.refs.templateJson.value)
  }

  render() {
    return (
      <section className="AllProperties">
        <div className="clear"></div>
        <select ref="elementPrivacy" className="TwoThirds"
                onChange={() => {this.props.setElementPrivacy(this.refs.elementPrivacy.value)}}>
          <option value="true">Private</option>
          <option value="false">Public</option>
        </select>
        <select ref="elementType" className="TwoThirds"
                onChange={() => {this.props.setElementType(this.refs.elementType.value)}}>
          <option value="Template">Template</option>
          <option value="Text">Text</option>
          <option value="Graphics">Graphics</option>
        </select>
        <input ref="templateName"
               type="text"
               placeholder="Name"
               className="TwoThirds"
               onChange={() => { this.props.setTemplateName(this.refs.templateName.value) }} />

        <textarea ref="templateJson" cols="40" rows="20" />
        <button className="TwoThirds" onClick={() => { this.exportToJson() }}>Export To Json</button>
        <button className="TwoThirds" onClick={() => { this.loadFromJson() }}>Load From Json</button>
        <br/>
        <br/>
        <br/>

      </section>
    )
  }
}
