import React, { useRef, useState } from "react";
import { Form, Button, Input, Header, Checkbox, Icon } from 'semantic-ui-react';
import { stylesFont } from "./QRCodeOptions";
import { contrast, getBase64FromUrl } from "./QRUtils";

const styles = {
   colorInput: {
      height: "48px",
      padding: "12px 10px",
   },
   input: {
      background: "#FFFFFF",
      border: "1px solid #D5D5D0",
      boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "2px",
      padding: "12px 10px",
      height: "48px",
      flex: "1",
   },
   fileInputName: {
      ...stylesFont.primary,
      flex: "1",
      background: "#FFFFFF",
      border: "1px solid #D5D5D0",
      boxSizing: "border-box",
      boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.1)",
      bordeRadius: "2px",
      padding: "12px 10px",
      marginRight: "16px",
      height: "48px",
      color: "#8B8B8E",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

   },
   fileInputBtn: {
      ...stylesFont.secondary,
      background: "#4B3650",
      borderRadius: "8px",
      padding: "12px 10px",
      color: "#FFFFFF",
      height: "48px",
      fontSize: "18px",
      textAlign: "center",
      letterSpacing: "-0.15px",
      whiteSpace: "nowrap",
   },
   fileInputLabel: {
      ...stylesFont.secondary,
      marginBottom: "6px",
   },
   colorWarning: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      color: 'rgba(255, 0, 0, 0.8)'
   }
}



const ColorContrastWarning = ({ contrast }) => {

   return contrast < 4.5
       ? <small style={styles.colorWarning}>  <Icon name='warning sign' /> Bad color contrast! </small>
       : null

}

const MainContent = ({ options, setOptions }) => {

   const [file, setFile] = useState(null);

   const fileInputRef = useRef(null);
   const handleChange = (e, { value, node }) => {
      setOptions(node, value);
   };

   const handleCancelFile = () => {
      setFile(null);
      setOptions("image", "");
   };
   const handleChangeFile = ({ target: { files } }) => {
      const image = files?.[0];
      setFile(image);
      const url = URL.createObjectURL(image);
      getBase64FromUrl(url).then(result => {
         setOptions("image", result);
         URL.revokeObjectURL(url);
      }).catch(console.log);

   };
   const handleClickFile = () => fileInputRef.current.click();

   return <Form widths="equal">

      <Form.Field>

         <Header size='medium' style={styles.fileInputLabel}>Upload logo</Header>
         <input
             ref={fileInputRef}
             key="image"
             type="file"
             name="image"
             node="image"
             hidden
             onChange={handleChangeFile}
         />
         <div className="buttons-container" style={{ display: "flex", alignItems: "center", flex: "1" }}>
            <div style={styles.fileInputName}>{file ? file.name : "Image file"}</div>
            {file
                ? <Button
                    onClick={handleCancelFile}
                    style={styles.fileInputBtn}>
                   Cancel
                </Button>
                : <Button
                    onClick={handleClickFile}
                    style={styles.fileInputBtn}>
                   Choose File
                </Button>}

         </div>
      </Form.Field>
      <Form.Input
          key="width"
          inline
          label="Width"
          type="number"
          name="width"
          node="width"
          min="100"
          max="500"
          value={options.width}
          onChange={handleChange}
          style={{ display: "flex" }}
          className="input-semantic-react"
      >
         <input style={styles.input} />
      </Form.Input>
      <Form.Input
          key="height"
          inline
          label="Height"
          type="number"
          name="height"
          node="height"
          min="100"
          max="500"
          value={options.height}
          onChange={handleChange}
          style={{ display: "flex" }}
          className="input-semantic-react"
      >
         <input style={styles.input} />
      </Form.Input>
      <Form.Input
          key="margin"
          inline
          label="Margin"
          type="number"
          name="margin"
          node="margin"
          min="0"
          max="100"
          value={options.margin}
          onChange={handleChange}
          style={{ display: "flex" }}
          className="input-semantic-react"
      >
         <input style={styles.input} />
      </Form.Input>
   </Form>
}

const dotsTypes = [
   { key: 'square', text: 'Square', value: 'square' },
   { key: 'dots', text: 'Dots', value: 'dots' },
   { key: 'rounded', text: 'Rounded', value: 'rounded' },
   { key: 'extra-rounded', text: 'Extra rounded', value: 'extra-rounded' },
   { key: 'classy', text: 'Classy', value: 'classy' },
   { key: 'classy-rounded', text: 'Classy rounded', value: 'classy-rounded' },
]

const DotsOptions = ({ options, setOptions }) => {

   // const [isSinglecolor, setIsSingleColor] = useState(true);
   const [colorContrast, setColorContrast] = useState(() => contrast(options.backgroundOptions.color, options.dotsOptions.color));

   const handleChange = (e, { value, node }) => {
      setOptions(node, value);
      if (node.includes('color')) {
         setColorContrast(contrast(options.backgroundOptions.color, value))
      }
   };

   // const handleChangeRadio = (e, { value, node }) => {
   //    console.log(node, value);
   //    //setIsSingleColor(e.target.checked);
   //    setOptions(node, value);
   // };

   return <Form widths="equal">
      <Form.Select
          label="Dots Type"
          options={dotsTypes}
          name="dotsOptions.type"
          node="dotsOptions.type"
          onChange={handleChange}
          value={options.dotsOptions.type}
          className="input-semantic-react"
      >
      </Form.Select>

      {/* <Form.Field inline>
         <Input
            type='radio'
            name='dots-color-type'
            node="dots-color-type"
            onChange={handleChangeRadio}
            value="single"
            checked={isSinglecolor}
         />
         <label style={{ paddingTop: "0" }}>Single color</label>
      </Form.Field> */}
      {/* <Form.Field inline>
         <Input
            type='radio'
            name='dots-color-type'
            node="dots-color-type"
            onChange={handleChangeRadio}
            value="gradient"
            checked={!isSinglecolor}
         />
         <label style={{ paddingTop: "0" }}>Color gradient</label>
      </Form.Field> */}
      <ColorContrastWarning contrast={colorContrast} />
      <Form.Input
          label="Dots Color"
          type="color"
          node="dotsOptions.color"
          name="dotsOptions.color"
          value={options.dotsOptions.color}
          onChange={handleChange}
          className="input-semantic-react"
      >
         <input style={styles.colorInput} />
      </Form.Input>
      {/* <Form.Group grouped label="Gradient Type">
         <Form.Field inline>
            <Input
               type='radio'
               name='dots-gradient-type'
               node="dots-gradient-type"
               // onChange={handleChange}
               value="linear"
               checked={options.dotsOptions.gradient?.type === "linear"}
            />
            <label style={{ paddingTop: "0" }}>Linear</label>
         </Form.Field>
         <Form.Field inline>
            <Input
               type='radio'
               name='dots-gradient-type'
               node="dots-gradient-type"
               // onChange={handleChange}
               value="radial"
               checked={options.dotsOptions.gradient?.type === "radial"}
            />
            <label style={{ paddingTop: "0" }}>Radial</label>
         </Form.Field>
      </Form.Group> */}

      {/* <Form.Group inline label="Gradient Type">
         <Form.Input
            fluid
            type="color"
            name="options.dotsOptions.gradient.colorStops[0].color"
            node="options.dotsOptions.gradient.colorStops[0].color"
            value={options.dotsOptions.gradient?.colorStops?.[0]?.color}
            onChange={handleChange} >
         </Form.Input>
         <Form.Input
            fluid
            type="color"
            name="options.dotsOptions.gradient.colorStops[1].color"
            node="options.dotsOptions.gradient.colorStops[1].color"
            value={options.dotsOptions.gradient?.colorStops?.[1]?.color}
            onChange={handleChange} >
         </Form.Input>
      </Form.Group> */}


      {/* <Form.Input
         inline
         label="Rotation"
         name="rotation"
         type="number"
         min="0"
         max="360"
         node="options.dotsOptions.gradient.colorStops.rotation"
         value="0"
         onChange={handleChange} >
      </Form.Input> */}
   </Form >
}

const cornersSquareStyles = [
   { key: 'none', text: 'None', value: 'none' },
   { key: 'square', text: 'Square', value: 'square' },
   { key: 'dot', text: 'Dot', value: 'dot' },
   { key: 'extra-rounded', text: 'Extra rounded', value: 'extra-rounded' },

]
const CornersSquareOptions = ({ options, setOptions }) => {
   const [colorContrast, setColorContrast] = useState(() => contrast(options.backgroundOptions.color, options.cornersSquareOptions.color));
   const handleChange = (e, { value, node }) => {
      const newValue = value === 'none' ? '' : value;
      setOptions(node, newValue);
      if (node.includes('color')) {
         setColorContrast(contrast(options.backgroundOptions.color, value))
      }
   };
   return <Form widths="equal">
      <Form.Select
          label="Corners Square Style"
          options={cornersSquareStyles}
          name="cornersSquareOptions.type"
          node="cornersSquareOptions.type"
          onChange={handleChange}
          value={options.cornersSquareOptions.type || 'none'}
          className="input-semantic-react"
      >

      </Form.Select>
      <ColorContrastWarning contrast={colorContrast} />
      <Form.Input
          label="Corners Square Color"
          type="color"
          node="cornersSquareOptions.color"
          name="cornersSquareOptions.color"
          value={options.cornersSquareOptions.color}
          onChange={handleChange}
          className="input-semantic-react"
      >
         <input style={styles.colorInput} />
      </Form.Input>
   </Form>
}

const cornersDotStyles = [
   { key: 'none', text: 'None', value: 'none' },
   { key: 'square', text: 'Square', value: 'square' },
   { key: 'dot', text: 'Dot', value: 'dot' },
]
const CornersDotOptions = ({ options, setOptions }) => {
   const [colorContrast, setColorContrast] = useState(() => contrast(options.backgroundOptions.color, options.cornersDotOptions.color));
   const handleChange = (e, { value, node }) => {
      const newValue = value === 'none' ? '' : value;
      setOptions(node, newValue);
      if (node.includes('color')) {
         setColorContrast(contrast(options.backgroundOptions.color, value))
      }
   };
   return <Form widths="equal">
      <Form.Select
          label="Corners Dot Style"
          options={cornersDotStyles}
          name="cornersDotOptions.type"
          node="cornersDotOptions.type"
          onChange={handleChange}
          value={options.cornersDotOptions.type || 'none'}
          className="input-semantic-react"
      >

      </Form.Select>
      <ColorContrastWarning contrast={colorContrast} />
      <Form.Input
          label="Corners Dot Color"
          type="color"
          node="cornersDotOptions.color"
          name="cornersDotOptions.color"
          value={options.cornersDotOptions.color}
          onChange={handleChange}
          className="input-semantic-react"
      >
         <input style={styles.colorInput} />

      </Form.Input>
   </Form>
}

const BackgroundOptions = ({ options, setOptions }) => {

   const [colorContrasts, setColorContrasts] = useState(() => ({
      dotsOptions: contrast(options.backgroundOptions.color, options.dotsOptions.color),
      cornersSquareOptions: contrast(options.backgroundOptions.color, options.cornersSquareOptions.color),
      cornersDotOptions: contrast(options.backgroundOptions.color, options.cornersDotOptions.color)
   }))
   const handleChange = (e, { value, node }) => {
      setOptions(node, value);
      setColorContrasts({
         dotsOptions: contrast(value, options.dotsOptions.color),
         cornersSquareOptions: contrast(value, options.cornersSquareOptions.color),
         cornersDotOptions: contrast(value, options.cornersDotOptions.color)
      })
   };
   return <Form widths="equal">
      <ColorContrastWarning contrast={Math.min(...Object.values(colorContrasts))} />
      <Form.Input
          label="Background Color"
          type="color"
          node="backgroundOptions.color"
          name="backgroundOptions.color"
          value={options.backgroundOptions.color}
          onChange={handleChange}
          className="input-semantic-react"
      >
         <input style={styles.colorInput} />
      </Form.Input>

   </Form>
}

const ImageOptions = ({ options, setOptions }) => {
   const handleChange = (e, { value, node }) => {
      setOptions(node, +value);
   };
   const handleChangeCheckbox = (e, { node, value, checked }) => {
      setOptions(node, checked);
   };

   return <Form widths="equal">

      <Form.Field inline style={{ marginTop: "10px" }}>
         <Checkbox
             label="Hide Background Dots"
             className="checkbox-semantic-react"
             name='imageOptions.hideBackgroundDots'
             node='imageOptions.hideBackgroundDots'
             onChange={handleChangeCheckbox}
             checked={options.imageOptions.hideBackgroundDots}
             style={{ display: "flex", alignItems: "center" }}
         />
      </Form.Field>
      <Form.Input
          inline
          label="Image Size"
          name="imageOptions.imageSize"
          node="imageOptions.imageSize"
          type="number"
          min="0"
          max="1"
          step="0.1"
          value={options.imageOptions.imageSize}
          onChange={handleChange}
          style={{ display: "flex" }}
          className="input-semantic-react"
      >
         <input style={styles.input} />
      </Form.Input>
      <Form.Input
          inline
          label="Margin"
          node="imageOptions.margin"
          type="number"
          min="0"
          max="100"
          value={options.imageOptions.margin}
          onChange={handleChange}
          style={{ display: "flex" }}
          className="input-semantic-react"
      >
         <input style={styles.input} />
      </Form.Input>

   </Form>
}

const qrOptionsModes = [
   { key: 'numeric', text: 'Numeric', value: 'Numeric' },
   { key: 'alphanumeric', text: 'Alphanumeric', value: 'Alphanumeric' },
   { key: 'byte', text: 'Byte', value: 'Byte' },
   { key: 'kanji', text: 'Kanji', value: 'Kanji' },
]
const qrOptionsErrorCorrectionLevel = [
   { key: 'L', text: 'L', value: 'L' },
   { key: 'M', text: 'M', value: 'M' },
   { key: 'Q', text: 'Q', value: 'Q' },
   { key: 'H', text: 'H', value: 'H' },
]

const QROptions = ({ options, setOptions }) => {
   const handleChange = (e, { value, node }) => {
      setOptions(node, value);
   };

   return <Form widths="equal">
      <Form.Input
          inline
          label="Type Number"
          name="qrOptions.typeNumber"
          node="qrOptions.typeNumber"
          type="number"
          min="0"
          max="40"
          value={options.qrOptions.typeNumber}
          onChange={handleChange}
          style={{ display: "flex" }}
          className="input-semantic-react"
      >
         <input style={styles.input} />
      </Form.Input>
      <Form.Select
          label="Mode"
          options={qrOptionsModes}
          name="qrOptions.mode"
          node="qrOptions.mode"
          value={options.qrOptions.mode}
          onChange={handleChange}
          className="input-semantic-react"
      >
      </Form.Select>
      <Form.Select
          label="Correction Level"
          options={qrOptionsErrorCorrectionLevel}
          name="qrOptions.errorCorrectionLevel"
          node="qrOptions.errorCorrectionLevel"
          value={options.qrOptions.errorCorrectionLevel}
          onChange={handleChange}
          className="input-semantic-react"
      >
      </Form.Select>
   </Form>
}

export const getPanels = (options, setOptions) => ({

   mainOptions: <MainContent options={options} setOptions={setOptions} />,
   dotsOptions: <DotsOptions options={options} setOptions={setOptions} />,
   cornersSquareOptions: <CornersSquareOptions options={options} setOptions={setOptions} />,
   cornersDotOptions: <CornersDotOptions options={options} setOptions={setOptions} />,
   backgroundOptions: <BackgroundOptions options={options} setOptions={setOptions} />,
   imageOptions: <ImageOptions options={options} setOptions={setOptions} />,
   //qrOptions: <QROptions options={options} setOptions={setOptions} />,

})
