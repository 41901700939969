import React from 'react'

export default class Welcome extends React.Component {
  state = {
    visible: true
  }

  onClose() {
    this.setState({ visible: false })
  }

  render() {
    return (
      <If condition={this.state.visible}>
        <div className="Modal">
          <div className="ModalWindow">
            <div className="Illustration" id="Welcome"></div>
            <div className="ModalContent">
              <div className="Title">
                Let&rsquo;s Get Started!
              </div>
              <div className="Body">
                 You&rsquo;re ready to start your first campaign. If you need help, hit the green chat bubble and someone will be right with you.
              </div>
            </div>
            <div className="ModalActions">
              <div className="Buttons">
                <div onClick={this.onClose.bind(this)} className="Button PrimaryAction">
                  Okay
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ModalBackground"></div>
      </If>
    )
  }
}