import React from "react"
import PropTypes from "prop-types"

export default class ErrorMessage extends React.Component {
  static propTypes = {
    body: PropTypes.string.isRequired
  }

  render() {
    return (
      <div className="Errors Visible">
        <div className="ErrorMessage">
          <div className="Icon">
            <div className="SubIcon">
              <div className="Background"></div>
            </div>
          </div>
          <div className="Heading">
            Sorry.
          </div>
          <div className="Body">
            {this.props.body}
          </div>
        </div>
        <div className="Clear"></div>
      </div>
    )
  }
}
