import React from 'react'
import PropTypes from 'prop-types'

export default class TextAlignmentButtons extends React.PureComponent {
  static propTypes = {
    setTextAlign: PropTypes.func,
    setAlign: PropTypes.func,
    activeTextAlign: PropTypes.string
  }

  render() {
    return (
      <section className="AlignmentProperties">
        <h3>Text Alignment</h3>

        <div className="SegmentedControl">
          <label onClick={() => { this.props.setTextAlign('left')}}
                 className={this.props.activeTextAlign == 'left' ? 'SegmentOption Active' : 'SegmentOption'}
                 id="LeftAlignTextControl"
                 htmlFor="LeftAlignText" />
          <label onClick={() => { this.props.setTextAlign('center') }}
                 className={this.props.activeTextAlign == 'center' ? 'SegmentOption Active' : 'SegmentOption'}
                 id="CenterAlignTextControl"
                 htmlFor="CenterAlignText" />
          <label onClick={() => { this.props.setTextAlign('right') }}
                 className={this.props.activeTextAlign == 'right' ? 'SegmentOption Active' : 'SegmentOption'}
                 id="RightAlignTextControl"
                 htmlFor="RightAlignText" />
          <label onClick={() => { this.props.setTextAlign('justify') }}
                 className={this.props.activeTextAlign == 'justify' ? 'SegmentOption Active' : 'SegmentOption'}
                 id="JustifyAlignTextControl"
                 htmlFor="JustifyAlignText" />
        </div>

        <div className="SegmentedControl">
          <label onClick={() => { this.props.setAlign('top') }}
                 className="SegmentOption"
                 id="TopAlignTextControl"
                 htmlFor="TopAlignText" />
          <label onClick={() => { this.props.setAlign('middle') }}
                 className="SegmentOption"
                 id="MiddleAlignTextControl"
                 htmlFor="MiddleAlignText" />
          <label onClick={() => { this.props.setAlign('bottom') }}
                 className="SegmentOption"
                 id="BottomAlignTextControl"
                 htmlFor="BottomAlignText" />
        </div>

        <div className="Clear"></div>
      </section>
    )
  }
}
