import React from "react";
import { Icon } from "semantic-ui-react";

const AlertPopup = ({closeAlertModal, type = "success" }) => {
  return (
    <div
        className={`contacts-segment-container small alert-cancel`}
    >
      <div className="close-icon" onClick={() => closeAlertModal()}>
      <Icon  name= {"close"}  Icon size='large'/>

      </div>

          <>
        <div className="contacts-segment-body">
          
          <Icon name= {type== "success" ? "check circle outline": 'exclamation circle'} Icon color={type== "success" ? "green": 'red'}   size='huge' />
              <h3 className="segment-modal-small-title">
              {type == "success" ? "Success":"Error" }
              </h3>
              <h4 className="segment-modal-small-subtitle">
              {type == "success" ? "Contacts have been synced successfully":"Please Disconnect & connect your account. Try Again " }
              </h4>
            </div>
            
          </>
    </div>
);
}

export default AlertPopup;

