import React, { Component } from "react";
import { Tab, Dropdown, Modal } from "semantic-ui-react";

import DatePicker from "react-datepicker";
import moment from "moment";
import CloseIcon from "../../../assets/images/close-gray.svg";
import ListInUseModal from "./components/ListInUseModal";
import { getData } from "../../lib/fetches";

class ContactModal extends Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      contact: this.props.contact,
      fullName: this.props.contact ? this.props.contact.business_name : "",
      firstName: "",
      lastName: "",
      email: this.props.contact ? this.props.contact.email : "",
      city: this.props.contact ? this.props.contact.city : "",
      state: this.props.contact ? this.props.contact.state : "",
      country: "US",
      postalCode: this.props.contact ? this.props.contact.postal_code : "",
      address: this.props.contact ? this.props.contact.address_line_1 : "",
      address2: this.props.contact ? this.props.contact.address_line_2 : "",
      validations: {
        firstName: {
          maxLength: 40,
          pattern: "^(.)*[A-Za-z]+(.)*$",
          required: true,
          valid: true
        },
        fullName: {
          maxLength: 40,
          pattern: "^(.)*[A-Za-z]+(.)*$",
          required: true,
          valid: true
        },
        email: {
          maxLength: 40,
          pattern: "^(.)*[A-Za-z]+(.)*$",
          required: true,
          valid: true
        },
        lastName: {
          maxLength: 40,
          pattern: "^(.)*[A-Za-z]+(.)*$",
          required: true,
          valid: true
        },
        address: {
          maxLength: 64,
          pattern: "^(.)*[A-Za-z]+(.)*$",
          required: true,
          valid: true,
        },
        address2: {
          maxLength: 64,
          pattern: "^(.)*[A-Za-z]+(.)*$",
          required: false,
          valid: true,
        },
        city: {
          maxLength: 40,
          pattern: "^[A-Za-z]*$",
          required: true,
          valid: true,
        },
        state: {
          maxLength: 40,
          pattern: "^[A-Za-z]*$",
          required: true,
          valid: true,
        },
        postalCode: {
          pattern: "",
          maxLength: 10,
          required: true,
          valid: true,
        }
      },

      newContactArray: [
        {
          key: "firstName",
          field: "First Name",
          value: "",
          placeholder: "Add First Name",
          type: "text",
        },
        {
          key: "lastName",
          field: "Last Name",
          value: "",
          placeholder: "Add Last Name",
          type: "text",
        },
        {
          key: "email",
          field: "Email",
          value: "",
          placeholder: "Add Email",
          type: "text",
        },
        {
          key: "address",
          field: "Address line 1",
          value: "",
          placeholder: "Add Address",
          type: "text",
        },
        {
          key: "address2",
          field: "Address line 2 (optional)",
          value: "",
          placeholder: "Add Address",
          type: "text",
        },
        {
          key: "city",
          field: "City",
          value: "",
          placeholder: "Add City",
          type: "text",
        },
        {
          key: "state",
          field: "State",
          value: "",
          placeholder: "Choose State",
          type: "text",
        },
        {
          key: "country",
          field: "Country",
          value: "US",
          placeholder: "US",
          type: "text",
        },
        {
          key: "postalCode",
          field: "Zip Code",
          value: "",
          placeholder: "Add Zip Code",
          type: "number",
        },
      ],
      selectedDate: "",
      customFieldOptions: [],
      customFieldsArray: [],
      modifiedCustomFields: [],
      list: "",
      listId: 0,
      listIdError: false,
      listInUseModal: false,
      usedCampaigns: []
    };
  }

  componentDidMount() {
    if (this.props.contact.hasOwnProperty("custom_dates")) {
      this.setCustomFieldsData(this.props.contact.custom_dates);
    }
    //this.updateValidations();
  }

  isValidField = (item) => {
    let result = true;

    if (this.props.contactStep !== 0) {
      if (item === 'fullName') {
        return result;
      }
    } else {
      if ('firstName, lastName, address2'.includes(item)) {
        return result;
      }
    }

    if (item in this.state) {
      const field = this.state.validations[item];
      if (!field) return result;
      const value = this.state[item];
      result = (field.required && !value)
          ? false
          : true;

      if (field.pattern && value) {
        const regex = new RegExp(field.pattern);
        if (!regex.test(value)) {
          // errors[item.filterValue] = `${text} is not valid`;
          result = false;
        }
      }
      return result;
    }
  }

  updateValidations = () => {
    const validations = { ...this.state.validations };
    let result = true;
    Object.keys(validations).forEach(item => {
      const valid = this.isValidField(item);
      validations[item].valid = valid;
      if (!valid) {
        result = false;
      }
    })
    this.setState({ validations })
    return result;
  }

  updateValidation = (item) => {
    const valid = this.isValidField(item);
    this.setState(prev => ({ validations: { ...prev.validations, [item]: { ...prev.validations[item], valid } } }));
  }

  setCustomFieldsData = (array) => {
    this.setState({
      customFieldOptions: array.map((item) => {
        let fieldOption = {
          key: item.name,
          value: item.id,
          text: item.name,
        };
        return fieldOption;
      }),
    });
  };

  setFieldForEdit = (array, id) => {
    const findedField = array.find((item) => item.id === id);
    const contactAddressesCustomFields = this.props.contact
        .addresses_custom_fields;
    const contactCustomFieldObj = contactAddressesCustomFields.find(
        (elem) => elem.custom_field && elem.custom_field.id === id
    );
    const newObj = {
      id: contactCustomFieldObj.id,
      value: findedField.value ? findedField.value : findedField.default_value,
      custom_field_id: id,
    };
    const newCustomFieldOptions = this.state.customFieldOptions.filter(
        (elem) => elem.value !== findedField.id
    );

    const findedFieldDateValue = findedField.value || findedField.default_value;
    const dateValue =
        findedField.field_type === "date" && findedFieldDateValue
            ? new Date(findedField.value)
            : "";
    this.setState({
      selectedDate: dateValue,
      customFieldOptions: [...newCustomFieldOptions],
      customFieldsArray: [...this.state.customFieldsArray, findedField],
      modifiedCustomFields: [...this.state.modifiedCustomFields, newObj],
    });
  };

  handleContactChange = (e, contactField) => {
    let contactKey = contactField;
    this.setState({
      [contactKey]: e.target.value,
      // [contactKey + 'Valid'] : e.target.value === 0 ? false : true
    });
    this.updateValidation(contactField);
  };

  settingCustomFields = (id, data) => {
    let fields = this.state.customFieldsArray;
    let updatedField = fields.find((elem) => {
      if (elem.id === id) {
        if (elem.field_type === "date") {
          this.setState({ selectedDate: data });
          elem.value = data ? moment(data).format("MM/DD/YYYY") : "";
        } else {
          elem.value = data.target.value;
        }
        return elem;
      }
    });
    let updatedArray = this.state.customFieldsArray.map((item) => {
      if (item.id === id) {
        item = updatedField;
      }
      return item;
    });
    let newArray = this.state.modifiedCustomFields.map((item) => {
      if (item.custom_field_id === updatedField.id) {
        item.value = updatedField.value;
      }
      return item;
    });
    this.setState({
      customFieldsArray: updatedArray,
      modifiedCustomFields: newArray,
    });
  };

  handleCustomFieldValue = (id, data) => {
    this.settingCustomFields(id, data);
    this.setState({ selectedDate: data });
  };

  editedContact = () => {
    let modifiedContact = {
      business_name: this.state.fullName,
      city: this.state.city,
      state: this.state.state,
      postal_code: this.state.postalCode,
      address_line_1: this.state.address,
      addresses_custom_fields_attributes: this.state.modifiedCustomFields,
    };
    return modifiedContact;
  };

  checkList = (id, obj) => {
    getData(
        `/contacts/${id}/check_list_usage`,
        this.props.authToken,
        (res) => {
          if (res.campaigns.length) {
            this.setState({
              usedCampaigns: res.campaigns,
              listInUseModal: true
            })
          } else {
            this.props.addContact(id, obj);
            this.props.openContactModal(false, null);
          }
        }
    );
  };

  acceptAdding = () => {
    let obj = this.addedContact();
    obj['with_charge'] = true
    this.setState({ listInUseModal: false })
    this.props.addContact(this.state.listId, obj);
    this.props.openContactModal(false, null);
  };

  addedContact = () => {
    let newContact = {
      business_name: this.state.firstName + " " + this.state.lastName,
      email: this.state.email,
      city: this.state.city,
      state: this.state.state,
      postal_code: this.state.postalCode,
      address_line_1: this.state.address,
      country: this.state.country,
    };
    if (this.state.address2) {
      newContact = { ...newContact, address_line_2: this.state.address2 };
    }
    return newContact;
  };

  formValid = () => {
    return this.updateValidations();
  }

  handleContactRequest = (id, obj, type) => {
    if (type === "edit") {
      if (this.formValid()) {
        this.props.updateContact(id, obj);
        this.props.openContactModal(false, null);
      }
    } else {

      const invalidContact = !this.updateValidations();
      this.setState({
        listIdError: this.state.listId !== 0 ? false : true,
      });
      if (!invalidContact && !this.state.listIdError && this.state.listId) {
        this.checkList(this.state.listId, obj)
      }
    }
  };

  newDateFormat = (date) => {
    let year = date.slice(0, 4);
    let month = date.slice(5, 7);
    let day = date.slice(8, 10);
    let rightDateFormat = `${day}/${month}/${year}`;
    return rightDateFormat;
  };
  
  handleNewContactChange = (data, field) => {
    let value = data.target.value;
    const array = this.state.newContactArray;
    let fieldInput = array.find((input) => {
      if (field === input.field) {
        this.setState({
          [input.key]: value,
        });
        input.value = value;
        //input.valid = true;
        return input;
      }
    });
    let newArray = this.state.newContactArray.map((item) => {
      if (item.field === fieldInput.field) {
        item = fieldInput;
      }
      return item;
    });
    this.setState({
      newContactArray: [...newArray],
    });
    console.log("hhh",this.state.newContactArray)
  };

  render() {
    const { selectedDate, validations } = this.state;
    return (
        <>
          <div className="contacts-segment-container">
            <div className="contacts-segment-body big">
              <div
                  className="segment-close-icon"
                  onClick={() => this.props.openContactModal(false, null)}
              >
                <img src={CloseIcon} />
              </div>
              <h3 className="segment-modal-title">
                Quick {this.props.contactStep === 0 ? "editing" : "add"} contact
              </h3>
              <h4 className="segment-modal-small-subtitle">
                {this.props.contactStep === 0
                    ? `Don't forget to save changes`
                    : `In order to add new contact, fill the form below:`}
              </h4>
              <div>
                {this.props.contactStep === 1 ? (
                    this.state.newContactArray.map((item, index) => {
                      return (
                          <div
                              key={index + 10000}
                              className="contacts-modal-input-cover"
                          >
                            <h4 className="contacts-tab-item-title">{item.field}</h4>
                            {item.field === "Country" ? (
                                <input
                                    className={`segment-modal-input`}
                                    placeholder={item.placeholder}
                                    value={item.value}
                                    disabled
                                />
                            ) : (
                                <input
                                    className={`segment-modal-input ${!validations[item.key].valid && "field-error"
                                    }`}
                                    placeholder={item.placeholder}
                                    value={item.value}
                                    onChange={(e) =>
                                        this.handleNewContactChange(e, item.field)
                                    }
                                    type={item.type}
                                    pattern={validations[item.key]?.pattern}
                                    maxLength={validations[item.key]?.maxLength}
                                />
                            )}
                          </div>
                      );
                    })
                ) : (
                    <>
                      <div className="contacts-modal-input-cover">
                        <h4 className="contacts-tab-item-title">Full Name</h4>
                        <input
                            className={`segment-modal-input ${!validations["fullName"].valid ? "field-error" : ""}`}
                            placeholder={this.props.contact.business_name}
                            value={this.state.fullName}
                            onChange={(e) => this.handleContactChange(e, "fullName")}
                            type="text"
                            pattern={validations["fullName"]?.pattern}
                            maxLength={validations["fullName"]?.maxLength}
                        />
                      </div>
                      <div className="contacts-modal-input-cover">
                        <h4 className="contacts-tab-item-title">City</h4>
                        <input
                            className={`segment-modal-input ${!validations["city"].valid ? "field-error" : ""}`}
                            placeholder={
                              this.props.contactStep === 0
                                  ? this.props.contact.city
                                  : "add City"
                            }
                            value={this.state.city}
                            onChange={(e) => this.handleContactChange(e, "city")}
                            type="text"
                            pattern={validations["city"]?.pattern}
                            maxLength={validations["city"]?.maxLength}
                        />
                      </div>
                      <div className="contacts-modal-input-cover">
                        <h4 className="contacts-tab-item-title">State</h4>
                        <input
                            className={`segment-modal-input ${!validations["state"].valid ? "field-error" : ""}`}
                            placeholder={
                              this.props.contactStep === 0
                                  ? this.props.contact.state
                                  : "choose State"
                            }
                            value={this.state.state}
                            onChange={(e) => this.handleContactChange(e, "state")}
                            type="text"
                            pattern={validations["state"]?.pattern}
                            maxLength={validations["state"]?.maxLength}
                        />
                      </div>
                      <div className="contacts-modal-input-cover">
                        <h4 className="contacts-tab-item-title">Address 1</h4>
                        <input
                            className={`segment-modal-input ${!validations["address"].valid ? "field-error" : ""}`}
                            placeholder={this.props.contact.address_line_1}
                            value={this.state.address}
                            onChange={(e) => this.handleContactChange(e, "address")}
                            type="text"
                            pattern={validations["address"]?.pattern}
                            maxLength={validations["address"]?.maxLength}
                        />
                      </div>
                      {this.props.contact.address_line_2 ? (
                          <div className="contacts-modal-input-cover">
                            <h4 className="contacts-tab-item-title">Address 2</h4>
                            <input
                                className={`segment-modal-input ${!validations["address2"].valid ? "field-error" : ""}`}
                                placeholder={this.props.contact.address_line_2}
                                value={this.state.address2}
                                onChange={(e) =>
                                    this.handleContactChange(e, "address2")
                                }
                                type="text"
                                pattern={validations["address2"]?.pattern}
                                maxLength={validations["address2"]?.maxLength}
                            />
                          </div>
                      ) : null}
                      <div className="contacts-modal-input-cover">
                        <h4 className="contacts-tab-item-title">Zip Code</h4>
                        <input
                            className={`segment-modal-input ${!validations["postalCode"].valid ? "field-error" : ""}`}
                            placeholder={this.props.contact.postal_code}
                            value={this.state.postalCode}
                            onChange={(e) =>
                                this.handleContactChange(e, "postalCode")
                            }
                            type="number"
                            pattern={validations["postalCode"]?.pattern}
                        />
                      </div>
                      <div className="contacts-modal-input-cover">
                        <h4 className="contacts-tab-item-title">Country</h4>
                        <input
                            className="segment-modal-input country"
                            placeholder={this.state.country}
                            disabled
                        />
                      </div>
                    </>
                )}
                {this.state.customFieldsArray.length > 0 &&
                    this.state.customFieldsArray.map((item, index) => {
                      const value = item.field_type === "date" && selectedDate;
                      return (
                          <div key={item.id} className="contacts-modal-input-cover">
                            <h4 className="contacts-tab-item-title">
                              {item.name}{" "}
                              {item.field_type === "date" && value
                                  ? moment(value).format("MM/DD/YYYY")
                                  : ""}
                            </h4>
                            {item.field_type === "date" ? (
                                <DatePicker
                                    className="custom-datepicker"
                                    closeOnScroll={true}
                                    dateFormat="MM/dd/yyyy"
                                    selected={selectedDate}
                                    onChange={(date) =>
                                        this.settingCustomFields(item.id, date)
                                    }
                                />
                            ) : (
                                <input
                                    className="segment-modal-input"
                                    value={item.value}
                                    onChange={(e) => {
                                      this.settingCustomFields(item.id, e);
                                    }}
                                    type={item.field_type}
                                />
                            )}
                          </div>
                      );
                    })}
                <div className="contacts-modal-input-cover">
                  {this.props.contactStep === 0 ? (
                      <>
                        {this.state.customFieldOptions.length < 1 ? null : (
                            <>
                              <h4 className="contacts-tab-item-title">
                                Custom fields
                              </h4>
                              <Dropdown
                                  className="segment-modal-input"
                                  placeholder="Custom fields"
                                  options={this.state.customFieldOptions}
                                  selection
                                  onChange={(event, data) => {
                                    this.setFieldForEdit(
                                        this.props.contact.custom_dates,
                                        data.value
                                    );
                                  }}
                              />
                            </>
                        )}
                      </>
                  ) : (
                      <>
                        <h4 className={`contacts-tab-item-title`}>
                          Belong to List
                        </h4>
                        <Dropdown
                            className={`segment-modal-input ${this.state.listIdError && "field-error"
                            }`}
                            placeholder="lists"
                            options={this.props.allLists}
                            selection
                            onChange={(event, data) => {
                              this.setState({
                                listId: data.value,
                                listIdError: false,
                              });
                            }}
                        />
                      </>
                  )}
                </div>
                <div className="segment-save-button">
                  <button
                      className="purple-button wd-100 "
                      onClick={() =>
                          this.props.contactStep === 0
                              ? this.handleContactRequest(
                                  this.props.contact.customer_id,
                                  this.editedContact(),
                                  "edit"
                              )
                              : this.handleContactRequest(
                                  this.state.listId,
                                  this.addedContact(),
                                  "add"
                              )
                      }
                  >
                    {this.props.contactStep === 0
                        ? `Save Changes`
                        : `Add contact`}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mod">
            <Modal
                open={this.state.listInUseModal}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                centered={true}
                className="contacts-segment-modal contact contacts-segment-modal-campaigns_list"
                onClose={() => this.setState({ listInUseModal: false })}
            >
              <ListInUseModal
                  campaigns={this.state.usedCampaigns}
                  user={this.props.user}
                  handleCloseModal={() => this.setState({ listInUseModal: false })}
                  acceptAdding={() => this.acceptAdding()}
              />
            </Modal>
          </div>
        </>
    );
  }
}

export default ContactModal;
