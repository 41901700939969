import React, { Component } from "react";
import { Grid, Pagination } from "semantic-ui-react";

class TablePagination extends Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      activePage: 1,
      boundaryRange: 1,
      siblingRange: 0,
      showEllipsis: true,
      showPreviousAndNextNav: true,
    };
  }

  handleCheckboxChange = (e, { checked, name }) =>
    this.setState({ [name]: checked });

  handleInputChange = (e, { name, value }) => this.setState({ [name]: value });

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage });
    this.props.handleListsRequest(activePage, this.props.searchKey);
  };

  render() {
    const {
      activePage,
      boundaryRange,
      siblingRange,
      showEllipsis,
      showFirstAndLastNav,
      showPreviousAndNextNav,
      totalPages,
    } = this.state;

    return (
      <Grid columns={1}>
        <Grid.Column>
          <Pagination
            activePage={this.props.firstPage ? 1 : activePage}
            boundaryRange={boundaryRange}
            onPageChange={this.handlePaginationChange}
            size="mini"
            siblingRange={siblingRange}
            totalPages={this.props.pages}
            ellipsisItem={showEllipsis ? undefined : null}
            firstItem={showFirstAndLastNav ? undefined : null}
            lastItem={showFirstAndLastNav ? undefined : null}
            prevItem={showPreviousAndNextNav ? undefined : null}
            nextItem={showPreviousAndNextNav ? undefined : null}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

export default TablePagination;
