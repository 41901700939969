import React from 'react'
import PropTypes from "prop-types"

class PropertiesBar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {showPositioningLabel, showStyle, objectSelected, showPositioning,
      xAxis, yAxis, angle, objWidth, objHeight} = this.props.parentState

    const {showStyleButtons, showPositioningButtons, sendToBack, bringToFront,
      sendBackwards, bringForward, clone, removeSelected, setAlign, styleProperties,
      setAxis, setAngle, setDimension} = this.props

    const selectedStyleClass = showStyle ? "SegmentOption Selected" : "SegmentOption"
    const selectedArrangeClass = showPositioning ? "SegmentOption Selected" : "SegmentOption"
    return (
      <aside className="PropertiesBar">
        <div className="StickyControl">
          <div className="SegmentedControl Tabs">
            <label onClick={() => {showStyleButtons()}}
                   className={selectedStyleClass}
                   id="StyleControl"
                   htmlFor="StyleDisplay">
              <span>Style</span>
            </label>
            <If condition={showPositioningLabel}>
              <label onClick={() => {showPositioningButtons()}}
                     className={selectedArrangeClass}
                     id="ArrangeControl"
                     htmlFor="ArrangeDisplay">
                <span>Arrange</span>
              </label>
            </If>
          </div>
        </div>

        <If condition={showStyle}>
          {styleProperties}
        </If>
        <If condition={showPositioning}>
          <div className="PropertiesBarContent StyleProperties">
            <If condition={objectSelected}>
              <section className="TwoLineContainer">
                <div className="TwoLineButton Input">
                  <input  value={xAxis}
                          type="number"
                          className="Third" ref="xAxis"
                          placeholder="x"
                          onChange={() => {setAxis('x', this.refs.xAxis.value)}} />
                  <span className="ButtonLabel">X</span>
                </div>
                <div className="TwoLineButton Input">
                  <input  value={yAxis}
                          type="number"
                          className="Third" ref="yAxis"
                          placeholder="y"
                          onChange={() => {setAxis('y', this.refs.yAxis.value)}} />
                  <span className="ButtonLabel">Y</span>
                </div>
                <div className="TwoLineButton Input">
                  <input  value={angle}
                          type="number"
                          className="Third" ref="angle"
                          placeholder="angle"
                          onChange={() => {setAngle(this.refs.angle.value)}} />
                  <span className="ButtonLabel">Rotation</span>
                </div>
                <div className="TwoLineButton Input">
                  <input  value={objWidth}
                          type="number"
                          className="Third" ref="objWidth"
                          placeholder="width"
                          onChange={() => {setDimension('width', this.refs.objWidth.value)}} />
                  <span className="ButtonLabel">Width</span>
                </div>
                <div className="TwoLineButton Input">
                  <input  value={objHeight}
                          type="number"
                          className="Third" ref="objHeight"
                          placeholder="height"
                          onChange={() => {setDimension('height', this.refs.objHeight.value)}} />
                  <span className="ButtonLabel">Height</span>
                </div>
              </section>
              <section className="TwoLineContainer">
                <h3>Order</h3>
                <div className="TwoLineButton SegmentButton">
                  <button onClick={() => {sendToBack()}}
                          className="HalfButton MoveToBackButton" />
                  <span className="ButtonLabel">Move to Back</span>
                </div>
                <div className="TwoLineButton SegmentButton">
                  <button onClick={() => {bringToFront()}}
                          className="HalfButton MoveToFrontButton" />
                  <span className="ButtonLabel">Move to Front</span>
                </div>
                <div className="TwoLineButton SegmentButton">
                  <button onClick={() => {sendBackwards()}}
                          className="HalfButton BackwardButton" />
                  <span className="ButtonLabel">Backwards</span>
                </div>
                <div className="TwoLineButton SegmentButton">
                  <button onClick={() => {bringForward()}}
                          className="HalfButton MoveToFrontButton" />
                  <span className="ButtonLabel">Forward</span>
                </div>
              </section>

              <div className="Clear"></div>

              <section>
                <h3>Alignment</h3>

                <div className="SegmentedControl">
                  <label onClick={() => {setAlign('left')}}
                         className="SegmentOption"
                         id="LeftAlignControl"
                         htmlFor="LeftAlign" />
                  <label onClick={() => {setAlign('center')}}
                         className="SegmentOption"
                         id="CenterAlignControl"
                         htmlFor="CenterAlign" />
                  <label onClick={() => {setAlign('right')}}
                         className="SegmentOption"
                         id="RightAlignControl"
                         htmlFor="RightAlign" />
                </div>

                <div className="Clear"></div>

                <div className="SegmentedControl">
                  <label onClick={() => {setAlign('top')}}
                         className="SegmentOption"
                         id="TopAlignControl"
                         htmlFor="TopAlign" />
                  <label onClick={() => {setAlign('middle')}}
                         className="SegmentOption"
                         id="MiddleAlignControl"
                         htmlFor="MiddleAlign" />
                  <label onClick={() => {setAlign('bottom')}}
                         className="SegmentOption"
                         id="BottomAlignControl"
                         htmlFor="BottomAlign" />
                </div>

                <div className="Clear"></div>

              </section>

              <div className="Clear"></div>

              <section className="TwoLineContainer">
                <div className="TwoLineButton">
                  <button onClick={() => {clone()}}
                          className="HalfButton DuplicateButton" />
                  <span className="ButtonLabel">
                    Duplicate
                  </span>
                </div>
                <div className="TwoLineButton">
                  <button onClick={() => {removeSelected()}}
                          className="HalfButton DeleteButton" />
                  <span className="ButtonLabel">
                    Delete
                  </span>
                </div>
              </section>
            </If>
          </div>
        </If>

        <div className="StickyFooter">
          <button
              className={["canvas-save-design transparent",
                this.props.parentState.savingTemplate
                    ? "Button OutlinedButton Disabled Loading"
                    : "Button OutlinedButton"
              ]}
              disabled={this.props.parentState.savingTemplate}
              onClick={() => {
                this.props.onOpenModal();
              }}
          >
            Save as Template
          </button>
        </div>
      </aside>
    )
  }
}

PropertiesBar.propTypes = {
  parentState: PropTypes.object,
  removeSelected: PropTypes.func,
  sendToBack: PropTypes.func,
  sendBackwards: PropTypes.func,
  bringToFront: PropTypes.func,
  bringForward: PropTypes.func,
  clone: PropTypes.func,
  showStyleButtons: PropTypes.func,
  showPositioningButtons: PropTypes.func
}

export default PropertiesBar
