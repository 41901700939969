import React, { useRef, useState } from 'react';
import cn from "classnames";
import Warning from './Warning';

const styles = {
    error: {
        border: "1px solid rgba(256, 0, 0, 0.8)",
    }
}

const AccountForm = (props) => {

    const form = useRef(null)
    const [error, setError] = useState("")
    const [clientErrors, setClientErrors] = useState(() => ({first_name: '', last_name: ''}))
    const [fields, setFields] = useState(() => ({ first_name: '', last_name: '', ...props }))


    const [validations, setValidations] = useState(() => ({
        first_name: {
            pattern: "^[a-zA-Z' _.-]+$",
            text: 'First name',
            required: true,
            valid: true,

        },
        last_name: {
            pattern: "^[a-zA-Z' _.-]+$",
            text: 'Last name',
            required: true,
            valid: true,
        },
    }));

    const getWarning = (data) => {
        if (Array.isArray(data)) {
            return data.join('. ');
        }

        if (data) {
            return data;
        }
        return "";
    }

    const isFormValid = () => {
        const errors = {};
        const newValidations = { ...validations };
        let isValid = true;
        Object.keys(newValidations).forEach((item) => {
            const { pattern, text, required } = newValidations[item]
            const regex = new RegExp(pattern);
            const result = regex.test(fields[item]);
            errors[item] = [];
            newValidations[item].valid = true;
            if (!fields[item] && required) {
                errors[item] = [...errors[item], `${text} is required`]
                newValidations[item].valid = false;
                isValid = false;
            }
            if (!result) {
                errors[item] = [...errors[item], `${text} is not valid`]
                newValidations[item].valid = false;
                isValid = false;
            }
        });
        setClientErrors(errors)
        setValidations(newValidations);
        return isValid;
    }

    const handleChange = ({ target: { id, value } }) => {
        const field = id.replace("user_", "")
        setFields(prev => ({ ...prev, [field]: value }))
    }

    const authToken = () => { return $('meta[name="csrf-token"]').attr("content"); }

    const onSubmit = (event) => {
        event.preventDefault();

        if (!isFormValid()) {
            console.debug("Form is invalid!")
            return
        }

        const formData = new FormData(form.current);
        setError('');
        fetch(`/update_account`, {
            body: formData,
            method: "POST",
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error("Not success");
                }
                if (response?.redirected) {
                    return { url: response.url };
                }
                return response.json();
            })
            .then(({ error = "", url = "" }) => {

                if (url) {
                    window.location.href=url;
                    return;
                }
                if (error) {
                    setError(error)
                }

            })
            .catch(console.debug);

    }
    return (
        <form
            className="edit_user"
            id="edit_user_5"
            ref={form}
            action="/update_account"
            acceptCharset="UTF-8"
            method="post"
            onSubmit={onSubmit}
        >
            <input type="hidden" name="authenticity_token" value={authToken()} />

            <fieldset>
                <h2>About You</h2>
                <div className='aboutyou-wrapper'>
                    <div className='aboutwrapper-input'>
                        <Warning data={error} />
                        <label htmlFor="user_first_name">First Name</label>
                        <input
                            type="text"
                            value={fields.first_name}
                            name="user[first_name]"
                            id="user_first_name"
                            style={!validations.first_name.valid ? styles.error : {}}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='aboutwrapper-input'>
                        <Warning data={getWarning(clientErrors?.first_name)} />
                        <label htmlFor="user_last_name">Last Name</label>
                        <input
                            type="text"
                            value={fields.last_name}
                            name="user[last_name]"
                            id="user_last_name"
                            style={!validations.last_name.valid ? styles.error : {}}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='aboutwrapper-input'>
                        <Warning data={getWarning(clientErrors?.last_name)} />
                        <label htmlFor="user_email">Email</label>
                        <input
                            autoComplete="email"
                            disabled
                            type="text"
                            value={fields.email}
                            name="user[email]"
                            id="user_email"
                        />
                    </div>
                    <div className="SubmitContainer">
                        <input
                            type="submit"
                            name="commit"
                            value="Update"
                            className="Button PurpleSubmit"
                            // data-disable-with="Update"
                        />
                    </div>
                </div>
            </fieldset>
        </form>
    );
};

export default AccountForm;
