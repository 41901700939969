import React from "react";
import AutomationImg from "../../../assets/images/automation-icon.jpg";

class AutomationsNotAllowed extends React.Component {
  constructor(props) {
    super(props);
  }

  redirectToPlanSettings() {
    window.location.href = "/settings/?tab=premium";
  }

  render() {
    // count will be from props this.props.count
    const count = 100;
    return (
      <div>
        <div className="Content PageContent FlexContent">
          <main className="Automation">
            <header className="PageHeader">
              <div className="AutomationHeader">
                <h1>Automation</h1>
              </div>
              <div className="Clear"></div>
              <div className="AutomationDescription">
                Automate communication with new customers. When automated mail
                is enabled, Mailfold syncs with your Etsy shop daily. If new
                customers match your defined rules, Mailfold will send them
                mail.
              </div>
            </header>
            <div className="Clear"></div>
            <div className="AutomationDescriptionBlock">
              <div className="AutomationSubItem">
                <div className="Title">Requires Premium Plan</div>
                <div className="Clear"></div>
                <div className="AutomationDescription w300">
                  You’ll need to upgrade to Mailfold’s Premium Plan starting from{" "}
                  $99 per month to
                  start using Automated Mail. Once upgraded, you can set rules
                  for sending automated mail to new customers.
                </div>
                <div className="AutomationDescription w300">
                  With a premium plan, you have access to add more than 5000 contacts. Plan includes all postcard sizes.
                </div>
                <div className="margin-top-s">
                  <button
                    className="green-bordered-button"
                    onClick={this.redirectToPlanSettings}
                  >
                    Upgrade now
                  </button>
                </div>
              </div>
              <div className="AutomationRectangle">
                <img
                  src={AutomationImg}
                />
              </div>
            </div>
          </main>
          <div className="AutomationSidebar AutomationSidebarUser">
            <div className="SidebarBody">
              <div className="UpgradeBlock">
                <div className="Description">
                  <p className="upgrade-title">
                    Upgrade to Premium starting from{" "}
                    $99 per month
                  </p>
                  This feature requires the Premium Plan.
                </div>
                <div className="UpgrateButton">
                  <button onClick={this.redirectToPlanSettings}>Upgrade</button>
                </div>
              </div>
              <div className="AutomationDescription">
                Mailfold will send you an email at the end of the week with a
                receipt and a summary of the activity on your account. Sales are
                synced at the end of the day, 11:59 PM Eastern Time.
              </div>

              <div className="Rules"></div>
            </div>
            <div className="AutomationSidebarBottom automations">
              <p>
                Your business is responsible for planting {count / 10} new trees
                with {count / 1000} days worth of employment.
                <div className="Clear"></div>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutomationsNotAllowed;
