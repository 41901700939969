const styles = {
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    note: {
        fontWeight: '500',
        color: '#B3B3B5'
    }
}


const SubscriptionPremiumTypes = ({ premiumType, setPremiumType, currentPlan = -1, centered = true }) => {

    const handleChange = ({ target: { value } }) => {
        setPremiumType(+value)
    }

    return (
        <div className="premium-subscribe-radio-group" style={centered ? styles.centered : null}>
            <div className="RadioRow premium-subscribe-radio-option">
                <input
                    checked={premiumType === 0}
                    className="premium-subscribe-input"
                    id="PremiumSubscribe1"
                    name="SettingsPremium"
                    type="radio"
                    value={0}
                    onChange={handleChange}
                />
                <label
                    className="RadioButton"
                    htmlFor="PremiumSubscribe1"></label>
                <label
                    className="premium-subscribe-label"
                    htmlFor="PremiumSubscribe1">
               <span className="premium-radio-main-label">
                  Monthly Subscription { }
                   {currentPlan === 0 && <span style={styles.note}>(current plan)</span>}
               </span>
                    <p className="premium-subscribe-tip">
                        $199 per month
                    </p>
                </label>
            </div>
            <div className="RadioRow premium-subscribe-radio-option">
                <input
                    checked={premiumType === 1}
                    className="premium-subscribe-input"
                    id="PremiumSubscribe2"
                    name="SettingsPremium"
                    type="radio"
                    value={1}
                    onChange={handleChange}
                />
                <label
                    className="RadioButton"
                    htmlFor="PremiumSubscribe2"></label>
                <label
                    className="premium-subscribe-label"
                    htmlFor="PremiumSubscribe2">
               <span className="premium-radio-main-label">
                  Annual Subscription { }
                   {currentPlan === 1 && <span style={styles.note}>(current plan)</span>}
               </span>
                    <p className="premium-subscribe-tip">
                        $99 per month
                    </p>
                </label>
            </div>
        </div>
    );
};

export default SubscriptionPremiumTypes;
