import React, { useState } from 'react';
import ConfirmSubscription from './ConfirmSubscription';
import SubscriptionPremiumTypes from './SubscriptionPremiumTypes';
import SubscriptionTable from './SubscriptionTable';
import { premiumSubscriptionTypeByName } from './utils';

const styles = {
    link: {
        fontSize: '14px',
        lineHeight: '24px',
        color: '#2a3b8d',
        textDecoration: 'none'
    }
}

const PremiumForm = ({ data, destroy, upgrade }) => {
    const premium = data.current_user?.['premium?'];
    const subscription_name = data.subscription_name
    if (!premium) {
        return null;
    }
    const [isEditing, setIsEditing] = useState(false);
    const [premiumType, setPremiumType] = useState(() => premiumSubscriptionTypeByName(subscription_name))
    const [showModal, setShowModal] = useState(false)

    const upgradeAccount = () => {
        return upgrade(premiumType);
    }

    const showConfirm = () => {
        if (premiumSubscriptionTypeByName(subscription_name) === premiumType) return;
        //upgrade(premiumType);
        setShowModal(true)
    }
    const destroyAccount = (e) => {
        e.preventDefault()
        destroy(premiumType);
    }

    const start_date = new Date(data.subscription_start_date)
        .toLocaleString("en-US", {
            day: "numeric",
            month: "long",
        })
        .split(' ').reverse().join(' ');

    const expiration_data = Math.ceil((new Date(data.subscription_expiration_date).getTime() - Date.now()) / (24 * 3600 * 1000));
    const premiumTypeText = premiumType === 0 ? "Monthly" : "Annual";
    const oldPremiumTypeText = premiumSubscriptionTypeByName(subscription_name) === 0 ? "Monthly" : "Annual";
    const premiumPeriod = premiumTypeText.toLowerCase() + (premiumTypeText.endsWith('ly') ? "" : "ly");
    return (<>
            {!isEditing
                ? (<div className="premium-description-block">
                        <div className="premium-description-text-container">
                            <h2 className="premium-title">
                                Your account is Premium
                            </h2>
                            <div className='premium-description-para'>
                                <p className="premium-description">
                                    Premium Subscription permits you to save your time and automate your mailing.
                                </p>
                                {/*
                                 <p className="premium-description">
                                     Your {premiumTypeText} Premium Subscription started on
                                     <b> {start_date} </b>
                                     and will renew {premiumPeriod}.
                                 </p>
                                 */}
                                <p className="premium-description">
                                    Your {premiumTypeText} Premium Subscription will be started on
                                    <b> {start_date} </b>
                                    and will renew {premiumPeriod}.
                                    Until then, you will use your previous subscription plan.
                                </p>

                                <p className="premium-description">
                                    If you want to stop Premium Subscription,
                                    <a rel="nofollow"
                                    href='#'
                                    onClick={destroyAccount}
                                    style={styles.link}
                                    > Click here</a>
                                </p>

                                {/* <p className="premium-description">
                                    Please note that your premium features will still be active until the expiration date, but the renewal of the subscription will be stopped after that date.
                                </p> */}
                            </div>
                            <div className="Clear"></div>

                            <button
                                className="green-bordered-button green-bordered-button-large-font"
                                onClick={() => setIsEditing(true)}
                            >
                                Change your subscription plan
                            </button>

                        </div>
                    </div>
                )
                : (<>
                    {
                        showModal ?
                            <ConfirmSubscription
                                showModal={showModal}
                                onClose={() => setShowModal(false)}
                                upgradeAccount={upgradeAccount}
                                title={`Change Subscription Plan`}
                                description={
                                    <>
                                        {`Your current ${oldPremiumTypeText} Subscription will be finished in `}
                                        <span style={{ fontWeight: 700 }}> {`${expiration_data < 0 ? 0 : expiration_data} days`}</span>
                                        {`. Are you sure that you want to change it to the ${premiumTypeText} Subscription?
                            Please note that your premium features from the current plan will still be active until the expiration date, but the renewal of the subscription will be changed after that date.`}
                                    </>
                                }
                            />
                            : (
                                <>
                                    <div className="premium-description-block">
                                        <div className="premium-description-text-container">
                                            <div className='selected-paradetail'>
                                                <h2 className="premium-title">
                                                    Premium Subscription
                                                </h2>
                                                <p className="premium-description">
                                                    Premium Subscription permits you to save your time and automate your mailing.
                                                </p>
                                            </div>
                                            <SubscriptionPremiumTypes
                                                premiumType={premiumType}
                                                setPremiumType={setPremiumType}
                                                currentPlan={!premium ? -1 : premiumSubscriptionTypeByName(subscription_name)}
                                                centered={false}
                                            />

                                            <div className="premium-buttons">
                                                <button
                                                    type="button"
                                                    className="green-button green-button-large-font"
                                                    onClick={showConfirm}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                    className="green-bordered-button green-bordered-button-large-font"
                                                    style={{ marginTop: '20' }}
                                                    onClick={() => setIsEditing(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <SubscriptionTable />
                                </>)}
                </>)}

        </>

    );
};

export default PremiumForm;
