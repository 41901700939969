import React, { Fragment, useState, useEffect } from "react";
import ClientItem from "./components/ClientItem";
import AddClientPopUp from "./components/AddClientPopUp";
import DelClientPopUp from "./components/DelClientPopUp";

import TrashIcon from "../../../assets/images/trash-icon.svg";
import AgencySidebar from "./components/AgencySidebar";

import { Modal } from "semantic-ui-react";
import { putData } from "../../lib/fetches";

export default function Clients({
  clients,
  total_values,
  agency,
  authToken,
  default_clients_amount
}) {
  const [checkboxAll, setCheckboxAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [addClient, setAddClientOpen] = useState(false);
  const [delClient, setDelClientOpen] = useState(false);
  const [clientsList, setClientsList] = useState(clients);
  const [totalValues, setTotalValues] = useState(total_values);
  const [currentAgency, setCurrentAgency] = useState(agency);

  const handleDeleteClient = (id, type) => {
    setDelClientOpen(true);
    selectItem(id, type);
  };

  const updateClientsList = (newClients) => {
    setClientsList(newClients);
  };

  const selectAllItems = (array, checkboxStatus) => {
    const status = checkboxStatus;
    const newArray = array.map((item) => {
      !status ? (item.checked = true) : (item.checked = false);
      return item;
    });
    const checkedItems = !status
      ? clientsList.map((item) => item.id)
      : [];
    setCheckboxAll(!status);
    setClientsList([...newArray]);
    setSelectedItems([...checkedItems])
  };

  const selectItem = (id, type) => {
    const array = clientsList;
    const selectedItemArray = selectedItems;
    const modifiedArray = array.map((item) => {
      if (item.id === id) {
        type === "checkbox"
          ? (item.checked = !item.checked)
          : (item.checked = true);
        item.checked === true
          ? selectedItemArray.push(item.id)
          : selectedItemArray.splice(selectedItemArray.indexOf(item.id), 1);
      } else {
        return item;
      }
      return item;
    });
    const selectedAllItems = selectedItemArray.length === array.length ? true : false;
    setCheckboxAll(selectedAllItems);
    setClientsList([...modifiedArray]);
    setSelectedItems([...selectedItemArray]);
  };

  const deleteItems = (ids) => {
    putData(`/agencies/${currentAgency.id}/remove_clients`, authToken, {
      client_ids: selectedItems
    }, (res) => {
      setCheckboxAll(false);
      setClientsList(res.clients);
      setTotalValues(res.total_values);
      setSelectedItems([]);
    });

  };
  return (
    <div className="Content PageContent">
      <main>
        <header className="agency-header">
          <h1 className="agency-header-title">Clients</h1>
        </header>
        <div className="agency-dashboard-container">
          <div className="agency-dashboard-header">
            <div className="agency-dashboard-header-left">
              <div className="agency-statistic-block">
                <span className="statistic-amount">{totalValues.campaigns}</span>
                <span className="statistic-desc">Total Campaigns</span>
              </div>
              <div className="agency-statistic-block">
                <span className="statistic-amount">{totalValues.contacts}</span>
                <span className="statistic-desc">Total Contacts</span>
              </div>
              <div className="agency-statistic-block">
                <span className="statistic-amount">{totalValues.automations}</span>
                <span className="statistic-desc">Total Automations</span>
              </div>
            </div>
            <div className="agency-dashboard-header-right">
              <button
                className="green-bordered-button"
                onClick={() => setAddClientOpen(true)}
              >
                + Add new Client
              </button>
            </div>
          </div>
          <table className="ui celled collapsing very basic table agency">
            <thead>
              <tr>
                <th className="agency-th">
                  <div className="ToggleContainer">
                    <input
                      type="checkbox"
                      onChange={() => selectAllItems(clientsList, checkboxAll)}
                      checked={checkboxAll}
                      name={"allClients"}
                      id={"allClients"}
                      value="1"
                    />
                    <label
                      className="CheckboxButton"
                      htmlFor={"allClients"}
                    ></label>
                  </div>
                  Username
                </th>
                <th>Contacts</th>
                <th>Campaigns</th>
                <th>Automation</th>
                <th>Plan</th>
                <th>Status</th>
                <th>
                  <img
                    src={TrashIcon}
                    alt="trash icon"
                    onClick={() => {
                      selectedItems.length && setDelClientOpen(true);
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {clientsList.length > 0 &&
                clientsList.map((item, index) => {
                  return (
                    <ClientItem
                      key={index}
                      selectItem={selectItem}
                      deleteItem={handleDeleteClient}
                      item={item}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        <Modal
          open={addClient}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => setAddClientOpen(false)}
        >
          <AddClientPopUp onClose={() => setAddClientOpen(false)} authToken={authToken} currentAgency={currentAgency} clientsCount={clientsList.length} default_clients_amount={default_clients_amount} updateClientsList={updateClientsList} setAgency={setCurrentAgency}/>
        </Modal>
        <Modal
          open={delClient}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => setDelClientOpen(false)}
        >
          <DelClientPopUp
            onClose={() => setDelClientOpen(false)}
            selectedItems={selectedItems}
            deleteItems={deleteItems}
          />
        </Modal>
      </main>
      <AgencySidebar />
    </div>
  );
}
