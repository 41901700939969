import React from 'react'

export default class CampaignsSidebar extends React.Component {
  render() {

    const count = this.props.campaigns.length

    let checkCurrState = true;
    this.props.allCampaigns.map((campaign) => {
      if(campaign.current_state === "In Transit" || campaign.current_state === "Paid" || campaign.current_state === "In Production" || campaign.current_state === "Out for Delivery") {
        checkCurrState = false
      }
    })

    return(
      <aside className="PageSidebar InfoSidebar Show">
        <div className="PageSidebarContents">
          <h2>
            <span className="Callout">{count}</span> Selected Rows
          </h2>
          {checkCurrState ?
            <div>
              <h3>Actions</h3>
              <a className="Button MediumPurpleButton" onClick={()=> this.props.deselectRowsCampaign(this.props.allCampaigns)}>Deselect Rows</a>
              <a className="Button MediumPurpleButton RedButton" onClick={()=> this.props.deleteSelectedCampaigns(this.props.campaigns)}>Delete Selected</a>
            </div>
            :
            null
          }

        </div>
      </aside>
    )


  }
}
