import React from "react";
import { fabric } from "fabric";
import { Modal, Dropdown } from "semantic-ui-react";
import $ from "jquery";
import canvasHelpers from "../shared/canvas-helpers";
import canvasGuidelines from "../shared/canvas-guidelines";
import canvasSetup from "../shared/canvas-setup";
import s3UploaderReact from "../shared/s3UploaderReact";
import imgixReact from "../shared/ImgixReact";
import SidebarButton from "../design_elements/SidebarButton";
import ToolTray from "../design_elements/ToolTray";
import PropertiesBar from "../design_elements/PropertiesBar";
import FontFaceObserver from "fontfaceobserver";
import TextAlignmentButton from "../design_elements/TextAlignmentButtons";
import TemplateForm from "../design_elements/TemplateForm";
import ColorPicker from "../design_elements/ColorPicker";
import FontFamilyOptions from "../design_elements/FontFamilyOptions";
import StyleProperties from "../design_elements/StyleProperties";
import { encodeXml, isQRcodeObject } from "../qr_codes/QRUtils";

const scaleUp = true;
const DPI = scaleUp ? 150 : 300;
const MULTIPLIER = 2;
const systemFonts = ["Arial", "Helvetica", "Times New Roman"];
const svgFonts = " <style type='text/css'>@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Arvo:400,700|Barlow|Bitter|Bree+Serif|Catamaran|Domine|Hind+Siliguri|Homemade+Apple|Kalam|Lobster|Lora|Montserrat:400,700|Mr+Dafoe|Pacifico|Patua+One|Permanent+Marker|Poppins|Questrial|Sacramento|Yantramanav|Zilla+Slab|Lato:400,700|Assistant:400,700|Arimo:400,700|Vollkorn:400,700|Yrsa:400,700|Ultra|Germania+One|Philosopher:400,700|Work+Sans:400,700|Viga:400,700|Alegreya+Sans:400,700|Eczar:400,700|Neuton:400,700')</style>";

class DesignCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.isLetter = this.props.cardSize === "8.5x11";
    let cardSize = this.props.cardSize || "4x6",
      bleedSize = 0.25,
      height =
        (parseFloat(cardSize.split("x")[this.isLetter ? 1 : 0]) +
          (this.isLetter ? 0 : bleedSize)) *
        (this.isLetter ? 96 : DPI),
      width =
        (parseFloat(cardSize.split("x")[this.isLetter ? 0 : 1]) +
          (this.isLetter ? 0 : bleedSize)) *
        (this.isLetter ? 96 : DPI),
      showBleed =
        JSON.parse(localStorage.getItem("showBleed")) == null
          ? false
          : JSON.parse(localStorage.getItem("showBleed")),
      showInkFreeZone =
        JSON.parse(localStorage.getItem("showInkFreeZone")) == null
          ? true
          : JSON.parse(localStorage.getItem("showInkFreeZone"));
    this.state = {
      cardSize: cardSize,
      canvasWidth: width,
      canvasHeight: height,
      updatedWidth: "",
      updatedHeight: "",
      left: width / 2,
      top: height / 2,
      fill: "",
      stroke: "",
      fontFamily: "",
      fontSize: "",
      charSpacing: "",
      lineHeight: "",
      textAlign: "",
      verticalAlign: "",
      backgroundColor: "rgba(255, 255, 255, 100)",
      backgroundImage: "",
      isUnderlined: "",
      isLinethrough: "",
      displayBGColorPicker: false,
      displayColorPicker: false,
      templates: props.templates || [],
      photos: props.photos || [],
      backgrounds: props.backgrounds || [],
      graphics: props.graphics || [],
      texts: props.texts || [],
      opacity: "",
      textSelected: false,
      imageSelected: false,
      shapeSelected: false,
      objectSelected: false,
      activeTray: "",
      cardType: props.cardType || "",
      mailer: props.mailer || {},
      undo: [],
      redo: [],
      canvasState: "",
      elementType: "",
      templateName: "",
      direction: { LEFT: 0, UP: 1, RIGHT: 2, DOWN: 3, DELETE: 4 },
      showInkFreeZone: showInkFreeZone,
      showBleed: showBleed,
      inkFreeZoneObj: "",
      bleedObj: "",
      fontWeightStyle: "",
      handleKeydown: false,
      showBgColorButton: true,
      oldText: "",
      showPositioningLabel: false,
      showPositioning: false,
      showStyle: true,
      showSaveTemplateModal: false,
      elementPrivacy: true,
      templateSize: cardSize,
      templateSide: this.props.cardType || "front",
      savingTemplate: false,
      groupSelected: false,
      xAxis: "",
      yAxis: "",
      angle: 0,
      objWidth: "",
      objHeight: "",
      categoriesOptions: this.props.template_categories.map((i) => {
        return { key: i.name, value: i.name, text: i.name }
      }),
      selectedCategorySaving: null,
    };

    this.fromJson = this.fromJson.bind(this);
    this.addImage = this.addImage.bind(this);
    //this.addDragNDropQRCode = this.addDragNDropQRCode.bind(this);
    this.setBackground = this.setBackground.bind(this);
    this.changeActiveTray = this.changeActiveTray.bind(this);
    this.loadObjects = this.loadObjects.bind(this);
  }

  componentDidMount() {
    let context = this;
    let leftSidebarWidth = 800; // Assumptions
    let dividingFactor = this.state.canvasWidth / leftSidebarWidth;
    let updatedWidth = this.state.canvasWidth / dividingFactor;
    let updatedHeight = this.state.canvasHeight / dividingFactor;

    window.addEventListener("keydown", this.handleKeydown, true);
    window.addEventListener("click", this.handleClick, true);

    fabric.DPI = this.isLetter ? 96 : DPI;

    const canvas = new fabric.Canvas("canvas", {
      hoverCursor: "pointer",
      selection: true,
      selectionBorderColor: "blue",
      backgroundColor: "rgba(255, 255, 255, 1)",
    });
    canvasSetup.init(
      canvas,
      context,
      this.state.canvasWidth,
      this.state.canvasHeight,
      updatedWidth,
      updatedHeight
    );
    canvasGuidelines.init(canvas);

    this.setState({
      canvas: canvas,
      updatedHeight: updatedHeight,
      updatedWidth: updatedWidth,
    });
    const json_data = this.props.designElement?.json_data;
    if (json_data
      && Object.keys(json_data).length > 0
     ) {
      try {
        this.addTemplateToCanvas(
          JSON.parse(json_data),
          canvas
        );
      } catch (error) {
        console.debug(error);
      }

    } else {
      if (this.props.mailer) this.changeActiveTray("Template");
    }

    this.setCanvasDefaults(canvas);
    this.saveState();
  }

  setCanvasDefaults = (canvas) => {
    if (this.isBack() && this.state.showInkFreeZone) {
      let inkFreeZoneObj = this.addInkFreeZone(canvas);
      this.setState({ inkFreeZoneObj: inkFreeZoneObj });
    } else if (this.isLetter && this.state.showInkFreeZone) {
      let inkFreeZoneObj = this.addInkFreeZone(canvas);
      this.setState({ inkFreeZoneObj: inkFreeZoneObj });
    }

    if (this.state.showBleed) {
      let bleedObj = this.addBleed(canvas);
      this.setState({ bleedObj: bleedObj });
    }
  };

  updateCanvasConstraints = () => {
    if (this.state.inkFreeZoneObj && this.state.inkFreeZoneObj != "")
      this.state.inkFreeZoneObj.bringToFront();

    if (this.state.bleedObj && this.state.bleedObj != "")
      this.state.bleedObj.bringToFront();
  };

  handleClick = (e) => {
    let handleKeydown = false;
    let displayBGColorPicker = this.state.displayBGColorPicker;
    let displayColorPicker = this.state.displayColorPicker;
    let activeTray = this.state.activeTray;
    if (displayBGColorPicker) handleKeydown = true;

    if (displayBGColorPicker && e.target.closest(".sketch-picker") == null) {
      displayBGColorPicker = false;
      handleKeydown = true;
    }

    if (displayColorPicker && e.target.closest(".sketch-picker") == null) {
      displayColorPicker = false;
    }

    if (_.includes(e.target.classList, "upper-canvas")) {
      handleKeydown = true;

      if (activeTray) {
        this.refs.editor.classList.remove("TrayOpen");
        activeTray = "";
      }
    }

    this.setState({
      handleKeydown,
      displayBGColorPicker,
      displayColorPicker,
      activeTray,
    });
  };

  handleEscape() {
    const canvas = this.state.canvas;
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    this.refs.editor.classList.remove("TrayOpen");

    this.resetPanels();
  }

  handleEnter(event) {
    const canvas = this.state.canvas,
      { ctrlKey, metaKey } = event,
      activeObject = this.activeObject();

    if (activeObject.type != "textbox") return;

    if (ctrlKey || metaKey) {
      event.preventDefault();
      activeObject.exitEditing();
    } else if (!activeObject.isEditing) {
      event.preventDefault();
      activeObject.enterEditing();
    }

    canvas.setActiveObject(activeObject);
    canvas.requestRenderAll();
  }

  handleZ(event) {
    let { ctrlKey, metaKey } = event;

    if (ctrlKey || metaKey) {
      event.preventDefault();
      this.replay("undo");
    }
  }

  handleKeydown = (e) => {
    const REGULAR_STEP = 1;
    const SHIFT_STEP = 10;
    let keyCode = e.keyCode;

    if (keyCode === 27) this.handleEscape();

    if (keyCode === 90) this.handleZ(e);

    if (!this.state.handleKeydown) return;

    if (keyCode === 13) this.handleEnter(e);

    const OBJECTS = this.activeObjects();

    if (OBJECTS) {
      OBJECTS.forEach((object) => {
        if (object.isEditing) return false;

        let step = REGULAR_STEP;

        if (e.shiftKey) step = SHIFT_STEP;

        if (keyCode === 8 || keyCode === 46) {
          this.removeSelected();
        } else if (keyCode === 37) {
          this.moveObject(object, this.state.direction.LEFT, step);
        } else if (keyCode === 38) {
          this.moveObject(object, this.state.direction.UP, step);
        } else if (keyCode === 39) {
          this.moveObject(object, this.state.direction.RIGHT, step);
        } else if (keyCode === 40) {
          this.moveObject(object, this.state.direction.DOWN, step);
        }
      });
    }
  };

  moveObject(object, direction, step) {
    switch (direction) {
      case this.state.direction.LEFT:
        object.left = object.left - step;
        break;
      case this.state.direction.RIGHT:
        object.left = object.left + step;
        break;
      case this.state.direction.UP:
        object.top = object.top - step;
        break;
      case this.state.direction.DOWN:
        object.top = object.top + step;
        break;
    }

    object.setCoords();
    this.state.canvas.renderAll();
  }

  saveState() {
    this.setState({ redo: [] });
    if (!this.state.canvas) return false;

    let state = this.state.canvasState;

    if (state) {
      let undo = this.state.undo;
      undo.push(state);
      this.setState({
        undo: undo,
      });
    }

    this.setState({
      canvasState: JSON.stringify(this.state.canvas.toJSON()),
    });
  }

  replay = (type) => {

    let state = this.state.canvasState;

    if (type == "undo") {
      let undo = this.state.undo;
      let redo = this.state.redo;

      redo.push(state);
      state = undo.pop();

      this.setState({
        redo: redo,
        undo: undo,
      });
    } else {
      let redo = this.state.redo;
      let undo = this.state.undo;

      undo.push(state);
      state = redo.pop();

      this.setState({
        redo: redo,
        undo: undo,
      });
    }

    if (state) {
      this.state.canvas.clear();
      this.fromJson(state);
    }
  };

  updateToolsSelection(selectedObject) {
    let objectSelected = false,
      shapeSelected = false,
      textSelected = false,
      imageSelected = false,
      groupSelected = false,
      showPositioningLabel = false;

    if (selectedObject.type == "activeSelection") {
      this.setState({
        showPositioningLabel: false,
        showPositioning: false,
      });
    }

    if (
      selectedObject.type !== "activeSelection" &&
      selectedObject.type !== "group" &&
      selectedObject
    ) {
      objectSelected = true;
      showPositioningLabel = true;

      this.getCommonObjectAttributes();

      switch (selectedObject.type) {
        case "line":
        case "rect":
        case "square":
        case "circle":
        case "triangle":
        case "polyline":
          shapeSelected = true;
          this.getFill();
          break;
        case "textbox":
          textSelected = true;
          this.getFontSize();
          this.getFontWeightStyle();
          this.getUnderline();
          this.getLinethrough();
          this.getCharSpacing();
          this.getLineHeight();
          this.getFill();
          this.getTextAlign();
          this.getFontFamily();
          break;
        case "image":
          imageSelected = true;
          break;
      }
      this.setState({
        objectSelected,
        shapeSelected,
        textSelected,
        imageSelected,
        showPositioningLabel,
        groupSelected,
      });
    } else {
      groupSelected = true;
      this.setState({ groupSelected });
    }
  }

  getCommonObjectAttributes() {
    this.getOpacity();
    this.getVerticalAlign();
    this.getAxis();
    this.getAngle();
    this.getDimension();
  }

  resetPanels() {
    this.setState({
      textSelected: false,
      imageSelected: false,
      shapeSelected: false,
      objectSelected: false,
      groupSelected: false,
      showPositioningLabel: false,
      showPositioning: false,
      showStyle: true,
      handleKeydown: true,
      displayColorPicker: false,
      displayBGColorPicker: false,
    });
  }

  updateDesignElements = (design_element, type) => {
    switch (type) {
      case "Template":
        let templates = this.state.templates;
        templates.push(design_element);

        this.setState({
          templates: templates,
        });
        break;
      case "Photo":
        let photos = this.state.photos;
        photos.push(design_element);

        this.setState({
          photos: photos,
        });
        break;
      case "Background":
        let backgrounds = this.state.backgrounds;
        backgrounds.push(design_element);

        this.setState({
          backgrounds: backgrounds,
        });
        break;
      case "Graphics":
        let graphics = this.state.graphics;
        graphics.push(design_element);

        this.setState({
          graphics: graphics,
        });
        break;
      case "Text":
        let texts = this.state.texts;
        texts.push(design_element);

        this.setState({
          texts: texts,
        });
        break;
    }
  };

  canvasData = (isPreview) => {
    let options = scaleUp ? { multiplier: MULTIPLIER } : {};
    this.removeInkFreeZone(this.state.canvas);
    this.removeBleed(this.state.canvas);
    let result;
    let canvasTexts = {};
    if (isPreview) {
      let personalizations = this.state.canvas.getObjects().filter(i => i.type == "textbox" && i.text.includes("{{"))
      personalizations.forEach((item, i) => {
        this.setActiveObject(item)
        canvasTexts[item.id] = item.text;
        Object.entries(this.props.personalizedValues).map(([k, v]) => {
          item.text = item.text.replaceAll(`{{${k}}}`, v)
        })
        item.enterEditing();
        this.state.canvas.renderAll();
      });
      result = this.state.canvas.toSVG(options);
      personalizations.forEach((item, i) => {
        item.text = canvasTexts[item.id];
        item.selectable = true;
        item.enterEditing();
      });
    } else {
      result = this.state.canvas.toSVG(options);
    }

    this.state.canvas.getObjects()
      .filter(object => object.type === "image")
      .map(image => image.getSrc())
      .forEach(src => result = result.replaceAll(src, encodeXml(src)));

    const srcBack = this.state.canvas.backgroundImage?.getSrc();
    if (srcBack) {
      result = result.replaceAll(srcBack, encodeXml(srcBack))
    }

    let svgString = result.split('</defs>');
    let res = svgString[0] + svgFonts + "</defs>" + svgString[1];
    //console.log({res});
    return res
  };

  canvasJson = () => {
    this.removeInkFreeZone(this.state.canvas);
    this.removeBleed(this.state.canvas);
    return this.state.canvas.toJSON(["selectable", "crossOrigin", "sourcePath"]);
  };

  uploadImage = (type, file) => {
    let _this = this;
    let mailer = this.state.mailer;
    let uuid = this.state.mailer.guid || canvasHelpers.generateUUID();

    return new Promise((resolve, reject) => {
      s3UploaderReact.upload(
        file,
        file.name,
        uuid,
        null,
        null,
        null,
        (path, fileUrl, htmlpath, htmlFileUrl) => {
          if (fileUrl) {
            const previewUrl = imgixReact.urlFromAwsUrl(fileUrl, "", "");
            let designElement = {
              name: s3UploaderReact.originalFileName(path),
              type: type || "Photo",
              url: fileUrl,
              preview_url: previewUrl,
            };

            if (mailer) {
              designElement.mailer_id = mailer.id;
            }

            _this.saveDesignElement(designElement).then((data) => {
              resolve();

              this.updateDesignElements(data.design_element, data.type);
              switch (data.type) {
                case "Photo":
                  this.addImage(data.design_element.url);
                  break;
                case "Background":
                  this.setBackground(data.design_element.url);
                  break;
              }
            });
          } else {
            reject();
          }
        }
      );
    });
  };

  saveDesignElement(designElement) {
    return new Promise((resolve, reject) => {
      let designElementUrl = "/design_elements";
      let httpMethod = "POST";

      if (this.props.designElement) {
        designElementUrl = `/design_elements/${this.props.designElement.id}`;
        httpMethod = "PATCH";
      }

      fetch(designElementUrl, {
        method: httpMethod,
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": this.authToken,
        },
        body: JSON.stringify({ design_element: designElement }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            resolve(response);
          } else reject(response);
        });
    });
  }

  // setQRSelectable = () => {
  //   this.state.canvas.getObjects().filter(object => object?.sourcePath === "qrcode.svg").forEach(object => object.selectable = true);
  //   this.state.canvas.renderAll();
  // }

  saveTemplate = (savedTemplate = false) => {
    const canvasJson = this.canvasJson();
    if (!canvasJson.objects.length && !canvasJson.backgroundImage) {
      console.error("Empty Design!", this.canvasJson());
      this.setCanvasDefaults(this.state.canvas);
      return false;
    }

    // this.setQRSelectable();

    this.setState({ savingTemplate: true });

    let _this = this;
    let mailer = this.state.mailer;
    let cardType = this.state.cardType.toString().toLowerCase();
    let uuid = this.state.mailer.guid || canvasHelpers.generateUUID();
    let templateName =
      this.state.mailer.name || this.state.templateName || "Untitled";
    let templateSize = this.state.templateSize;
    let templateSide = this.state.templateSide;
    let templateCategory = this.state.selectedCategorySaving;
    let elementPrivacy = this.state.elementPrivacy;
    let file = canvasHelpers.svgToBlob(templateName, this.canvasData(true));
    let HTMLfile = canvasHelpers.svgToHTMLBlob(templateName, this.canvasData(false));
    let name = `${templateName} - ${cardType}.svg`;
    let htmlName = `${templateName} - ${cardType}.html`;
    s3UploaderReact.upload(
      file,
      name,
      uuid,
      "svg",
      htmlName,
      HTMLfile,
      (path, fileUrl, htmlpath, htmlFileUrl) => {
        if (fileUrl) {
          let previewUrl = imgixReact.urlFromAwsUrl(fileUrl, "", "");
          let designElement = {
            name: templateName,
            type: this.state.elementType || "Template",
            private: elementPrivacy,
            json_data: JSON.stringify(this.canvasJson(), null, 2),
            url: fileUrl,
            html_url: htmlFileUrl,
            preview_url: previewUrl,
            size: templateSize,
            side: templateSide,
            template_category_name: templateCategory,
            saved_template: savedTemplate,
          };
          if (!_.isEmpty(mailer) && cardType) {
            designElement.mailer_id = mailer.id;
            designElement.mailer_type =
              this.props.mailerType == "campaigns"
                ? "Campaign"
                : "AutomationRule";
            designElement.side = cardType;
          }

          let success = false;
          this.saveDesignElement(designElement)
            .then((result) => {
              success = true;
              console.log("Card successfully saved.");
              console.log(result);
            })
            .catch((error) => {
              console.log("Failed! Could not save Template.");
              console.log(error);
            });

          this.setState({ savingTemplate: false });

          if (!_.isEmpty(mailer) && cardType) {
            _this
              .updateMailer(uuid, path, htmlpath, name, cardType)
              .then((result) => {
                if (this.props.mailerType == "campaigns") {
                  window.location = (this.state.cardType === "front" && !this.isLetter) ? `/campaigns/${uuid}/design` : `/campaigns/${uuid}/qr_code`;
                } else {
                  window.location = `/automations#${uuid}#design`;
                }
              });
          } else if (success) {
            window.location = `/design_elements/new`;
          }
        }
      }
    );
  };

  get authToken() {
    return $('meta[name="csrf-token"]').attr("content");
  }

  updateMailer = (guid, path, htmlpath, name, type) => {
    return new Promise((resolve, reject) => {
      let typeUrl = `/${this.props.mailerType}/${guid}/postcards/${type}`;

      $.post(typeUrl, {
        authenticity_token: this.authToken,
        "postcard[scale]": "plain",
        "postcard[file_name]": name,
        "postcard[path]": path,
        "postcard[html_path]": htmlpath,
      }).done((result) => {
        console.debug("[PATCH] Finished saving postcard");
        resolve(result);
      });
    });
  };

  addText = (value) => {
    let text = canvasHelpers.getText({
      text: value,
      left: this.state.left,
      top: this.state.top,
    });
    text = this.hideControlVisibility(text);
    this.addAndRender(text);
    text.enterEditing();
    text.hiddenTextarea.focus();
    text.selectAll();
  };

  hideControlVisibility(obj) {
    let controls = ["mt", "mb"];
    _.forEach(controls, function (control) {
      obj.setControlVisible(control, false);
    });
    return obj;
  }

  setActiveObject(obj) {
    this.state.canvas.setActiveObject(obj);
  }

  fromJson(json) {
    if (typeof json == "undefined") return false;
    this.addTemplateToCanvas(json, this.state.canvas);
  }

  addTemplateToCanvas(template, canvas) {
    if (template.backgroundImage) {
      template.backgroundImage.crossOrigin = "Anonymous";
    } else if (template.background) {
      this.setState({ backgroundColor: template.background });
    }
    canvas.loadFromJSON(template, canvas.renderAll.bind(canvas), function (
      o,
      object
    ) {
      canvasHelpers.updateObjectWithDefaults(object);
      if (isQRcodeObject(object)) {
        object.selectable = false;
      }
    });
  }

  loadObjects(objects) {
    let _this = this;

    fabric.util.enlivenObjects(objects, function (newObjects) {
      let origRenderOnAddRemove = _this.state.canvas.renderOnAddRemove;
      _this.state.canvas.renderOnAddRemove = false;

      newObjects.forEach(function (newObject) {
        newObject = canvasHelpers.updateObjectWithDefaults(newObject);
        _this.state.canvas.add(newObject);
      });

      _this.state.canvas.renderOnAddRemove = origRenderOnAddRemove;
      _this.state.canvas.renderAll();
    });
  }

  exportPNG = () => {
    canvasHelpers.exportCanvas(this.state.canvas, "png");
  };

  getTextAlign = () => {
    this.setState({ textAlign: this.getActiveProp("textAlign") });
  };

  setTextAlign = (value) => {
    this.activeObject().exitEditing();
    //this.setActiveProp("textAlign", value);
    this.setActiveStyle("textAlign", value);

    // if (value != "justify") this.setActiveProp("originX", value);
    if (value != "justify") this.setActiveStyle("originX", value);

    this.setState({ textAlign: value });
    this.saveState();
  };

  getVerticalAlign = () => {
    this.setState({ verticalAlign: this.getActiveStyle("originY", null) });
  };

  setVerticalAlign = (value) => {
    this.setActiveStyle("originY", value);
    this.setState({
      verticalAlign: value,
    });

    this.saveState();
  };

  getFontFamily = () => {
    this.setState({ fontFamily: this.getActiveProp("fontFamily") });
  };

  setFontFamily = (value) => {
    let context = this;
    const newFontFamily = value;
    const newFontFamilyLoader = new FontFaceObserver(newFontFamily);

    if (_.includes(systemFonts, value)) {
      //context.setActiveProp("fontFamily", newFontFamily);
      context.setActiveStyle("fontFamily", newFontFamily, null);
    } else {
      newFontFamilyLoader
        .load()
        .then(function () {
          //context.setActiveProp("fontFamily", newFontFamily);
          context.setActiveStyle("fontFamily", newFontFamily, null);
        })
        .catch(function (e) {
          console.log(e);
          console.error("Font loading failed " + font);
        });
    }
    context.setState({ fontFamily: newFontFamily });
    context.saveState();
  };

  getUnderline = () => {
    this.setState({ isUnderlined: this.activeObject().get("underline") });
  };

  setUnderline = () => {
    // this.activeObject().set("linethrough", false);
    // this.activeObject().set("underline", true);
    this.setActiveStyle("linethrough", false);
    this.setActiveStyle("underline", true);

    this.state.canvas.renderAll();

    this.setState({
      isUnderlined: true,
      isLinethrough: false,
    });
    this.saveState();
  };

  getLinethrough = () => {
    this.setState({ isLinethrough: this.activeObject().get("linethrough") });
  };

  setLinethrough = () => {
    // this.activeObject().set("underline", false);
    // this.activeObject().set("linethrough", true);
    this.setActiveStyle("underline", false);
    this.setActiveStyle("linethrough", true);

    this.state.canvas.renderAll();

    this.setState({
      isLinethrough: true,
      isUnderlined: false,
    });
    this.saveState();
  };

  resetTextStyle = () => {
    // this.activeObject().set("linethrough", false);
    // this.activeObject().set("underline", false);
    this.setActiveStyle("linethrough", false);
    this.setActiveStyle("underline", false);

    this.state.canvas.renderAll();

    this.setState({
      isLinethrough: false,
      isUnderlined: false,
    });
    this.saveState();
  };

  getFontSize = () => {
    let fontSizeInPixel = this.getActiveStyle("fontSize", null);
    let fontSizeInPt = Math.ceil(fontSizeInPixel * (72 / fabric.DPI));

    this.setState({
      fontSize: fontSizeInPt,
    });
  };

  setFontSize = (fontSizeInPt) => {
    let fontSizeInPixel = fabric.util.parseUnit(fontSizeInPt + "pt");

    //this.setActiveProp("fontSize", fontSizeInPixel);
    this.setActiveStyle("fontSize", fontSizeInPixel, null);

    this.setState({
      fontSize: fontSizeInPt,
    });

    this.saveState();
  };

  getOpacity = () => {
    let currentOpacity = this.getActiveStyle("opacity", null) * 100;
    this.setState({
      opacity: currentOpacity,
    });
  };

  setOpacity = (value) => {
    let newOpacity = parseInt(value);
    this.setActiveStyle("opacity", newOpacity / 100, null);
    this.setState({
      opacity: newOpacity,
    });
  };

  getActiveStyle = (styleName, object) => {
    object = object || this.activeObject();
    if (!object) return "";

    return object.getSelectionStyles && object.isEditing
      ? object.getSelectionStyles()[styleName] || ""
      : object[styleName] || "";
  };

  setActiveStyle = (styleName, value, object) => {
    object = object || this.activeObject();
    if (!object) return;

    canvasHelpers.setStyle(styleName, value, object, this.state.canvas);
  };

  getActiveProp = (name) => {
    let object = this.activeObject();
    if (!object) return "";

    return object[name] || "";
  };

  setActiveProp = (name, value) => {
    let object = this.activeObject();
    if (!object) return;

    object.set(name, value).setCoords();
    this.state.canvas.renderAll();
  };

  removeSelected = () => {
    let activeObjects = this.activeObjects();

    if (activeObjects) {
      let self = this;
      activeObjects.forEach(function (object) {
        self.state.canvas.remove(object);
      });

      self.state.canvas.discardActiveObject();
    }
  };

  activeObject = () => {
    return this.state.canvas.getActiveObject();
  };

  activeObjects = () => {
    return this.state.canvas.getActiveObjects();
  };

  bringToFront = () => {
    let activeObject = this.activeObject(),
      activeObjects = this.activeObjects();

    if (activeObject) activeObject.bringToFront();
    else if (activeObjects)
      activeObjects.forEach((object) => {
        object.bringToFront();
      });

    this.updateCanvasConstraints();
  };

  bringForward = () => {
    let activeObject = this.activeObject(),
      activeObjects = this.activeObjects();

    if (activeObject) activeObject.bringForward();
    else if (activeObjects)
      activeObjects.forEach((object) => {
        object.bringForward();
      });

    this.updateCanvasConstraints();
  };

  sendToBack = () => {
    let activeObject = this.activeObject(),
      activeObjects = this.activeObjects();

    if (activeObject) activeObject.sendToBack();
    else if (activeObjects)
      activeObjects.forEach((object) => {
        object.sendToBack();
      });
  };

  sendBackwards = () => {
    let activeObject = this.activeObject(),
      activeObjects = this.activeObjects();

    if (activeObject) activeObject.sendBackwards();
    else if (activeObjects)
      activeObjects.forEach((object) => {
        object.sendBackwards();
      });
  };

  clearScreen = () => {
    this.state.canvas.clear();
    this.changeBackgroundColor({ rgb: { r: 255, g: 255, b: 255, a: 1 } });
    this.setCanvasDefaults(this.state.canvas);
  };

  resetBackground = () => {
    this.state.canvas.backgroundImage = false;
    this.state.canvas.renderAll();

    this.saveState();
  };

  changeBackgroundColor = (color) => {
    const rgbColor = `rgba(${Object.values(color.rgb).join(", ")})`;
    this.setState({ backgroundColor: rgbColor });
    this.state.canvas.backgroundColor = rgbColor;
    this.state.canvas.renderAll();

    this.saveState();
  };

  getFill = () => {
    let color = "";
    if (this.activeObject().type == "line")
      color = this.getActiveStyle("stroke", null);
    else color = this.getActiveStyle("fill", null);

    this.setState({
      fill: color,
      stroke: color,
    });
  };

  setFill = (color) => {
    const rgbColor = `rgba(${Object.values(color.rgb).join(", ")})`;

    if (this.activeObject().type == "line") {
      this.setActiveStyle("fill", rgbColor, null);
      this.setActiveStyle("stroke", rgbColor, null);
    } else this.setActiveStyle("fill", rgbColor, null);

    this.setState({
      fill: rgbColor,
      stroke: rgbColor,
    });

    this.saveState();
  };

  handleBGColorPickerClick = () => {
    this.setState({
      displayBGColorPicker: !this.state.displayBGColorPicker,
    });
  };

  handleColorPickerClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false, displayBGColorPicker: false });
  };

  addShape = (shapeType) => {
    let shape = canvasHelpers.getShape(
      shapeType,
      this.state.left,
      this.state.top
    );
    this.addAndRender(shape);
  };

  // addDragNDropQRCode(url) {
  //   if (url) {
  //     // TODO: add condition for rendering only one QRCode(image)
  //     fabric.Image.fromURL(
  //         url,
  //         (image) => {
  //           image.set(
  //               _.extend(canvasHelpers.defaultCanvasObjProperties(), {
  //                 left: this.state.left,
  //                 top: this.state.top,
  //               })
  //           );

  //           if (
  //               image.height > this.state.canvas.height ||
  //               image.width > this.state.canvas.width
  //           ) {
  //             let scaleHeight = this.state.canvas.height / image.height;
  //             let scaleWidth = this.state.canvas.width / image.width;

  //             if (scaleWidth > scaleHeight)
  //               image.scaleToHeight(this.state.canvas.height);
  //             else image.scaleToWidth(this.state.canvas.width);
  //           }

  //           this.addAndRender(image);
  //         },
  //         { crossOrigin: "Anonymous" }
  //     );
  //   }
  // };

  addImage(url) {
    if (url) {
      fabric.Image.fromURL(
        url,
        (image) => {
          image.set(
            _.extend(canvasHelpers.defaultCanvasObjProperties(), {
              left: this.state.left,
              top: this.state.top,
            })
          );

          if (
            image.height > this.state.canvas.height ||
            image.width > this.state.canvas.width
          ) {
            let scaleHeight = this.state.canvas.height / image.height;
            let scaleWidth = this.state.canvas.width / image.width;

            if (scaleWidth > scaleHeight)
              image.scaleToHeight(this.state.canvas.height);
            else image.scaleToWidth(this.state.canvas.width);
          }

          this.addAndRender(image);
        },
        { crossOrigin: "Anonymous" }
      );
    }
  }

  addAndRender(obj) {
    this.state.canvas.add(obj);
    this.state.canvas.setActiveObject(obj);
    this.state.canvas.renderAll();

    this.saveState();
  }

  getLineHeight = () => {
    this.setState({
      lineHeight: this.getActiveStyle("lineHeight", null),
    });
  };

  setLineHeight = (value) => {
    let newLineHeight = parseFloat(value);
    this.setActiveStyle("lineHeight", newLineHeight, null);

    this.setState({ lineHeight: newLineHeight });
    this.saveState();
  };

  getCharSpacing = () => {
    let currentCharSpacing = this.getActiveStyle("charSpacing", null);
    this.setState({ charSpacing: currentCharSpacing });
  };

  setCharSpacing = (value) => {
    let newCharSpacing = value;
    this.activeObject().exitEditing();
    this.setActiveStyle("charSpacing", newCharSpacing, null);

    this.setState({ charSpacing: newCharSpacing });
    this.saveState();
  };

  setBackground = (imageUrl) => {
    let _this = this;

    fabric.Image.fromURL(
      imageUrl,
      function (img) {
        let scaleHeight = _this.state.canvas.height / img.height;
        let scaleWidth = _this.state.canvas.width / img.width;

        if (scaleWidth > scaleHeight)
          img.scaleToWidth(_this.state.canvas.width);
        else img.scaleToHeight(_this.state.canvas.height);

        _this.state.canvas.setBackgroundImage(img);
        _this.state.canvas.requestRenderAll();
        _this.saveState();
      },
      { crossOrigin: "anonymous" }
    );
  };

  changeActiveTray(trayType) {
    if (trayType == "Back") {
      this.refs.editor.classList.remove("TrayOpen");
      this.setState({ activeTray: "" });
    } else {
      this.refs.editor.classList.add("TrayOpen");
      this.setState({ activeTray: trayType });
    }
  }

  clone = () => {
    let activeObject = this.activeObject();

    if (activeObject) {
      let clone;

      switch (activeObject.type) {
        case "rect":
          clone = new fabric.Rect(activeObject.toObject());
          break;
        case "circle":
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case "triangle":
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case "textbox":
          clone = new fabric.Textbox("", activeObject.toObject());
          break;
        case "image":
          clone = fabric.util.object.clone(activeObject);
          break;
        default:
          clone = fabric.util.object.clone(activeObject);
          break;
      }
      if (clone) {
        clone = canvasHelpers.updateObjectWithDefaults(clone);
        clone.set({ left: activeObject.left + 20, top: activeObject.top + 20 });
        this.addAndRender(clone);
      }
    }
  };

  setElementType = (value) => {
    this.setState({ elementType: value });
  };

  setTemplateName = (value) => {
    this.setState({ templateName: value });
  };

  setElementPrivacy = (value) => {
    this.setState({ elementPrivacy: value });
  };

  addInkFreeZone(canvas) {
    let inkFreeZoneObj = !this.isLetter
      ? canvasHelpers.reservedArea(
        this.state.canvasWidth,
        this.state.canvasHeight
      )
      : canvasHelpers.reservedAreaLetter(
        this.state.canvasHeight,
        this.state.canvasWidth
      );
    canvas.add(inkFreeZoneObj);
    return inkFreeZoneObj;
  }

  removeInkFreeZone(canvas) {
    let inkFreeZoneObj = this.state.inkFreeZoneObj;
    canvas.remove(inkFreeZoneObj);
    return "";
  }


  addBleed(canvas) {
    let bleedObj = canvasHelpers.bleedArea(
      this.state.canvasWidth,
      this.state.canvasHeight
    );
    canvas.add(bleedObj);
    return bleedObj;
  }

  removeBleed(canvas) {
    let bleedObj = this.state.bleedObj;
    canvas.remove(bleedObj);
    return "";
  }

  setLocalStorage(options) {
    _.forEach(Object.keys(options), (key) =>
      localStorage.setItem(key, options[key])
    );
  }

  toggleBleed = () => {
    let options = {};

    if (this.state.showBleed) {
      options.showBleed = false;
      options.bleedObj = this.removeBleed(this.state.canvas);
    } else {
      options.showBleed = true;
      options.bleedObj = this.addBleed(this.state.canvas);
    }

    this.setLocalStorage(options);
    this.setState(options);
  };

  getFontWeightStyle = () => {
    let fontWeightStyle = [];

    fontWeightStyle.push(this.getActiveStyle("fontWeight", null));
    fontWeightStyle.push(this.getActiveStyle("fontStyle", null));

    let filtered = fontWeightStyle.filter(function (str) {
      return str != "normal" && str != "400";
    });

    this.setState({
      fontWeightStyle: filtered.join(","),
    });
  };

  setFontWeightStyle = (fontWeightStyle) => {
    if (this.activeObject().isEditing) this.activeObject().exitEditing();

    switch (fontWeightStyle) {
      case "":
        this.setActiveStyle("fontWeight", "400", null);
        this.setActiveStyle("fontStyle", "normal", null);
        break;
      case "700":
        this.setActiveStyle("fontStyle", "normal", null);
        this.setActiveStyle("fontWeight", "700", null);
        break;
      case "italic":
        this.setActiveStyle("fontWeight", "400", null);
        this.setActiveStyle("fontStyle", "italic", null);
        break;
      case "700,italic":
        this.setActiveStyle("fontWeight", "700", null);
        this.setActiveStyle("fontStyle", "italic", null);
        break;
    }

    this.setState({
      fontWeightStyle: fontWeightStyle,
    });

    this.saveState();
  };

  mailerName = () => {
    return this.props.mailer ? this.props.mailer.name : "Design Template";
  };

  showStyleButtons = () => {
    this.setState({ showStyle: true, showPositioning: false });
  };

  showPositioningButtons = () => {
    this.setState({ showPositioning: true, showStyle: false });
  };

  isBack() {
    return this.props.cardType == "back";
  }

  setAlign = (align) => {
    canvasHelpers.setAlign(align, this.state.canvas);
    this.saveState();
  };

  getColorPicker = (type) => {
    if (type == "object") {
      return (
        <ColorPicker
          handleColorPickerButtonClick={this.handleColorPickerClick}
          colorPickerButtonColor={this.state.fill}
          showColorBoard={this.state.displayColorPicker}
          handleClose={this.handleClose}
          changeColor={this.setFill}
        />
      );
    } else if (type == "background") {
      return (
        <ColorPicker
          handleColorPickerButtonClick={this.handleBGColorPickerClick}
          colorPickerButtonColor={this.state.backgroundColor}
          showColorBoard={this.state.displayBGColorPicker}
          handleClose={this.handleClose}
          changeColor={this.changeBackgroundColor}
        />
      );
    }
  };

  showCanvasBorder() {
    return (
      this.state.handleKeydown &&
      !this.state.objectSelected &&
      !this.state.groupSelected
    );
  }

  openModal = () => {
    this.setState({
      showSaveTemplateModal: true
    })
  }

  closeModal = () => {
    this.setState({
      showSaveTemplateModal: false
    })
  }

  getAxis = () => {
    const activeObject = this.activeObject();
    if (activeObject == null) return;

    this.setState({
      xAxis: Math.ceil(activeObject.left),
      yAxis: Math.ceil(activeObject.top),
    });
  };

  setAxis = (axis, value) => {
    const ceiledValue = Math.ceil(value);
    const activeObject = this.activeObject();
    if (activeObject == null) return;

    switch (axis) {
      case "x":
        activeObject.set("left", ceiledValue);
        this.setState({ xAxis: ceiledValue });
        break;
      case "y":
        activeObject.set("top", ceiledValue);
        this.setState({ yAxis: ceiledValue });
        break;
    }

    activeObject.setCoords();
    this.state.canvas.renderAll();
  };

  getAngle = () => {
    const activeObject = this.activeObject();
    if (activeObject == null) return;

    this.setState({ angle: Math.ceil(activeObject.angle) });
  };

  setAngle = (degree) => {
    const ceiledValue = Math.ceil(degree);
    const activeObject = this.activeObject();
    if (activeObject == null) return;

    activeObject.rotate(ceiledValue);
    activeObject.setCoords();
    this.state.canvas.renderAll();
    this.setState({ angle: ceiledValue });
  };

  getDimension = () => {
    const activeObject = this.activeObject();
    if (activeObject == null) return;

    this.setState({
      objWidth: Math.ceil(activeObject.width),
      objHeight: Math.ceil(activeObject.height),
    });
  };

  setDimension = (dimension, value) => {
    const ceiledValue = Math.ceil(value);
    const activeObject = this.activeObject();
    if (activeObject == null) return;

    activeObject.set(dimension, ceiledValue);
    activeObject.setCoords();
    this.state.canvas.renderAll();
    this.setState({ [_.camelCase(`obj ${dimension}`)]: ceiledValue });
  };

  render() {
    const bleedButtonClass = this.state.showBleed
      ? "BleedButton On"
      : "BleedButton Off",
      sideType = _.capitalize(this.props.cardType),
      [cH, cW] = this.state.cardSize.split("x"),
      saveTemplateBtnClass = this.state.savingTemplate
        ? "Button PurpleButton Disabled Loading"
        : "Button PurpleButton",
      pagesClassName = this.showCanvasBorder() ? "Page Selected" : "Page";

    return (
      <div ref="editor" className="Editor">
        <header className="GlobalHeader DesignHeader">
          <div className="Content">
            <a className="Logo" href={`/${this.props.mailerType}`}>
              Mailfold
            </a>
            <div className="CampaignName">{this.mailerName()}</div>
            <nav>
              <button
                className="Button ExitButton canvas-save-design"
                onClick={() => window.location.href = `/campaigns/${this.props.mailer.guid}/design`}
              >
                Cancel
              </button>
              <button
                className={`${saveTemplateBtnClass} canvas-save-design `}
                disabled={this.state.savingTemplate}
                onClick={() => {
                  this.saveTemplate();
                }}
              >
                Save
              </button>
            </nav>
          </div>
        </header>
        <aside className="ButtonBar">
          <div className="CloseButtonOverlay">
            <div className="DesignerButtons">
              <SidebarButton
                changeActiveTray={this.changeActiveTray}
                label="Back"
              />
            </div>
          </div>
          <div className="DesignerButtons PrimaryButtons">
            <SidebarButton
              changeActiveTray={this.changeActiveTray}
              label="Template"
            />
            <div className="ButtonDivider"></div>

            <div
              onClick={() => {
                this.addText("Type something");
              }}
              className="DesignerButtonContainer"
            >
              <div className="DesignerButton TextButton"></div>
              <span className="ButtonLabel">Text</span>
            </div>
            <SidebarButton
              changeActiveTray={this.changeActiveTray}
              label="Shape"
            />
            <SidebarButton
              changeActiveTray={this.changeActiveTray}
              label="Image"
            />
            <SidebarButton
              changeActiveTray={this.changeActiveTray}
              label="Background"
            />
            <SidebarButton
              changeActiveTray={this.changeActiveTray}
              label="Personalize"
            />
            {/* <SidebarButton
                changeActiveTray={this.changeActiveTray}
                label="Add QR"
            /> */}
          </div>
          <div className="DesignerButtons BottomButtons">
            <div className="SlimButton UndoButton">
              <span
                onClick={() => {
                  this.replay("undo");
                }}
                className="ButtonLabel"
              >
                Undo
              </span>
            </div>
          </div>
        </aside>
        <ToolTray
          activeTray={this.state.activeTray}
          templates={this.state.templates}
          templates={this.state.templates}
          photos={this.state.photos}
          backgrounds={this.state.backgrounds}
          graphics={this.state.graphics}
          texts={this.state.texts}
          fromJson={this.fromJson}
          addImage={this.addImage}
          // addDragNDropQRCode={this.addDragNDropQRCode}
          uploadImage={this.uploadImage}
          setBackground={this.setBackground}
          loadObjects={this.loadObjects}
          parentState={this.state}
          addShape={this.addShape}
          clearScreen={this.clearScreen}
          resetBackground={this.resetBackground}
          custom_fields={this.props.custom_fields}
          addText={this.addText}
          authToken={this.authToken}
        />

        <div onClick={this.toggleBleed} className={bleedButtonClass}>
          <span className="Icon"></span>
          <span className="HiddenLabel On">Hide Bleed</span>
          <span className="HiddenLabel Off">Show Bleed</span>
        </div>

        <main className={`Canvas ${this.isLetter ? "letter" : ""}`}>
          <div className={`Pages ${this.isLetter ? "letter" : ""}`}>
            <div className="PostcardMetadata">
              <span className="Side">{sideType} Side</span>{" "}
              <span className="Division">&bull;</span> {cH}&prime; &times; {cW}
              &prime;
            </div>
            <div
              className={pagesClassName}
              style={{
                width: this.state.updatedWidth,
                height: this.state.updatedHeight,
              }}
            >
              <canvas id="canvas" />
            </div>
          </div>
        </main>

        <PropertiesBar
          cardType={this.props.cardType}
          parentState={this.state}
          setOpacity={this.setOpacity}
          removeSelected={this.removeSelected}
          sendToBack={this.sendToBack}
          sendBackwards={this.sendBackwards}
          bringToFront={this.bringToFront}
          bringForward={this.bringForward}
          clone={this.clone}
          exportPNG={this.exportPNG}
          toggleBleed={this.toggleBleed}
          showStyleButtons={this.showStyleButtons}
          showPositioningButtons={this.showPositioningButtons}
          setAlign={this.setAlign}
          setAxis={this.setAxis}
          setAngle={this.setAngle}
          setDimension={this.setDimension}
          onOpenModal={this.openModal}
          styleProperties={
            <StyleProperties
              parentState={this.state}
              admin={this.props.admin}
              setFontSize={this.setFontSize}
              setFontWeightStyle={this.setFontWeightStyle}
              resetTextStyle={this.resetTextStyle}
              setUnderline={this.setUnderline}
              setLinethrough={this.setLinethrough}
              setCharSpacing={this.setCharSpacing}
              setLineHeight={this.setLineHeight}
              resetBackground={this.resetBackground}
              objectColorPicker={this.getColorPicker("object")}
              bgColorPicker={this.getColorPicker("background")}
              fontFamilyOptions={
                <FontFamilyOptions
                  defaultFontFamily={this.state.fontFamily}
                  setFontFamily={this.setFontFamily}
                />
              }
              textAlignment={
                <TextAlignmentButton
                  setTextAlign={this.setTextAlign}
                  setAlign={this.setAlign}
                  activeTextAlign={this.state.textAlign}
                />
              }
              templateForm={
                <TemplateForm
                  setElementType={this.setElementType}
                  canvasJson={this.canvasJson}
                  fromJson={this.fromJson}
                  setElementPrivacy={this.setElementPrivacy}
                  setTemplateName={this.setTemplateName}
                  saveTemplate={this.saveTemplate}
                />
              }
            />
          }
        />
        {this.state.showSaveTemplateModal &&
          <Modal
            open={this.state.showSaveTemplateModal}
            closeOnEscape={false}
            closeOnDimmerClick={true}
            className="designer-segment-modal"
            onClose={() => {
              this.closeModal();
              this.setState({ selectedCategorySaving: null });
            }}
          >
            <div className="design-modal">
              <h2 className="agency-blog-modal-title">Save design as Template</h2>
              <p className="agency-blog-modal-text">If you want to save the design as a template you should choose a category below</p>
              <div className="canvas-dropdown">
                <Dropdown
                  placeholder='Select Category'
                  fluid
                  selection
                  value={this.state.selectedCategorySaving}
                  onChange={(e, item) => this.setState({ selectedCategorySaving: item.value })}
                  options={this.state.categoriesOptions}
                />
              </div>
              {this.props.admin &&
                <div className="design-modal-checkbox">
                  <input type="checkbox" id="DesignForAdmin" checked={!this.state.elementPrivacy} />
                  <label
                    htmlFor="DesignForAdmin"
                    className="SupportToggleContainer"
                  >
                    <label
                      htmlFor="DesignForAdmin"
                      className="SupportToggle"
                      onClick={() => {
                        this.setState({ elementPrivacy: !this.state.elementPrivacy })
                      }}
                    >
                      <div className="SupportToggleSwitch">
                        <div className="SupportToggleSwitchInside"></div>
                      </div>
                    </label>
                  </label>
                  <p className="design-modal-checkbox-text">Save as Public Template</p>
                </div>
              }
              <div className="design-modal-bottom">
                <button onClick={() => {
                  this.closeModal();
                  this.setState({ selectedCategorySaving: null });
                }} className="green-bordered-button max-wd-unset">Cancel</button>
                <button className="green-bordered-button max-wd-unset"
                  disabled={!this.state.selectedCategorySaving}
                  onClick={() => {
                    this.saveTemplate(true);
                  }}>Save</button>
              </div>
            </div>
          </Modal>
        }
      </div>
    );
  }
}

export default DesignCanvas;
