import React from "react";
import { Modal } from "semantic-ui-react";
import { postData } from "../../lib/fetches";


const initialState = {
  showModal: false,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  resIsSucccess: null,
  errorMessage: null
};

class SignUpRequestModal extends React.Component {
  constructor(props) {
    props;
    super(props);

    this.state = initialState;
  }

  resetState() {
    this.setState(initialState);
  }

  formIsValid = () => {
    return (this.state.firstName.length &&
      this.state.lastName.length &&
      this.state.phone.length &&
      this.state.email.length)
  }

  modalOpen = () => {
    this.setState({
      showModal: true
    })
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      resIsSucccess: null
    });
  }

  setField = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value || ""
    })
  }

  sendRequest = (e) => {
    e.preventDefault();
    postData('/request_sign_up',
      this.props.authToken,
      {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email
      },
      (res) => {
        if (res.message === "Success") {
          this.resetState()

          this.setState({
            resIsSucccess: "complete",
            showModal: false,
            errorMessage: null
          })
        } else {
          this.setState({
            resIsSucccess: "wrong",
            showModal: false,
            errorMessage: res.message
          })
        }
      }
    );
  };

  closeSecondModal = () => {
    this.setState({
      resIsSucccess: null
    })
  }

  render() {
    return (
      <>
        <button onClick={() => this.modalOpen()} className={'Button CTA HomeHeaderLink'}>Request Invitation Code</button>
        <div className={'Modal-container'}>
          <Modal
            open={this.state.showModal}
            onClose={() => this.closeModal()}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className={"InvitationModal"}
          >

            <form className="code-request">
              <span onClick={() => this.closeModal()} className="code-request-close">×</span>

              <div className="code-request-row">
                <label for="request-first-name">First Name</label>
                <input id="request-first-name" value={this.state.firstName} placeholder="First Name" type="text" onChange={(e) => this.setField('firstName', e)}></input>
              </div>

              <div className="code-request-row">
                <label for="request-last-name">Last Name</label>
                <input id="request-last-name" value={this.state.lastName} placeholder="Last Name" type="text" onChange={(e) => this.setField('lastName', e)}></input>
              </div>

              <div className="code-request-row">
                <label for="request-email">Email</label>
                <input id="request-email" value={this.state.email} placeholder="Email" type="email" onChange={(e) => this.setField('email', e)}></input>
              </div>

              <div className="code-request-row">
                <label for="request-number">Phone number</label>
                <input id="request-number" value={this.state.phone} placeholder="Phone" type="number" onChange={(e) => this.setField('phone', e)}></input>
              </div>

              <button className="code-request-btn CTA" onClick={(e) => this.sendRequest(e)} disabled={!this.formIsValid()}>Request Invitation Code</button>
            </form>

          </Modal>
        </div>
        {this.state.resIsSucccess === "complete" ? (
          <div className="HomeSecondModal">
            <div className="Modal">
              <div className="ModalWindow">
                <div className="Illustration" id="Thanks">
                  <div className="GreenTick"></div>
                </div>
                <div className="ModalContent">
                  <div className="Title">
                    Great Job.
                        </div>
                  <div className="Body">
                    Your request was successfully sent, Please check your e-mail.
                        </div>
                </div>
                <div className="ModalActions">
                  <div className="Buttons">
                    <div onClick={() => this.closeSecondModal()} className="Button PrimaryAction">
                      Okay
                          </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ModalBackground"></div>
          </div>
        ) : this.state.resIsSucccess === "wrong" ? (
          <div className="HomeSecondModal">
            <div className="Modal">
              <div className="ModalWindow">
                <div className="ModalContent">
                  <div className="Title">
                    Something is wrong!
                        </div>
                  <div className="Body">
                    {this.state.errorMessage}
                  </div>
                </div>
                <div className="ModalActions">
                  <div className="Buttons">
                    <div onClick={() => {
                      this.closeSecondModal()
                      this.modalOpen()
                    }} className="Button PrimaryAction">
                      Okay
                          </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ModalBackground"></div>
          </div>
        ) : null}
      </>
    );
  }
}

export default SignUpRequestModal;
