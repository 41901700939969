const canvasHelpers = {
  getShape(shapeType, left, top) {
    let shape = {}

    switch (shapeType) {
      case 'triangle':
        shape = new fabric.Triangle(_.extend(this.defaultCanvasObjProperties(), {
          width: 250,
          height: 250,
          left: left,
          top: top,
          fill: 'rgba(76, 145, 245, 100)',
          lockUniScaling: false
        }))
        break

      case 'square':
        shape = new fabric.Rect(_.extend(this.defaultCanvasObjProperties(), {
          width: 250,
          height: 250,
          left: left,
          top: top,
          lockUniScaling: false,
          fill: 'rgba(76, 145, 245, 100)'
        }))
        break

      case 'circle':
        shape = new fabric.Circle(_.extend(this.defaultCanvasObjProperties(), {
          radius: 150,
          left: left,
          top: top,
          fill: 'rgba(76, 145, 245, 100)',
          lockUniScaling: false
        }))
        break

      case 'line':
        shape = new fabric.Line([0, 0, 250, 0], _.extend(this.defaultCanvasObjProperties(), {
          left: left,
          top: top,
          stroke: 'rgba(76, 145, 245, 100)',
          strokeWidth: 10,
          lockUniScaling: false
        }))
        break

      case 'starpolygon':
        shape = new fabric.Polyline([
          { x: 100, y: 10 },
          { x: 40, y: 198 },
          { x: 190, y: 78 },
          { x: 10, y: 78 },
          { x: 160, y: 198 }
        ], _.extend(this.defaultCanvasObjProperties(), {
          left: left,
          top: top,
          stroke: 'rgba(76, 145, 245, 0)',
          strokeWidth: 1,
          fill: 'rgba(76, 145, 245, 100)',
          lockUniScaling: false
        }))
        break

      case 'polygon':
        shape = new fabric.Polyline([
          { x: 50, y: 0 },
          { x: 100, y: 38 },
          { x: 82, y: 100 },
          { x: 18, y: 100 },
          { x: 0, y: 38 }
        ], _.extend(this.defaultCanvasObjProperties(), {
          left: left,
          top: top,
          stroke: 'rgba(76, 145, 245, 0)',
          strokeWidth: 1,
          fill: 'rgba(76, 145, 245, 100)',
          lockUniScaling: false
        }))
        break
    }
    return shape
  },

  getText({text, left, top}) {
    let textObj = new fabric.Textbox(text, _.extend(this.defaultCanvasObjProperties(), {
      left: left,
      top: top,
      scaleX: 1,
      scaleY: 1,
      fontFamily: 'Helvetica',
      fill: 'rgba(0, 0, 0, 100)',
      fontSize: 50,
      charSpacing: 0,
      kerning: 1,
      textAlign: 'center',
      lockUniScaling: false
    }));

    return textObj
  },

  defaultCanvasObjProperties() {
    return {
      id: Date.now(),
      borderColor: '#F5C54C',
      borderOpacityWhenMoving: 0,
      borderScaleFactor: 2,
      cornerSize: 10,
      cornerStyle: 'circle',
      cornerColor: '#fff',
      cornerStrokeColor: 'rgba(0,0,0,.4)',
      hasBorders: true,
      hasRotatingPoint: true,
      left: 40,
      lockUniScaling: true,
      padding: 1,
      originX: 'center',
      originY: 'center',
      strokeLineCap: 'round',
      strokeLineJoin: 'round',
      top: 40,
      transparentCorners: false,
      kerning: 1
    }
  },

  generateUUID() {
    let d = new Date().getTime()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
      d += performance.now()
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  },

  svgToBlob(name, svg) {
    return new Blob([svg], {type: "image/svg+xml;charset=utf-8", name: name});
  },

  svgToHTMLBlob(name, svg) {
    let htmlString = "<html><svg"
    htmlString += svg.split("<svg")[1]
    htmlString += "</html>"
    return new Blob([htmlString], {type: "text/html;charset=utf-8", name: name});
  },

  updateObjectWithDefaults(object) {
    let defaults = this.defaultCanvasObjProperties()

    object.borderColor = defaults.borderColor
    object.borderOpacityWhenMoving = defaults.borderOpacityWhenMoving
    object.borderScaleFactor = defaults.borderScaleFactor
    object.cornerSize = defaults.cornerSize
    object.cornerStyle = defaults.cornerStyle
    object.cornerColor = defaults.cornerColor
    object.cornerStrokeColor = defaults.cornerStrokeColor
    object.strokeLineCap = defaults.strokeLineCap
    object.strokeLineJoin = defaults.strokeLineJoin
    object.transparentCorners = defaults.transparentCorners
    object.kerning = defaults.kerning

    if(object.charSpacing == 1) {
      object.charSpacing = ''
    }

    if(object.type == 'image') {
      object.crossOrigin = 'Anonymous'
    }

    return object
  },

  exportCanvas(canvas, format) {
    if (!fabric.Canvas.supports('toDataURL')) {
      alert('This browser doesn\'t provide means to serialize canvas to an image')
    }
    else {
      let image = new Image()
      image.src = canvas.toDataURL(format)
      let w = window.open('')
      w.document.write(image.outerHTML)
    }
  },

  setStyle(styleName, value, object, canvas) {
    if (object.setSelectionStyles && object.isEditing) {
      let style = {}
      style[styleName] = value
      object.setSelectionStyles(style)
    }
    else {
      if (object.styles) {
        object.removeStyle(styleName);
      }
      object.set(styleName, value)
    }

    object.setCoords()
    canvas.renderAll()
  },

  reservedArea(w, h) {
    const box = new fabric.Rect({
      fill: "#EEE",
      top: Math.floor(h / 2.4),
      left: Math.floor(w / 2.4),
      width: Math.floor(w / 1.7),
      height:  Math.floor(h / 1.7),
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false,
      stroke: "#DDD"
    })

    const text = new fabric.Text('INK-FREE ADDRESS ZONE', {
      fontSize: 15,
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false,
      top:  Math.floor(h / 1.4),
      left:  Math.floor(w / 1.4)
    })

    return new fabric.Group([ box, text ], {
      top: Math.floor(h / 2.4) - 20,
      left: Math.floor(w / 2.4) - 20,
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false
    })
  },

  reservedAreaLetter(w, h) {
    const box = new fabric.Rect({
      fill: "#EEE",
      top: (0.84 * 96),
      left: (0.6 * 96),
      width: (3.15 * 96),
      height: (2 * 96),
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false,
      stroke: "#DDD"
    })
    const text = new fabric.Text('INK-FREE ADDRESS ZONE', {
      fontSize: 15,
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false,
      top: (0.84 * 96) + 96,
      left: (0.6 * 96) + ((3.15 * 96)/2)
    })

    return new fabric.Group([ box, text ], {
      top: (0.84 * 96),
      left: (0.6 * 96),
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false
    })
  },

  bleedArea(w, h) {
    return new fabric.Rect({
      top: 0,
      left: 0,
      width: w - 18.75,
      height: h - 18.75,
      fill: 'rgba(255, 255, 255, 0)',
      stroke: 'rgba(181, 216, 220, 0.80)',
      strokeWidth: 18.75,
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      evented: false,
      selectable: false,
      excludeFromExport: true
    })
  },

  setAlign(align, canvas) {
    let activeObj = canvas.getActiveObject(),
      horizontalCenter = (activeObj.width * activeObj.scaleX) / 2,
      verticalCenter = (activeObj.height * activeObj.scaleY) / 2,
      { width, height } = canvas

    switch (align) {
      case 'top':
        activeObj.set({ top: verticalCenter })
        break
      case 'left':
        activeObj.set({ left: horizontalCenter })
        break
      case 'bottom':
        activeObj.set({ top: height - verticalCenter })
        break
      case 'right':
        activeObj.set({ left: width - horizontalCenter })
        break
      case 'center':
        activeObj.set({ left: (width / 2) })
        break
      case 'middle':
        activeObj.set({ top: (height / 2) })
        break
    }
    activeObj.setCoords()
    canvas.renderAll()
  }
}

export default canvasHelpers
