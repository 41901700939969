import React from "react";
import { Dropdown, Label, Icon } from "semantic-ui-react";

class RulesTab extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      customDatesList: this.props.customDatesList
        ? this.listToOptions(this.props.customDatesList)
        : null,
      selectedCustomDate: this.props.selectedCustomDate || [],
      ruleOption: this.props.ruleOption,
      totalPrice: 0,
      totalCards: 0,
    };
    this.changeRadio = this.changeRadio.bind(this);
  }

  listToOptions = (list) => {
    return list.map((item) => {
      return { key: item, value: item, text: item };
    });
  };

  getTotalValues = () => {
    let customDates = this.state.ruleOption == "asap" ? [] : this.state.selectedCustomDate
    fetch(`automations/${this.props.guid}/total_values`, {
      method: "POST",
      body: JSON.stringify({
        selected_dates: customDates,
        rule_option: this.state.ruleOption
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.authToken,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ totalCards: data.amount, totalPrice: data.price });
      })
      .catch((error) => console.log(error));
  };

  updateCustomDate = (event, value) => {
    this.setState({ selectedCustomDate: value }, () => {
      this.getTotalValues();
    });
    this.props.updateField("selectedCustomDate", value, "rulesChanged");
  };

  changeRadio(event) {
    if (event.target.id == "asap"){
      this.setState({ ruleOption: event.target.id, selectedCustomDate: [] }, () => {
        this.getTotalValues();
      });
      this.props.updateField("selectedCustomDate", [], "rulesChanged");
    } else {
      this.setState({ ruleOption: event.target.id }, () => {
        this.getTotalValues();
      });
    }

    this.props.updateField("ruleOption", event.target.id, "rulesChanged");
  }

  componentDidMount() {
    this.getTotalValues();
    if (this.state.customDatesList) {
      fetch(`/automations/${this.props.guid}/custom_dates_list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.props.authToken,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Not success");
          }
          return response.json();
        })
        .then((data) => {
          this.setState({ customDatesList: this.listToOptions(data) });
        })
        .catch((error) => console.log(error));
    }
  }

  render() {
    let customDateSelected = this.state.ruleOption == "select";
    let asapSelected = this.state.ruleOption == "asap";
    return (
      <div className="automation-tab">
        <div className="rule-content-block field-group">
          <div className="rule-left-aside">
            <div className="title">Delivery Timing</div>
          </div>
          <div className="rule-content">
            <div className="field">
              <div className="RadioRow column">
                <div className="label-cover">
                  <input
                    id="asap"
                    type="radio"
                    value="asap"
                    checked={asapSelected}
                    name="campaign[design_option]"
                    onChange={this.changeRadio}
                  />

                  <label className="RadioButton" htmlFor="asap"></label>
                  <div className="rule-label-column">
                    <label htmlFor="asap">ASAP</label>
                    <label className="recipients-label mgl-7">
                      Mail will be sent every week in specific days
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="RadioRow column">
                <div className="label-cover">
                  <input
                    id="select"
                    type="radio"
                    value="select"
                    checked={customDateSelected}
                    name="campaign[design_option]"
                    onChange={this.changeRadio}
                  />
                  <label className="RadioButton" htmlFor="select"></label>
                  <div className="rule-label-column">
                    <label htmlFor="select">Customers Dates</label>
                    <label className="recipients-label mgl-7">
                      Mail will be sent every month in specific days
                    </label>
                  </div>
                </div>
                <div
                  className={`recipients-form-cover custom-dates ${
                    !customDateSelected && "disabledBlock"
                  }`}
                >
                  <div>
                    <Dropdown
                      className="recipients-input"
                      placeholder="Customer's date"
                      options={this.state.customDatesList}
                      multiple
                      name="skills"
                      selection
                      value={this.state.selectedCustomDate}
                      onChange={(event, data) => {
                        this.updateCustomDate(event, data.value);
                      }}
                    />
                    <div className="ztt-multiple-select-wrapper">
                      {this.state.selectedCustomDate &&
                        this.state.selectedCustomDate.map((item, index) => {
                          return (
                            <Label
                              key={index.key}
                              as="a"
                              className="ztt-multiple-select-label"
                              content={item}
                            >
                              {item}
                              <Icon
                                link
                                name="close"
                                onClick={(e, value) => {
                                  e.stopPropagation();
                                  let values = this.state.selectedCustomDate.filter(
                                    (option) => option != item
                                  );
                                  this.setState({ selectedCustomDate: values });
                                  let newArr = values;
                                  this.updateCustomDate(
                                    event,
                                    newArr
                                  );
                                  return e.preventDefault();
                                }}
                              />
                            </Label>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="FormDivision"></hr>
        <div className="field-group">
          <div className="fields-block grid-2 recipients etsy">
            <div className="field">
              <div className="subtitle rule mg-0">
                Sticking to the automation, this month there are{" "}
                {this.state.totalCards} postcards needed to be produced and
                send. It will be start only you run the automation.
              </div>
            </div>
            <div className="field">
              <div>
                <p className="rule-prices-text-block">
                  Postcards amount:{" "}
                  <div className="rule-prices-width">
                    {this.state.totalCards}
                  </div>
                </p>
              </div>
              <div>
                <p className="rule-prices-text-block">
                  <strong>
                    Monthly price:{" "}
                    <div className="rule-prices-width">
                      <span className="icon-Mailfold-coin"></span>{" "}
                      {this.state.totalPrice.toFixed(2)}
                    </div>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RulesTab;
