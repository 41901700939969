import React from "react";
import PropTypes from "prop-types";
import Postcard from "./Postcard";
import Size from "./Size";
import SizeOption from "./SizeOption";
import ErrorMessage from "./ErrorMessage";
import DesignOptions from "./DesignOptions";

const scales = ["fill", "crop", "scale", "plain"];

export default class extends React.Component {
  static propTypes = {
    backId: PropTypes.number,
    frontId: PropTypes.number,
    size: PropTypes.oneOf(["4x6", "6x9", "6x11", "8.5x11"]),
    guid: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    designOption: PropTypes.oneOf(["create", "upload"]),
  };

  constructor(props) {
    super(props);

    this.sizes = {
      "4x6": new SizeOption({
        widthInInches: 6,
        heightInInches: 4,
        className: "Small",
        price: 0.89,
      }),
      "6x9": new SizeOption({
        widthInInches: 9,
        heightInInches: 6,
        className: "Medium",
        price: 1.21,
      }),
      "6x11": new SizeOption({
        widthInInches: 11,
        heightInInches: 6,
        className: "Large",
        price: 1.49,
      }),
      "8.5x11": new SizeOption({
        widthInInches: 11,
        heightInInches: 8.5,
        className: "Letter",
        price: 1.25,
      }),
    };

    let id = props.size;
    if (!props.size) {
      id = "4x6";
      console.info("No size id found, using 4x6");
    }

    const size = this.sizes[id];
    if (!size) {
      console.error("No size found for id", id, "in", this.sizes);
    }

    console.info("Set default size", size);

    this.state = {
      size: size,
      frontIsValid: false,
      backIsValid: false,
      saveButton: "Save & Exit",
      isLoading: false,
      nextStepLoadingMessage: "Next Step",
      frontId: props.frontId || "",
      backId: props.backId || "",
      errorMessage: props.errorMessage,
      designOption: props.designOption || "create",
    };

    this.sizeChanged = this.sizeChanged.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onNextPage = this.onNextPage.bind(this);
    this.designOptionChanged = this.designOptionChanged.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    window.location.href=`/campaigns/${this.props.guid}/edit`;
  }

  onNextPage() {
    this.setState({ isLoading: true, nextStepLoadingMessage: "Saving" });
    this.onSave();
    this.saveAll()
      .then(() => {
        if (this.isFrontAndBackValid) {
          console.info("Postcards are valid, submit form");
          this.refs.form.submit();
        } else {
          console.error("[Bug] Postcards are not valid, do not submit");
        }
      })
      .catch((error) => {
        console.error("OnNextPage error", error);
      });
  }

  onSave(event) {
    console.debug("Called onSave in Design.js");
    event?.preventDefault();
    this.setState({ saveButton: "Saving ..." });

    this.saveAll()
      .then(() => {
        window.location.href = "/campaigns";
        console.info("Finished saving");
      })
      .catch((error) => {
        console.error("Error in Design#save", error);
      });
  }

  saveAll() {
    // TODO: Handle errors
    const finished = () => {
      this.setState({ saveButton: "✓" });

      setTimeout(() => {
        this.setState({ saveButton: "Save & Exit" });
      }, 2000);
    };

    return Promise.all([
      this.refs.front.save(),
      this.refs?.back?.save(),
      this.saveDesignOption(this.state.designOption),
      this.save(),
    ])
      .then(finished)
      .catch(finished);
  }

  toField(type, name) {
    return `campaign[${type}_attributes][${name}]`;
  }

  // @postcard = back / front
  // @validity Is the postcard valid?
  onValid(postcard, validity) {
    console.info("Update postcard", postcard);
    switch (postcard) {
      case "front":
        return this.setState({ frontIsValid: validity });
      case "back":
        return this.setState({ backIsValid: validity });
    }
  }

  sizeChanged(size) {
    this.setState({ size });
    this.saveSize(size.id());
  }

  get sizeId() {
    return this.state.size.id();
  }

  get authToken() {
    return $('meta[name="csrf-token"]').attr("content");
  }

  saveSize(size) {
    return new Promise((resolve, reject) => {
      $.post(`/campaigns/${this.props.guid}/size/${size}`, {
        _method: "PATCH",
        authenticity_token: this.authToken,
      })
        .done((result) => {
          console.debug("Finished saving postcard size");
          resolve(result);
        })
        .error((error) => {
          console.error("Error saving postcard size", error);
          reject(error);
        });
    });
  }

  saveDesignOption(designOption) {
    return new Promise((resolve, reject) => {
      $.post(`/campaigns/${this.props.guid}`, {
        _method: "PATCH",
        authenticity_token: this.authToken,
        "campaign[design_option]": designOption,
      })
        .done((result) => {
          console.debug("Finished saving postcard design option");
          resolve(result);
        })
        .error((error) => {
          console.error("Error saving postcard design option", error);
          reject(error);
        });
    });
  }

  save() {
    return new Promise((resolve, reject) => {
      this.saveSize(this.sizeId)
        .done((result) => {
          console.debug("Finished saving design");
          resolve(result);
        })
        .error((error) => {
          console.error("Error saving design", error);
          reject(error);
        });
    });
  }

  get arePostcardsValid() {
    if (this.state.isLoading) {
      return false;
    }

    return this.isFrontAndBackValid;
  }

  get isFrontAndBackValid() {
    return this.state.frontIsValid && this.state.backIsValid;
  }

  height() {
    return this.state.size.height;
  }

  width() {
    return this.state.size.width;
  }

  heightInInches() {
    return this.state.size.heightInInches;
  }

  widthInInches() {
    return this.state.size.widthInInches;
  }

  designOptionChanged(designOption) {
    this.setState({ designOption });
    this.saveDesignOption(designOption);
  }

  render() {
    const { size } = this.state;
    const vertical = size.className;
    return (
      <div className="InnerColumnContentWrapper">
        <div className="InnerColumnContent UploadForm">
          
          <If condition={this.state.errorMessage}>
            <ErrorMessage body={this.state.errorMessage} />
          </If>

          <div className="Clear"></div>

          <form
            key="edit-design"
            className="edit_design"
            action={`/campaigns/${this.props.guid}/verify_designs`}
            acceptCharset="UTF-8"
            ref="form"
            method="post"
          >
            <Size
              sizes={this.sizes}
              size={this.state.size}
              onChange={this.sizeChanged}
            ></Size>

            <div className="Clear"></div>

            <DesignOptions
              designOption={this.state.designOption}
              onChange={this.designOptionChanged}
            ></DesignOptions>

            <hr className="FormDivision" />
            <div className="Clear"></div>

            <If condition={this.state.designOption == "upload"}>
              <fieldset className="FileSelect" id="card-design">
                <h2>Upload Design Files</h2>
                <p id="UploadTip">
                  <span>
                    {this.heightInInches()}″ × {this.widthInInches()}″ postcards
                    must be{" "}
                    <b>
                      {this.width()}px × {this.height()}px.
                    </b>
                    That's {this.heightInInches()}.25″ × {this.widthInInches()}
                    .25″ @ 300dpi with the bleed.
                  </span>
                  PNG, PDF, and JPG formats accepted.
                </p>

                {// <div className="TemplateLinks">
                //   <h3>Additional Help</h3>
                //   <a href="http://support.mailfold.com/frequently-asked-questions/tools-for-designing-postcards">
                //     Simple Design Tools
                //   </a>
                //   <a href="https://support.mailfold.com/frequently-asked-questions/designing-a-postcard">
                //     Sizes and Templates
                //   </a>
                // </div>
                }
                <div className="Clear"></div>
              </fieldset>
            </If>
          <div className="fieldset-wrapper">
            <Postcard
              key="front"
              ref="front"
              type="front"
              mailer="campaigns"
              size={this.state.size}
              guid={this.props.guid}
              onValid={this.onValid}
              id={this.state.frontId}
              designOption={this.state.designOption}
              letter={vertical}
            />

            {vertical !== "Letter" ? (
              <Postcard
                key="back"
                ref="back"
                type="back"
                mailer="campaigns"
                size={this.state.size}
                guid={this.props.guid}
                onValid={this.onValid}
                id={this.state.backId}
                designOption={this.state.designOption}
                letter={vertical}
              />
            ) : null}
            </div>
            <div className="SubmitContainer">
              <div className="InnerColumnContentWrapper">
                <div className="InnerColumnContent buttons">
                  <input
                      type="button"
                      value="Go Back"
                      className="Button ExitButton"
                      onClick={this.goBack}
                  />

                  <div>
                    <input
                        type="submit"
                        name="commit"
                        value={this.state.saveButton}
                        className="Button OutlinedButton"
                        onClick={this.onSave}
                    />

                    <input
                        type="submit"
                        name="commit"
                        value={this.state.nextStepLoadingMessage}
                        id="NextStepButton"
                        className="Button PurpleSubmit UploadSubmit"
                        onClick={this.onNextPage}
                    />
                  </div>

                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
