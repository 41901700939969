import React, { useRef, useState } from 'react';
import cn from "classnames";
import Warning from './Warning';

const initialState = {
    business_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    country: "",
    state: "",
    state_2: "",
    postal_code: "",
}

const styles = {
    error: {
        border: "1px solid rgba(256, 0, 0, 0.8)",
    },
    marginleft20: {
        marginLeft: '20px'
    }
}

const ReturnAddressForm = ({ return_address, countries = [['United States', 'US']], states = [] }) => {

    const form = useRef(null)
    const [errors, setErrors] = useState(() => ({}))
    const [fields, setFields] = useState(() => ({ ...initialState, ...return_address, state_2: return_address.state }))

    const getWarning = (data) => {
        if (Array.isArray(data)) {
            return data.join('. ');
        }

        if (data) {
            return data;
        }
        return "";
    }
    const handleChange = ({ target: { id, value } }) => {
        const field = id.replace("address_", "")
        setFields(prev => ({ ...prev, [field]: value }))
    }

    const authToken = () => { return $('meta[name="csrf-token"]').attr("content"); }

    const onSubmit = (event) => {

        event.preventDefault();
        const formData = new FormData(form.current);
        setErrors({})
        fetch(`/update_return_address`, {
            body: formData,
            method: "POST",
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error("Not success");
                }

                return response.json();
            })
            .then(({ errors = {} }) => {
                setErrors(errors)
            })
            .catch(console.debug);
    }
    return (
        <form
            className="edit_address"
            id="edit_address_28"
            action="/update_return_address"
            acceptCharset="UTF-8"
            method="post"
            onSubmit={onSubmit}
            ref={form}
        >
            <input
                type="hidden"
                name="authenticity_token"
                value={authToken()}
            />
            <fieldset className="custom-padding">
                <h2>Return Address</h2>
                <div className='adresswrapper'>
                    <div className='adresswraper-input'>
                        <label htmlFor="address_business_name">Business Name</label>
                        <input
                            placeholder="Fiddle Leaf Fig Company"
                            type="text"
                            value={fields?.business_name}
                            name="address[business_name]"
                            id="address_business_name"
                            onChange={handleChange}
                            style={errors?.business_name ? styles.error : {}}
                        />
                <       Warning data={getWarning(errors?.business_name)} />
                    </div>
                    <div className='adresswraper-input'>
                        <label htmlFor="address_city">City</label>
                        <input
                            placeholder="Maplewood"
                            type="text"
                            // pattern="^[a-zA-Z\s]*$"
                            // title="City should only contain letters"
                            value={fields?.city}
                            name="address[city]"
                            id="address_city"
                            onChange={handleChange}
                            className={cn({ error: !!errors?.city })}
                            style={errors?.city ? styles.error : {}}
                        />
                        <Warning data={getWarning(errors?.city)} />
                    </div>
                    <div className='adresswraper-input'>
                        <label htmlFor="address_country">Country</label>
                        <select
                            className="CountryField"
                            style={errors?.country ? styles.error : {}}
                            name="address[country]"
                            id="address_country"
                            value={fields?.country}
                            onChange={handleChange}
                        >
                            {countries.map(([country, key]) => (
                                <option
                                    key={key}
                                    value={key}

                                >
                                    {country}
                                </option>)
                            )}
                        </select>
                        <Warning data={getWarning(errors?.country)} />
                    </div>
                </div>
                 <div className='adresswrapper'>
                    <div className='adresswraper-input'>
                        <label htmlFor="address_address_line_1">Address Line 1</label>
                        <input
                            placeholder="345 Oak St."
                            type="text"
                            value={fields?.address_line_1}
                            name="address[address_line_1]"
                            id="address_address_line_1"
                            onChange={handleChange}
                            style={errors?.address_line_1 ? styles.error : {}}
                        />
                        <Warning data={getWarning(errors?.address_line_1)} />
                    </div>
                    <div className='adresswraper-input'>
                        <label htmlFor="address_address_line_2">Address Line 2</label>
                        <input
                            placeholder="Apartment, Suite, or Floor"
                            type="text"
                            value={fields?.address_line_2}
                            name="address[address_line_2]"
                            id="address_address_line_2"
                            onChange={handleChange}
                            style={errors?.address_line_2 ? styles.error : {}}
                        />
                        <Warning data={getWarning(errors?.address_line_2)} />
                    </div>
                 </div>
                 <div className='adresswrapper'>
                    <div className='adresswraper-input'>
                        <label htmlFor="address_state">State/Province/Region</label>
                        <select
                            className="StatesDropDown"
                            name="address[state]"
                            id="address_state"
                            value={fields?.state}
                            onChange={handleChange}
                        >
                            {states.map(([state, key]) => (
                                <option
                                    key={key}
                                    value={key}

                                >
                                    {state}
                                </option>)
                            )}
                        </select>
                    </div>
                    <div className='adresswraper-input'>
                        <label htmlFor="address_postal_code">Postal Code</label>
                        <input
                            placeholder="00000"
                            type="text"
                            value={fields?.postal_code}
                            name="address[postal_code]"
                            id="address_postal_code"
                            onChange={handleChange}
                            className="StatesTextField"
                            style={errors?.postal_code ? styles.error : {}}
                        />
                        <Warning data={getWarning(errors?.postal_code)} />
                    </div>
                 </div>
                <input
                    type="submit"
                    name="commit"
                    value="Update"
                    className="Button PurpleSubmit"
                    // data-disable-with="Update"
                />
            </fieldset>
        </form>
    );
};

export default ReturnAddressForm;
