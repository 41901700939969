import React, { Fragment } from "react";

function DeleteModalPopUp(props) {
  const {
    allContactsId,
    allRecipientsId,
    listType,
    deleteAccess,
    contactView,
    listTableName,
    openedListId,
    contactInfo,
    handleDeleteAllRequest,
    destroyLists,
    destroyContactsFromList,
    destroyContacts,
    closeModal,
  } = props;

  return (
    <Fragment>
      <div className="contacts-segment-container small">
        <div className="contacts-segment-body">
          {allContactsId.length > 1 && (
            <>
              <h3 className="segment-modal-small-title">Delete all lists</h3>
              <h4 className="segment-modal-small-subtitle">
                We want to make sure you actually want to delete all lists. Once
                you delete it, it can't be undone. Write DELETE (in all caps) in
                the textbox below to confirm.
              </h4>
              <input
                className="segment-modal-input"
                placeholder="DELETE"
                type="text"
                value={deleteAccess}
                onChange={(e) => handleDeleteAllRequest(e)}
              />
            </>
          )}
          {allContactsId.length === 1 && (
            <>
              <h3 className="segment-modal-small-title">Delete List</h3>
              <h4 className="segment-modal-small-subtitle">
                Are you sure that you want to delete this list?
              </h4>
            </>
          )}

          {allRecipientsId.length > 1 && (
            <>
              <h3 className="segment-modal-small-title">
                Delete all contacts from this{" "}
                {listType === "list"
                  ? "list"
                    ? listType === "segment"
                    : "segment"
                  : ""}
              </h3>
              <h4 className="segment-modal-small-subtitle">
                We want to make sure you actually want to delete all contacts
                from this
                {listType === "list"
                  ? "list"
                    ? listType === "segment"
                    : "segment"
                  : ""}
                . Once you delete it, it can't be undone. Write DELETE (in all
                caps) in the textbox below to confirm.
              </h4>
              <input
                className="segment-modal-input"
                placeholder="DELETE"
                type="text"
                value={deleteAccess}
                onChange={(e) => handleDeleteAllRequest(e)}
              />
            </>
          )}
          {allRecipientsId.length === 1 && (
            <>
              <h3 className="segment-modal-small-title">Delete contact</h3>
              <h4 className="segment-modal-small-subtitle">
                Are you sure that you want to delete this contact?
              </h4>
            </>
          )}
          {contactView && (
            <>
              <h3 className="segment-modal-small-title">Delete contact</h3>
              <h4 className="segment-modal-small-subtitle">
                Are you sure that you want to delete this contact{" "}
                {!openedListId
                  ? `from ALL your lists and segments`
                  : `from ${listTableName}`}
                ?
              </h4>
            </>
          )}
        </div>
        <div className="segment-modal-footer">
          <div className="grid grid-2 segment-modal-buttons">
            <div className="grid-item">
              <button className="gray-button" onClick={() => closeModal()}>
                Cancel
              </button>
            </div>
            <div className="grid-item">
              <button
                className="blue-button"
                onClick={() => {
                  allContactsId && destroyLists(allContactsId, deleteAccess);
                  allRecipientsId &&
                    openedListId &&
                    destroyContactsFromList(allRecipientsId, deleteAccess);
                  allRecipientsId &&
                    !openedListId &&
                    destroyContacts(allRecipientsId, deleteAccess);
                  contactView &&
                    !openedListId &&
                    destroyContacts([contactInfo.customer_id]);
                  contactView &&
                    openedListId &&
                    destroyContactsFromList([contactInfo.customer_id]);
                }}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DeleteModalPopUp;
