import React from "react";
import { Dropdown, Input, Label, Icon } from "semantic-ui-react";
import * as Papa from "papaparse";
import XLSX from "xlsx";
import CustomListMapping from "./CustomListMapping";

class ContactsTab extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      file: "",
      name: this.props.selectedListName,
      sampleData: [],
      defaultPositions: {},
      fileName: "Select a .CSV or .XLSX file",
      listOptions: this.props.userLists.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      }),
      selectedList: this.props.selectedList,
      isLoading: false,
      saveButton: "Save",
      saveDisabled: true,
      fileUploaded: false,
      etsyConnected: this.props.etsyConnected,
    };
  }

  updateListValue = (event, data) => {
    let selectedList = this.state.listOptions.find(e => e.value == data.value)
    let value = { id: selectedList.value, name: selectedList.text }
    this.setState({ selectedList: value });
    this.props.updateField("selectedList", value, "recipientsChanged");
  };

  updateListName = (event) => {
    this.setState({ name: event.target.value });
  };

  acceptedFileFormats() {
    return [
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ].join(", ");
  }

  setDefaultPositions = (defaultPositions) => {
    let saveDisabled = !this.isMappingsValid();

    this.setState({
      defaultPositions,
      saveDisabled,
    });
  };

  isMappingsValid = () => {
    const { defaultPositions } = this.state;
    const presentKeys = Object.keys(defaultPositions);

    const requiredKeys = ["addressLine1", "city", "state", "postalCode"];
    const requiredNameKeys = ["firstName", "lastName", "businessName"];

    const gotRequiredKeys = requiredKeys.every((k) => k in defaultPositions);
    const gotRequiredNameKeys = requiredNameKeys.some(
      (v) => presentKeys.indexOf(v) !== -1
    );
    return gotRequiredKeys && gotRequiredNameKeys;
  };

  fileOnSelect = (file) => {
    if (!file) return console.log("No file found!");

    const fileExtension = file.name.split(".").pop();
    const context = this;

    if (fileExtension === "csv") {
      Papa.parse(file, {
        complete: (results) => {
          context.setState({
            sampleData: results.data,
            fileName: file.name,
            file,
          });
        },
      });
    } else if (fileExtension === "xlsx") {
      let reader = new FileReader();
      reader.onload = (e) => {
        let binaryString = e.target.result;
        let workBook = XLSX.read(binaryString, { type: "binary" });
        let first_worksheet = workBook.Sheets[workBook.SheetNames[0]];
        let data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        context.setState({
          sampleData: data,
          fileName: file.name,
          file,
        });
      };
      reader.readAsBinaryString(file);
    } else {
      throw new TypeError(`${file.type} File Type not supported.`, file.name);
    }
  };

  connectYourStore() {
    window.location.href = `/contacts/integrations`;
  }

  goToContacts() {
    window.location.href = `/contacts`;
  }

  saveList = (e) => {
    e.preventDefault();
    this.setState({ saveButton: "Saving ..." });
    const finished = () => {
      this.setState({ saveButton: "✓" });

      setTimeout(() => {
        this.setState({ saveButton: "Uploaded" });
      }, 2000);
    };

    let formData = new FormData();
    let { file, name, defaultPositions } = this.state;

    if (!this.isMappingsValid()) {
      throw new Error("Required Data Missing!");
      return;
    }

    formData.append("file", file);
    formData.append("name", name);
    formData.append("mappings", JSON.stringify(defaultPositions));

    fetch(`/process_list`, {
      body: formData,
      method: "POST",
    })
      .then(this.handleErrors)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ fileUploaded: true });
        this.props.updateField(
          "selectedListType",
          "select",
          "recipientsChanged"
        );
        this.props.updateField(
          "selectedList",
          [data.address_book_id],
          "recipientsChanged"
        );
      })
      .then(finished)
      .catch((error) => console.log(error));
  };

  render() {

    return (
      <div className="automation-tab recipients">
        <div className="field-group">
          <div className="grid grid-2 contacts-tab">
            <div className="grid-item contacts-tab">
              <div className="title">Contacts</div>
              <div className="subtitle recipients mg-0">
                All customers you work with
              </div>
              <div className="fields-block recipients">
                <div>
                  <div className="field">
                    <div className="RadioRow column">
                      <div className={`recipients-form-cover contacts`}>
                        <div>
                          <Dropdown
                            className="recipients-input contacts-tab-dropdown"
                            placeholder={
                              this.state.selectedList
                                ? this.state.selectedList.name
                                : ""
                            }
                            options={this.state.listOptions}
                            selection
                            onChange={(event, data) => {
                              this.updateListValue(event, data);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item contacts-tab">
              <div className="field-group">
                <div className="fields-block contacts recipients ">
                  <div className="field">
                    <div className="title">No lists ?</div>
                    <div className="subtitle recipients mg-0">
                      You can easily do it by clicking on the button below.
                    </div>
                  </div>
                  <div className="field">
                    <div className="button-cover">
                      <button
                        className="green-button margin-bottom-s contacts"
                        onClick={() => this.goToContacts()}
                      >
                        Add new list
                      </button>
                    </div>
                    <div className="subtitle recipients mg-0">
                      You’ll be taken to contacts page. We’ll see you in just a
                      minute.
                    </div>
                  </div>
                </div>
              </div>
              <div className="field-group">
                <div className="fields-block contacts recipients etsy">
                  <div className="field">
                    <div className="title">
                      {this.state.etsyConnected
                        ? "Connected with something"
                        : "Integration"}
                    </div>
                    <div className="subtitle recipients mg-0">
                      This will only need to happen once. You'll be taken to
                      Etsy's website where you'll need give Mailfold access to
                      your customer list.
                    </div>
                  </div>
                  <div className="field">
                    <div className="button-cover">
                      <button
                        className="green-button margin-bottom-s contacts"
                        disabled={this.state.etsyConnected}
                        onClick={() => this.connectYourStore()}
                      >
                        {this.state.etsyConnected
                          ? "Connected to some store"
                          : "Connect your store"}
                      </button>
                    </div>
                    <div className="subtitle recipients mg-0">
                      {!this.state.etsyConnected &&
                        `
                    You'll be taken to Etsy's website. We'll see you in just a
                    minute.
                   `}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactsTab;
