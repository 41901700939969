import { object } from "prop-types";
import React from "react";
import ModalCrop from "./components/ModalCrop";
import { putData } from "../../lib/fetches";

var ReactS3Uploader = require('react-s3-uploader');

class AgencyEdit extends React.Component {
    constructor(props) {
      props;
      super(props);
      this.state = {
        formVisible: false,
        showModal: false,
        imgUrl: null,
        previewUrl: null,
        id: props.agency.id,
        name: props.agency.name,
        description: props.agency.description,
        email: props.agency.email,
        logoUrl: props.agency.logo
      };
      this.imgRef = React.createRef();
      this.submitUpdates = this.submitUpdates.bind(this);
      this.handleFile = this.handleFile.bind(this);
      this.onSelectFile = this.onSelectFile.bind(this);
      this.cancelUpload = this.cancelUpload.bind(this)
      this.deleteLogo = this.deleteLogo.bind(this)

      this.setPreviewUrl = this.setPreviewUrl.bind(this);
      this.setLogoUrl = this.setLogoUrl.bind(this);
      this.checkDataValid = this.checkDataValid.bind(this);
    }

    submitUpdates(e){
      e.preventDefault();
      if(this.checkDataValid()) {
        putData(`/agencies/${this.state.id}`, this.props.authToken, {
          agency: {
            name: this.state.name,
            description: this.state.description,
            email: this.state.email,
            logo: this.state.logoUrl
          }
        }, (res) => {
          this.setState({
            formVisible: false,
            name: res.agency.name,
            description: res.agency.description,
            email: res.agency.email,
            logoUrl: res.agency.logo
          })
        });
      }
    }

    handleFile() {
      this.imgRef.current.click()
    }

    onSelectFile(e) {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {

          this.setState({
            showModal: true,
            imgUrl: reader.result
          })
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    cancelUpload() {
      this.imgRef.current.value = null;
      this.setState({
        showModal: false,
        PreviewUrl: null
      })
    }

    deleteLogo() {
      this.setState({
        previewUrl: null,
        logoUrl: null
      })
    }

    setPreviewUrl(url) {
       this.setState({
        previewUrl: url
      })
    }

    setLogoUrl(url) {
       this.setState({
        logoUrl: url,
        showModal: false,
      })
    }

    checkDataValid() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.state.email).toLowerCase()) && this.state.name.length > 0
    }

    render() {
      let imageUrl = this.state.logoUrl || this.state.previewUrl
      if(this.state.formVisible) {
        return(
          <div className="edit-form-container">
            <h2 className="settings-agency-title">
              Agency's logo
            </h2>
            <p>
              Here you can upload a logo of your agency
            </p>
            <div className="logo-upload-container">

              <div className="logo-upload-box">
                {imageUrl ? ( <img src={imageUrl}/> ) : "LOGO"}
              </div>
              <input onChange={this.onSelectFile} ref={this.imgRef} className="logo-upload-input" type="file"></input>
              <button onClick={this.handleFile} className="green-bordered-button max-wd-170 agency">
                Upload new Logo
              </button>
              {this.state.showModal &&
              <ModalCrop setPreviewUrl={this.setPreviewUrl} setLogoUrl={this.setLogoUrl}cancel={this.cancelUpload} src={this.state.imgUrl} agency={this.props.agency}/>
              }
              <span onClick={this.deleteLogo} className="settings-agency-delete">
                Delete
              </span>
            </div>
            <h2 className="settings-agency-title">
              Agency's information
            </h2>
            <form className="agency-info-form">
              <div className="agency-info-area">
                <label htmlFor="company-name">Company name</label>
                <input className={!this.state.name.length > 0 ? "field-error" : ""} id="company-name" type="text" value={this.state.name} onChange={(e)=> this.setState({name: e.target.value})} />
              </div>
              <div className="agency-info-area agency-info-area-with-edit">
                <label htmlFor="company-email">Email</label>
                <input id="company-email" placeholder="company@gmail.com" onChange={(e)=> this.setState({email: e.target.value})} value={this.state.email} type="email" />
              </div>
              <div className="agency-info-area">
                <label htmlFor="company-description">Short description</label>
                <textarea id="company-description" rows={5} value={this.state.description} onChange={(e)=> this.setState({description: e.target.value})} defaultValue={"Some text about agency. This agency is the most incredible agency in the world!"} />
              </div>
              <button className="agency-update-btn" onClick={(e) => this.submitUpdates(e)} disabled={!this.checkDataValid()}>Update all information</button>
            </form>
          </div>
        )
      } else {
        return(
          <div>
              <h2 className="settings-agency-title">
                  Your Agency Information
              </h2>
              <div className="agency-info-container">
                  <div className="agency-card-info-container">
                      <div className="agency-card-info-header">
                          <div className="agency-card-logo-box">
                          {this.state.logoUrl &&
                           <img src={this.state.logoUrl}/>
                          }
                          </div>
                          <h3 className="agency-card-info-header-title">
                              {this.state.name}
                          </h3>
                      </div>

                      <div className="agency-card-info-content">
                        <p>
                          {this.state.description}
                        </p>
                      </div>
                  </div>
                  <button className="green-bordered-button max-wd-170 agency" onClick={()=> this.setState({formVisible: true})}>
                      Edit information
                  </button>
              </div>
          </div>
        )
      }
    }
  }

  export default AgencyEdit;
