import React, { Fragment, useState, useEffect } from "react";

import Close from "../../../../assets/images/btn_close.svg";

function DelClientPopUp({ onClose, deleteItems, selectedItems }) {

  return (
    <>
      <div className="agency-blog-modal client-modal-content">
        <div className="agency-blog-modal-content">
          <div className="agency-blog-modal-container">
            <div className="agency-blog-closeAgency">
              <img src={Close} alt="close image" onClick={() => onClose()} />
            </div>
            <h2 className="agency-blog-modal-title">Delete client?</h2>
            <p>Are you sure that you want to delete the client from the list?</p>
            <div className="agency-blog-modal-bottom-buttons">            
            <button
              className="green-button"
              onClick={() => {
                deleteItems(selectedItems);
                onClose();
              }}
            >
              Yes, Delete
            </button>
            <button
              className="blue-button"
              onClick={() => {
                onClose();
              }}
            >
              No, don’t
            </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DelClientPopUp;
