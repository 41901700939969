import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { deleteData } from "../../lib/fetches";

function CancelAccount(props) {
  const [open, setOpen] = useState(false);

  const confirmDelete = () => {
    deleteData('/cancel_account', props.authToken, (data) => {
      window.location.href = data.url
    })
  }

  return (
    <div>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
        dimmer="blurring"
        trigger={<button className="Button RedButton">Cancel Account</button>}
        className={"ModalCancelAccount"}
      >
        <Modal.Header>
          <p>
            Deleting account
          </p>
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure to delete your account and all related data? This action
            cannot be undone.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <button className="Button GreenSubmit" onClick={() => setOpen(false)}>
            No
          </button>
          <button className="Button RedButton" onClick={confirmDelete}>
            Cancel Account
          </button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default CancelAccount;
