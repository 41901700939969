import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import folderIcon from "images/folder-demography.svg";
import listIcon from "images/accordion-icon.svg";

export default class DemographyBar extends Component {
    constructor(props) {
      super(props)

      this.state = {
        activeIndexs: [0],
      }
    }

    handleClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeIndexs } = this.state;
      const newIndex = activeIndexs;
  
      const currentIndexPosition = activeIndexs.indexOf(index);
      if (currentIndexPosition > -1) {
        newIndex.splice(currentIndexPosition, 1);
      } else {
        newIndex.push(index);
      }
  
      this.setState({ activeIndexs: newIndex });
    };

  render() {
    const { activeIndexs } = this.state;

    return (
      <Accordion
        exclusive={false}
      >

        <Accordion.Title
          active={activeIndexs.includes(0)}
          index={0}
          onClick={this.handleClick}
        >
          <span className="close-icon"></span>
          <img src={folderIcon}></img>
          General options
        </Accordion.Title>
        <Accordion.Content active={activeIndexs.includes(0)}>

          {this.props.targetValue === "individuals" &&
          
            <ul className="accordion-list">

              <li className={`accordion-list-item ${this.props.activeOption === "HouseholdIncome" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("HouseholdIncome")}}>

                <img src={listIcon}></img>
                Household income
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "ContactAge" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("ContactAge")}}>
                <img src={listIcon}></img>
                Contact age
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "OwnerRenter" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("OwnerRenter")}}>
                <img src={listIcon}></img>
                Owner/Renter
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "MartialStatus" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("MartialStatus")}}>
                <img src={listIcon}></img>
                Martial status
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "LengthOfResidency" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("LengthOfResidency")}}>
                <img src={listIcon}></img>
                Length of residency
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "AgeCode" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("AgeCode")}}>
                <img src={listIcon}></img>
                Age code
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "NumberOfAllPeople" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("NumberOfAllPeople")}}>
                <img src={listIcon}></img>
                Number of all people
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "NumberOfAdults" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("NumberOfAdults")}}>
                <img src={listIcon}></img>
                Number of adults
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "NumberOfChildren" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("NumberOfChildren")}}>
                <img src={listIcon}></img>
                Number of children
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "CreditCard" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("CreditCard")}}>
                <img src={listIcon}></img>
                Credit card
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "Mail" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("Mail")}}>
                <img src={listIcon}></img>
                Mail
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "NetWorth" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("NetWorth")}}>
                <img src={listIcon}></img>
                Net worth
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "HomeValuation" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("HomeValuation")}}>
                <img src={listIcon}></img>
                Home valuation
              </li>

            </ul>
          }

          {this.props.targetValue === "businesses" &&

            <ul className="accordion-list">

              {/* <li className={`accordion-list-item ${this.props.activeOption === "SICcode" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("SICcode")}}>
                <img src={listIcon}></img>
                SIC code
              </li> */}

              <li className={`accordion-list-item ${this.props.activeOption === "NumberOfEmployees" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("NumberOfEmployees")}}>
                <img src={listIcon}></img>
                Number of employees
              </li>

              <li className={`accordion-list-item ${this.props.activeOption === "AnnualSales" ? "accordion-list-item-active" : ""}`} 
                onClick={() => {this.props.showActiveContent("AnnualSales")}}>
                <img src={listIcon}></img>
                Annual sales
              </li>

            </ul>

          }
        </Accordion.Content>

        {this.props.targetValue === "individuals" &&
          <>
            <Accordion.Title
              active={activeIndexs.includes(1)}
              index={1}
              onClick={this.handleClick}
            >
              <span className="close-icon"></span>
              <img src={folderIcon}></img>
              Personal Details
            </Accordion.Title>
            <Accordion.Content active={activeIndexs.includes(1)}>
            <ul className="accordion-list">

                <li className={`accordion-list-item ${this.props.activeOption === "Gender" ? "accordion-list-item-active" : ""}`} 
                  onClick={() => {this.props.showActiveContent("Gender")}}>
                  <img src={listIcon}></img>
                  Gender
                </li>

              </ul>
            </Accordion.Content>
          </>
        }

      </Accordion>
    )
  }
}
