const imgixReact = {
  constructor() {
    this.IMGIX_BASE = 'https://mailfold-campaign-src.imgix.net'
  },

  urlFromAwsUrl(awsUrl, width, height) {
    this.constructor();
    let fileName = awsUrl.split('amazonaws.com/')[1]

    if(width || height)
      return `${this.IMGIX_BASE}/${fileName}?w=${width}&h=${height}`
    else
      return `${this.IMGIX_BASE}/${fileName}?`
  },

  url(fileName, size) {
    return this.IMGIX_BASE + '/' + fileName + '?fm=png' + '&w=' + size.w
  }
}

export default imgixReact;
