import React from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { connect } from "react-redux";

import CampaignsTable from "./CampaignsTable";
import DefaultSidebar from "./DefaultSidebar";
import Notice from "../shared/Notice";
import { getData } from "../../lib/fetches";


function reducer(state = {}, action) {
  switch (action.type) {
    case "DeselectRowsCampaign":
      const selectedRowsCampaigns = action.campaigns.map((c) => {
        if (action.selected.find((x) => x.id == c.id)) {
          c.selected = false;
          return c;
        } else {
          return c;
        }
      });
      return {
        allSelected: action.campaigns.every((c) => c.selected),
        campaigns: action.campaigns,
        campaign: null,
      };
    case "SelectAllCampaign":
      const selectedCampaigns = action.campaigns.map((c) => {
        c.selected = !action.allSelected;
        return c;
      });
      return {
        allSelected: !action.allSelected,
        campaigns: selectedCampaigns,
        campaign: null,
      };
    case "SetCampaign":
      const campaigns = action.campaigns.map((c) => {
        if (c.id == action.campaign.id) {
          c.selected = !c.selected;
          return c;
        } else {
          return c;
        }
      });
      const allSelected = action.campaigns.every((c) => {
        return c.selected;
      });
      return {
        allSelected: allSelected,
        campaigns: campaigns,
        campaign: action.campaign,
      };
      case "ChangeCampaigns":
        return {
          campaigns: action.campaigns,
          activeTab: action.activeTab
        }
    default:
      return state;
  }
}

const store = createStore(reducer);

class Home extends React.Component {
  render() {

    const count = this.props.campaigns.reduce(
      (a, c) => a + c.recipients.length,
      0
    );
    return (
      <Provider store={store}>
        <div className="Content PageContent Campaign">
          <main>
            <header className="PageHeader campaign">
              <div className="iconhedaer">
                <span className="icon">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9C1 7.63281 2.09375 6.5 3.5 6.5H8.5V14V15.25V20.25C8.5 20.9531 7.91406 21.5 7.25 21.5H4.75C4.04688 21.5 3.5 20.9531 3.5 20.25V15.25C2.09375 15.25 1 14.1562 1 12.75V9ZM21 10.875C21 11.9688 20.4531 12.9062 19.75 13.2578V8.53125C20.4531 8.88281 21 9.82031 21 10.875Z" fill="#051237" fill-opacity="0.6"/>
                    <path d="M19.75 2.75V19C19.75 19.5078 19.4375 19.9766 18.9688 20.1719C18.5 20.3672 17.9531 20.25 17.6016 19.8984L15.8828 18.1797C14.0078 16.3047 11.4688 15.25 8.8125 15.25H8.5V14V12.75V9V7.75V6.5H8.8125C11.4688 6.5 14.0078 5.44531 15.8828 3.57031L17.6016 1.89062C17.9531 1.53906 18.5 1.42188 18.9688 1.61719C19.4375 1.8125 19.75 2.28125 19.75 2.75Z" fill="#051237" fill-opacity="0.24"/>
                  </svg>
                </span>
                <div className="headerheading">
                  <h1>Campaigns</h1>
                  <div className="AutomationDescription">
                    You can create a unique campaign and send it to your customers,
                    friends or family members anywhere in the United States.
                    Just add the necessary addresses, create a postcard design
                    and we will print and send environmentally friendly
                    direct mail as soon as possible or on the selected date.
                  </div>
                </div>
              </div>
              <div className="Clear"></div>
              <Notice {...this.props.params} />
              <div className="Clear"></div>
              <p className="SectionParagraph">
                The next major shopping holiday is New Years Eve which has an
                ideal deadline of November 31. Visit the marketing calendar for
                a full list of holidays deadlines.
              </p>
              <div className="Actions"></div>
            </header>
            <div className="Clear"></div>
            <CampaignsTable
              allSelected={this.props.allSelected}
              campaigns={this.props.campaigns}
              authToken={this.props.authToken}
            />
          </main>
          <DefaultSidebar {...this.props} count={count} />
        </div>
      </Provider>
    );
  }
}

export default Home;
