import React from "react";
import { Accordion, Menu } from 'semantic-ui-react';
export const stylesFont = {
   primary: {
      fontFamily: "YogaSansOffc, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
   },
   secondary: {
      fontFamily: "YogaSansOffc, sans-serif",
      fontFtyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
   },
}

const styles = {

   title: {
      ...stylesFont.primary,
      fontSize: "20px",
      lineHeight: "28px",
      textAlign: "center",
      color: "#18181D",
   },

   paragraph: {
      textAlign: 'justify',
   },

   menu: {
      background: "transparent",
      border: "none",
      boxShadow: "none",
   },
   menuItem: {
      padding: "0px",
   },
   category: {
      ...stylesFont.secondary,
      fontSize: "16px",
      padding: "13px 25px",
      color: "#8D8D7F",
   },
   selectedCategory: {
      color: "#4B3650",
   },
   option: {
      ...stylesFont.primary,
   }
}

const MenuGroup = ({ active, content, index, onClick, panel }) => {
   const categoryStyles = active ? { ...styles.category, ...styles.selectedCategory } : styles.category;
   return (
      <Menu.Item style={styles.menuItem}>
         <Accordion.Title
            active={active}
            content={content}
            index={index}
            onClick={onClick}
            style={categoryStyles}
         />
         <Accordion.Content
            active={active}
            content={panel}
            className={active ? "semantic-react-selected-category" : ""}
         />

      </Menu.Item>
   )
}

class QRCodeOptions extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         activeIndex: -1,
      }
   }

   handleClick = (e, titleProps) => {
      const { index } = titleProps
      const { activeIndex } = this.state
      const newIndex = activeIndex === index ? -1 : index

      this.setState({ activeIndex: newIndex })
   }

   render() {
      const { activeIndex } = this.state;
      const {
         mainOptions,
         dotsOptions,
         cornersSquareOptions,
         cornersDotOptions,
         backgroundOptions,
         imageOptions,
         qrOptions
      } = this.props.panels;

      return (
         <div style={styles.mainFont}>
            <h2 style={styles.title}>QR Code Styling</h2>
            {/*<p style={styles.paragraph}>*/}
            {/*   Please note, to make your QR code visible for scans, you need to follow some rules: do not invert QR Code colors, give your QR Code colors enough contrast, don’t create blurry QR Codes, don't make your code too small, leave a border space around your QR Code.*/}
            {/*</p>*/}
            <Accordion
               as={Menu}
               vertical fluid
               style={styles.menu}>

               <MenuGroup
                  active={activeIndex === 0}
                  content='Main Options'
                  index={0}
                  onClick={this.handleClick}
                  panel={mainOptions} />

               <MenuGroup
                  active={activeIndex === 1}
                  content='Dots Options'
                  index={1}
                  onClick={this.handleClick}
                  panel={dotsOptions} />

               <MenuGroup
                  active={activeIndex === 2}
                  content='Corners Square Options'
                  index={2}
                  onClick={this.handleClick}
                  panel={cornersSquareOptions} />

               <MenuGroup
                  active={activeIndex === 3}
                  content='Corners Dot Options'
                  index={3}
                  onClick={this.handleClick}
                  panel={cornersDotOptions} />

               <MenuGroup
                  active={activeIndex === 4}
                  content='Background Options'
                  index={4}
                  onClick={this.handleClick}
                  panel={backgroundOptions} />

               <MenuGroup
                  active={activeIndex === 5}
                  content='Image Options'
                  index={5}
                  onClick={this.handleClick}
                  panel={imageOptions} />

               {/* <MenuGroup
                  active={activeIndex === 6}
                  content='QR Options'
                  index={6}
                  onClick={this.handleClick}
                  panel={qrOptions} /> */}

            </Accordion>
         </div>
      )
   }
};

export default QRCodeOptions;