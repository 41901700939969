import React from "react";
import Size from "../../design/Size";
import SizeOption from "../../design/SizeOption";
import DesignOptions from "../../design/DesignOptions";
import Postcard from "../../design/Postcard";

class DesignTab extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.sizeChanged = this.sizeChanged.bind(this);
    this.designOptionChanged = this.designOptionChanged.bind(this);
    this.onValid = this.onValid.bind(this);
    this.saveAll = this.saveAll.bind(this);

    this.sizes = {
      "4x6": new SizeOption({
        widthInInches: 6,
        heightInInches: 4,
        className: "Small",
        price: 0.89,
      }),
      "6x9": new SizeOption({
        widthInInches: 9,
        heightInInches: 6,
        className: "Medium",
        price: 1.21,
      }),
      "6x11": new SizeOption({
        widthInInches: 11,
        heightInInches: 6,
        className: "Large",
        price: 1.49,
      }),
      "8.5x11": new SizeOption({
        widthInInches: 11,
        heightInInches: 8.5,
        className: "Letter",
        price: 1.25,
      }),
    };
    let id = props.size;
    if (!props.size) {
      id = "4x6";
      console.info("No size id found, using 4x6");
    }

    const size = this.sizes[id];
    if (!size) {
      console.error("No size found for id", id, "in", this.sizes);
    }

    console.info("Set default size", size);
    this.props.updateField("size", size.id(), "designChanged");

    this.state = {
      size: size,
      designOption: props.designOption,
      frontIsValid: false,
      backIsValid: false,
      isLoading: false,
      saveButton: "Save",
      frontId: props.frontId || "",
      backId: props.backId || "",
    };
  }

  updateFieldValue = (name, val) => {
    this.props.updateField(name, val, "designChanged");
  };

  componentWillUnmount() {
    this.saveAll();
  }

  sizeChanged(size) {
    this.setState({ size });
    this.updateFieldValue("size", size.id());
    setTimeout(() => {
      this.props.saveTabFormData();
    }, 1000);
  }

  designOptionChanged(designOption) {
    this.setState({ designOption });
    this.updateFieldValue("designOption", designOption);
  }

  get arePostcardsValid() {
    if (this.state.isLoading) {
      return false;
    }

    return this.isFrontAndBackValid;
  }

  get isFrontAndBackValid() {
    let isLetter = this.state.size.className == "Letter"
    return this.state.frontIsValid && (this.state.backIsValid || isLetter);
  }

  saveAll() {
    // TODO: Handle errors
    console.debug("Called onSave in Design.js");

    this.setState({ saveButton: "Saving ..." });
    const finished = () => {
      this.setState({ saveButton: "✓" });

      setTimeout(() => {
        this.setState({ saveButton: "Save" });
      }, 2000);
    };
    if(this.state.size.className == "Letter"){
      return Promise.all([this.refs.front.save()])
        .then(finished)
        .catch(finished);
    } else {
      return Promise.all([this.refs.front.save(), this.refs.back.save()])
        .then(finished)
        .catch(finished);
    }
  }

  height() {
    return this.state.size.height;
  }

  width() {
    return this.state.size.width;
  }

  heightInInches() {
    return this.state.size.heightInInches;
  }

  widthInInches() {
    return this.state.size.widthInInches;
  }

  onValid(postcard, validity) {
    console.info("Update postcard", postcard);
    switch (postcard) {
      case "front":
        return this.setState({ frontIsValid: validity });
      case "back":
        return this.setState({ backIsValid: validity });
    }
  }

  render() {
    const { size } = this.state;
    const vertical = size.className;
    return (
      <div className="automation-tab">
        <form
          key="edit-design"
          className="edit_design"
          acceptCharset="UTF-8"
          ref="form"
          method="post"
        >
          <Size
            sizes={this.sizes}
            size={this.state.size}
            onChange={this.sizeChanged}
          ></Size>

          <div className="Clear"></div>

          <DesignOptions
            designOption={this.state.designOption}
            onChange={this.designOptionChanged}
          ></DesignOptions>
          <div className="Clear"></div>
          <If condition={this.state.designOption == "upload"}>
            <fieldset className="FileSelect" id="card-design">
              <div className="grid grid-2 upload-option">
                <div className="grid-item upload-option">
                  <h2 className="automation-tab-title subtitle">
                    Upload Design Files
                  </h2>
                  <p id="UploadTip">
                    <span>
                      {this.heightInInches()}″ × {this.widthInInches()}″
                      postcards must be{" "}
                      <b>
                        {this.width()}px × {this.height()}px.
                      </b>
                      That's {this.heightInInches()}.25″ ×{" "}
                      {this.widthInInches()}
                      .25″ @ 300dpi with the bleed.
                    </span>
                    PNG, PDF, and JPG formats accepted.
                  </p>
                </div>
                <div className="grid-item upload-option">
                  {// <div className="TemplateLinks">
                  //   <h3>Additional Help</h3>
                  //   <a href="http://support.mailfold.com/frequently-asked-questions/tools-for-designing-postcards">
                  //     Simple Design Tools
                  //   </a>
                  //   <a href="https://support.mailfold.com/frequently-asked-questions/designing-a-postcard">
                  //     Sizes and Templates
                  //   </a>
                  // </div>
                }
                </div>
              </div>
              <div className="Clear"></div>
            </fieldset>
          </If>

          <Postcard
            key="front"
            ref="front"
            type="front"
            mailer="automations"
            size={this.state.size}
            guid={this.props.guid}
            onValid={this.onValid}
            id={this.state.frontId}
            designOption={this.state.designOption}
            letter={vertical}
          />
          {vertical !== "Letter" ? (
            
            <Postcard
              key="back"
              ref="back"
              type="back"
              mailer="automations"
              size={this.state.size}
              guid={this.props.guid}
              onValid={this.onValid}
              id={this.state.backId}
              designOption={this.state.designOption}
              letter={vertical}
            />
          ) : null}

          <input
            type="submit"
            name="commit"
            value={this.state.saveButton}
            className="Button ExitButton"
            disabled={!this.arePostcardsValid}
            onClick={(e) => {e.preventDefault(); this.saveAll()}}
          />
        </form>
      </div>
    );
  }
}

export default DesignTab;
