import React, { Fragment, useState, useEffect } from "react";
import { Dropdown } from 'semantic-ui-react'
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

const GraphTable = ({graphTableHeader}) => {
  return graphTableHeader.map((i) => {
    return (
      <div className="graph-table">
        <div className="graph-table-block">
            <span className="graph-table-block-number">{i[1]}</span>
            <span className="graph-table-block-category">{i[0]}</span>
        </div>
      </div>
    )
  })
};

const Graph = ({graphType, options}) => {
    return (
        <div className="graph-main-block">
            {graphType == 'bar' ?
                <Bar data={ options }/>
                :
                <Doughnut options={options.options} data={ options }/>
            }
        </div>
    )
};


const AgenciesGraphs = ({
  options, headerText, title,
  showTable, showSelect, graphType,
  dropdownOptions, selectedDropdown,
  setSelectedDropdown, graphTableHeader}) => {

    return(
        <div className="graph-container">
            <div className="graph-header">
              <h3 className="graph-header-title">{headerText}</h3>
              {showSelect &&
                <Dropdown
                  fluid
                  selection
                  value={selectedDropdown}
                  options={dropdownOptions}
                  onChange={(e, item)=> setSelectedDropdown(item.value)}
                />
              }
            </div>
            <div className="showTable-container">
              {showTable &&
                <GraphTable graphTableHeader={graphTableHeader}/>
              }
            </div>
            <div className="graph-main">
                <Graph graphType={graphType} options={options}/>
                <div className="graph-name">{title}</div>
            </div>
        </div>
    )
};

export default AgenciesGraphs;
