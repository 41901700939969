import React from "react";

class QRToolTray extends React.Component {
  render() {
    return (
      <aside className="ToolTray">
        <div className="TrayContents">
           {this.props.children}
        </div>
      </aside>
    );
  }
}

QRToolTray.propTypes = {};

export default QRToolTray;
