import React from "react";

import TrashIcon from "../../../assets/images/trash-icon.svg";
import CloseIcon from "../../../assets/images/close-gray.svg";

import { Dropdown, Input, Modal } from "semantic-ui-react";
import Warning from "../shared/Warning";
class NewSegmentModal extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      conditionOption: true,
      listName: "",
      conditionOpen: false,
      firstInputOptions: [
        {
          key: "business_name",
          value: "business_name",
          text: "Full Name",
          maxLength: 40,
          pattern: "^(.)*[A-Za-z]+(.)*$",
        },
        {
          key: "address_line_1",
          value: "address_line_1",
          text: "Address",
          maxLength: 64,
          pattern: "^(.)*[A-Za-z]+(.)*$",
        },
        {
          key: "city",
          value: "city",
          text: "City",
          maxLength: 40,
          pattern: "^[A-Za-z]*$",
        },
        {
          key: "state",
          value: "state",
          text: "State",
          maxLength: 40,
          pattern: "^[A-Za-z]*$",
        },
        {
          key: "postal_code",
          value: "postal_code",
          text: "Zip Code",
          maxLength: 10,
          pattern: "^\d{5,10}$",
        },
      ],
      secondInputOptions: [
        { key: "is", value: "is", text: "is" },
        { key: "is not", value: "is not", text: "is not" },
        { key: "contains", value: "contains", text: "contains" },
        {
          key: "does not contain",
          value: "does not contain",
          text: "does not contain",
        },
      ],

      segmentOptions: [
        {
          id: 1,
          filterValue: "business_name",
          query: "eq",
          searchValue: "",
          valid: true,
        },
      ],
      segmentId: 2,
      newListId: 0,
      condition: "",
      listNameErrorType: "",
      segmentError: false,
      listNameError: false,
      formErrors: '',
    };
  }

  //del Condition
  delCondition = (id) => {
    id !== 1 &&
    this.setState({
      segmentOptions: [
        ...this.state.segmentOptions.filter((item) => item.id !== id),
      ],
    });
  };

  //Add Condition
  addCondition = (condition, id) => {
    const newCondition = {
      id,
      filterValue: "business_name",
      query: "eq",
      searchValue: "",
      valid: true,
      condition,
    };
    let validatedArray = this.state.segmentOptions.map((item) => {
      if (!item.searchValue) {
        item.valid = false;
      }
      return item;
    });
    this.setState({ segmentOptions: [...validatedArray] });
    let checkValidation = validatedArray.some((item) => item.valid === false);
    this.state.segmentOptions.length !== 4 &&
    !checkValidation &&
    this.setState({
      segmentOptions: [...this.state.segmentOptions, newCondition],
      segmentId: id + 1,
    });
  };

  searchValueState = (id, text) => {
    let segmentItem = this.state.segmentOptions.find((item) => {
      if (item.id === id) {
        item.searchValue = text;
      }
      return item;
    });
    return segmentItem.searchValue;
  };

  settingConditions = (id, data, field) => {
    let segments = this.state.segmentOptions;
    let updatedSegment = segments.filter((elem) => {
      if (elem.id === id) {
        if (field === "first") {
          elem.filterValue = data;
        } else if (field === "second") {
          elem.query = this.getSegmentQuery(data);
        } else {
          elem.searchValue = data;
          elem.valid = true;
        }
        return elem;
      }
    });

    let updatedSegmentsArray = segments.map((item) => {
      if (item.id === id) {
        item = updatedSegment[0];
      }
      return item;
    });
    this.setState({ segmentOptions: updatedSegmentsArray });
  };

  getSegmentQuery(value) {
    let query = "";
    switch (value) {
      case "is":
        query = "eq";
        break;
      case "is not":
        query = "not_eq";
        break;
      case "contains":
        query = "cont";
        break;
      case "does not contain":
        query = "not_cont";
        break;
      default:
        query = "eq";
    }
    return query;
  }

  isFormValid() {
    const errors = {};
    this.state.segmentOptions.forEach((item) => {
      if (!item.searchValue) {
        return;
      }
      const {pattern, text} = this.state.firstInputOptions.find(({key}) => key === item.filterValue)
      const regex = new RegExp(pattern);
      const result = regex.test(item.searchValue);
      if (!result) {
        errors[item.filterValue] = `${text} is not valid`;
        item.valid=false;
      }
    });
    this.setState({formErrors: errors})
    return Object.keys(errors).length === 0;
  }



  saveModifiedSegment(id, options) {

    if (!this.isFormValid()) {
      console.debug('Form validation error!')
      return;
    }

    let url = `/contacts/${id}/add_segment_addresses`;
    let validatedArray = this.state.segmentOptions.map((item) => {
      if (!item.searchValue) {
        item.valid = false;
      }

      const {pattern} = this.state.firstInputOptions.find(({key}) => key === item.filterValue)
      const regex = new RegExp(pattern);
      if (item.searchValue) {
        item.valid = regex.test(item.searchValue);
      }

      return item;
    });
    this.setState({ segmentOptions: [...validatedArray] });
    let checkValidation = validatedArray.some((item) => item.valid === false);
    if (!checkValidation) {
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        body: JSON.stringify({
          options: options,
        }),
      })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error("Not success");
            }
            return response.json();
          })
          .then((data) => {
            this.props.openNewSegment(false, null);
            this.props.contactsRequest();
          })
          .then(() => {
            this.props.openListRequest(id);
          })
          .catch((error) => console.log(error));
    }
  }

  createList(name, list_type) {
    fetch(`/contacts/?name=${name}&list_type=${list_type}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
    })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Not success");
          }
          return response.json();
        })
        .then((data) => {
          if (data.errors && data.errors.name[0]) {
            this.setState({
              listNameError: true,
              listNameErrorType: data.errors.name[0],
            });
          } else {
            this.setState({ newListId: data.list.id });
            if (this.props.step === 1) {
              this.props.openNewSegment(false);
              this.props.contactsRequest();
              setTimeout(() => {
                this.props.openListRequest(data.list.id);
              }, 0);
            } else if (this.props.step === 0) {
              this.props.openNewSegment(true, 2);
            }
          }
        })
        .catch((error) => console.log(error));
  }

  handleChangeListName = (e) => {
    this.setState({
      listName: e.target.value,
      listNameError: false,
      listNameErrorType: "",
    });
  };

  handleThirdOption = (e, itemId) => {
    this.settingConditions(itemId, e.target.value, "else");
  };

  render() {
    const { listNameErrorType } = this.state;
    return (
        <div
            className={`contacts-segment-container ${this.props.step === 0 && `small`
            } ${this.props.step === 1 && `small`}`}
        >
          {this.props.step === 0 && (
              <>
                <div className="contacts-segment-body">
                  <h3 className="segment-modal-small-title">
                    Creating new segment
                  </h3>
                  <h4 className="segment-modal-small-subtitle">
                    Please, enter the name of the segment:
                  </h4>
                  {listNameErrorType !== "" ? (
                      <h4 className="list-error">
                        {listNameErrorType === `can't be blank`
                            ? `Field ${listNameErrorType}`
                            : `This name ${listNameErrorType}`}
                      </h4>
                  ) : null}
                  <input
                      className={`segment-modal-input ${this.state.listNameError ? "field-error" : ""
                      } `}
                      placeholder="Segment Name"
                      value={this.state.listName}
                      onChange={(e) => this.handleChangeListName(e)}
                      type="text"
                  />
                </div>
                <div className="segment-modal-footer">
                  <div className="grid grid-2 segment-modal-buttons">
                    <div className="grid-item">
                      <button
                          className="gray-button"
                          onClick={() => this.props.openNewSegment(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="grid-item">
                      <button
                          className="blue-button"
                          onClick={() => {
                            this.createList(this.state.listName, "segment");
                          }}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </>
          )}
          {this.props.step === 1 && (
              <>
                <div className="contacts-segment-body">
                  <h3 className="segment-modal-small-title">Creating new List</h3>
                  <h4 className="segment-modal-small-subtitle">
                    Please, enter the name of the list:
                  </h4>
                  {listNameErrorType !== "" ? (
                      <h4 className="list-error">
                        {listNameErrorType === `can't be blank`
                            ? `Field ${listNameErrorType}`
                            : `This name ${listNameErrorType}`}
                      </h4>
                  ) : null}
                  <input
                      className={`segment-modal-input ${this.state.listNameError ? "field-error" : ""
                      } `}
                      placeholder="List Name"
                      value={this.state.listName}
                      onChange={(e) => this.handleChangeListName(e)}
                      type="text"
                  />
                </div>
                <div className="segment-modal-footer">
                  <div className="grid grid-2 segment-modal-buttons">
                    <div className="grid-item">
                      <button
                          className="gray-button"
                          onClick={() => this.props.openNewSegment(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="grid-item">
                      <button
                          className="blue-button"
                          onClick={() => this.createList(this.state.listName, "list")}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </>
          )}
          {this.props.step === 2 && (
              <>
                <div className="contacts-segment-body big">
                  <div
                      className="segment-close-icon"
                      onClick={() => this.props.openNewSegment(false)}
                  >
                    <img src={CloseIcon} />
                  </div>
                  <h3 className="segment-modal-title">Creating new segment</h3>
                  <h4 className="segment-modal-small-subtitle">
                    In order to add new segmant, choose needed settings below
                  </h4>
                  <div className="segment-matching-cover">
                    <span>Contacts matching any of the following conditions:</span>{" "}
                    {/* <div className="segment-matching-dropdown">
                  <Dropdown
                    className=""
                    placeholder="any"
                    options={this.state.filterOptions}
                    selection
                    onChange={(event, data) => {
                      this.filterLists(data.value);
                    }}
                  />
                </div> */}
                    <span> </span>
                  </div>
                  {this.state.segmentOptions.map((item, index) => {
                    const {maxLength} = this.state.firstInputOptions.find(({key}) => key === item.filterValue)
                    return (
                        <div key={item.id}>
                          <div className="condition-option">{item.condition}</div>
                          <form className="">
                            <div className="grid grid-3 segment-form">
                              <div className="grid-item segment-form-input">
                                <Dropdown
                                    className=""
                                    placeholder="Full Name"
                                    options={this.state.firstInputOptions}
                                    selection
                                    onChange={(event, data) => {
                                      this.settingConditions(
                                          item.id,
                                          data.value,
                                          "first"
                                      );
                                    }}
                                />
                              </div>
                              <div className="grid-item segment-form-input">
                                <Dropdown
                                    className=""
                                    placeholder="is"
                                    options={this.state.secondInputOptions}
                                    selection
                                    onChange={(event, data) => {
                                      this.settingConditions(
                                          item.id,
                                          data.value,
                                          "second"
                                      );
                                    }}
                                />
                              </div>
                              <div className="grid-item segment-form-input">
                                <input
                                    className={`segment-modal-input name ${!item.valid && "field-error"
                                    } `}
                                    placeholder="Please enter a value"
                                    value={item.searchValue}
                                    onChange={(e) => {
                                      this.handleThirdOption(e, item.id);
                                    }}
                                    type="text"
                                    maxLength={maxLength}
                                />
                                <Warning data={this.state.formErrors[item.filterValue]}/>
                              </div>
                              <div className="segment-trash-icon">
                                <img
                                    src={TrashIcon}
                                    onClick={() => this.delCondition(item.id)}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                    );
                  })}
                  {this.state.segmentOptions.length < 4 && (
                      <div className="segment-condition-cover">
                        <Dropdown text='+ Add Condition' button style={{background: '#f3f3f3'}}>
                          <Dropdown.Menu>
                            <Dropdown.Item   onClick={() =>
                                this.addCondition("and", this.state.segmentId)
                            }>
                              And{" "}
                              <span>
                          (add new conditions to the conditions you’d created)
                        </span>
                            </Dropdown.Item>
                            <Dropdown.Item  onClick={() =>
                                this.addCondition("or", this.state.segmentId)
                            }>
                              OR <span>(another condition)</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <button className="segment-condition-button">
                    + Add Condition
                  </button>
                  <div className="segment-condition-select">
                    <div
                      className="segment-condition-option"
                      onClick={() =>
                        this.addCondition("and", this.state.segmentId)
                      }
                    >
                      And{" "}
                      <span>
                        (add new conditions to the conditions you’d created)
                      </span>
                    </div>
                    <div
                      className="segment-condition-option"
                      onClick={() =>
                        this.addCondition("or", this.state.segmentId)
                      }
                    >
                      OR <span>(another condition)</span>
                    </div>
                  </div> */}
                      </div>
                  )}

                  <div className="segment-save-button">
                    <button
                        className="purple-button"
                        onClick={() => {
                          this.saveModifiedSegment(
                              this.state.newListId,
                              this.state.segmentOptions
                          );
                        }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </>
          )}
        </div>
    );
  }
}
export default NewSegmentModal;
