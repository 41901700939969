import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

const styles = {
    modal: {
        animation: 'none',
        margin: 'auto',
        height: 'auto',
        left: '0',
        right: '0',
        top: '30%',
        maxWidth: '460px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },

    title: {
        fontWeight: '700',
        margin: '30px 0 12px 0',
        fontSize: '18px',
        lineHeight: '24px',

    },
    description: {
        padding: '0 24px 30px 24px',
        fontSize: '18px',
        lineHeight: '24px',
        margin: '0',
        textAlign: 'left'
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        background: '#F3F3F3',
        padding: '12px 52px',
    },
    btnCancel: {
        maxWidth: '170px',
        background: '#D4D7E4',
        color: '#6E7AAF',
        marginRight: '12px',
        fontSize: '16px',
        fontWeight: 500,
    },
    btnOk: {
        maxWidth: '170px',
        background: '#2A3B8D',
        color: 'white',
        fontSize: '16px',
        fontWeight: 500,
    },

    btnOkDisabled: {
        maxWidth: '170px',
        background: '#FFF',
        color: '#B3B3B5',
        fontSize: '16px',
        fontWeight: 500,
    }

}

const ConfirmSubscription = ({ showModal, onClose, upgradeAccount, description, title }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const upgrade = () => {
        setIsSubmitting(true)
        upgradeAccount().finally(() => setIsSubmitting(false))
    }

    return (
        <Modal
            open={showModal}
            closeOnEscape={false}
            closeOnDimmerClick={true}
            className="designer-segment-modal"
            style={styles.modal}
        >
            <div
                className="design-modal"
                style={styles.content}
            >
                <h2 className="agency-blog-modal-title" style={styles.title}>{title}</h2>
                <p className="agency-blog-modal-text" style={styles.description}>{description}</p>

                <div className="design-modal-bottom" style={styles.actions}>
                    <button onClick={onClose} className="bordered-button" style={styles.btnCancel} autoFocus>Cancel</button>
                    <button className="bordered-button" onClick={upgrade} style={isSubmitting ? styles.btnOkDisabled : styles.btnOk} disabled={isSubmitting}>Confirm</button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmSubscription;
