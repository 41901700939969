import React, { useState, useEffect } from "react";


import _ from "lodash";

import { getData, deleteData,postGhl, getList, postContactsToMailfold} from "../../../lib/fetches";

import Etsy from "../../../../assets/images/etsy-logo.svg";
import Woo from "../../../../assets/images/woo.svg";
import Zapier from "../../../../assets/images/zapier.svg";
import Shopify from "../../../../assets/images/shopify.svg";
import Highlevel from "../../../../assets/images/topline.png";
import { Dropdown, Modal,Icon, } from "semantic-ui-react";
import ContactSycnConfirmation from "../ContactSycnConfirmation";
import AlertPopup from "../AlertPopup";

function IntegrationPage(props) {
  const { user, token } = props;
  const [etsyStatus, setEtsyStatus] = useState(user.etsy_linked);
  const [addressList, setAddressList] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [selectionActive, setSelectionActive] = useState(false);
  const [alertModalType, setAlertModalType] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [wooCommersModal, setWooCommersModal] = useState(false);
  const [shopifyModal, setShopifyModal] = useState(false);
  const [wooCommersUrl, setWooCommersUrl] = useState("");
  const [shopifyUrl, setShopifyUrl] = useState("");
  const [type, setType] = useState(false);
  const [syncLoader, setSyncLoader] = useState(false);
  const [fetchingLoader, setFetchingLoader] = useState("");
  const [syncConfirmation,setSyncConfirmation] = useState(false)
  const [wooCommersConnected, setWooCommersConnected] = useState(
    user["woocommerce_connected?"]
  );
  const [shopifyStatus, setShopifyStatus] = useState(
    user["shopify_connected?"]
  );

  const etsyConnection = () => {
    etsyStatus
      ? getData("/disconnect_etsy", token, (res) => {
          setEtsyStatus(res.etsy_linked);
        })
      : (window.location.href = `/user_etsy_link`);
  };

  const validURL = (str) => {
    let pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const validShopifyURL = (str) => {
    var pattern = new RegExp(
      "[^.\s]+\.myshopify\.com(\/|)$"
    );
    return !!pattern.test(str);
  };

 
    
  const wooCommersConnection = () => {
    let url = wooCommersUrl;
    validURL(url)
      ? getData(
          `/link_woocommerce?woocommerce_url=${wooCommersUrl}`,
          token,
          (res) => {
            setWooCommersModal(false);
            window.location.href = res.redirect_url;
          }
        )
      : setInvalidUrl(true);
  };
  const shopifyConnection = () => {
    shopifyStatus
      ? deleteData("/unlink_shopify", props.authToken, (res) => {
          setShopifyStatus(res.shopify_linked);
        })
      : getData(`/auth_shopify?shop=${shopifyUrl}`, token, (res) => {
        window.location.href = res.path
      });
  };

  const disconnectWoo = () => {
    deleteData(`/disconnect_woocommerce`, props.authToken, (res) => {
      setWooCommersConnected(false);
    });
  };

  const disconnectGhl = () => { 
    
    postGhl(`/update_ghl`, props.authToken, (res) => {
     
    });
  };

  const syncContactfromTopline = (id) => { 
    setSyncLoader(true)
    postContactsToMailfold(`/sync_contacts_from_topline?list_id=` + id, props.authToken, (res) => {
      if (res == "success")
      {
        setAlertModal(true)
        setAlertModalType("success")
        closeSyncModal()
        setAddressList([])
      setSelectionActive(false)

      }
      else {
        setAlertModal(true)
        setAlertModalType("alert")
        closeSyncModal()
      }
    setSyncLoader(false)
    });
  };
  const postContact = async(id) => {
    setSyncLoader(true)
    await postGhl(`/sync_contacts?list_name=` + id, props.authToken, (res) => {
      if (res == "success")
      {
        setAlertModal(true)
        setAlertModalType("success")
        closeSyncModal()
        setAddressList([])
      setSelectionActive(false)

      }
      else {
        setAlertModal(true)
        setAlertModalType("alert")
        closeSyncModal()
      }
      setSyncLoader(false)
    });
  };
  const getLists = async(active) => { 
    setFetchingLoader(active ? "btnToLoader" : "btnFromLoader")
    setSelectedAddress(null)
    await getList(`/get_lists`, props.authToken, (res) => {
      let data = []
      for (let i = 0; i < res.length; i++) {
        data.push({
          key: res[i].id,
          text: res[i].name,
          value: res[i].id,
          icon: active ? res[i].synced_with_topline ? "check" : "" : "",
          active:active,
       })
      }
        if (res.length == 0) {
        data.push({
          key: "",
          text: "No Data",
          value: "",
          icon: "",
          active:"",
       })
      }
      setAddressList(data)
      setSelectionActive(true)
      setFetchingLoader("")
    });
  };
  const handleUrl = (e) => {
    setWooCommersUrl(e.target.value);
    setInvalidUrl(false);
  };
  const handleShopifyUrl = (e) => {
    let url = e.target.value.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    if (validShopifyURL(url)){
      setInvalidUrl(false);
    } else {
      setInvalidUrl(true);
    }
    setShopifyUrl(url);
  };

  const backToContacts = () => {
    window.location.href = "/contacts";
  };
  useEffect(() => {

  }, []);
  console.log("user", user)

  const closeSyncModal = () => {
   setSyncConfirmation(false)
  };
  const closeAlertModal = () => {
    setAlertModal(false)
   };
  const openSyncModal = (id,type) => {
    setType(type? "to":"from")
    setSyncConfirmation(true)
  }
  return (
    <>
      <div className="Content PageContent Campaign">
        <main className=" contacts-settings gray">
          <div className="arrow-back-green" onClick={() => backToContacts()}>
            <img src="/packs/media/images/arrow-left-green-187b08ab.svg" />
            Back to all contacts
          </div>
          <header className="PageHeader contacts page-settings">
            <h2 className="contacts-table-title">Integrations</h2>
          </header>
          <div className="contacts-page-main">
            <div className="grid grid-4">
              <div className="grid-item">
                <div className="card-integrations">
                  <div className="card-integrations-header">
                    <img src={Etsy} />
                  </div>
                  <div className="card-integrations-body">
                    <h4>Just connect your Etsy</h4>
                    <p>
                      Etsy is a popular marketplace for buying and selling a
                      variety of creative things online.
                    </p>
                  </div>
                  <div className="card-integrations-footer">
                    <button
                      className="card-integrations-button"
                      onClick={() => etsyConnection()}
                    >
                      {etsyStatus
                        ? `Disconnect from Etsy`
                        : `Connect your store`}
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="card-integrations">
                  <div className="card-integrations-header">
                    <img src={Shopify} />
                  </div>
                  <div className="card-integrations-body">
                    <h4>Just connect your Shopify store</h4>
                    <p>
                      Sync your current Shopify customers and automatically
                      add/update customers going forward.
                    </p>
                  </div>
                  <div className="card-integrations-footer">
                    {shopifyStatus ? (
                      <button
                        className="card-integrations-button"
                        onClick={() => shopifyConnection()}
                      >
                        Disconnect your store
                      </button>
                    ) : (
                      <button
                        className="card-integrations-button"
                        onClick={() => setShopifyModal(true)}
                      >
                        Connect your store
                      </button>
                    )} 
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="card-integrations">
                  <div className="card-integrations-header" style={{textAlign: 'center'}}>
                  <img src={Highlevel} style={{ width: '50%', height: 'auto' }} alt="Small Logo" />

                  </div>
                  <div className="card-integrations-body">
                    <h4>Just connect with Topline OS</h4>
                    <p>
                     Click below button to connect with Topline OS
                    </p>
                  </div>
                  <div className="card-integrations-footer">
                    {user.ghl_token ? (
                      <>
                        <button
                          className="card-integrations-button"
                          onClick={() => { disconnectGhl() }}
                        >
                          Disconnect
                        </button>
                      
                        <button
                          className="card-integrations-button"
                          onClick={() => {setAddressList([]) ,setSelectionActive(false), getLists(false)}}>
                          Sync Contacts from Topline OS {"  "}
                          {fetchingLoader == "btnFromLoader" &&
                          <Icon loading name='spinner' />
                         } 
                        </button>
                       
                        <button
                          className="card-integrations-button"
                          onClick={() => { setAddressList([]),setSelectionActive(false), getLists(true) }}>
                          Sync Contacts To Topline OS{"  "}
                          {fetchingLoader == "btnToLoader" && <Icon loading name='spinner' />}
                        </button> 
                      
                        {selectionActive &&
                          <Dropdown
                          simple item
                          placeholder="Contact List"
                          className="card-integrations-button icon contact-list"
                            options={addressList}
                            // value={selectedAddress}
                            selection
                          onChange={(event, data) => {
                              console.log("inn",data)
                              setSelectedAddress(data.value)
                              openSyncModal(data.vlaue,data.options[0].active)
                            }}
                          />
                        }
                      </>
                    ) : (
                      <button
                        className="card-integrations-button"
                        onClick={() => {
                      
                          const popUp = window.open("https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://staging.mailfold.com/contacts/integrations&client_id=655332e0843c43bd11cd8371-lpskq0se&client_secret=6305d184-52b4-4a5e-a88e-270ef5738f63&scope=contacts.readonly contacts.write locations.readonly locations/customValues.readonly locations/customFields.readonly", 'Google', 'width=500,height=500,top=100,left=100');
                          popUp.focus();
                          console.log(popUp);
                          setInterval(() => {
                            if (popUp && popUp.window.location.href.includes('https://staging.mailfold.com/contacts') ) {
                            
                            popUp.close();   
                              window.location.reload(); 
            
                            }
                          }, 1000); 
                         
                     
                      }}
                                  >
                      Connect your store
                    </button>
                    
                    
                    )} 
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="card-integrations">
                  <div className="card-integrations-header">
                    <img src={Woo} />
                  </div>
                  <div className="card-integrations-body">
                    <h4>Just connect your WOO Commerce</h4>
                    <p>
                      WooCommerce is a customizable, open-source eCommerce
                      platform built on WordPress.
                    </p>
                  </div>
                  <div className="card-integrations-footer">
                    {wooCommersConnected ? (
                      <button
                        className="card-integrations-button"
                        onClick={() => disconnectWoo()}
                      >
                        Disconnect your store
                      </button>
                    ) : (
                      <button
                        className="card-integrations-button"
                        onClick={() => setWooCommersModal(true)}
                      >
                        Connect your store
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="card-integrations">
                  <div className="card-integrations-header">
                    <img src={Zapier} />
                  </div>
                  <div className="card-integrations-body">
                    <h4>Automate with Zapier</h4>
                    <p>
                      Automate workflows with 750+ apps like Salesforce,
                      Hubspot, ZoHo, and BigCommerce.
                    </p>
                  </div>

                  <div className="card-integrations-footer">
                    <button
                      onClick={() => {
                        window.location.href =
                          "https://zapier.com/app/dashboard";
                      }}
                      className="card-integrations-button"
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        open={shopifyModal}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        className="contacts-segment-modal"
        onClose={() => {setShopifyModal(false); setInvalidUrl(false)}}
      >
        <div className={`contacts-segment-container small`}>
          <div className="contacts-segment-body">
            <h3 className="segment-modal-small-title">
              Creating new integration
            </h3>

            <h4 className="segment-modal-small-subtitle">
              Please, enter your webstore name:
            </h4>
            <input
              className={`segment-modal-input ${invalidUrl && "field-error"}`}
              placeholder="mystore.myshopify.com"
              value={shopifyUrl}
              onChange={(e) => handleShopifyUrl(e)}
              type="text"
            />
          </div>
          <div className="segment-modal-footer">postContact
            <div className="grid grid-2 segment-modal-buttons">
              <div className="grid-item">
                <button
                  className="gray-button"
                  onClick={() => {setShopifyModal(false); setInvalidUrl(false)}}
                >
                  Cancel
                </button>
              </div>
              <div className="grid-item">
                <button
                  className="blue-button"
                  disabled={invalidUrl || !shopifyUrl.length}
                  onClick={() => shopifyConnection()}
                >
                  Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={wooCommersModal}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        className="contacts-segment-modal"
        onClose={() => {setWooCommersModal(false); setInvalidUrl(false)}}
      >postContact
        <div className={`contacts-segment-container small`}>
          <div className="contacts-segment-body">
            <h3 className="segment-modal-small-title">
              Creating new integration
            </h3>
            <h4 className="segment-modal-small-subtitle">
              Please, enter your Website URL:
            </h4>
            <input
              className={`segment-modal-input ${invalidUrl && "field-error"}`}
              placeholder="Test_Segment_Name"
              value={wooCommersUrl}
              onChange={(e) => handleUrl(e)}
              type="text"
            />
          </div>
          <div className="segment-modal-footer">
            <div className="grid grid-2 segment-modal-buttons">
              <div className="grid-item">
                <button
                  className="gray-button"
                  onClick={() => {setWooCommersModal(false); setInvalidUrl(false)}}
                >
                  Cancel
                </button>
              </div>
              <div className="grid-item">
                <button
                  className="blue-button"
                  onClick={() => wooCommersConnection()}
                  disabled={invalidUrl || !wooCommersUrl.length}
                >
                  Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
          open={syncConfirmation}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => {closeSyncModal()}}
        >
          <ContactSycnConfirmation
          closeSyncModal={() => closeSyncModal()}
          selectedAddress={selectedAddress}
          type={type}
          loader={syncLoader}
          syncContactfromTopline={syncContactfromTopline}
          postContact={postContact}
          />
      </Modal>
      <Modal
          open={alertModal}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => closeAlertModal()}
        >
          <AlertPopup
          closeAlertModal={() => closeAlertModal()}
          type={alertModalType}
          />
        </Modal>
    </>
  );
}

export default IntegrationPage;
