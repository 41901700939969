import React from "react";
import PropTypes from "prop-types";
import CardOption from "./CardOption";

import FrontBleed4x6 from "../../../assets/images/card-preview-front-land-4-6.png";
import FrontBleed6x9 from "../../../assets/images/card-preview-front-land-6-9.png";
import FronyBleed6x11 from "../../../assets/images/card-preview-front-land-6-11.png";

import BackBleed4x6 from "../../../assets/images/card-preview-back-land-4-6.png";
import BackBleed6x9 from "../../../assets/images/card-preview-back-land-6-9.png";
import BackBleed6x11 from "../../../assets/images/card-preview-back-land-6-11.png";

export default class extends React.Component {
  defaultUrl = "";

  static propTypes = {
    guid: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["front", "back"]).isRequired,
    onValid: PropTypes.func.isRequired,
    size: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      scale: "plain",
      fileName: "",
      path: "",
      imageError: "", // validation error
      uploadImageMessage: null,
      displayScaleOptions: false,
      isLoading: false,
    };

    this.toggleBleedMode = this.toggleBleedMode.bind(this);
    this.imageSelected = this.imageSelected.bind(this);
    this.scaleOptionChanged = this.scaleOptionChanged.bind(this);
    this.goAndCreateDesign = this.goAndCreateDesign.bind(this);
  }

  landscapeType() {
    return (
      "Landscape" +
      (this.props.size.id() !== "8.5x11" ? this.props.size.id() : "Letter")
    );
  }

  propagateToParent() {
    this.props.onValid(this.props.type, this.isValid());
  }

  get postcardUrl() {
    return `/${this.props.mailer}/${this.props.guid}/postcards/${this.props.type}`;
  }

  componentDidMount() {
    console.info("[GET]", this.postcardUrl);
    this.setState({ isLoading: true });
    $.get(this.postcardUrl)
      .done((side) => {
        console.info("Found postcard", side);
        this.setState({ ...side });
      })
      .error((error) => {
        /* No postcard found */
      })
      .always(() => {
        console.debug("No longer loading");
        this.setState({ isLoading: false });
        this.propagateToParent();
      });
  }

  get authToken() {
    return $('meta[name="csrf-token"]').attr("content");
  }

  // Save postcard, called by Design.js or DesignTab.js
  save() {
    console.debug("Save called in Postcard.js");
    this.setState({ isLoading: true, imageError: "" });

    return new Promise((resolve, reject) => {
      $.post(this.postcardUrl, {
        authenticity_token: this.authToken,
        "postcard[scale]": this.state.scale,
        "postcard[file_name]": this.state.fileName,
        "postcard[path]": this.state.path,
      })
        .done((result) => {
          console.debug("[PATCH] Finished saving postcard");
          resolve();
        })
        .error(({ errors }) => {
          console.debug("[PATCH] Error saving postcard", errors);
          this.setState({ imageError: errors });
          reject();
        })
        .always(() => {
          console.debug("[PATCH] Loading: false");
          this.setState({ isLoading: false });
        });
    });
  }

  isValid() {
    if (this.state.imageError) {
      return false;
    }

    if (!this.state.path) {
      return false;
    }

    return true;
  }

  // @file = new Image(an image)
  // An image was selected by the user
  imageSelected(event) {
    console.info("Image selected");

    const file = event.target.files[0];
    if (!file) {
      return console.error("No file found");
    }

    console.info("Image uploaded", file);

    const validator = new FileValidator();
    const size = {
      requiredWidth: this.widthInInches(),
      requiredHeight: this.heightInInches(),
    };

    if (!validator.checkMIMEValidity(file.type)) {
      return this.setState(
        {
          imageError: "Image is of the wrong type",
        },
        () => {
          this.propagateToParent();
        }
      );
    }

    this.setState({ imageError: null }, () => {
      this.propagateToParent();
    });

    const checkId = this.randomID();
    this.checkId = checkId;

    validator.checkDimensionValidity(file, size, (isValid) => {
      if (this.checkId !== checkId) {
        return console.debug("CID has changed, do not update");
      }

      this.uploadImageToS3(file, !isValid);
    });
  }

  randomID() {
    return Math.random();
  }

  height() {
    return this.props.size.height;
  }

  width() {
    return this.props.size.width;
  }

  heightInInches() {
    return this.props.size.heightInInches;
  }

  widthInInches() {
    return this.props.size.widthInInches;
  }

  url() {
    if (!this.state.path) {
      return this.defaultUrl;
    }

    if (!this.state.scale) {
      return this.defaultUrl;
    }

    const base =
      "https://mailfold-campaign-src.imgix.net/" +
      this.state.path +
      "?" +
      "&w=" +
      this.smallWidth +
      "&h=" +
      this.smallHeight +
      "&fm=png";

    switch (this.state.scale) {
      case "plain":
        return base;
      case "crop":
        return base + "&fit=crop";
      case "fill":
        return base + "&fit=fill";
      case "scale":
        return base + "&fit=scale";
      default:
        return console.error(`${fit} is not a valid resize option`);
    }
  }

  get ratio() {
    return 540 / this.width();
  }

  get smallWidth() {
    return Math.round(this.ratio * this.width());
  }

  get smallHeight() {
    return Math.round(this.ratio * this.height());
  }

  uploadImageToS3(file, displayScaleOptions) {
    this.setState(
      {
        imageError: null,
        scale: "plain",
        path: "",
        fileName: "",
        displayScaleOptions: false,
        uploadImageMessage: "Uploading image. Just a moment...",
        isLoading: true,
      },
      () => {
        this.uploadFile(file, displayScaleOptions);
      }
    );
  }

  uploadFile(file, displayScaleOptions) {
    console.info("Uploading file ...");

    const uploader = new S3Uploader();
    const cid = this.props.guid;
    const uploadID = this.randomID();
    this.uploadID = uploadID;

    uploader.upload(file, "Untitled", cid, null, (name, url) => {
      if (this.uploadID !== uploadID) {
        return console.debug("RID has changed, do not update");
      }

      if (!name) {
        return console.error("[BUG] No file name", name, url);
      }

      if (displayScaleOptions) {
        this.setState({ scale: "fill" });
      }

      this.setState(
        {
          path: name,
          uploadImageMessage: null,
          fileName: file.name,
          displayScaleOptions: displayScaleOptions,
          isLoading: false,
        },
        () => {
          this.propagateToParent();
        }
      );
    });
  }

  // "front" => "Front"
  typeName() {
    const type = this.props.type;
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
  }

  // User clicked the bleed button
  toggleBleedMode() {
    console.info("Clicked toggleBleedMode");
  }

  bleedImage(cardSize, side) {
    const bleedSide = side.charAt(0).toUpperCase() + side.slice(1);
    const bleedSize =
      cardSize === "Small"
        ? "Bleed4x6"
        : cardSize === "Medium"
        ? "Bleed6x9"
        : cardSize === "Large"
        ? "Bleed6x11"
        : null;
    const src = bleedSide + bleedSize;
    return src;
  }

  // @option == ["scale", "fit", "resize"]
  // User selected a scale option from the dropdown menu
  scaleOptionChanged(event) {
    const scale = event.target.value;
    console.info("Update postcard scale to", scale);
    this.setState({ scale }, () => {
      this.propagateToParent();
    });
  }

  get displayScaleList() {
    if (this.state.displayScaleOptions) {
      return true;
    }

    return this.state.scale != "plain";
  }

  filenameDisplay() {
    if (this.state.uploadImageMessage) {
      return this.state.uploadImageMessage;
    }

    if (this.state.fileName) {
      return this.state.fileName;
    }

    return "Choose a file";
  }

  join(...args) {
    return args.join("");
  }

  sum(...args) {
    return args.join(" ");
  }

  toField(name) {
    return `campaign[${this.props.type}_attributes][${name}]`;
  }

  get isLoadingOpacity() {
    return this.state.isLoading ? 1 : 0;
  }

  get bleedToggleId() {
    return `${this.typeName()}BleedToggle`;
  }

  get fileNameClass() {
    const base = this.sum("FilenameDisplay", this.designID);

    if (!this.state.isLoading) {
      return base;
    }

    return this.sum(base, "Loading");
  }

  get designID() {
    return `${this.typeName()}Design`;
  }

  get fieldId() {
    return this.props.type + "-postcard";
  }

  goAndCreateDesign(e) {
    e.preventDefault();
    let cardSize = `${this.props.size.heightInInches}x${this.props.size.widthInInches}`;
    window.location = `/design_elements/new?cid=${this.props.guid}&type=${this.props.type}&card_size=${cardSize}&mailer_type=${this.props.mailer}`;
  }

  render() {
    const { letter, type } = this.props;
    const letterFormat = letter;
    let letterClass = letterFormat === "Letter" ? "letter" : "";
    const bleedFormat = this.bleedImage(letterFormat, type);
    const bleedSrc =
      bleedFormat === "FrontBleed4x6"
        ? FrontBleed4x6
        : bleedFormat === "FrontBleed6x9"
        ? FrontBleed6x9
        : bleedFormat === "FrontBleed6x11"
        ? FronyBleed6x11
        : bleedFormat === "BackBleed4x6"
        ? BackBleed4x6
        : bleedFormat === "BackBleed6x9"
        ? BackBleed6x9
        : bleedFormat === "BackBleed6x11"
        ? BackBleed6x11
        : null;
    return (
      <fieldset
        id={this.fieldId}
        className={`UploadContainer paddingleft20 ${letterClass}`}
      >
        <h3>{this.typeName()} Design</h3>
        {letterFormat === "Letter" || !this.url() ? null : (
          <>
            <div className="bleed-text">Show Bleed</div>
            <input
              value=""
              className="ToggleSwitchInput"
              id={this.bleedToggleId}
              type="checkbox"
              onChange={this.toggleBleedMode}
            ></input>
            <label
              className="ToggleSwitchContainer"
              htmlFor={this.bleedToggleId}
            >
              <label
                className="ToggleSwitch"
                htmlFor={this.bleedToggleId}
              ></label>
            </label>
          </>
        )}

        <div
          className={this.sum(this.typeName(), "Preview", this.landscapeType())}
        >
          <div
            className={`AssetContainer  ${letterClass}`}
            style={letterFormat === "Letter"
            ? {width: 'auto', height: 'auto'}
            : {width: this.smallWidth, height: this.smallHeight}}
          >
            {this.url() ? (
              <>
                {typeof this.url() === "string" &&
                this.url().indexOf("svg") !== -1 ? (
                  <object
                    data={this.url()}
                    width={this.smallWidth}
                    height={this.smallHeight}
                    type="image/svg+xml"
                  ></object>
                ) : (
                  <img src={this.url()} />
                )}
                {bleedSrc ? (
                  <img className="bleed-image" src={bleedSrc} />
                ) : null}
              </>
            ) : null}
          </div>
          {this.url() === "" && (
            <div
              className={`diagonal-line ${letterClass}`}
            ></div>
          )}
        </div>
        <div className="Clear"></div>
        <If condition={this.props.designOption == "upload"}>
          <div className="button-cover postcard">
            <input
              name="campaign[guid]"
              type="hidden"
              value={this.props.guid}
            ></input>

            <input
              value=""
              accept="image/png, image/jpeg, application/pdf, application/x-pdf"
              id={this.designID}
              name={`campaign[${this.props.type}-design]`}
              type="file"
              onChange={this.imageSelected}
            ></input>
            <div
              className={`${this.fileNameClass} ${letterClass}`}
            >
              {this.filenameDisplay()}
            </div>
            <div
              className="LoadingSpinner"
              style={{ opacity: this.isLoadingOpacity }}
            ></div>

            <label className="FileInputButton" htmlFor={this.designID}>
              Choose File
            </label>
          </div>
        </If>
        <If condition={this.props.designOption == "create"}>
          <div className="button-cover postcard">
            <button
              className={`Button GreenSubmit UploadSubmit ${letterClass} `}
              onClick={this.goAndCreateDesign}
            >
              Create a {this.props.type} design
            </button>
          </div>
        </If>
        <input
          name={this.toField("side_type")}
          type="hidden"
          value={this.props.type}
        ></input>
        <If condition={this.state.id}>
          <input
            name={this.toField("id")}
            type="hidden"
            value={this.state.id}
          ></input>
        </If>
        <input
          name={this.toField("scale")}
          type="hidden"
          value={this.state.scale}
        ></input>
        <input
          name={this.toField("file_name")}
          value={this.state.fileName}
          type="hidden"
        ></input>
        <input
          name={this.toField("path")}
          value={this.state.path}
          type="hidden"
        ></input>
        <If condition={this.state.imageError}>
          <div className="Message">
            <div className="Icon">
              <div className="SubIcon"></div>
              <div className="Background"></div>
            </div>
            <p>
              <b>One small thing.</b>
              {this.state.imageError}
            </p>
            <div className="Clear"></div>
          </div>
        </If>
        <If
          condition={
            this.state.displayScaleOptions &&
            this.props.designOption !== "create"
              ? true
              : false
          }
        >
          <div
            className={`DesignFix ${letterClass}`}
          >
            <div className="Clear"></div>
            <div className="Message">
              <div className="Icon">
                <div className="SubIcon"></div>
                <div className="Background"></div>
              </div>
              <p className={letterClass}>
                <b>One small thing.</b>
                The image you uploaded should have been {this.height()}px
                &times; {this.width()}px but it wasn't. <b>That's ok,</b> we can
                fix it for you. Select a fix option below or try again with a
                new image.
              </p>
              <div className="Clear"></div>
            </div>
          </div>
        </If>
        <If
          condition={
            this.displayScaleList && this.props.designOption !== "create"
              ? true
              : false
          }
        >
          <div className="design-wrapper">
            <label>Adjust Design</label>
            <div className="Message">
              <div className="Clear"></div>
              <select
                className="resize"
                value={this.state.scale}
                onChange={this.scaleOptionChanged}
              >
                <option value="fill">Fill Entire Card</option>
                <option value="crop">Fit Design Inside Card</option>
                <option value="scale">Stretch Design to Fit Card</option>
              </select>
            </div>
          </div>
        </If>
        <div className="Clear"></div>
      </fieldset>
    );
  }
}
