import React, { useState } from 'react';
import closeIcon from "../../../assets/images/red-close-icon.svg"
import prospectingImg1 from "../../../assets/images/prospecting-page-image-1"
import prospectingImg2 from "../../../assets/images/prospecting-page-image-2"
import { postData } from "../../lib/fetches";

import { Modal } from "semantic-ui-react";
import { premiumSubscriptionByType, premiumSubscriptionTypeByName } from '../shared/subscriptions/utils';
import SubscriptionPremiumTypes from '../shared/subscriptions/SubscriptionPremiumTypes';

export default function Prospecting(props) {

  const [redTip, setRedTip] = useState(false);
  const [user, setUser] = useState(props.user);
  const [buttonDisabled, disableButton] = useState(false);
  const [modalUpgrade, setModalUpgrade] = useState(false);
  const [premiumType, setPremiumType] = useState(() => premiumSubscriptionTypeByName(props.user?.['premium?']))

  function redirectToPlanSettings() {
    window.location.href = "/settings/?tab=premium";
  }
  function redirectToNewProspecting() {
    window.location.href = props.redirect_path;
  }

  const upgradeAccount = (premiumType) => {
    disableButton(true);
    if (user["with_card?"]) {
      postData(
          "/set_premium",
          props.authToken,
          {premiumType: premiumSubscriptionByType(premiumType)},
          (res) => {
            setUser(res);
            window.location.reload();
          }
      );
    } else {
      window.location.href = "/settings?tab=billing"
    }

  }


  return (
      <div>
        <div className="Content PageContent FlexContent">
          <main className="Automation">
            <div className="Clear"></div>
            <div className="prospecting-description">
              <div className="prospecting-description-left">
                <h1 className='Title'>Prospecting</h1>
                <div className="prospecting-description-left-text">
                  Create prospecting for mailing all the potential clients and take your business to the next level.
                  Creating prospecting allows you extend your target audience and find people by location, demography etc.
                  Prospecting gives you a new opportunity to conquer the world with your business and attract many new customers.
                </div>
                <div className='prospacting-button-wrapper'>
                  <button
                            className="green-bordered-button"
                            onClick={() => setModalUpgrade(true)}
                        >
                          Upgrade now
                        </button>
                  {user["premium?"] &&
                      <button className="green-bordered-button" onClick={() => redirectToNewProspecting()}>Create Prospecting</button>
                  }

                </div>
              </div>
              <div className='prospactingimg-wrapper'>
                {/* <div className="AutomationRectangle ProspectingRectangle">
                      <img
                          src={prospectingImg1}
                      />
                      <img
                          src={prospectingImg2}
                      />
                    </div> */}
                {user["premium?"] &&
                    <div className="AutomationRectangle ProspectingRectangle">
                      <img
                          src={prospectingImg1}
                      />
                      <img
                          src={prospectingImg2}
                      />
                    </div>
                }

              </div>
            </div>
            {!user["premium?"] &&
                <div className="AutomationDescriptionBlock ProspectingDescriptionBlock">
                  <div className="AutomationSubItem">                
                    <Modal
                        open={modalUpgrade}
                        closeOnEscape={true}
                        closeOnDimmerClick={true}
                        className="contacts-segment-modal"
                        onClose={() => setModalUpgrade(false)}
                    >
                      <div className="contacts-segment-container small">

                        <div className="contacts-segment-body">
                          <h3 className="segment-modal-small-title">
                            Upgrade to Premium
                          </h3>
                          <h4 className="segment-modal-small-subtitle">
                            In order to create Prospecting Campaigns you have to upgrade your account to Premium. Upgrade now?
                          </h4>
                          {user["with_card?"] && <SubscriptionPremiumTypes premiumType={premiumType} setPremiumType={setPremiumType}/>}
                        </div>
                        <div className="segment-modal-footer">
                          <div className="grid grid-2 segment-modal-buttons">
                            <div className="grid-item">
                              <button
                                  className="blue-button"
                                  disabled={buttonDisabled}
                                  style={{ fontSize: "14px" }}
                                  onClick={() => upgradeAccount(premiumType)}
                              >
                                Upgrade to Premium
                              </button>
                            </div>
                            <div className="grid-item">
                              <button
                                  className="gray-button"
                                  onClick={() => setModalUpgrade(false)}
                              >
                                Maybe later
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Modal>
                  </div>                 
                </div>
            }
          </main>
          <div className="AutomationSidebar">
            <div className="SidebarBody">
              <div className="balance-block">
                <p>Your account balance:</p>
                <div className="balance-value">
                  <span className="icon-Mailfold-coin-full-currency"></span>
                  {
                    props.user.balance === 0
                        ? "0.00"
                        : parseFloat(props.user.balance).toFixed(2)
                  }
                </div>
                <button
                    className="to-replenish-link green-bordered-button margin-top-s"
                    onClick={() => {
                      window.location.href = "settings/?tab=balance";
                    }}
                >
                  Replenish my balance
                </button>
              </div>

              {!user["premium?"] &&
                  <div>
                    {redTip ?
                        (<div className="prospectingRedTip">
                          <p>Your Premium Subscription is finished. In order to continue automation mailing, please upgraide to Premium </p>
                          <span onClick={() => setRedTip(false)}><img src={closeIcon}></img></span>
                        </div>)
                        :
                        null
                    }
                  </div>
              }

              {/* {!user["premium?"]
                  &&
                  <div className="UpgradeBlock ProspectingUpgradeBlock">
                    <div className="Description">
                      <p className="upgrade-title">
                        Upgrade to Premium starting from{" "}
                        $99 per month
                      </p>
                      This feature requires the Premium Plan.
                    </div>
                    <div className="UpgrateButton">
                      <button onClick={redirectToPlanSettings}>Upgrade</button>
                    </div>
                  </div>
              } */}

              <div className="sidebar-automation-block prospecting-automation-block">
                <h4>Automate mailing</h4>
                <div className="sidebar-text">
                  Automate communication with new customers.
                  When automated mail is enabled,
                  Mailfold syncs with your Etsy shop daily.
                  If new customers match your defined rules, Mailfold will send them mail.
                </div>
              </div>
                <button
                    className="to-replenish-link green-bordered-button margin-top-s max-wd-300"
                    onClick={() => {
                      window.location.href = "/automations";
                    }}
                >
                  Automate mailing
                </button>
            </div>
          </div>
        </div>
      </div>
  )
}
