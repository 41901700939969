import React from "react";
import { connect } from "react-redux";

import CampaignSidebar from "./CampaignSidebar";
import CampaignsSidebar from "./CampaignsSidebar";

class DefaultSidebar extends React.Component {
  deselectRowsCampaign = (campaigns) => {
    this.props.dispatch({
      type: "DeselectRowsCampaign",
      selected: campaigns.filter((c) => c.selected),
      campaigns: campaigns,
    });
  };

  deleteSelectedCampaigns = (campaigns) => {
    const campaign_ids = campaigns.map(c => c.id)
    fetch(`/campaigns/destroy_selected/${campaign_ids}`, {
      credentials: 'same-origin',
      method: 'DELETE',
      body: { campaign_ids: campaign_ids }
    }).then(response => response.json())
      .then(response => window.location.reload())
  };

  defaultSidebar() {
    const count = this.props.count;
    return (
      <aside className="PageSidebar campaign-fill">
        <div className="PageSidebarContents">
          <a className="Button GreenSubmit campaign" href="/campaigns/new">
            Create Campaign
          </a>
          <div className="sidebar-divider"></div>
          <div className="sidebar-text">
            Your campaigns have created <b>{count / 10} new trees</b> and{" "}
            <b>{count / 1000} workdays </b>
            in a developing country.
          </div>
          <div className="sidebar-automation-block">
           
          <button
              className="to-replenish-link green-bordered-button margin-top-s max-wd-300"
              onClick={() => {
                window.location.href = "/automations";
              }}
            >
              Automate mailing
            </button>
            <div className="sidebar-text">
              Automate communication with new customers.
            </div>

            
          </div>
        </div>

        {/* {count != 0 && (
          <div className="PageSidebarFooter">
            <div
              className={
                "TreeAccounting Leaf" + (1 + parseInt(Math.random() * 5))
              }
            >

            </div>
          </div>
        )} */}
      </aside>
    );
  }
  render() {
    const selected = this.props.campaigns.filter((c) => c.selected);
    let sidebar;
    if (this.props.campaign == undefined && selected.length == 0) {
      sidebar = this.defaultSidebar();
    } else if (selected.length == 1) {
      sidebar = <CampaignSidebar campaign={selected[0]} />;
    } else if (selected.length > 1) {
      sidebar = (
        <CampaignsSidebar
          deselectRowsCampaign={this.deselectRowsCampaign}
          deleteSelectedCampaigns={this.deleteSelectedCampaigns}
          allCampaigns={this.props.campaigns}
          campaigns={selected}
        />
      );
    } else if (selected.length == 0) {
      sidebar = this.defaultSidebar();
    }
    return <>{sidebar}</>;
  }
}

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(DefaultSidebar);
