import React, { useEffect, useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { postData } from '../../../lib/fetches';
import NoPremiumForm from './NoPremiumForm';
import PremiumForm from './PremiumForm';
import { premiumSubscriptionByType } from './utils';


const SubscriptionForm = (props) => {
    const authToken = () => { return $('meta[name="csrf-token"]').attr("content"); }
    const [message, setMessage] = useState({ content: '', error: false })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const upgradeUserPlan = (premiumType) => {
        setIsSubmitting(true)
        setMessage((prev) => ({...prev, content: ''}))
        return postData(
            "/set_premium",
            authToken(),
            { premiumType: premiumSubscriptionByType(premiumType) },
            (res) => {
                try {
                    localStorage.setItem('last_message', JSON.stringify({ content: 'You have changed Premium subscription successfully.', error: false }))
                } catch (error) {

                }
                setIsSubmitting(false)
                window.location.reload();
            },
            (err) => {
                try {
                    localStorage.setItem('last_message', JSON.stringify({ content: 'Something went wrong. Failed to change Premium subscription.', error: true }))
                } catch (error) {

                }
                setIsSubmitting(false)
                window.location.reload();
            }
        );
    }

    const deleteData = (url, body, afterAction, afterActionError = null) => {

        const formBody = new URLSearchParams({
            _method: 'delete',
            authenticity_token: authToken(),
            ...body
        }).toString()

        fetch(url, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-CSRF-Token": authToken(),
            },
            body: formBody,
        })
            .then((response) => {
                if (response.status > 204) {
                    throw new Error("Not success");
                }
                return response;
            })
            .then((data) => {
                afterAction(data);
            })
            .catch((error) => {
                afterActionError && afterActionError(error)
                console.log(error);
            });
    };

    const destroyUserPlan = (premiumType) => {

        const reply = window.confirm("Are you sure?");
        if (!reply) {
            return;
        }
        setIsSubmitting(true)
        setMessage((prev) => ({...prev, content: ''}))

        deleteData(
            "/destroy_user_plan",
            { premiumType: premiumSubscriptionByType(premiumType) },
            res => {
                setIsSubmitting(false)
                try {
                    localStorage.setItem('last_message', JSON.stringify({ content: 'You have stopped Premium Subscription successfully!', error: false }))
                } catch (error) {

                }
                window.location.reload()

            },
            (error) => {
                setIsSubmitting(false)
                try {
                    localStorage.setItem('last_message', JSON.stringify({ content: 'Something went wrong. Failed to stop Premium subscription.', error: true }))
                } catch (error) {

                }
                window.location.reload()
            }
        );
    }

    useEffect(() => {
        try {
            const savedMessage = JSON.parse(localStorage.getItem('last_message') || '{}');
            if (Object.keys(savedMessage).length) {
                localStorage.removeItem('last_message')
                setMessage(savedMessage)
            }
        } catch (error) {

        }
    }, [])

    console.log({ props });

    return (
        <>

            {isSubmitting
                ? <Loader active inline />
                : message?.content && <Message
                style={{ display: 'block', paddingRight: '26px' }}
                success={!message.error}
                error={message.error}
                header=""
                size='mini'
                floating
                content={message.content}
                onDismiss={() => setMessage((prev) => ({ ...prev, content: "" }))}
            />
            }

            <div style={{ display: 'flex' }}>
                <PremiumForm
                    data={props}
                    upgrade={upgradeUserPlan}
                    destroy={destroyUserPlan}
                />
                <NoPremiumForm
                    data={props}
                    upgrade={upgradeUserPlan}
                />
            </div>
        </>
    );
};

export default SubscriptionForm;
