import React, { useState }  from "react";

import HelpSidebar from "./HelpSidebar";
import ReportCharts from "./ReportCharts";
import ReportTable from "./ReportTable";

import "react-datepicker/dist/react-datepicker.css";
import EditIcon from "../../../../assets/images/edit-icon.svg";
import ArrowLeft from "../../../../assets/images/arrow-left-gray.svg";

const QRReportWrapper = (props) => {

    const [isFullReportShown, setIsFullReportShown] = useState(false);

    const goBack = () => {
        if(isFullReportShown) return setIsFullReportShown(false);
        window.location.href = '/campaigns';
    };

    return (
      <div>
          <div className="Content PageContent FlexContent QRReportPageWrapper">
              <main className="QRReport">

                  {/*TODO: ?move header to separate component*/}
                  <header className="PageHeader margin-bottom-s">
                      {/** Mocked method */}

                      <button className="transparent-gray-button back-btn margin-bottom-s" onClick={() => goBack()}>
                          <img src={ArrowLeft} alt="arrow-left"/>
                          Back
                      </button>

                      <h1>QR Code Report</h1>
                      <div className="Clear" />
                      <div className="QRReport__Description">
                          Review the QR code tracking report to see the scans details  and adjust your future campaigns accordingly.
                      </div>
                  </header>

                  <div className="Clear"/>

                  {/* *Input for URL */}
                  {
                      !isFullReportShown &&
                          (<section className="URL-section">
                              <label htmlFor="URL"><span className="LabelTip">Destination URL</span></label>

                              <form className="URL-section__form">
                                  <input type="text" id="URL" placeholder="URL"/>

                                  <button className="transparent-gray-button transparent-gray-button_small">
                                      <img src={EditIcon} alt="edit-icon"/>
                                      Edit
                                  </button>
                              </form>
                          </section>)
                  }

                  {/* *Table with chart */}

                  {
                      isFullReportShown ?  <ReportTable /> : <ReportCharts />
                  }

              </main>

              <HelpSidebar>
                  <button className="Button GreenSubmit campaign" onClick={() => setIsFullReportShown(true)}>
                      View Full QR Code Report
                  </button>
              </HelpSidebar>

          </div>
      </div>
    )
};

export default QRReportWrapper;