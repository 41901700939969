import React from "react";
import PropTypes from "prop-types";
import { autobind } from "core-decorators";
import ErrorMessage from "../design/ErrorMessage";
import Welcome from "../shared/Welcome";
import greenCloseIcon from "../../../assets/images/green-close-icon.svg"
import Warning from "../shared/Warning";
import cn from "classnames";

@autobind
export default class CampaignForm extends React.Component {
  ignoreKeys = ["dirty", "hideState", "valid"];
  countries = [];
  states = [];
  queue = {};

  static get propTypes() {
    return {
      country: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      isScheduled: PropTypes.bool.isRequired,
      sendDate: PropTypes.string.isRequired,
      addressId: PropTypes.string.isRequired,
      addressLine1: PropTypes.string.isRequired,
      addressLine2: PropTypes.string.isRequired,
      businessName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      errors: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    for (const [name, key] of props.countries) {
      this.countries.push({ key, name });
    }

    for (const [name, key] of props.states) {
      this.states.push({ key, name });
    }

    this.state = {
      ...props,
      dirty: false,
      hideState: false,
      valid: true,
      saveButton: "Save & Exit",
      businessName: props.return_address?.business_name ?? "",
      addressLine1: props.return_address?.address_line_1 ?? "",
      addressLine2: props.return_address?.address_line_2 ?? "",
      city: props.return_address?.city ?? "",
      state: props.return_address?.state ?? "",
      postalCode: props.return_address?.postal_code ?? "",
      country: "US",
      showGreenTip: true,
      errorsForm: {}
    };

    for (let i = 0; i < localStorage.length; i++) {
      const name = localStorage.key(i);
      const value = localStorage.getItem(name);

      if (this.ignoreKeys.includes(name)) {
        continue;
      }

      if (name === "isComplimentaryCopy") {
        this.state[name] = value === "true";
      } else if (name === "isScheduled") {
        this.state[name] = value === "true";
      } else if (!this.state[name]) {
        this.state[name] = value;
      } else if (name === "name") {
        this.updateMainTitle(value);
      }
    }

    if (this.state.country === "US") {
      this.state.hideState = false;
    } else if (this.state.country) {
      this.state.hideState = true;
    } else {
      this.state.country = "US";
      this.state.hideState = false;
    }
  }

  get currentState() {
    if (this.state.hideState) {
      return "NONE";
    }

    return this.state.state || "";
  }

  onCountryChange(event) {
    const country = event.target.value;

    if (country == "US") {
      this.setState({ hideState: false });
    } else {
      this.setState({ hideState: true });
    }

    this.setState({
      country: country,
      dirty: true,
    });
  }

  componentWillUnmount() {
    $("body").off("click", ".Calendar *", this.onDidClickCalender);
  }

  componentDidMount() {
    const now = new Date();
    const saveDate = new Date(now);
    const maxDate = new Date(now);

    this.calendar = pickmeup(".Calendar", {
      mode: "single",
      flat: true,
      select_month: false,
      select_year: false,
      calendars: 2,
      render: function (date) {
        if (date < saveDate || date > maxDate) {
          return { disabled: true, class_name: "date-in-past" };
        }
        return {};
      },
    });

    saveDate.setDate(saveDate.getDate() + 5);
    maxDate.setDate(maxDate.getDate() + 90);

    // if (this.isValid) {
    //   this.setState({ valid: true });
    // }

    if (this.state.isScheduled) {
      this.onScheduledDate();
    } else {
      this.onScheduledAsap();
    }
  }

  onScheduledDate(event) {
    const now = new Date();
    let nearest = new Date(now);

    if (this.state.sendDate) {
      nearest = new Date(this.state.sendDate);
    } else {
      nearest.setDate(nearest.getDate() + 6);
    }

    this.calendar.set_date(nearest);
    this.calendar.update();

    this.setState({
      isScheduled: true,
      sendDate: this.calendar.get_date("A, b d Y"),
      dirty: event !== undefined,
    });

    $(".Calendar").show();
    $(".Calendar *").on("click", this.onDidClickCalender);
  }

  onDidClickCalender() {
    this.setState({
      isScheduled: true,
      sendDate: this.calendar.get_date("A, b d Y"),
      dirty: true,
    });
  }

  updateMainTitle(title) {
    $(".CampaignName").text(title);
  }

  onScheduledAsap(event) {
    this.setState({
      isScheduled: false,
      sendDate: "",
      dirty: event !== undefined,
    });

    $(".Calendar").hide();
  }

  onSave() {
    for (const key in this.state) {
      if (this.ignoreKeys.includes(key)) {
        continue;
      }

      localStorage.setItem(key, this.state[key]);
    }

    this.setState({ dirty: false, saveButton: "Saving..." });
    setTimeout(() => {
      this.refs.form.submit();
      this.setState({ saveButton: "Save & Exit" });
      this.goBack();
    }, 2000);
  }

  checkClientValidity() {
    let result = true;

    if (!this.state.name) {
      this.setState((prev) => ({ ...prev, errorsForm: { ...prev.errorsForm, name: ['Campaign name is required.'] } }))
      result = false;
    }
    // if (this.state.city) {
    //   const regex = new RegExp("^[a-zA-Z\s]*$");
    //   if (!regex.test(this.state.city)) {
    //     this.setState((prev) => ({ ...prev, errorsForm: { ...prev.errorsForm, city: ['City is invalid.'] } }))
    //     result = false;
    //   }
    // }

    return result;
  }

  onSubmit(event) {

    event.preventDefault();

    if (!this.isValid) {
      //event.preventDefault();
      return console.error("Form is not valid");
    }

    const formData = new FormData(this.refs.form);

    fetch(`/campaigns`, {
      body: formData,
      method: "POST",
    })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Not success");
          }
          if (response?.redirected) {
            return { url: response.url };
          }
          return response.json();
        })
        .then((data) => {

          if (data.url) {
            this.resetStore();
            window.location.href = data.url;
            return;
          }
          this.setState({ errorsForm: data.errors ?? {} })

        })
        .catch((error) => console.log(error));

  }

  resetStore() {
    localStorage.clear();
  }

  goBack() {
    window.location.href = "/campaigns";
  }

  onChange({ target }) {
    const field = target.getAttribute("field");
    const value = target.value;

    if (field == "name") {
      this.updateMainTitle(value);
    }

    if (!field) {
      return console.debug("Field not found for", target.name);
    }

    this.setState({
      [field]: value,
      dirty: true,
    });
  }

  onChangeComplimentaryCopy({ target }) {
    this.setState({
      isComplimentaryCopy: target.checked,
      dirty: true,
    });
  }

  get isValid() {
    // return this.refs.form.checkValidity();
    return this.checkClientValidity();
  }

  throttled(id, callback) {
    if (this.queue[id]) {
      clearTimeout(this.queue[id]);
    }

    this.queue[id] = setTimeout(() => {
      this.queue[id] = null;
      callback();
    }, 200);
  }

  onAllChange() {
    this.throttled("valid", () => {
      this.setState({ valid: this.isValid });
    });
  }

  showWelcome() {
    if ((Date.now() - new Date(this.props.urd).getTime()) / 1000 <= 30)
      return <Welcome />;
  }

  closeGreenTip() {
    this.setState({
      showGreenTip: false
    })
  }

  getWarning(data) {
    if (Array.isArray(data)) {
      return data.join('. ');
    }

    if (data) {
      return data;
    }
    return "";
  }

  render() {

    return (
        <div>
          {this.showWelcome()}
          <form
              role="form"
              ref="form"
              method="post"
              onChange={this.onAllChange}
              onSubmit={this.onSubmit}
              action="/campaigns"
          >
            {this.props.with_prospecting && this.state.showGreenTip &&
                <div className="prospectingCampaignGreenTip">
                  <span>The Propecting will be available after creating a Campaign and Design.</span>
                  <span className="close-icon" onClick={this.closeGreenTip}><img src={greenCloseIcon}></img></span>
                </div>
            }
            <If condition={this.props.errors}>
              <ErrorMessage body={this.props.errors} />
            </If>

            <input
                type="hidden"
                name="campaign[return_address_attributes][id]"
                id="campaign_return_address_attributes_id"
                onChange={this.onChange}
                value={this.state.addressId}
            />

            <input type="hidden" name="page" defaultValue="setup" />
            <input type="hidden" name="campaign[with_prospecting]" value={this.props.with_prospecting} />
            <input type="hidden" name="existing_campaign" value={this.props.existing_campaign} />

            <div className="ScrollOffset">&nbsp;</div>
            <div className="formwrapper">
              <fieldset>
                <div className="formheading-wrapper">
                  <div className="form-heading">
                    <h2>Basics</h2>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                    </p>
                  </div>
                  <div className="form-heading">
                    <h2>Return Address</h2>
                    <p>
                      This mailing address will be printed on your postcards. Try to use
                      a name that will be recognizable to your customers.
                    </p>
                  </div>
                </div>
                <div className="forminput-wrapper">
                  <div className="form-input">
                    <label className="ExtendedWidth" htmlFor="campaign-name">
                      Name Your Campaign{" "}
                      <span className="LabelTip">
                      <span className="Bullet">&bull;</span>Only You See This
                    </span>
                    </label>
                    <input
                        placeholder="Untitled Campaign"
                        type="text"
                        field="name"
                        name="campaign[name]"
                        //maxLength="70"
                        //required
                        id="campaign-name"
                        value={this.state.name}
                        onChange={this.onChange}
                        className={cn({ error: !!this.state.errorsForm.name })}
                    />
                  </div>
                  <div className="form-input">
                    <label htmlFor="business-name">
                    Name{" "}
                    <span className="LabelTip">
                    <span className="Bullet">&bull;</span>Business Name, Store Name,
                    or Your Name
                  </span>
                  </label>
                  <input
                      type="text"
                      id="business-name"
                      placeholder="Fiddle Leaf Fig Company"
                      name="campaign[return_address_attributes][business_name]"
                      field="businessName"
                      onChange={this.onChange}
                      // required="required"
                      value={this.state.businessName}
                      // maxLength="40"
                      // minLength="3"
                      className={cn({ error: !!this.state.errorsForm.business_name })}
                  />
                  </div>
                </div>
                <div className="forminput-wrapper">
                  <div className="form-input">
                    <label htmlFor="address_line_1">Address Line 1</label>
                    <input
                        placeholder="345 Oak St."
                        type="text"
                        // required="required"
                        // maxLength="64"
                        // minLength="3"
                        name="campaign[return_address_attributes][address_line_1]"
                        id="address_line_1"
                        onChange={this.onChange}
                        field="addressLine1"
                        value={this.state.addressLine1}
                        className={cn({ error: !!this.state.errorsForm.address_line_1 })}
                    />
                    <Warning data={this.getWarning(this.state.errorsForm.address_line_1)} />
                  </div>
                  <div className="form-input">
                    <label htmlFor="address_line_2">Address Line 2 (optional)</label>
                    <input
                        placeholder="Apartment, Suite, or Floor"
                        type="text"
                        name="campaign[return_address_attributes][address_line_2]"
                        id="address_line_2"
                        onChange={this.onChange}
                        field="addressLine2"
                        value={this.state.addressLine2}
                        className={cn({ error: !!this.state.errorsForm.address_line_2 })}
                    />
                    <Warning data={this.getWarning(this.state.errorsForm.address_line_2)} />
                  </div>
                </div>
                <div className="forminput-wrapper">
                  <div className="form-input">
                    <label htmlFor="city">City</label>
                    <input
                        type="text"
                        placeholder="Maplewood"
                        //pattern="^[a-zA-Z\s]*$"
                        title="City should only contain letters"
                        name="campaign[return_address_attributes][city]"
                        id="city"
                        field="city"
                        onChange={this.onChange}
                        // required="required"
                        value={this.state.city}
                        className={cn({ error: !!this.state.errorsForm.city })}
                    />
                    <Warning data={this.getWarning(this.state.errorsForm.city)} />
                  </div>
                  <div className="form-input">
                    <label htmlFor="country">Country</label>
                    <select
                        className={cn("CountryField avoid-clicks", { error: !!this.state.errorsForm.country })}
                        name="campaign[return_address_attributes][country]"
                        id="country"
                        field="country"
                        // required="required"
                        value={this.state.country}
                        defaultValue={this.state.country}
                    >
                      {/* <For each="country" of={this.countries}>
                      <option key={country.key} value={country.key}>
                        {country.name}
                      </option>
                    </For> */}
                      <option>US</option>
                    </select>
                    <Warning data={this.getWarning(this.state.errorsForm.country)} />
                  </div>
                </div>
                <div className="forminput-wrapper">
                  <div className="form-input">
                    <label htmlFor="postal_code">Postal Code</label>
                    <input
                        placeholder="12345"
                        type="text"
                        name="campaign[return_address_attributes][postal_code]"
                        id="postal_code"
                        onChange={this.onChange}
                        // minLength="5"
                        field="postalCode"
                        // required="required"
                        value={this.state.postalCode}
                        className={cn({ error: !!this.state.errorsForm.postal_code })}
                    />
                    <Warning data={this.getWarning(this.state.errorsForm.postal_code)} />
                  </div>
                  <div className="form-input">
                    <label htmlFor="address-state">State/Province/Region</label>
                    <select
                        className={cn("StatesDropDown", { error: !!this.state.errorsForm.state })}
                        name="campaign[return_address_attributes][state]"
                        id="address-state"
                        field="state"
                        onChange={this.onChange}
                        disabled={this.state.hideState}
                        value={this.currentState}
                    >
                      <If condition={this.state.hideState}>
                        <option value="" key="NONE">
                          Not Applicable
                        </option>
                      </If>

                      <For each="state" of={this.states}>
                        <option key={state.key} value={state.key}>
                          {state.name}
                        </option>
                      </For>
                    </select>
                    <Warning data={this.getWarning(this.state.errorsForm.state)} />
                  </div>
                </div>
                <div className="OptionsInline">
                  <div className="RadioRow">
                    <div className="lavel-cover">
                      <input
                          type="checkbox"
                          checked={this.state.isComplimentaryCopy}
                          name="campaign[complimentary_copy]"
                          onChange={this.onChangeComplimentaryCopy}
                          field="isComplimentaryCopy"
                          id="complimentary-copy"
                      />
                      <input type="hidden" name="campaign[complimentary_copy]" value={this.state.isComplimentaryCopy} />

                      <label className="CheckboxButton" htmlFor="complimentary-copy">
                        Complimentary copy
                      </label>

                      <label
                          htmlFor="complimentary-copy"
                          className="builder-label-padding"
                      >
                        Receive a postcard at my return address when this campaign is
                        mailed.
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <fieldset className="WideListOptions Schedule">
              <h2>Delivery Timing</h2>
              <p>
                Control when customers receive this campaign. We can mail this
                campaign immediately after printing or deliver by a future date.
              </p>

              <div className="Options">
                <div className="RadioRow">
                  <div className="label-cover">
                    <input
                        type="radio"
                        name="schedule"
                        id="scheduled-asap"
                        checked={!this.state.isScheduled}
                        onChange={this.onScheduledAsap}
                    />

                    <label className="RadioButton" htmlFor="scheduled-asap" />

                    <label htmlFor="scheduled-asap">
                      Deliver ASAP
                      <span className="Description">
                      Campaign delivered within 3-5 business days
                    </span>
                    </label>
                  </div>
                </div>

                <div className="RadioRow">
                  <div className="label-cover">
                    <input
                        type="radio"
                        name="schedule"
                        id="scheduled-date"
                        onChange={this.onScheduledDate}
                        checked={this.state.isScheduled}
                    />

                    <label className="RadioButton" htmlFor="scheduled-date" />

                    <label htmlFor="scheduled-date">
                      Deliver by Specific Date
                      <span className="Description">
                      Campaign delivered within 2 days of specific date
                    </span>
                    </label>
                  </div>
                </div>
              </div>

              <If condition={this.state.isScheduled}>
                <label className="DeliveryByLabel" htmlFor="campaign_send_date">
                  Deliver Campaign By {this.state.sendDate}
                </label>

                <input
                    placeholder="Select a Date"
                    className={cn("DateInputText", { error: !!this.state.errorsForm.sendDate })}
                    name="campaign[send_date]"
                    id="campaign_send_date"
                    value={this.state.sendDate}
                    field="sendDate"
                    onChange={this.onChange}
                />
                <Warning data={this.state.errorsForm.sendDate} />
              </If>

              <input
                  type="hidden"
                  value={this.state.isScheduled}
                  name="campaign[scheduled]"
                  field="scheduled"
                  onChange={this.onChange}
              />

              <div className="clear" />
              <div className="Calendar" />
            </fieldset>

            <div className="SubmitContainer campaign">
              <div className="InnerColumnContentWrapper">
                <div className="InnerColumnContent buttons">

                  <input type="button"
                         value="Go back"
                         onClick={this.goBack}
                         className="Button ExitButton"
                  />

                  <div>
                    <button
                        disabled={!this.state.dirty}
                        name="save_first_step"
                        value={true}
                        onClick={this.state.onSave}
                        className="Button OutlinedButton"
                    >{this.state.saveButton}</button>

                    <input
                        id="next-step"
                        type="submit"
                        name="commit"
                        value="Next Step"
                        className="Button PurpleSubmit"
                    />
                  </div>

                </div>
              </div>
            </div>
          </form>
        </div>
    );
  }
}
