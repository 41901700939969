import React from 'react';
import Image502 from '../../../../assets/images/502.svg';

const QRReportWrapperError = () => {
   return (
      <div class="Content PageContent">
         <main class="error-block">
            <div class="">
               <img alt="error_img" src={Image502} />
               <div class="error_text">
                  This page is coming soon! Stay tuned!
               </div>
               <div>
                  <form class="button_to" method="get" action="/">
                     <input class="green-button margin-top-s" type="submit" value="Go back" />
                  </form>
               </div>
            </div>
         </main>
      </div>
   );
};

export default QRReportWrapperError;