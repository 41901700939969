import React from 'react'
import PropTypes from 'prop-types'

export default class FontFamilyOptions extends React.Component {
  static propTypes = {
    defaultFontFamily: PropTypes.string,
    setFontFamily: PropTypes.func
  }

  render() {
    return (
      <select ref="fontFamily"
              key={this.props.defaultFontFamily}
              defaultValue={this.props.defaultFontFamily}
              onChange={() => { this.props.setFontFamily(this.refs.fontFamily.value) }}>

        <option value="Alegreya Sans">Alegreya Sans</option>
        <option value="Arial">Arial</option>
        <option value="Arimo">Arimo</option>
        <option value="Arvo">Arvo</option>
        <option value="Assistant">Assistant</option>
        <option value="Eczar">Eczar</option>
        <option value="Germania One">Germania One</option>
        <option value="Helvetica">Helvetica</option>
        {/* <option value="Lato">Lato</option> */}
        <option value="Permanent Marker">Permanent Marker</option>
        <option value="Philosopher">Philosopher</option>
        <option value="Montserrat">Montserrat</option>
        <option value="Neuton">Neuton</option>
        <option value="Times New Roman">Times New Roman</option>
        <option value="Ultra">Ultra</option>
        <option value="Viga">Viga</option>
        <option value="Vollkorn">Vollkorn</option>
        <option value="Work Sans">Work Sans</option>
        <option value="Yrsa">Yrsa</option>
      </select>
    )
  }
}
