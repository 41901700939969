import React, { Fragment, Component } from 'react';
import ReactCrop from 'react-image-crop';
import s3UploaderReact from "../../shared/s3UploaderReact";
import 'react-image-crop/dist/ReactCrop.css';

class ModalCrop extends Component {
    constructor(props) {
      props;
      super(props);
      this.state = {
        formVisible: true,
        fileObj: null,
      };

      this.onSave = this.onSave.bind(this)
    }
      // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
        console.log('ImageLoaded')
      };

      onCropComplete = crop => {
        this.makeClientCrop(crop);
        console.log('onCropComplete')
      };

      onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
        console.log('onCropChange')
      };

      onSave = (type) => {
        let _this = this;
        let uuid = this.props.agency.id;
        let name = `${this.props.agency.name}-${new Date().getTime()}`;
        return new Promise((resolve, reject) => {
          s3UploaderReact.upload(
            this.state.fileObj,
            name,
            uuid,
            null,
            null,
            null,
            (path, fileUrl) => {
              this.props.setPreviewUrl(fileUrl);
              this.props.setLogoUrl(fileUrl);
            }
          );
        });
      }

      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const {croppedImageUrl, file} = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.props.setPreviewUrl(croppedImageUrl);
          this.setState({ croppedImageUrl, fileObj: file });
        }
      }
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');

        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, image.width, image.height)
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              console.error('Canvas is empty');
              return;
            }
            blob.name = fileName;

            const file = new File([blob], 'image', {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });


            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve({croppedImageUrl: this.fileUrl, file});
          }, 'image/jpeg');
        });
      }

      render() {
        const { crop, croppedImageUrl, src } = this.state;
        return (
            <>
            <div className="agency-blog-modal">
            <div className="agency-blog-modal-content">
              <div className="agency-blog-modal-container">
                <div className="agency-blog-closeAgency">
                </div>
                <div className="agency-crop-container">
                    <h2 className="agency-crop-container-title">Edit Company Logo</h2>
                    <ReactCrop
                      src={this.props.src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                    <div className="agency-crop-btns">
                      <button onClick={this.props.cancel} className="agency-crop-btns-cancel">Cancel</button>
                      <button className="agency-crop-btns-save" onClick={this.onSave} disabled={!this.state.fileObj}>Save</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          </>
        );
      }
  }

  export default ModalCrop;
