import React, { Component } from "react";
import PropTypes from "prop-types";

const NumberOfValues = 2;

class CustomListMapping extends Component {
  handleDropdownChange = (event) => {
    let defaultPositions = this.props.defaultPositions;
    let i = parseInt(event.target.name.split("_")[1]);
    let currentKey = _.invert(defaultPositions)[i];
    delete defaultPositions[currentKey];

    this.removeDuplicate(event);

    defaultPositions[event.target.value] = i;
    this.props.setDefaultPositions(defaultPositions);
  };

  removeDuplicate = (event) => {
    let defaultPositions = this.props.defaultPositions;
    let oldIndex = defaultPositions[event.target.value];
    if (typeof oldIndex !== "undefined") {
      this.refs[`index_${oldIndex}`].value = `null_${oldIndex}`;
    }
  };

  partOfValues(values) {
    return values.slice(0, NumberOfValues);
  }

  columnValues = (rows, columnIndex) => {
    let values = [];

    rows.forEach((row) => {
      values.push(row[columnIndex]);
    });

    return this.partOfValues(
      values.filter((value) => value !== "" && typeof value !== "undefined")
    );
  };

  mapDropdownOptions = () => {
    let { sampleData } = this.props;

    return _.range(sampleData[0].length).map((index) => {
      let columnValues = this.columnValues(sampleData, index);

      if (columnValues.length > 0) {
        return (
          <div>
            <div key={index} className="Half KeyValue width100">
              <select
                ref={`index_${index}`}
                name={`index_${index}`}
                key={`index_${index}`}
                onChange={(e) => {
                  this.handleDropdownChange(e, index);
                }}
              >
                <option value={`null_${index}`}>Other</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="businessName">Full Name</option>
                <option value="addressLine1">Address Line 1</option>
                <option value="addressLine2">Address Line 2</option>
                <option value="city">City</option>
                <option value="state">State</option>
                <option value="postalCode">Postal Code</option>
              </select>
            </div>
            <div className="Half SampleValue">
              <ul className="recipients-sublable-item">
                {columnValues.map((column) => {
                  return <li>{column}</li>;
                })}
              </ul>
            </div>
          </div>
        );
      } else {
        return "";
      }
    });
  };

  render() {
    return (
      <fieldset>
        {/* <h2>Review and Label</h2>
        <p>
          Help us understand your mailing list. We’ll show you example values
          from each column and you tell us what to call those values.
        </p>
        <div className="Half">
          <label>Labels</label>
        </div>
        <div className="Half">
          <label>Sample Values</label>
        </div> */}
        <div className="Clear" />
        {this.mapDropdownOptions()}
      </fieldset>
    );
  }
}

CustomListMapping.propTypes = {
  sampleData: PropTypes.array.isRequired,
  defaultPositions: PropTypes.object.isRequired,
  setDefaultPositions: PropTypes.func.isRequired,
};

export default CustomListMapping;
