import React, { useState, useEffect } from "react";

import { Dropdown, Input, Modal } from "semantic-ui-react";
import Moment from "react-moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";

import SaveIcon from "images/save.svg";
import TrashIcon from "images/trash-icon.svg";
import EditIcon from "images/edit.svg";

import { getData, postData, putData, deleteData } from "lib/fetches";
function CustomFieldsPage(props) {
  const { page, token, openModalPopUp } = props;

  useEffect(() => {
    getData(`/custom_fields`, token, (res) => {
      setCustomFieldsData(res.custom_fields);
    });
  }, []);

  const [fieldTypeOptions, setFieldTypeOptions] = useState([
    { key: "text", value: "text", text: "text" },
    { key: "number", value: "number", text: "number" },
    { key: "date", value: "date", text: "date" },
  ]);
  const [customFieldsArray, setCustomFieldsArray] = useState([]);
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const [fieldId, setFieldId] = useState(1);
  const [delFieldModal, setDelFieldModal] = useState(false);
  const [delFieldAccess, setDelFieldAccess] = useState("");
  const [delFieldId, setDelFieldId] = useState(0);
  const [typeError, setTypeError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const handleCreateCustomField = (newObject, id, status) => {
    let body = newObject;
    if (!body.field_type && !body.name) {
      setTypeError(true);
      setNameError(true);
    } else if (!body.field_type) {
      setTypeError(true);
    } else if (!body.name) {
      setNameError(true);
    } else {
      if (status === "edit") {
        putData(`/custom_fields/${id}`, token, body, (res) => {
          console.log(res.custom_fields);
          if(res.custom_fields) setCustomFieldsData(res.custom_fields);      
          delCustomField(id);
        });
      } else {
        postData(`/custom_fields`, token, body, (res) => {
          if(res.custom_fields) setCustomFieldsData(res.custom_fields);
          delCustomField(id);
        });
      }
    }
  };

  const handleDeleteCustomField = (id) => {
    delFieldAccess === "DELETE" &&
      deleteData(`custom_fields/${id}`, token, (res) => {
        setCustomFieldsData(res.custom_fields);
        setDelFieldAccess("");
        setDelFieldModal(false);
        setDelFieldId(0);
      });
  };

  const handleEditCustomField = (customField) => {
    let fieldForEdit = customField;
    fieldForEdit.status = "edit";
    customFieldsArray.length < 1
      ? setCustomFieldsArray([fieldForEdit, ...customFieldsArray])
      : null;
  };

  const delCustomField = (id) => {
    setCustomFieldsArray(customFieldsArray.filter((item) => item.id !== id));
  };

  const handleDelFieldConfirm = (e) => {
    setDelFieldAccess(e.target.value);
  };

  const addCustomField = (id) => {
    if (customFieldsData.length < 20) {
      const newCustomField = {
        id,
        name: "",
        default_value: "",
        field_type: "",
      };
      setCustomFieldsArray([...customFieldsArray, newCustomField]);
      setFieldId(fieldId + 1);
      setTypeError(false);
      setNameError(false);
    } else {
      openModalPopUp(true, "fieldsLimit");
    }
  };

  const handleCustomFieldName = (data, id, type) => {
    let fieldsArray = customFieldsArray;
    let modifiedItem = customFieldsArray.find((elem) => {
      elem.id === id;
      if (type === "fieldName") {
        elem["name"] = data.target.value;
        setNameError(false);
      } else if (type === "fieldValue") {
        if (elem.field_type === "date") {
          setStartDate(data);
          elem["default_value"] = moment(data).format("MM/DD/YYYY");
        } else {
          elem["default_value"] = data.target.value;
        }
      } else {
        elem["field_type"] = data.value;
        elem["default_value"] = "";
        setTypeError(false);
      }
      return elem;
    });
    let updatedFieldsArray = fieldsArray.map((item) => {
      if (item.id === id) {
        item = modifiedItem;
      }
      return item;
    });
    setCustomFieldsArray(updatedFieldsArray);
  };

  return (
    <>
      <header className="PageHeader contacts page-settings">
        <h2 className="contacts-table-title">Custom fields</h2>
        <div className="contacts-page-add-field-cover">
          <span>Need help?</span>
          <button
            className="Button GreenSubmit brdr-8"
            onClick={() =>
              customFieldsArray.length !== 1 && addCustomField(fieldId)
            }
          >
            + Add new field
          </button>
        </div>
      </header>
      <div className="custom-form-header">
        <div className="grid grid-3">
          <div className="grid-item custom-fields-title">Select field type</div>
          <div className="grid-item custom-fields-title">Field name</div>
          <div className="grid-item custom-fields-title">Default Value</div>
        </div>
      </div>
      {customFieldsArray.length > 0 &&
        customFieldsArray.map((item, index) => {
          item.field_type === "date" && !item.default_value
            ? (item.default_value = moment(new Date()).format("MM/DD/YYYY"))
            : null;
          return (
            <div key={item.id} className="custom-form-body">
              <div className="grid grid-3">
                <div className="grid-item">
                  <div className={`contacts-modal-input-cover custom-fields`}>
                    <Dropdown
                      className={`segment-modal-input ${
                        typeError && "field-error"
                      }`}
                      placeholder="Select field type"
                      options={fieldTypeOptions}
                      selection
                      value={item.field_type}
                      onChange={(event, data) => {
                        handleCustomFieldName(data, item.id, "fieldType");
                      }}
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <div className={`contacts-modal-input-cover custom-fields `}>
                    <input
                      className={`segment-modal-input ${
                        nameError && "field-error"
                      }`}
                      placeholder="Field name"
                      value={item.name}
                      onChange={(e) => {
                        handleCustomFieldName(e, item.id, "fieldName");
                      }}
                      type="text"
                    />
                  </div>
                </div>
                <div className="grid-item">
                  {item.field_type === "date" ? (
                    <DatePicker
                      className="custom-datepicker create"
                      closeOnScroll={true}
                      dateFormat="MM/dd/yyyy"
                      selected={startDate}
                      onChange={(date) =>
                        handleCustomFieldName(date, item.id, "fieldValue")
                      }
                    />
                  ) : (
                    <div className="contacts-modal-input-cover custom-fields">
                      <input
                        className="segment-modal-input"
                        placeholder="Default value"
                        type={item.field_type}
                        value={item.value}
                        onChange={(e) => {
                          handleCustomFieldName(e, item.id, "fieldValue");
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="custom-fields-icons create">
                  <img
                    src={SaveIcon}
                    alt="save icon"
                    onClick={() => {
                      item.status === "edit"
                        ? handleCreateCustomField(item, item.id, "edit")
                        : handleCreateCustomField(item, item.id, "new");
                    }}
                  />
                  <img
                    src={TrashIcon}
                    alt="trash icon"
                    onClick={() => {
                      delCustomField(item.id);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      {customFieldsData.length > 0 &&
        customFieldsData
          .sort((a, b) => b.id - a.id)
          .map((item, index) => {
            const value = item.value || item.default_value;
            return (
              <>
                <div key={item.id} className="custom-form-header existed">
                  <div className="grid grid-3">
                    <div className="grid-item custom-fields-text">
                      {item.field_type}
                    </div>
                    <div className="grid-item custom-fields-text">
                      {item.name}
                    </div>
                    <div className="grid-item custom-fields-text">
                      {item.field_type === "date" && value ? (
                        <Moment format="DD/MM/YYYY" withTitle>
                          {value}
                        </Moment>
                      ) : (
                        value
                      )}
                    </div>
                    <div className="custom-fields-icons">
                      <img
                        src={EditIcon}
                        alt="save icon"
                        onClick={() => handleEditCustomField(item)}
                      />
                      <img
                        src={TrashIcon}
                        alt="trash icon"
                        onClick={() => {
                          setDelFieldModal(true);
                          setDelFieldId(item.id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      <Modal
        open={delFieldModal}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        className="contacts-segment-modal"
        onClose={() => setDelFieldModal(false)}
      >
        <div className="contacts-segment-container small">
          <div className="contacts-segment-body">
            <>
              <h3 className="segment-modal-small-title">Delete this field</h3>
              <h4 className="segment-modal-small-subtitle">
                We want to make sure you actually want to delete this custom
                field. Once you delete it, it can't be undone. If it's
                referenced in a design, it will no longer merge. Write DELETE
                (in all caps) in the textbox below to confirm.
              </h4>
              <input
                className="segment-modal-input"
                placeholder="DELETE"
                type="text"
                value={delFieldAccess}
                onChange={(e) => {
                  handleDelFieldConfirm(e);
                }}
              />
            </>
          </div>
          <div className="segment-modal-footer">
            <div className="grid grid-2 segment-modal-buttons">
              <div className="grid-item">
                <button
                  className="gray-button"
                  onClick={() => setDelFieldModal(false)}
                >
                  Cancel
                </button>
              </div>
              <div className="grid-item">
                <button
                  className="blue-button"
                  onClick={() => handleDeleteCustomField(delFieldId)}
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CustomFieldsPage;
