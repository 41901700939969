import React from "react";
import { getData } from "../../lib/fetches";
import { connect } from "react-redux";

import EditImage from "../../../assets/images/edit-icon.svg"
import { Icon, Pagination } from "semantic-ui-react";

class CampaignsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      campaignFilter: null,
      activeTab: "Draft",
      page: 1,
      pages: 1
    }
    this.campaigns = this.campaigns.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  postcardSize = (postcard_size) => {
    switch (postcard_size) {
      case "4x6":
        return "Small 4″ × 6″ Postcard";
      case "6x9":
        return "Medium 6″ × 9″ Postcard";
      case "6x11":
        return "Large 6″ × 11″ Postcard";
      default:
        return "";
    }
  };
  setCampaign = (campaign, campaigns) => {
    this.props.dispatch({
      type: "SetCampaign",
      allSelected: false,
      campaign: campaign,
      campaigns: campaigns,
    });
  };
  selectAllCampaign = (campaigns, allSelected) => {
    this.props.dispatch({
      type: "SelectAllCampaign",
      allSelected: allSelected,
      campaign: null,
      campaigns: campaigns,
    });
  };

  componentDidMount(){
    this.setActiveTab('Draft')
  }

   setActiveTab = (val) => {
    getData(`/campaigns/pagination/pages_count/?current_tab=${val}`, this.props.authToken, (res) => {
      this.setState({
        pages: res.pages
      })
    })
    getData(`campaigns/?active_tab=${val}&page=1`, this.props.authToken, (res) => {
      this.props.dispatch({
        type: "ChangeCampaigns",
        campaigns: res,
      });
    });
    this.setState({
      activeTab: val,
      page: 1
    })
  };

  onPageChanged = (e, data) => {
    getData(`campaigns/?active_tab=${this.state.activeTab}&page=${data.activePage}`, this.props.authToken, (res) => {
      this.props.dispatch({
        type: "ChangeCampaigns",
        campaigns: res,
      });
      this.setState({
        page: data.activePage
      })
    });
  }

  campaigns(campaigns) {
    return campaigns.map((campaign, idx) => {
      let Front = campaign.imgix_front_thumbnail;
      let Back = campaign.imgix_back_thumbnail;

      if (typeof Front === "string") {
        Front =
          Front.indexOf("svg") !== -1 ? (
            <object
              data={campaign.imgix_front_thumbnail}
              width={162}
              type="image/svg+xml"
            ></object>
          ) : (
            <img src={campaign.imgix_front_thumbnail} />
          );
      } else {
        null;
      }

      if (typeof Back === "string") {
        Back =
          Back.indexOf("svg") !== -1 ? (
            <object
              data={campaign.imgix_back_thumbnail}
              width={162}
              type="image/svg+xml"
            ></object>
          ) : (
            <img src={campaign.imgix_back_thumbnail} />
          );
      } else {
        null;
      }
      return (
        <div
          className={` ${
            campaign.selected ? "Selected CampaignRow" : "CampaignRow"
          }  campaign-flex `}
          key={"campaign_tr_" + campaign.id}
          onClick={() => this.setCampaign(campaign, this.props.campaigns)}
        >
          <div className="campaign-flex-block">
            <div className="ToggleContainer">
              <input
                type="checkbox"
                onChange={() =>
                  this.setCampaign(campaign, this.props.campaigns)
                }
                checked={campaign.selected}
                name={"campaign_" + campaign.id}
                id={"campaign_" + campaign.id}
                value="1"
              />
              <label
                className="CheckboxButton"
                htmlFor={"campaign_" + campaign.id}
              ></label>
            </div>
            {campaign.postcard_size === "8.5x11" ? (
              <div className="LetterContainer">
                <div
                  className={
                    campaign.postcard_size
                      ? "ThumbLetter" + " FrontThumbnail"
                      : "FrontThumbnail"
                  }
                >
                  {campaign.imgix_front_thumbnail && Front}
                </div>
              </div>
            ) : (
              <>
                <div
                  className={
                    campaign.postcard_size
                      ? "Thumb" + campaign.postcard_size + " FrontThumbnail"
                      : "FrontThumbnail"
                  }
                >
                  {campaign.imgix_front_thumbnail && Front}
                </div>
                <div
                  className={
                    campaign.postcard_size
                      ? "Thumb" + campaign.postcard_size + " BackThumbnail"
                      : "BackThumbnail"
                  }
                >
                  {campaign.imgix_back_thumbnail && Back}
                </div>
              </>
            )}
          </div>

          <div className="Metadata">
            <span className="LinkedName">
              <a>{campaign.name}</a>
            </span>
            {/* <span className="Reach">
              Reaching{" "}
              {campaign.show_recipients ? campaign.recipients_count : 0}{" "}
              Recipients
            </span>            */}
          </div>
          <div className="description">
            <span className="ProductType">
              {this.postcardSize(campaign.postcard_size)}
            </span>
          </div>
          <div className="lastedit">
          <span className="CurrentState">
              {campaign.current_state} &nbsp;&bull;&nbsp; Last edited{" "}
              {campaign.last_update}
            </span>
          </div>
          <div className="Actions">
            {campaign.with_prospecting && (
                <div className="ProspectingRectangle">
                  <span className="ProspectingText">Prospecting</span>
                </div>
            )}

          {campaign.with_qr_code && (
              <a href={`/campaigns/${campaign.guid}/qr_code_data`} className="ReportLink">View Report</a>
            )
          }

            {campaign.current_state != "Paid" &&
            campaign.current_state != "Error" &&
            campaign.current_state != "In Production" &&
            // campaign.current_state != "In Transit" &&
            campaign.current_state != "Sent" &&
            campaign.current_state != "Out for Delivery" && (
                <a
                    className="EditLink"
                    onClick={(event) => event.stopPropagation()}
                    href={campaign.edit_campaign_path}
                >
                  <img src={EditImage} alt="edit-image"/>
                </a>
            )}
          </div>
        </div>
      );
    })
  }

  render() {
    const selected = this.props.campaigns.filter((c) => c.selected);
    let checkEmpty = false;
    for (let i in this.props.campaigns) {
        if (this.props.campaigns.hasOwnProperty(i)) {
          checkEmpty = true;
        }
    }
    return (
      <div className="CampaignsTable">
        <div className="CampaignsTable_tabs">
          <button className={["CampaignsTable-tab", `${this.state.activeTab === "Draft" ? "CampaignsTable-tab-active" : ''}`].join(' ')} value="Draft" onClick={() =>  this.setActiveTab('Draft')}>Draft</button>
          <button className={["CampaignsTable-tab", `${this.state.activeTab === "Sent" ? "CampaignsTable-tab-active" : ''}`].join(' ')} value="Sent" onClick={() =>  this.setActiveTab('Sent')}>Sent</button>
          {/*<button className={["CampaignsTable-tab", `${this.state.activeTab === "In Transit" ? "CampaignsTable-tab-active" : ''}`].join(' ')} value="In Transit" onClick={() =>  this.setActiveTab('In Transit')}>In Transit</button>*/}
          {/*<button className={["CampaignsTable-tab", `${this.state.activeTab === "Delivered" ? "CampaignsTable-tab-active" : ''}`].join(' ')} value="Delivered" onClick={() =>  this.setActiveTab('Delivered')}>Delivered</button>*/}
        </div>
        {checkEmpty ? (
            <div
            className="CampaignsTableHeader"
            onClick={() =>
              this.selectAllCampaign(this.props.campaigns, this.props.allSelected)
            }
            key="campaign_tr_th"
          >
            <div className="table-header-wrapper">
              <div className="ToggleContainer">
                <input
                  type="checkbox"
                  checked={this.props.allSelected}
                  name="SelectAll"
                  id="SelectAll"
                  value="1"
                />
                <label className="CheckboxButton"></label>
                <span className="SelectAllLabel">Select All</span>
              </div>
              <div className="table-header-heading">
                <span>
                  Event Title
                </span>
              </div>
              <div className="table-header-heading">
                <span>
                  Description
                </span>
              </div>
              <div className="table-header-heading">
                <span>
                  Last Edit
                </span>
              </div>
              <div className="table-header-heading">
                <span>
                  Actions
                </span>
              </div>
            </div>
            <div className="Clear"></div>
          </div>
        ) : (
          <h1 className="EmptyCampaigns">No campaigns yet</h1>
        )}
        {this.campaigns(this.props.campaigns)}
        {
          !!this.props.campaigns.length &&
            <Pagination
                className="default-pagination default-pagination__m-t"
                activePage={ this.state.page }
                defaultActivePage={1}
                siblingRange={1}
                totalPages={ +this.state.pages }
                firstItem={null}
                lastItem={null}
                prevItem={{ content: <Icon name='angle orange-icon left' />, icon: true }}
                nextItem={{ content: <Icon name='angle orange-icon right' />, icon: true }}
                onPageChange={this.onPageChanged}
            />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(CampaignsTable);