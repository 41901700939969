import React, { Component } from 'react';
import { putData, getData } from "../../lib/fetches";
import ProspectingPrice from "./prospectings/ProspectingPrice";
import ProspectingTabs from "./prospectings/ProspectingTabs";

class ProspectingsSettings extends Component {
    constructor(props) {
        props;
        super(props);
        this.propsObj = this.props.prospecting.request_body
        this.state = {
           price: this.props.prospecting.price || 0,
           records: this.props.prospecting.records_number || 0,
           IndividualsDemographic: this.propsObj.individuals_demographic || {},
           BusinessesDemographic: this.propsObj.business_demographic || {},
           targetValue: this.propsObj.target_value || "individuals",
           radiusMiles: this.propsObj.radius_value || 0,
          //  individualsSubValue: this.propsObj.individuals_subvalue || "",
           recipiensNumber: this.propsObj.amount || 0,
           locationValue: this.propsObj.location_value || 'usa',
           showRecordsInput: false,
           showRadiusInput: false,
           viewport: this.propsObj.viewport ?
           {
              width: '100%',
              height: 420,
              latitude: this.propsObj.viewport.latitude,
              longitude: this.propsObj.viewport.longitude,
              transitionInterpolator: this.propsObj.viewport.transitionInterpolator,
              zoom: this.propsObj.viewport.zoom,
              pitch: 0,
              bearing: 0
           } :
           {
            width: '100%',
            height: 420,
            latitude: 37.090240,
            longitude: -95.712891,
            zoom: 3,
            pitch: 0,
            bearing: 0
          },
          clearGeocoder: false
        }        
    }

    sendRequest = () => {
      this.setState({
        loading: true
      });

      putData(`/prospectings/${this.props.prospecting.id}`, this.props.authToken,
      { prospecting: {
          amount: this.state.recipiensNumber || "0",
          target_value: this.state.targetValue,
          // individuals_subvalue: this.state.individualsSubValue,
          location_value: this.state.locationValue,
          radius_value: this.state.radiusMiles.toString(),
          individuals_demographic : this.state.IndividualsDemographic,
          business_demographic: this.state.BusinessesDemographic,
          viewport: this.state.viewport,
        }
      }, (res) => {
        this.setState({
          price: res.price,
          records: res.count,
          showRecordsInput: res.with_records,
          showRadiusInput: res.with_radius,
          loading: false
        });
      })
    }

    resetPrice = () => {
      this.setState({
        price: 0
      })
    }

    handleNext = (e) => {
      e.preventDefault();
      getData(`/prospectings/${this.props.prospecting.id}/submit_prospecting`, this.props.authToken, (res) => {
        window.location.href = res.path
      });
    };

    updateLocationValue = (locationValue) => {
      this.setState({
        locationValue: locationValue,
        clearGeocoder: false
      }, () => this.sendRequest());
    }

    handleViewportChange = (viewport) => {
      this.setState({
        viewport: viewport
      })
    }

    updateRadiusValue = (radiusValue) => {
      this.setState({
        radiusMiles: radiusValue,
      }, () => this.sendRequest());
    }

    updatePricingAmount = (recipiensNumber) => {
      this.setState({
        recipiensNumber: recipiensNumber
      }, () => this.sendRequest());
    }

    targetChoice = (targetValue) => {
      this.setState({
        targetValue: targetValue
      }, () => this.sendRequest());
    }

    // individualsSubChoice = (value) => {
    //   this.setState({
    //     individualsSubValue: value
    //   }, () => this.sendRequest());
    // }

    addDemographyFilter = (individual, business) => {
      this.setState({
        IndividualsDemographic: individual,
        BusinessesDemographic: business
      }, () => this.sendRequest())
    }

    deleteSingleItem = (name) => {
      let individualsObj = this.state.IndividualsDemographic;
      let businessObj = this.state.BusinessesDemographic;

      if (typeof(individualsObj[name]) !== 'undefined') {
        individualsObj[name] = {};
        this.setState({
          IndividualsDemographic: individualsObj
        }, () => this.sendRequest())
      } else {
        businessObj[name] = {};
        this.setState({
          BusinessesDemographic: businessObj
        }, () => this.sendRequest())
      }
    }

    deleteAllItems = () => {
      this.setState({
        IndividualsDemographic: {},
        BusinessesDemographic: {},
        locationValue: "usa",
        radiusMiles: 0,
        recipiensNumber: 0,
        viewport:  {
          width: '100%',
          height: 420,
          latitude: 37.090240,
          longitude: -95.712891,
          zoom: 3,
          pitch: 0,
          bearing: 0
        },
        clearGeocoder: true
      }, () => this.sendRequest())
    }

    deleteIndividualItems = () => {
      this.setState({
        IndividualsDemographic: {}
      }, () => this.sendRequest())
    }

    deleteBusinessesItems = () => {
        this.setState({
          BusinessesDemographic: {}
        }, () => this.sendRequest())
    }

    clearLocationValue = () => {
      this.setState({
        locationValue: "usa",
        radiusMiles: 0,
        recipiensNumber: 0,
        viewport:  {
          width: '100%',
          height: 405,
          latitude: 37.090240,
          longitude: -95.712891,
          zoom: 3,
          pitch: 0,
          bearing: 0
        },
        clearGeocoder: true
      }, () => this.sendRequest())
    }


    render() {
        return (
            <div className="prospecting_container">
                <h1>Create Prospecting</h1>
                <p className="prospecting-note">Please note, you need to <strong>complete all tabs</strong> before moving to the next step.</p>
                <div className="prospecting-content">
                <ProspectingTabs
                    targetChoice={this.targetChoice}
                    addDemographyFilter={this.addDemographyFilter}
                    loading={this.state.loading}
                    locationValue={this.state.locationValue}
                    updateLocationValue={this.updateLocationValue}
                    updateRadiusValue={this.updateRadiusValue}
                    updateRecordsAmount={this.updatePricingAmount}
                    recipiensNumber={this.state.recipiensNumber}
                    targetValue={this.state.targetValue}
                    // individualsSubValue={this.state.individualsSubValue}
                    individualsSubChoice={this.individualsSubChoice}
                    IndividualsDemographic={this.state.IndividualsDemographic}
                    BusinessesDemographic={this.state.BusinessesDemographic}
                    deleteIndividualItems={this.deleteIndividualItems}
                    deleteBusinessesItems={this.deleteBusinessesItems}
                    handleViewportChange={this.handleViewportChange}
                    viewport={this.state.viewport}
                    clearGeocoder={this.state.clearGeocoder}
                    showRecordsInput={this.state.showRecordsInput}
                    showRadiusInput={this.state.showRadiusInput}
                />
                <div className="prospecting-content-right">
                    <ProspectingPrice
                        price={this.state.price}
                        targetValue={this.state.targetValue}
                        records={this.state.records}
                        resetPrice={this.resetPrice}
                        stateInfo={this.state}
                        deleteSingleItem={this.deleteSingleItem}
                        deleteAllItems= {this.deleteAllItems}
                        clearLocationValue={this.clearLocationValue}
                    />
                </div>
            </div>
                <div className="InnerColumnContent">
                    <form action={`/campaigns/${this.props.campaign.guid}/skip_prospecting`} className="SubmitContainer" method="post">
                        <div className="InnerColumnContentWrapper">
                            <div className="InnerColumnContent buttons">
                                <input type="hidden" name="with_prospecting" value={false}/>
                                {/* TODO: check the possinility of redirec with BE*/}
                                <button
                                    className="Button ExitButton"
                                    type="submit"
                                >
                                    Cancel
                                </button>
                                <button className="Button PurpleSubmit"
                                        disabled={!this.state.records > 0}
                                        onClick={(e) => this.handleNext(e)}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ProspectingsSettings;
