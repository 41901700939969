import React from "react";
import NewSegmentModal from "./NewSegmentModal";
import ContactsSidebar from "./ContactsSidebar";
import TablePagination from "./TablePagination";
import ContactInformation from "./ContactInformation";
import ContactModal from "./ContactModal";
import DeleteModalPopUp from "./DeleteModalPopUp";
import ContactsTables from "./ContactsTables";
import ContactsHeader from "./ContactsHeader";
import ContactsSettings from "./ContactsSettings";
import IntegrationModals from "./components/IntegrationModals";

import { setCotactFilterOptions } from "../../lib/helpers";
import { getData, postData, putData, deleteData } from "../../lib/fetches";
import _ from "lodash";

import ArrowLeftGreen from "../../../assets/images/arrow-left-green.svg";
import EditIcon from "../../../assets/images/edit.svg";
import TrashIcon from "../../../assets/images/trash-icon.svg";

import { Button, Dropdown, Input, Modal } from "semantic-ui-react";
import ContactSycnConfirmation from "./ContactSycnConfirmation";
class Contacts extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      tableList: this.updatedArray(this.props.user_lists),
      tableRecipients: [],
      customDates: [],
      filterOptions: setCotactFilterOptions(),
      allContactsId: [],
      allRecipientsId: [],
      allLists: [],
      pages: this.props.pages_amount,
      contactInfo: {},
      allContacts: false,
      segmentModalOpen: false,
      deleteButton: false,
      deleteListModal: false,
      contactModal: false,
      contactsPage: true,
      contactView: false,
      sidebarShow: true,
      integrationModal: false,
      integrationUpload: false,
      firstPage: false,
      contactStep: 0,
      newListModal: 0,
      typingTimeout: 0,
      contactsSettings: "",
      deleteAccess: "",
      openedListId: "",
      listType: "",
      listTableName: "",
      filterKey: "",
      filterValue: "",
      searchKey: "",
      sortValue: "",
      orderValue: "",
      customSortValue: "",
      contactsSearchParams:
        "business_name_or_city_or_state_or_postal_code_cont",
    };
  }

  componentDidMount() {
    let urlLocation = window.location.href;
    if (
      urlLocation.indexOf("#from_etsy") !== -1 &&
      this.props.user.etsy_linked
    ) {
      this.setState({
        integrationUpload: true,
        integrationModal: true,
      });
      getData(
        `/contacts/fetch_etsy_addresses`,
        this.props.authToken,
        (res) => {
          this.getUserStatus();
          if (res.list) this.setState({ openedListId: res.list.id })
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterKey !== prevState.filterKey) {
      this.setState({ searchKey: "" });
    }
  }

  getUserStatus = () => {
    getData(
      `contacts/user_status?provider=etsy`,
      this.props.authToken,
      (res) => {
        if (res.status === "syncing") {
          this.getUserStatus();
          console.log(" get user status");
        } else if (res.status === "error") {
          console.log("error");
        } else {
          setTimeout(() => {
            console.log("finish");
            this.setState({ integrationUpload: false });
            this.contactsRequest();
          }, 5000);
        }
      }
    );
  };

  openNewSegment = (type, num) => {
    this.setState({ segmentModalOpen: type, newListModal: num });
  };

  openContactModal = (type, num) => {
    this.setState({ contactModal: type, contactStep: num });
    this.getAllContacts("select");
  };

  openContactsSettings = (page) => {
    page === ""
      ? this.setState({
        contactsSettings: page,
        allLists: [],
        sidebarShow: true,
      })
      : this.setState({ contactsSettings: page, sidebarShow: false });
    this.getAllContacts("import");
  };

  backToContacts = () => {
    this.contactsRequest();
    this.setState({
      contactsPage: true,
      customDates: [],
      allContactsId: [],
      allContacts: false,
      listType: "",
      listTableName: "",
      openedListId: "",
      contactsSettings: "",
      contactView: false,
    });
  };

  closeIntegrationModal = () => {
    this.setState({ integrationModal: false });
    window.location.hash = "";
  };

  updatedArray = (array) => {
    let newArray = array.map((item) => {
      item.selected = false;
      return item;
    });

    return newArray;
  };

  contactsRequest = () => {
    getData("contacts/", this.props.authToken, (res) => {
      if (window.location.href.indexOf("#from_etsy") !== -1) {
        let etsyList = res.lists.find(list => list.provider == 'etsy')
        if (etsyList !== null) {
          this.setState({
            openedListId: etsyList.id,
          });
        }
      }
      this.setState({
        tableList: this.updatedArray(res.lists),
        pages: this.state.openedListId ? 0 : res.pages_amount,
        integrationUpload: false,
      });
    });
  };

  modifiedArrayForAllLists = (listsArray) => {
    let listsOptions = listsArray.map((item) => {
      let modifiedItem = {
        key: item.name,
        value: item.id,
        text: item.name,
      };
      return modifiedItem;
    });
    return listsOptions;
  };

  getAllContacts = (type) => {
    getData("contacts/", this.props.authToken, (res) => {
      type === "select"
        ? this.setState({
          allLists: [...this.modifiedArrayForAllLists(res.lists)],
        })
        : this.setState({
          allLists: [
            {
              key: "Create new list",
              value: "new list",
              text: "Create new list",
            },
            ...this.modifiedArrayForAllLists(res.lists),
          ],
        });
    });
  };

  selectContact = (id, type) => {
    type === "recipients"
      ? this.setState({
        tableRecipients: this.state.tableRecipients.map((item) => {
          let contactIdArray = this.state.allRecipientsId;
          if (item.customer_id === id) {
            item.selected = !item.selected;
            item.selected === true
              ? contactIdArray.push(item.customer_id)
              : contactIdArray.splice(
                contactIdArray.indexOf(item.customer_id),
                1
              );
          }
          return item;
        }),
        deleteButton: true,
      })
      : this.setState({
        tableList: this.state.tableList.map((item) => {
          let contactIdArray = this.state.allContactsId;
          if (item.id === id) {
            item.selected = !item.selected;
            item.selected === true
              ? contactIdArray.push(item.id)
              : contactIdArray.splice(contactIdArray.indexOf(item.id), 1);
          }
          return item;
        }),
        deleteButton: true,
      });
  };

  selecteAllContacts = (type) => {
    type === "recipients"
      ? this.setState({
        allContacts: !this.state.allContacts,
        tableRecipients: this.state.tableRecipients.map((item) => {
          let contactIdArray = this.state.allRecipientsId;
          if (this.state.allContacts) {
            item.selected = false;
            contactIdArray.splice(
              contactIdArray.indexOf(item.customer_id),
              1
            );
          } else {
            item.selected = true;
            let selectedItem = contactIdArray.includes(item.customer_id);
            if (!selectedItem) {
              contactIdArray.push(item.customer_id);
            }
          }
          return item;
        }),
        deleteButton: true,
      })
      : this.setState({
        allContacts: !this.state.allContacts,
        tableList: this.state.tableList.map((item) => {
          let contactIdArray = this.state.allContactsId;
          if (this.state.allContacts) {
            item.selected = false;
            contactIdArray.splice(contactIdArray.indexOf(item.id), 1);
          } else {
            item.selected = true;
            let selectedItem = contactIdArray.includes(item.id);
            if (!selectedItem) {
              contactIdArray.push(item.id);
            }
          }
          return item;
        }),
        deleteButton: true,
      });
  };

  handleDeleteAllRequest = (e) => {
    this.setState({ deleteAccess: e.target.value });
  };

  deleteFetch = (idsArray, confirm, url, updateList) => {
    if (!idsArray.length) return;
    if (idsArray.length > 1 && confirm !== "DELETE") return;
    deleteData(url, this.props.authToken, (res) => updateList(res));
    if (idsArray.length > 1) {
      this.setState({ allContacts: false });
    }
  };

  destroyContactsFromList = (idsArray, confirm) => {
    this.deleteFetch(
      idsArray,
      confirm,
      `contacts/${this.state.openedListId}/delete_contacts_from_list/${idsArray}`,
      (res) => {
        this.setState({
          deleteListModal: false,
          tableRecipients: this.updatedArray(res.contacts),
          allContacts: false,
          allRecipientsId: [],
          contactView: false,
          deleteAccess: "",
        });
      }
    );
  };

  destroyContacts = (idsArray, confirm) => {
    this.deleteFetch(
      idsArray,
      confirm,
      `contacts/delete_contacts/${idsArray}`,
      (res) => {
        this.setState({
          deleteListModal: false,
          tableRecipients: this.updatedArray(res.contacts),
          allContacts: false,
          allRecipientsId: [],
          contactView: false,
          deleteAccess: "",
        });
      }
    );
  };

  destroyLists = (idsArray, confirm) => {
    this.deleteFetch(idsArray, confirm, `contacts/${idsArray}`, (res) => {
      this.setState({
        deleteListModal: false,
        tableList: this.updatedArray(res),
        allContacts: false,
        allContactsId: [],
        deleteAccess: "",
      });
    });
  };

  filterLists = (filterKey) => {
    let url = "";
    const searchKey =
      filterKey !== this.state.filterKey ? "" : this.state.searchKey;
    if (filterKey === "all") {
      url = `contacts/all_contacts`;
    } else if (filterKey === "allListsSegments") {
      url = `contacts?q[name_cont]=${encodeURIComponent(searchKey)}`;
    } else {
      url = `contacts?q[list_type_eq]=${encodeURIComponent(
        filterKey
      )}&q[name_cont]=${encodeURIComponent(searchKey)}`;
    }
    getData(url, this.props.authToken, (res) => {
      res.contacts !== undefined &&
        this.setState({
          contactsPage: false,
          tableList: [],
          tableRecipients: this.updatedArray(res.contacts),
          customDates: [...res.custom_dates],
          filterKey: filterKey,
          allContacts: false,
          allContactsId: [],
          allRecipientsId: [],
          openedListId: 0,
          listType: "",
          listTableName: "Contacts",
          pages: res.pages_amount,
        });
      res.lists !== undefined &&
        this.setState({
          contactsPage: true,
          tableRecipients: [],
          customDates: [],
          tableList: this.updatedArray(res.lists),
          filterKey: filterKey,
          allContacts: false,
          allContactsId: [],
          allRecipientsId: [],
          listTableName: "",
          openedListId: 0,
          listType: "",
          pages: res.pages_amount,
        });
    });
  };

  handleSearchList = (e, data) => {
    if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    this.setState({
      searchKey: data.value,
      typingTimeout: setTimeout(() => {
        this.searchLists(data.value);
      }, 700),
    });
  };

  handleSearchContacts = (e, data) => {
    if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    this.setState({
      searchKey: data.value,
      typingTimeout: setTimeout(() => {
        this.searchContacts(
          this.state.listTableName === "" ? undefined : this.state.openedListId,
          data.value
        );
      }, 700),
    });
  };

  searchLists = (searchKey) => {
    let url =
      this.state.filterKey === "allListsSegments"
        ? `contacts?q[name_cont]=${encodeURIComponent(searchKey)}`
        : `contacts?q[name_cont]=${encodeURIComponent(
          searchKey
        )}&q[list_type_eq]=${encodeURIComponent(this.state.filterKey)}`;
    getData(url, this.props.authToken, (res) => {
      this.setState({
        tableList: this.updatedArray(res.lists),
        searchKey: searchKey,
        allContacts: false,
        allContactsId: [],
        pages: res.pages_amount,
      });
    });
  };

  searchContacts = (id, searchKey) => {
    let url = `contacts/all_contacts?q[${this.state.contactsSearchParams
      }]=${encodeURIComponent(searchKey)}`;
    if (id) {
      url = `contacts/${id}/contacts_in_list?q[${this.state.contactsSearchParams
        }]=${encodeURIComponent(searchKey)}`;
    }
    getData(url, this.props.authToken, (res) => {
      this.setState({
        tableRecipients: this.updatedArray(res.contacts),
        customDates: [...res.custom_dates],
        searchKey: searchKey,
        allContacts: false,
        allRecipientsId: [],
        pages: res.pages_amount,
      });
    });
  };

  openListRequest = (id) => {
    this.setState({ pages: 0 });
    getData(`/contacts/${id}/show_list`, this.props.authToken, (res) => {
      this.setState({
        tableRecipients: this.updatedArray(res.contacts),
        customDates: [...res.custom_dates],
        listTableName: res.list.name,
        listType: res.list.list_type,
        allContactsId: [],
        allContacts: false,
        openedListId: id,
        pages: res.pages_amount,
        deleteButton: false,
        contactsPage: false,
        filterKey: "",
        contactView: false,
      });
    });
  };

  updateContact = (id, newObject) => {
    const body = newObject;
    putData(
      `/contacts/update_contact/${id}`,
      this.props.authToken,
      body,
      (res) => {
        if (res.contact) {
          this.setState({ contactInfo: res.contact });
        }
      }
    );
  };

  addCustomFieldToList = (listId, fieldId) => {
    let body = fieldId;
    putData(
      `contacts/${listId}/add_custom_field`,
      this.props.authToken,
      body,
      (res) => {
        this.setState({
          tableRecipients: this.updatedArray(res.contacts),
          customDates: [...res.custom_dates],
        });
      }
    );
  };

  contactsInformation = (contactId) => {
    getData(
      `/contacts/contacts_information/${contactId}`,
      this.props.authToken,
      (res) => {
        this.setState({ contactView: true, contactInfo: res.contact });
      }
    );
  };

  changeListName = (name, id) => {
    let body = { name };
    putData(`/contacts/${id}`, this.props.authToken, body, (res) => {
      this.setState({ listTableName: res.list.name });
    });
  };

  addContact = (id, newObject) => {
    const body = newObject;
    postData(
      `/contacts/${id}/add_contact`,
      this.props.authToken,
      body,
      (res) => {
        if (res.list) {
          this.setState({
            tableRecipients: this.updatedArray(res.list.addresses),
          });
          this.state.contactsPage
            ? this.contactsRequest()
            : this.openListRequest(id);
        } else if (res.redirect_url) {
          window.location.href = res.redirect_url
        }
      }
    );
  };

  contactsRequestSort = (sortValue, sort) => {
    this.setState({ orderValue: sort ? "ASC" : "DESC", sortValue });
    let orderValue = sort ? "ASC" : "DESC";
    getData(
      `contacts/?sort_value=${sortValue}&order_value=${orderValue}&q[list_type_eq]=${encodeURIComponent(
        this.state.filterKey
      )}&q[name_cont]=${encodeURIComponent(this.state.searchKey)}`,
      this.props.authToken,
      (res) => {
        this.setState({
          tableList: this.updatedArray(res.lists),
          pages: res.pages_amount,
          firstPage: true,
        });
      }
    );
  };

  contactsInListSort = (id, sortValue, sort, customSortValue) => {
    this.setState({
      orderValue: sort ? "ASC" : "DESC",
      sortValue,
      customSortValue,
    });
    let orderValue = sort ? "ASC" : "DESC";
    let url = customSortValue
      ? `contacts/${id}/contacts_in_list?custom_order_value=${true}&order_value=${orderValue}&custom_field_name=${customSortValue}`
      : `contacts/${id}/contacts_in_list?sort_value=${sortValue}&order_value=${orderValue}`;
    let urlAllContacts = customSortValue
      ? `contacts/all_contacts?custom_order_value=${true}&order_value=${orderValue}&custom_field_name=${customSortValue}`
      : `contacts/all_contacts?sort_value=${sortValue}&order_value=${orderValue}`;
    getData(id ? url : urlAllContacts, this.props.authToken, (res) => {
      this.setState({
        tableRecipients: this.updatedArray(res.contacts),
        firstPage: true,
      });
    });
  };

  handleListsRequest = (page, searchKey) => {
    let url = "";
    let sortValue = this.state.sortValue;
    let orderValue = this.state.orderValue;
    let customSortValue = this.state.customSortValue;
    if (this.state.filterKey === "all") {
      url = customSortValue
        ? `contacts/all_contacts?custom_order_value=${true}&order_value=${orderValue}&custom_field_name=${customSortValue}&page=${page}&q[${this.state.contactsSearchParams
        }]=${encodeURIComponent(searchKey)}`
        : `contacts/all_contacts?sort_value=${sortValue}&order_value=${orderValue}&page=${page}&q[${this.state.contactsSearchParams
        }]=${encodeURIComponent(searchKey)}`;
    } else if (this.state.filterKey === "allListsSegments") {
      url = `/contacts?sort_value=${sortValue}&order_value=${orderValue}&page=${page}&q[name_cont]=${encodeURIComponent(
        searchKey
      )}`;
    } else if (this.state.openedListId) {
      let id = this.state.openedListId;
      url = customSortValue
        ? `contacts/${id}/contacts_in_list?custom_order_value=${true}&order_value=${orderValue}&custom_field_name=${customSortValue}&page=${page}&q[${this.state.contactsSearchParams
        }]=${encodeURIComponent(searchKey)}`
        : `contacts/${id}/contacts_in_list?sort_value=${sortValue}&order_value=${orderValue}&page=${page}&q[${this.state.contactsSearchParams
        }]=${encodeURIComponent(searchKey)}`;
    } else {
      url = `/contacts?sort_value=${sortValue}&order_value=${orderValue}&page=${page}&q[list_type_eq]=${encodeURIComponent(
        this.state.filterKey
      )}&q[name_cont]=${encodeURIComponent(searchKey)}`;
    }

    getData(url, this.props.authToken, (res) => {
      res.contacts !== undefined &&
        this.setState({
          tableRecipients: this.updatedArray(res.contacts),
          firstPage: false,
        });
      res.lists !== undefined &&
        this.setState({
          tableList: this.updatedArray(res.lists),
          firstPage: false,
        });
    });
  };

  setFilterValue = (data) => {
    this.setState({ filterValue: data.value });
  };

  render() {
    return (
      <div className={`Content PageContent Campaign`}>
        <main
          className={` ${this.state.contactsSettings === "integration"
            ? "contacts-settings gray"
            : this.state.contactsSettings === "import" ||
              this.state.contactsSettings === "customField"
              ? "contacts-settings"
              : "contacts"
            }  `}
        >
          {this.state.contactsSettings === "import" ||
            this.state.contactsSettings === "customField" ||
            this.state.contactsSettings === "integration" ? (
            <ContactsSettings
              token={this.props.authToken}
              page={this.state.contactsSettings}
              allLists={this.state.allLists}
              user={this.props.user}
              goBack={this.openContactsSettings}
            />
          ) : (
            <>
              {this.state.contactView ? (
                <>
                  <div
                    className="arrow-back-green"
                    onClick={() =>
                      this.state.openedListId
                        ? this.openListRequest(this.state.openedListId)
                        : this.backToContacts()
                    }
                  >
                    <img src={ArrowLeftGreen} />
                    Back to {this.state.listTableName}
                  </div>
                  <header className="PageHeader contacts">
                    <h1 className="contacts-table-title">
                      Contact's information
                    </h1>
                    <div className="contacts-filter">
                      <div
                        className="contacts-icon-cover"
                        onClick={() => this.openContactModal(true, 0)}
                      >
                        <img src={EditIcon} />
                      </div>
                      <div
                        className="contacts-icon-cover"
                        onClick={() => this.setState({ deleteListModal: true })}
                      >
                        <img src={TrashIcon} />
                      </div>
                    </div>
                  </header>
                  <ContactInformation
                    contact={this.state.contactInfo}
                    listId={this.state.openedListId}
                    token={this.props.authToken}
                    openListReques={this.openListRequest}
                  />
                </>
              ) : (
                <>
                  <ContactsHeader
                    token={this.props.authToken}
                    contactsPage={this.state.contactsPage}
                    filterKey={this.state.filterKey}
                    listTableName={this.state.listTableName}
                    listType={this.state.listType}
                    filterOptions={this.state.filterOptions}
                    filterValue={this.state.filterValue}
                    allContactsId={this.state.allContactsId}
                    allRecipientsId={this.state.allRecipientsId}
                    openedListId={this.state.openedListId}
                    deleteButton={this.state.deleteButton}
                    customDates={this.state.customDates}
                    openedListId={this.state.openedListId}
                    addCustomFieldToList={this.addCustomFieldToList}
                    filterLists={this.filterLists}
                    setFilterValue={this.setFilterValue}
                    handleSearchList={this.handleSearchList}
                    handleSearchContacts={this.handleSearchContacts}
                    backToContacts={this.backToContacts}
                    changeListName={this.changeListName}
                    openListRequest={this.openListRequest}
                    openDeleteModal={() =>
                      this.setState({ deleteListModal: true })
                    }
                  />
                  <div className="Clear"></div>
                  <ContactsTables
                    token={this.props.authToken}
                    contactsPage={this.state.contactsPage}
                    allContacts={this.state.allContacts}
                    tableList={this.state.tableList}
                    tableRecipients={this.state.tableRecipients}
                    customDates={this.state.customDates}
                    openedListId={this.state.openedListId}
                    selecteAllContacts={this.selecteAllContacts}
                    openListRequest={this.openListRequest}
                    selectContact={this.selectContact}
                    contactsInformation={this.contactsInformation}
                    contactsRequest={this.contactsRequest}
                    contactsRequestSort={this.contactsRequestSort}
                    contactsInListSort={this.contactsInListSort}
                  />
                  {this.state.pages > 1 && (
                    <div className="pagination-cover">
                      <TablePagination
                        searchKey={this.state.searchKey}
                        pages={this.state.pages}
                        firstPage={this.state.firstPage}
                        handleListsRequest={this.handleListsRequest}
                        contactsRequestSort={this.contactsRequestSort}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </main>
        {this.state.sidebarShow && (
          <ContactsSidebar
            openContactsSettings={this.openContactsSettings}
            openNewSegment={this.openNewSegment}
            openContactModal={this.openContactModal}
          />
        )}
        <Modal
          open={this.state.segmentModalOpen}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => this.openNewSegment(false)}
        >
          <NewSegmentModal
            step={this.state.newListModal}
            openNewSegment={this.openNewSegment}
            contactsRequest={this.contactsRequest}
            openListRequest={this.openListRequest}
          />
        </Modal>
        <Modal
          open={this.state.syncConfirmation}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => this.openNewSegment(false)}
        >
          <ContactSycnConfirmation
            step={this.state.newListModal}
            openNewSegment={this.openNewSegment}
            contactsRequest={this.contactsRequest}
            openListRequest={this.openListRequest}
          />
        </Modal>

        <Modal
          open={this.state.contactModal}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal contact"
          onClose={() => this.setState({ contactModal: false })}
        >
          {this.state.allLists.length !== 0 ?
            <ContactModal
              contact={this.state.contactStep === 0 ? this.state.contactInfo : {}}
              contactStep={this.state.contactStep}
              openContactModal={this.openContactModal}
              updateContact={this.updateContact}
              addContact={this.addContact}
              user={this.props.user}
              allLists={this.state.allLists}
            />

            : <>
              <Modal.Header><h2>Sorry.</h2></Modal.Header>
              <Modal.Content>
                <p>
                  Please create at least one contact list before adding new contact!
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({ contactModal: false })}>
                  OK
                </Button>
              </Modal.Actions>
            </>}
        </Modal>


        <Modal
          open={this.state.deleteListModal}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => this.setState({ deleteListModal: false })}
        >
          <DeleteModalPopUp
            allContactsId={this.state.allContactsId}
            allRecipientsId={this.state.allRecipientsId}
            listType={this.state.listType}
            deleteAccess={this.state.deleteAccess}
            contactView={this.state.contactView}
            listTableName={this.state.listTableName}
            openedListId={this.state.openedListId}
            contactInfo={this.state.contactInfo}
            handleDeleteAllRequest={this.handleDeleteAllRequest}
            destroyLists={this.destroyLists}
            destroyContactsFromList={this.destroyContactsFromList}
            destroyContacts={this.destroyContacts}
            closeModal={() => this.setState({ deleteListModal: false })}
          />
        </Modal>
        <Modal
          open={this.state.integrationModal}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          className="contacts-segment-modal"
          onClose={() => this.setState({ integrationModal: false })}
        >
          <IntegrationModals
            integration="etsy"
            openedListId={this.state.openedListId}
            integrationUpload={this.state.integrationUpload}
            handleCloseModal={this.closeIntegrationModal}
            openListRequest={this.openListRequest}
          />
        </Modal>
      </div>
    );
  }
}
export default Contacts;
