import React, { Fragment, useState, useEffect, useRef } from "react";
import { ItemMeta, Modal } from "semantic-ui-react";

import TrashIcon from "../../../../assets/images/trash-icon.svg";
import Close from "../../../../assets/images/btn_close.svg";
import { postData } from "../../../lib/fetches";

function AddClientPopUp({ onClose, currentAgency, authToken, updateClientsList, clientsCount, default_clients_amount, setAgency}) {

  const isMountRef = useRef(true);
  const [emailReg] = useState(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  const [formValid, setFormValid] = useState(false);
  const [emailArray, setEmailArray] = useState([
    { id: Math.random() * 1000, value: "", valid: false },
  ]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [addingPopupData, setAddingPopupData] = useState({ message: null, already_invited: [], registered_users: [] });
  const [addingPopupOpen, setAddingPopupOpen] = useState(false);
  const [requestSendingDisable, setRequestSendingDisable] = useState(false);
  const addEmail = () => {
    const newEmail = {
      id: Math.random() * 1000,
      value: "",
      valid: true,
    };
    const newEmailArray = [...emailArray, newEmail];
    setFormValid(false);
    setEmailArray([...newEmailArray]);
  };

  const delEmail = (id) => {
    if (emailArray.length > 1) {
      let formValid = true;
      const modifiedArray =
        emailArray.filter((item) => {
          if (item.id !== id) {
            formValid = item.valid ? formValid : false
            return true
          }
          return false
        })
      setFormValid(formValid);
      setEmailArray([...modifiedArray]);
    }
  };

  const handleEmailValue = (e, id) => {
    let formValid = true;
    let emailExists = emailArray.map(e => e.value)
    const modifiedArray = emailArray.map((item) => {
      if (item.id === id) {
        item.value = e.target.value
        item.valid = emailReg.test(item.value)
      }
      if (!item.valid || emailExists.includes(e.target.value) ) {
        formValid = false;
      }
      return item;
    });
    setFormValid(formValid);
    setEmailArray([...modifiedArray]);
  };

  const inviteEmailRequest = (e) => {
    e.preventDefault();
    if (formValid) {
      if (clientsCount + emailArray.length >= default_clients_amount) {
        setConfirmOpen(true);
      } else {
        sendRequest();
      }
    }
  };
  const closeResultsPopup = (e) => {
    e.preventDefault();
    setAddingPopupOpen(false);
    updateClientsList(clients);
    if (!(addingPopupData.already_invited.length > 0 || addingPopupData.registered_users.length > 0)) {
      onClose();
    }
  };

  const sendRequest = () => {
    setRequestSendingDisable(true);
    postData(
      `/agencies/${currentAgency.id}/invite_clients`,
      authToken,
      { emails: emailArray.map(i => i.value) },
      (res) => {
        setAgency(res.agency);
        if(res.message || res.registered_users || res.already_invited){
          setClients(res.clients);
          setRequestSendingDisable(false);
          setAddingPopupData({
            mesage: res.message || null,
            already_invited: res.already_invited || [],
            registered_users: res.registered_users || []
          });
        } else {
          updateClientsList(res.clients);
          setRequestSendingDisable(false);
          onClose();
        }
      }
    );
  };

  const chargingPrice = () => {
    let result = 0
    if (clientsCount < default_clients_amount) {
      let additionalCount = clientsCount - default_clients_amount + emailArray.length
      let paidAmount = additionalCount > 0 ? additionalCount : 0
      result = (currentAgency.current_plan_cost + (paidAmount* 5))
    } else {
      result = (currentAgency.current_plan_cost + (emailArray.length*5))
    }
    return result
  }

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false;
      return;
    } else {
      setAddingPopupOpen(true);
    }
  }, [addingPopupData]);

  return (
    <>
      <div className="agency-blog-modal">
        <div className="agency-blog-modal-content">
          <form className="agency-blog-modal-container">
            <div className="agency-blog-closeAgency">
              <img src={Close} alt="close image" onClick={() => onClose()} />
            </div>
            <h2 className="agency-blog-modal-title">Add New Client</h2>
            <p>Enter an email of a client that you want to add</p>
            <div className="agency-blog-modal-input-container">
              {emailArray.map((item) => {
                const itemKey = Math.floor(item.id);
                return (
                  <div className={['agency-blog-modal-input-cover', !item.valid && item.value.length > 0 ? ' has-error' : ''].join('')}>
                    <input
                      key={itemKey}
                      type="email"
                      onChange={(e) => handleEmailValue(e, item.id)}
                      className="agency-modal-input"
                      value={item.value}
                    />
                    <img
                      src={TrashIcon}
                      alt="trash icon"
                      onClick={() => delEmail(item.id)}
                    />
                  </div>
                );
              })}
            </div>
            <span className="span-button" onClick={() => addEmail()}>
              + Add one more client
            </span>
            <button
              disabled={!formValid || requestSendingDisable}
              className="green-button margin-top-s max-wd-unset"
              onClick={(e) => inviteEmailRequest(e)}
            >
            { requestSendingDisable ? 'sending...' : 'Invite Client' }
            </button>
          </form>
        </div>
      </div>
      <Modal
        open={confirmOpen}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        className="contacts-segment-modal"
        onClose={() => setConfirmOpen(false)}
      >
        <div className="agency-blog-modal-content client-modal-content">
          <div className="agency-blog-modal-container">
            <h2 className="agency-blog-modal-title">
              Subscription Plan
          </h2>
          <p className="agency-blog-modal-text">
            With current plan you can add 3 clients for free. Each new user will cost you $5/mo. Confirm changing your suscription plan to ${chargingPrice()}/mo
          </p>
            <div className="agency-blog-modal-bottom-buttons">
              <button onClick={() => setConfirmOpen(false)} className="modal-bottom-buttons-btn modal-bottom-submit-btn-cancel">Cancel</button>
              <button onClick={() => sendRequest()} disabled={requestSendingDisable} className="modal-bottom-buttons-btn modal-bottom-submit-btn-confirm">{requestSendingDisable ? 'sending...' : 'Confirm'}</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={addingPopupOpen}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        className="contacts-segment-modal"
        onClose={() => setAddingPopupOpen(false)}
      >
        <div className="agency-blog-modal-content client-modal-content">
          <div className="agency-blog-modal-container">
            <h2 className="agency-blog-modal-title">
              Adding Results
          </h2>
            {addingPopupData.message ?
              <p className="agency-blog-modal-text">
                {addingPopupData.message}
              </p> : ""
            }
            {
              addingPopupData.already_invited.length ?
                <p className="agency-blog-modal-text">
                  These clients have already been invited before: {addingPopupData.already_invited.join(", ")}
                </p> : ""
            }
            {
              addingPopupData.registered_users.length ?
                <p className="agency-blog-modal-text">
                  These clients are already registered in Mailfold: {addingPopupData.registered_users.join(", ")}
                </p> : ""
            }
            {!addingPopupData.registered_users.length && !addingPopupData.already_invited.length && !addingPopupData.message ?
              <p className="agency-blog-modal-text">
                Invitations was sent successfully!
            </p> : ""
            }
            <div className="agency-blog-modal-bottom-buttons centered">
              <button onClick={(e) => {
                closeResultsPopup(e)
                setConfirmOpen(false)
              }} className="modal-bottom-buttons-btn modal-bottom-submit-btn-confirm">Okay</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddClientPopUp;
