import React from "react";

import { Line } from "react-chartjs-2";

const data = {
    labels: ["January","February","March","April","May","June","July",
            "August","September","October","November","December"],
    datasets: [
        {
            label: "Total",
            data: [330, 2001, 850, 410, 4004, 100, 330, 928, 850, 410, 500, 650],
            fill: false,
            borderColor: "#8BCCAF",
            pointBackgroundColor: '#8BCCAF',
            pointHoverRadius: 8,
            pointHoverBorderWidth: 2,
            pointHoverBorderColor: '#FFF',
            pointRadius: 1,
        },
        {
            label: "Unique",
            data: [330, 250, 350, 510, 540, 1392, 220, 2012, 500, 0, 400, 900],
            fill: false,
            borderColor: "#FADDDC",
            pointBackgroundColor: '#FADDDC',
            pointHoverRadius: 8,
            pointHoverBorderWidth: 2,
            pointHoverBorderColor: '#FFF',
            pointRadius: 1,
        }
    ]
};

const options = {

    layout : {
        padding: {
            top: 50
        },
    },
    legend: {
        position: 'bottom',
        labels: {
            usePointStyle: true,
            boxWidth: 6,
            fontFamily: "YogaSansOffc, sans-serif"
        }
    },
    scales: {
        xAxes: [{
            gridLines: {
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
            },
            ticks: {
                fontFamily: "YogaSansOffc, sans-serif",
                fontSize: 10,
                fontColor: '#646468',
                padding: 10
            }
        }],
        yAxes: [{
            gridLines: {
                display: true,
                drawOnChartArea: false,
                drawTicks: false,

            },
            ticks: {
                fontFamily: "YogaSansOffc, sans-serif",
                fontSize: 10,
                fontColor: '#646468',
                padding: 10
            }
        }]
    },
}

const plugin = {
    afterDraw: chart => {
        const ctx = chart.chart.ctx;
        ctx.save();
        ctx.font = "10px \'YogaSansOffc\'";
        ctx.fillStyle = "#646468";

        ctx.textAlign = 'left';
        ctx.fillText('Amount', 15, 20);

        ctx.textAlign = 'right';
        ctx.fillText('Date', chart.chart.width - 15, chart.chart.height - 75);
        ctx.restore();

        if (chart.tooltip._active && chart.tooltip._active.length) {
            // find coordinates of tooltip
            const activePoint = chart.tooltip._active[0];
            const { x } = activePoint._model;
            const topY = chart.scales["y-axis-0"].top;
            const bottomY = chart.scales["y-axis-0"].bottom;

            // draw vertical line
            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([5, 10]);
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'rgba(23, 153, 96, 0.5)';
            ctx.stroke();
            ctx.restore();
        }
    },
}

const LineChart = () => {
    return (
        <Line options={options} data={data} plugins={[plugin]}/>
    );
};

export default LineChart;