import React, { useState, useCallback } from "react";
import { Doughnut } from 'react-chartjs-2';


const labels = [
    'iOS',
    'Windows',
    'Android',
    'MacOS',
    'Linux'
];
const dataSet = [64, 20, 9, 4, 3];

const data = {
    labels: labels,
    datasets: [{
        data: dataSet,
        backgroundColor: [
            '#FADDDC',
            '#C1C7E3',
            '#8BCCAF',
            '#B3B3B5',
            '#FDC8A0'
        ],
        hoverOffset: 4
    }]
};

const options = {
    legend: {
        display: false,
        fullWidth: false,
    },
    responsive: false,
    cutoutPercentage: 70,
    aspectRatio: 1,

    legendCallback: (chartEl) => {
        let labels = chartEl.data.labels
        let borderWidth = chartEl.data.datasets && chartEl.data.datasets[0].borderWidth
        return (
            <div className="chart-legend">
                {labels && labels.map((l, i) => (
                    <div className="chart-legend__item" key={i} onClick={(event) => handleLegendClick(event, i, chartEl)}>
                        <div className="chart-legend__label-wrapper">
                            <span className="chart-legend__point" style={{backgroundColor: data.datasets[0].backgroundColor[i], border: `${borderWidth}px solid`}} />
                            {labels && <span>{labels[i]}</span>}
                        </div>
                        <span><b>{dataSet[i]} %</b></span>
                    </div>
                ))}
            </div>
        )
    },
};

const handleLegendClick = (event, datasetIndex, chartEl) => {

    let e = event.target;
    chartEl.getDatasetMeta(0).data[datasetIndex].hidden =
        chartEl.getDatasetMeta(0).data[datasetIndex].hidden ? false : true;

    e.classList.contains("chart-legend__item") ?
        e.classList.toggle("crossed-line") :
        e.closest(".chart-legend__item").classList.toggle("crossed-line");
    chartEl.update();
};

const plugins = [{
    beforeDraw: function(chart) {

            // TODO: remove test data
            // Get ctx from string
            let ctx = chart.ctx;

            // Get options from the center object in options
            let fontStyle = 'YogaSansOffc';
            let txt = "iOS - 64%";
            let color =  '#000';
            let maxFontSize = 15;
            let sidePadding = 20;
            let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            // Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            let stringWidth = ctx.measureText(txt).width;
            let elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            let widthRatio = elementWidth / stringWidth;
            let newFontSize = Math.floor(30 * widthRatio);
            let elementHeight = (chart.innerRadius * 2);

            // Pick a new font size so it will not be larger than the height of label.
            let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
            let minFontSize = 10;
            let lineHeight = 25;
            let wrapText = false;

            if (minFontSize === undefined) {
                minFontSize = 20;
            }

            if (minFontSize && fontSizeToUse < minFontSize) {
                fontSizeToUse = minFontSize;
                wrapText = true;
            }

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            if (!wrapText) {
                ctx.fillText(txt, centerX, centerY);
                return;
            }

            let words = txt.split(' ');
            let line = '';
            let lines = [];

            // Break words up into multiple lines if necessary
            for (let n = 0; n < words.length; n++) {
                let testLine = line + words[n] + ' ';
                let metrics = ctx.measureText(testLine);
                let testWidth = metrics.width;
                if (testWidth > elementWidth && n > 0) {
                    lines.push(line);
                    line = words[n] + ' ';
                } else {
                    line = testLine;
                }
            }

            // Move the center up depending on line height and number of lines
            centerY -= (lines.length / 2) * lineHeight;

            for (let n = 0; n < lines.length; n++) {
                ctx.fillText(lines[n], centerX, centerY);
                centerY += lineHeight;
            }
            //Draw text in center
            ctx.fillText(line, centerX, centerY);
    },
}]


const DoughnutChart = () => {

    const [legend, setLegend] = useState();
    const [ref, setRef] = useState();

    const onRefChange = useCallback(node => {

        // ref value changed to node
        setRef(node); // e.g. change ref state to trigger re-render
        if (node !== null) {
            setLegend(node.chartInstance.generateLegend())
        }
    }, []);

    return (
        <div className="doughnut-chart__wrapper">
            <Doughnut
                ref={onRefChange}
                data={ data }
                options={ options }
                plugins={ plugins }
                height={180}
                width={180}
            />
            {legend}
        </div>
    )
};

export default DoughnutChart;


