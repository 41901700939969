import React, { Component } from "react";
import { Input } from "semantic-ui-react";

const NumberOfValues = 2;

class CustomListMapping extends Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      defaultPositions: this.props.defaultPositions,
    };
  }

  handleDropdownChange = (event, index) => {
    let defaultPositions = this.state.defaultPositions;
    let i = parseInt(event.target.name.split("_")[1]);
    let currentKey = _.invert(defaultPositions)[i];
    delete defaultPositions[currentKey];
    let key = event.target.value;
    this.removeDuplicate(event);
    defaultPositions[event.target.value] = i;
    this.setState({ defaultPositions: defaultPositions });
    this.props.setDefaultPositions(defaultPositions);
    let listFields = Object.keys(defaultPositions);
    let emptyField = listFields.find((string) => string.includes("null"));
    listFields.length >= 7 && !emptyField
      ? this.props.setListFilled(true)
      : this.props.setListFilled(false);
  };

  removeDuplicate = (event) => {
    let defaultPositions = this.state.defaultPositions;
    let oldIndex = defaultPositions[event.target.value];
    if (typeof oldIndex !== "undefined") {
      this.refs[`index_${oldIndex}`].value = `null_${oldIndex}`;
    }
  };

  setFieldKey = (event, index) => {
    let defaultPositions = this.state.defaultPositions;
    let currentKey = _.invert(defaultPositions)[index];
    delete defaultPositions[currentKey];
    defaultPositions[`customField_${event.target.value}`] = index;
    this.setState({ defaultPositions: defaultPositions });
    this.props.setDefaultPositions(defaultPositions);
  };

  partOfValues(values) {
    return values.slice(0, NumberOfValues);
  }

  columnValues = (rows, columnIndex) => {
    let values = [];

    rows.forEach((row) => {
      values.push(row[columnIndex]);
    });

    return this.partOfValues(
      values.filter((value) => value !== "" && typeof value !== "undefined")
    );
  };

  mapDropdownOptions = () => {
    let { sampleData } = this.props;
    let defaultPositions = _.invert(this.state.defaultPositions);
    return _.range(sampleData[0].length).map((index) => {
      let columnValues = this.columnValues(sampleData, index);

      if (columnValues.length > 0) {
        return (
          <div className="imported-fields">
            <div key={index} className="Half KeyValue width100">
              <select
                ref={`index_${index}`}
                name={`index_${index}`}
                key={`index_${index}`}
                onChange={(e) => {
                  this.handleDropdownChange(e, index);
                }}
              >
                <option value={`null_${index}`}>Other</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="businessName">Full Name</option>
                <option value="addressLine1">Address Line 1</option>
                <option value="addressLine2">Address Line 2</option>
                <option value="city">City</option>
                <option value="state">State</option>
                <option value="postalCode">Postal Code</option>
                <option value={`customDate_${index}`}>Custom Date</option>
              </select>
            </div>
            <div className="Half SampleValue">
              {defaultPositions[index] &&
              defaultPositions[index].includes("customDate") ? (
                <Input
                  key={`input{index}`}
                  placeholder="Set name of this field"
                  onChange={(e) => {
                    this.setFieldKey(e, index);
                  }}
                />
              ) : (
                ""
              )}
              <ul className="recipients-sublable-item">
                {columnValues.map((column) => {
                  return <li>{column}</li>;
                })}
              </ul>
            </div>
          </div>
        );
      } else {
        return "";
      }
    });
  };

  render() {
    return (
      <>
        {/* <h2>Review and Label</h2>
        <p>
          Help us understand your mailing list. We’ll show you example values
          from each column and you tell us what to call those values.
        </p>
        <div className="Half">
          <label>Labels</label>
        </div> */}
        {/* <div className="Half">
          <label>Sample Values</label>
        </div> */}
        <div className="Clear" />
        {this.mapDropdownOptions()}
      </>
    );
  }
}

export default CustomListMapping;
