import React from "react";
import PropTypes from "prop-types";

export default class DesignOptions extends React.Component {
  static propTypes = {
    designOption: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { designOption: props.designOption };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    let designOption = event.target.value;
    this.props.onChange(designOption);
  }

  render() {
    return (
      <div className="DesignProcess-container">
        <fieldset className="DesignProcess">
          {/* <h2>Your Design Process</h2> */}
          <h2 className="automation-tab-title subtitle">Select Files</h2>
          <div className="radiowrapper">
            <div className="RadioRow">
              <div className="label-cover">
                <input
                  id="design_option_create"
                  type="radio"
                  value="create"
                  checked={this.props.designOption == "create"}
                  name="campaign[design_option]"
                  onChange={this.onChange}
                />
                <label
                  className="RadioButton"
                  htmlFor="design_option_create"
                ></label>

                <label htmlFor="design_option_create">
                  Design a Postcard
                  <span className="Description FourCol">
                    Create a postcard from scratch or start witih one of our
                    beautiful templates.
                  </span>
                </label>
              </div>
            </div>
            <div className="RadioRow">
              <div className="label-cover">
                <input
                  id="design_option_upload"
                  type="radio"
                  value="upload"
                  checked={this.props.designOption == "upload"}
                  name="campaign[design_option]"
                  onChange={this.onChange}
                />
                <label
                  className="RadioButton"
                  htmlFor="design_option_upload"
                ></label>

                <label htmlFor="design_option_upload">
                  Upload Design Files
                  <span className="Description">
                    Provide us with your own design files
                  </span>
                </label>
              </div>
            </div>
          </div>
        </fieldset>
        <div className="Tip">
          <h4 className="Tip-title">File Formats</h4>
          <div>
            Our postcards are 6x9 inches with an area on the back reserved for
            the sender and contact address. We accept PNG, PDF, and JPG formats.
            It's recommended that you use our template if this is your
            first time.
          </div>
        </div>
      </div>
    );
  }
}
