import React from "react";

const data = [
    {
        region: "California",
        percentage: 66
    },
    {
        region: "South Carolina",
        percentage: 20
    },
    {
        region: "Pennsylvania",
        percentage: 10
    },
    {
        region: "Massashusets",
        percentage: 6
    },
    {
        region: "South Dakota",
        percentage: 4
    }
];

const mostPopular = () => data.reduce((prev, curr) => prev.percentage > curr.percentage ? prev : curr);

const BarChart = () => {
    return (
        <div className="bar-chart__wrapper">
            <div className="data-card data-card_small">
                <h5 className="data-card__title">Top location</h5>
                <div className="data-card__value">{ mostPopular().region }</div>
                <div className="data-card__infographic">{ mostPopular().percentage } %</div>
            </div>
            <div className="bar-chart__chart-block">
                {data.map((d, idx) => (
                    <div className="bar-chart__element" key={idx}>
                        <span className="bar-chart__label">{ idx + 1 }.{ d.region }</span>

                        <div className="progress__wrapper">
                            <div className="progress__value" style={{width: `${d.percentage}%`}}></div>
                        </div>

                        <span className="bar-chart__value"><b>{ d.percentage }%</b></span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BarChart;