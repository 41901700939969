import React from "react";
import { render } from "react-dom";

import Campaigns from "../components/campaigns/Campaigns";
import MailingLists from "../components/mailing_lists/MailingLists";
import CustomList from "../components/campaigns/CustomList";

import DesignCanvas from "../components/campaigns/DesignCanvas";
import SidebarButton from "../components/design_elements/SidebarButton";

import Design from "../components/design/Design";
import Size from "../components/design/Size";
import CampaignForm from "../components/campaigns/CampaignForm";
import Postcard from "../components/design/Postcard";

import AutomationsNotAllowed from "../components/automations/AutomationsNotAllowed";
import AutomationsPremium from "../components/automations/AutomationsPremium";

import Prospecting from "../components/prospectings/Prospecting";

import Agency from "../components/agency/Agency";
import AgencyEdit from "../components/agency/AgencyEdit";
import SignUpRequestModal from "../components/agency/SignUpRequestModal";
import AgencySidebar from "../components/agency/components/AgencySidebar";
import Clients from "../components/agency/Clients";
import Contacts from "../components/contacts/Contacts";
import IntegrationPage from "../components/contacts/components/IntegrationPage";
import Recipients from "../components/campaigns/Recipients";
import ProspectingsSettings from "../components/campaigns/ProspectingsSettings";
import CancelAccount from "../components/shared/CancelAccount";

import QRCode from "../components/qr_codes/QRCode";
import QRDesignCanvas from "../components/qr_codes/QRDesignCanvas";
import AccountForm from "../components/shared/AccountForm";
import PasswordForm from "../components/shared/PasswordForm";
import ReturnAddressForm from "../components/shared/ReturnAddressForm";
import BillingForm from "../components/shared/BillingForm";
import SubscriptionForm from "../components/shared/subscriptions/SubscriptionForm";
import QRReportWrapper from "../components/qr_codes/QRReport/QRReportWrapper";
import QRReportWrapperError from "../components/qr_codes/QRReport/QRReportWrapperError";

import WebpackerReact from "webpacker-react";

WebpackerReact.setup({ Campaigns });
WebpackerReact.setup({ MailingLists });
WebpackerReact.setup({ CustomList });

WebpackerReact.setup({ DesignCanvas });
WebpackerReact.setup({ SidebarButton });

WebpackerReact.setup({ Design });
WebpackerReact.setup({ Size });
WebpackerReact.setup({ Postcard });
WebpackerReact.setup({ CampaignForm });

WebpackerReact.setup({ AutomationsNotAllowed });
WebpackerReact.setup({ AutomationsPremium });

WebpackerReact.setup({ Prospecting });

WebpackerReact.setup({ Contacts });
WebpackerReact.setup({ IntegrationPage });
WebpackerReact.setup({ Recipients });
WebpackerReact.setup({ ProspectingsSettings });
WebpackerReact.setup({ Agency });
WebpackerReact.setup({ AgencyEdit });
WebpackerReact.setup({ SignUpRequestModal });
WebpackerReact.setup({ AgencySidebar });
WebpackerReact.setup({ Clients });
WebpackerReact.setup({ CancelAccount });

WebpackerReact.setup({ QRCode });
WebpackerReact.setup({ QRDesignCanvas });
WebpackerReact.setup({ AccountForm });
WebpackerReact.setup({ PasswordForm });
WebpackerReact.setup({ ReturnAddressForm });
WebpackerReact.setup({ BillingForm });
WebpackerReact.setup({ SubscriptionForm });
WebpackerReact.setup({ QRReportWrapper });
WebpackerReact.setup({ QRReportWrapperError });
