import React from 'react'

class SidebarButton extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="DesignerButtonContainer">
        <div onClick={() => {this.props.changeActiveTray(this.props.label)}} className={"DesignerButton " + this.props.label + "Button"}></div>
        <span className="ButtonLabel">{this.props.label}</span>
      </div>
    )
  }
}

export default SidebarButton
