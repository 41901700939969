import React from "react";
import QRCodeStyling from "qr-code-styling";

class QRGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            qrLink: props.qrCodeCustomerUrl || "",
            isValidQRLink: !!props.qrCodeCustomerUrl,
        }
        this.url = React.createRef();
        this.qrCode = new QRCodeStyling(props.options);
    }

    generateDefaultQRRedirectLink = () => {
        const baseUrl = window.location.origin;
        const campaignId = this.props.campaignId;
        const path = `qr_code_scanning?utm_source=qr_code&utm_medium=qr_code_scanning&utm_campaign=${campaignId}&utm_id=${campaignId}`;
        const url = `${baseUrl}/campaigns/${campaignId}/${path}`;
        //console.log({url});
        return url;
    }

    get authToken() {
        return document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    }

    getQRCodeUrl = async () => {
        try {
            const blob = await this.qrCode.getRawData("png");
            return URL.createObjectURL(blob);
        } catch (error) {
            console.log(error);
        }
    }

    handleGenerateQR = (event) => {
        event.preventDefault();

        let httplink = this.state.qrLink;
        if (!String(httplink).trimStart().startsWith('http')) {
            httplink = `http://${httplink}`;
        }

        fetch(`/campaigns/${this.props.campaignId}/save_qr_code_url`, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": this.authToken,
            },
            body: JSON.stringify({ qr_code_url: httplink, cid: this.props.campaignId }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Ошибка HTTP: " + response.status);
                }
                return response.json();
            })
            .then((response) => {

                if (response.success) {
                    const data = this.props.options.data;
                    const generatedUrl = this.generateDefaultQRRedirectLink();
                    try {
                        this.qrCode.update(data ? this.props.options : { ...this.props.options, data: generatedUrl });
                    } catch (error) {
                        console.log(error);
                    }

                    let objectURL;
                    this.qrCode.getRawData("png").then((blob) => {
                        objectURL = URL.createObjectURL(blob);
                        this.props.setQRRedirectLink(generatedUrl, objectURL);
                    }).catch(console.log)
                    console.debug("Customer url saved successfully!");
                } else {

                    throw new Error("Customer url not saved!");

                }
            }).catch(console.log);
    };

    handleLinkChange = e => {
        e.preventDefault();
        this.setState({
            qrLink: e.target.value
        }, this.checkQRLink);
    };

    updateQRCode = (options) => {

        try {
            if (this.ref.current) {
                this.qrCode.append(this.ref.current);
            }
            this.qrCode.update(options);
        } catch (error) {
            console.debug("Error qrCode", options, error);
        }
    }

    componentDidMount() {
        // if (this.props.qrCodeRedirectUrl) {
        this.updateQRCode(this.props.options);
        // }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options) {
            this.updateQRCode(this.props.options);
        }
    };

    checkQRLink = () => {
        let result = true;
        if (!this.state.qrLink) {
            result = false;
        }

        // if (this.url.current && !this.url.current?.validity?.valid) {
        //     result = false;
        // }
        this.setState({ isValidQRLink: result });
    }

    render() {

        return (
            <div className="QR-block">
                <h2>{this.props.qrCodeCustomerUrl ? 'Edit ' : 'Add'} URL</h2>
                <form
                    className="QR-block__form"
                    onSubmit={this.handleGenerateQR}
                >
                    <input
                        type="text"
                        className="segment-modal-input SearchForm"
                        placeholder="website.com/lander"
                        value={this.state.qrLink}
                        ref={this.url}
                        onChange={this.handleLinkChange}
                    />
                    <button
                        className="PurpleButton PurpleButton_w-100"
                        disabled={!this.state.isValidQRLink}
                        type="submit"
                    >
                        Generate QR code
                    </button>
                </form>

                <div className="TrayOptions">
                    <div ref={this.ref} style={{ visibility: "hidden" }} />
                </div>

            </div>
        )
    }
}


export default QRGenerator;