import React, { Fragment, useState, useEffect } from "react";

import { Dropdown, Input } from "semantic-ui-react";

import _ from "lodash";

import { getData, postData, putData, deleteData } from "../../lib/fetches";
import DeskIconBig from "../../../assets/images/desk-icon-big.svg";
import DiagramIconBig from "../../../assets/images/diagram-icon-big.svg";
import SearchIcon from "../../../assets/images/search-icon.svg";
import EditIcon from "../../../assets/images/edit.svg";
import ArrowLeftGreen from "../../../assets/images/arrow-left-green.svg";
import SaveIcon from "../../../assets/images/save.svg";

function ContactsHeader(props) {
  const {
    contactsPage,
    token,
    addCustomFieldToList,
    filterKey,
    listTableName,
    listType,
    filterOptions,
    filterValue,
    allContactsId,
    allRecipientsId,
    filterLists,
    setFilterValue,
    handleSearchList,
    handleSearchContacts,
    backToContacts,
    openDeleteModal,
    changeListName,
    openedListId,
    deleteButton,
    customDates,
  } = props;

  useEffect(() => {}, []);

  const [newListName, setNewListName] = useState();
  const [openEditName, setOpenEditName] = useState(false);
  const [customDatesArray, setCustomDatesArray] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);

  const handleListName = (e) => {
    setNewListName(e.target.value);
  };

  const handleAddCustomField = (listId, customFieldId) => {
    addCustomFieldToList(listId, customFieldId);
    setOpenDropdown(false);
  };

  const setCustomFieldsOptions = () => {
    getData(`/custom_fields`, token, (res) => {
      let optionsArray = res.custom_fields.map((elem) => {
        let optionElem = {
          key: elem.name,
          text: elem.name,
          value: elem.name,
          id: elem.id,
          selected: false,
        };
        return optionElem;
      });
      const arrayForOptions = optionsArray.map((item) => {
        if (customDates.find((elem) => elem === item.value)) {
          item.selected = true;
          return item;
        }
        return item;
      });
      const filteredArray = arrayForOptions
        .map((item) => {
          if (item.selected === true) {
            return item.id;
          }
        })
        .filter((elem) => {
          if (elem) {
            return elem;
          }
        });
      setFieldsData(filteredArray);
      setCustomDatesArray(arrayForOptions);
    });
    setOpenDropdown(!openDropdown);
  };

  const handleBackToContacts = () => {
    backToContacts();
    setCustomDatesArray([]);
    setFieldsData([]);
    setOpenEditName(false);
    setOpenDropdown(false);
  };

  const selectCustomFields = (id) => {
    let selectedField = customDatesArray.filter((elem) => {
      if (elem.id === id) {
        elem.selected = !elem.selected;
      }
      return elem;
    });
    let updatedArray = customDatesArray.map((item) =>
      item.id === selectedField.id ? (item = selectedField[0]) : item
    );

    let filteredArray = updatedArray
      .map((item) => {
        if (item.selected) {
          return item.id;
        }
      })
      .filter((elem) => {
        if (elem) {
          return elem;
        }
      });
    setFieldsData(filteredArray);
    setCustomDatesArray(updatedArray);
  };

  return (
    <>
      {!contactsPage && filterKey !== "all" && (
        <div className="arrowbackbutton">
        <div className="arrow-back-green" onClick={() => goBack("")}>
          <img src={ArrowLeftGreen} />
          Back to all contacts
        </div>
      </div>
      )}
      <header className="PageHeader contacts">
        {/* <h1 className="contacts-table-title">
          {contactsPage && ""}
          {listTableName !== "" && (
            <>
              {listType === "list" && <img src={DeskIconBig} />}
              {listType === "segment" && <img src={DiagramIconBig} />}
              {listType === "" && <></>}
              {openEditName ? (
                <>
                  <div className="contacts-table-title input-cover">
                    <input
                      className="contacts-table-title input"
                      value={newListName}
                      onChange={(e) => handleListName(e)}
                    />
                  </div>
                  <img
                    src={SaveIcon}
                    className="table-icon"
                    onClick={() => {
                      changeListName(newListName, openedListId);
                      setOpenEditName(false);
                      setNewListName("");
                    }}
                  />
                </>
              ) : (
                <>{listTableName}</>
              )}
              {openedListId !== 0 && !openEditName && (
                <img
                  src={EditIcon}
                  className="table-icon"
                  onClick={() => {
                    setOpenEditName(true);
                    setNewListName(listTableName);
                  }}
                />
              )}
            </>
          )}
        </h1> */}

        <div className="contacts-filter">
          {contactsPage ? (
            <>
              <div className="filter-cover">
                <Dropdown
                  placeholder="Show the list of all contacts"
                  options={filterOptions}
                  value={filterValue}
                  selection
                  onChange={(event, data) => {
                    filterLists(data.value);
                    setFilterValue(data);
                  }}
                />
              </div>
              <div className="contacts-input filter-cover">
                <Input
                  className=""
                  placeholder="Search"
                  onChange={_.debounce(handleSearchList, 300)}
                />
                <img src={SearchIcon} />
              </div>
            </>
          ) : (
            <>
              {filterKey === "all" && (
                <>
                  <div className="filter-cover">
                    <Dropdown
                      className=""
                      placeholder="Show the list of all contacts"
                      options={filterOptions}
                      value={filterValue}
                      selection
                      onChange={(event, data) => {
                        filterLists(data.value);
                        setFilterValue(data);
                      }}
                    />
                  </div>
                </>
              )}
              <div className="contacts-input filter-cover">
                <Input
                  className=""
                  placeholder="Search"
                  onChange={_.debounce(handleSearchContacts, 300)}
                />
                <img src={SearchIcon} />
              </div>
            </>
          )}
        </div>
      </header>
      <div
        className={`contacts-delete-button-cover ${
          deleteButton && openedListId && "between"
        } `}
      >
        {openedListId != 0 && (
          <div className="custom-field-dropdown-cover">
            <button
              className="custom-field-dropdown"
              onClick={() => setCustomFieldsOptions()}
            >
              Customize Fields
            </button>
            {customDatesArray.length > 0 && openDropdown && (
              <ul>
                <li className="dropdown-title">
                  Add or remove fields in table
                </li>
                {customDatesArray.map((item, index) => {
                  return (
                    <li key={item.key}>
                      <div className="ToggleContainer">
                        <input
                          type="checkbox"
                          checked={item.selected}
                          readOnly
                          name={"fields_" + item.id}
                          id={"fields_" + item.id}
                          value="1"
                        />
                        <label
                          className="CheckboxButton"
                          htmlFor={"fields_" + item.id}
                          onClick={() => selectCustomFields(item.id)}
                        ></label>
                      </div>
                      {item.text}
                    </li>
                  );
                })}
                <li
                  className="dropdown-save-button"
                  onClick={() =>
                    handleAddCustomField(openedListId, {
                      custom_field_id: fieldsData,
                    })
                  }
                >
                  Save
                </li>
              </ul>
            )}
          </div>
        )}

        {allContactsId.length > 0 && deleteButton && (
          <button
            className="contacts-delete-button"
            onClick={() => openDeleteModal()}
          >
            Delete selected
          </button>
        )}
        {allRecipientsId.length > 0 && deleteButton && (
          <button
            className="contacts-delete-button"
            onClick={() => openDeleteModal()}
          >
            Delete selected
          </button>
        )}
      </div>
    </>
  );
}

export default ContactsHeader;
