import React from "react";
import { Tab, Radio, Modal } from "semantic-ui-react";

import CloseButton from "../../../assets/images/btn_close.svg";
import SetupTab from "./components/SetupTab";
import DesignTab from "./components/DesignTab";
import ContactsTab from "./components/ContactsTab";
import RulesTab from "./components/RulesTab";
import ActivityTab from "./components/ActivityTab";
import { putData, deleteData } from "../../lib/fetches";

class AutomationBuilder extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      title: props.automation.title || "",
      businessName: props.automation.return_address
        ? props.automation.return_address.business_name
        : "",
      addressLine1: props.automation.return_address
        ? props.automation.return_address.address_line_1
        : "",
      addressLine2: props.automation.return_address
        ? props.automation.return_address.address_line_2
        : "",
      city: props.automation.return_address
        ? props.automation.return_address.city
        : "",
      state: props.automation.return_address
        ? props.automation.return_address.state
        : "Alaska",
      postalCode: props.automation.return_address
        ? props.automation.return_address.postal_code
        : "",
      country: props.automation.return_address
        ? props.automation.return_address.country
        : "US",
      status: props.automation.status,
      size: props.automation.postcard_size,
      designOption: props.automation.designOption || "create",
      guid: props.automation.guid || "",
      etsyConnected: props.automation.etsy_linked,
      exitModalOpen: props.exitModalOpen,
      deleteModalOpen: false,
      countries: props.countries,
      states: props.states,
      selectedList: props.automation.address_book,
      selectedListType: props.automation.list_option || "select",
      selectedListName: "",
      activeIndex: props.activeTab,
      setupChanged: false,
      designChanged: false,
      recipientsChanged: false,
      rulesChanged: false,
      customDatesList: this.props.automation.custom_dates_names,
      selectedCustomDate: this.props.automation.rule_data
        ? this.props.automation.rule_data.mailing_date
        : [],
      ruleOption: this.props.automation.rule_option || "asap",
      automationValid: false,
      activityList: this.props.automation.group_activities,
    };
    this.updateField = this.updateField.bind(this);
  }

  handleTabChange = (e, { activeIndex }) => {
    let changed = this.tabWasChanged(this.state.activeIndex);

    if (changed) {
      this.saveTabFormData(this.state.activeIndex, activeIndex);
    } else {
      this.setState({ activeIndex });
    }
  };

  componentWillReceiveProps(nextProps) {
  this.setState({ exitModalOpen: nextProps.exitModalOpen });
}

  componentDidMount() {
    this.automationStatus();
  }

  tabWasChanged = (activeIndex) => {
    switch (activeIndex) {
      case 0:
        return this.state.setupChanged;
      case 1:
        return this.state.designChanged;
      case 2:
        return this.state.recipientsChanged;
      case 3:
        return this.state.rulesChanged;
      default:
        return false;
    }
  };

  automationStatus() {
    let setupParams =
      this.state.title !== "" &&
      this.state.title &&
      this.state.businessName !== "" &&
      this.state.businessName &&
      this.state.addressLine1 !== "" &&
      this.state.addressLine1 &&
      this.state.city !== "" &&
      this.state.city &&
      this.state.state !== "" &&
      this.state.state &&
      this.state.country !== "" &&
      this.state.country &&
      this.state.postalCode !== "" &&
      this.state.postalCode &&
      this.state.postalCode.length > 1;
    let designParams = false
    if (this.props.automation.postcard_size == "8.5x11") {
      designParams = this.state.size && !!this.props.automation.front
    } else {
      designParams =
        this.state.size &&
        !!this.props.automation.front &&
        !!this.props.automation.back;
    }
    let recipientsParams =
      (this.state.selectedListType && this.state.selectedList) ||
      this.state.etsyConnected;
    let rulesParams = true;
    if (this.state.ruleOption == "select") {
      rulesParams = this.state.selectedCustomDate && this.state.selectedCustomDate.length > 0;
    }
    this.setState({
      automationValid:
        setupParams && designParams && recipientsParams && rulesParams,
    });

    if (setupParams && designParams && recipientsParams && rulesParams) {
      let newStatus =
        this.state.status == "disabled" ? "not-active" : this.state.status;
      this.setState({ status: newStatus });
    } else {
      this.setState({ status: "disabled" });
    }
  }

  openExitModal = () => {
    if (this.state.automationValid) {
      if (this.state.activeIndex == 3){
        this.setState({ status: "active"}, ()=> {
          this.closeExitModal()
        })
      } else {
        this.closeExitModal();
      }
    } else {
       this.props.handleExitModalOpen(true)
    }
  };

  openDeleteModal = () => {
    this.setState({ deleteModalOpen: true });
  };

  closeExitModal = () => {
    let changed = this.tabWasChanged(this.state.activeIndex);
    if (changed) this.saveTabFormData(this.state.activeIndex);
    this.props.handleExitModalOpen(false);
    this.changeStatus();
  };

  closeDeleteModal = (closeBuilder) => {
    this.setState({ deleteModalOpen: false });
    if (closeBuilder) {
      deleteData(`/automations/${this.state.guid}`, this.props.authToken, (res) => {
        this.props.closeAutomationModal(this.state.guid);
      })
    }
  };

  updateField(name, value, tabNameField) {
    this.setState({ [name]: value, [tabNameField]: true }, () =>
      this.automationStatus()
    );
  }

  saveTabFormData = (tabIndex, newIndex = null) => {
    let url_path = "automations/";
    let formData = {};
    switch (tabIndex) {
      case 0:
        formData = this.getSetupForm();
        url_path += `${this.state.guid}/setup_update`;
        break;
      case 1:
        formData = this.getDesignForm();
        url_path += `${this.state.guid}/design_update`;
        break;
      case 2:
        formData = this.getContactsForm();
        url_path += `${this.state.guid}/recipients_update`;
        break;
      case 3:
        formData = this.getRulesForm();
        url_path += `${this.state.guid}/rules_update`;
        break;
      default:
        return {};
    }
    putData(
      url_path,
      this.props.authToken,
      formData,
      (res) => {
        if(newIndex !== null) this.setState({activeIndex: newIndex})
      }
    );
  };

  getStatusForm = () => {
    return {
      automation: {
        status: this.state.status,
      },
    };
  };

  changeStatus = () => {
    putData(
      `automations/${this.state.guid}/change_status`,
      this.props.authToken,
      this.getStatusForm(),
      (res) => {
        this.props.closeAutomationModal();
      }
    );
  };

  checkedStatus() {
    return this.state.status === "active";
  }

  getSetupForm = () => {
    return {
      automation: {
        title: this.state.title,
        return_address_attributes: {
          business_name: this.state.businessName,
          address_line_1: this.state.addressLine1,
          address_line_2: this.state.addressLine2,
          city: this.state.city,
          state: this.state.state,
          postal_code: this.state.postalCode,
          country: this.state.country,
        },
      },
    };
  };
  getDesignForm = () => {
    return {
      automation: {
        postcard_size: this.state.size,
        design_option: this.state.designOption,
      },
    };
  };

  getContactsForm = () => {
    return {
      automation: {
        list_option: "select",
        address_book_id: this.state.selectedList.id,
      },
    };
  };

  getRulesForm = () => {
    if (this.state.ruleOption === "asap") {
      return {
        automation: {
          rule_option: this.state.ruleOption,
          rule_data_attributes: {
            mailing_date: [],
          },
        },
      };
    } else {
      return {
        automation: {
          rule_option: this.state.ruleOption,
          rule_data_attributes: {
            mailing_date: this.state.selectedCustomDate,
          },
        },
      };
    }
  };

  renderStatus = () => {
    if (this.state.status == "active") return "running";
    if (this.state.status == "draft" || this.state.status == "disabled")
      return "disabled";
    return "not-active";
  };

  render() {
    const { closeOnEscape, closeOnDimmerClick } = this.state;
    let exitOpen = this.state.exitModalOpen
    const panes = [
      {
        menuItem: "Setup",
        render: () => (
          <Tab.Pane attached={false}>
            <SetupTab {...this.state} updateField={this.updateField} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Design",
        render: () => (
          <Tab.Pane attached={false}>
            <DesignTab
              {...this.state}
              updateField={this.updateField}
              saveTabFormData={() => this.saveTabFormData(1)}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Contacts",
        render: () => (
          <Tab.Pane attached={false}>
            <ContactsTab
              {...this.state}
              updateField={this.updateField}
              userLists={this.props.userLists}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Rules",
        render: () => (
          <Tab.Pane attached={false}>
            <RulesTab
              {...this.state}
              updateField={this.updateField}
              authToken={this.props.authToken}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Activity",
        render: () => (
          <Tab.Pane attached={false} className="activity-table">
            <ActivityTab {...this.state} updateField={this.updateField} />
          </Tab.Pane>
        ),
      },
    ];
    // let automationStatus = this.state.automationValid ? this.state.status : 'disabled'
    return (
      <div className="automation-builder-container">
        <img
          src={CloseButton}
          className="automation-close-button"
          onClick={() => this.closeExitModal()}
        />
        <h2>Automation</h2>
        <div className="popup-buttonwrapper">
          <div className="automation-subtitle">
            <span>
              Current automation:{" "}
              <b> {this.state.title.length ? this.state.title : "Untitled"}</b>
            </span>
            <div className="delete" onClick={this.openDeleteModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="none"
              >
                <path
                  fill="#8B8B8E"
                  d="M9.168 1.328h3.677v.619h1.328V1.24c0-.684-.556-1.241-1.24-1.241H9.08c-.684 0-1.24.557-1.24 1.241v.706h1.327v-.619zm8.072 5.88H4.774a.585.585 0 00-.583.632l1.042 12.887A1.384 1.384 0 006.613 22H15.4c.721 0 1.321-.554 1.38-1.274L17.821 7.84a.585.585 0 00-.582-.632zM7.791 20.625a.664.664 0 01-.704-.622l-.65-10.578a.664.664 0 011.325-.081l.653 10.579a.664.664 0 01-.622.703zm3.886-.662a.664.664 0 01-1.328 0V9.383a.664.664 0 111.328 0v10.58zm3.9-10.54l-.624 10.579a.664.664 0 11-1.325-.078l.623-10.58a.663.663 0 111.326.079zm3.958-4.263L19.1 3.851a.844.844 0 00-.8-.577H3.713a.844.844 0 00-.8.577l-.437 1.307a.547.547 0 00.52.721h16.018a.547.547 0 00.52-.721z"
                />
              </svg>
              <span>Delete this rules</span>
            </div>
          </div>
          <div className="switcher">
            <span>
              This Automation is <b>{this.renderStatus()}</b>
            </span>
            <Radio
              className="green-toggler"
              toggle
              disabled={!this.state.automationValid}
              checked={this.checkedStatus()}
              onChange={(event, data) =>
                this.setState({ status: data.checked ? "active" : "not-active" })
              }
            />
          </div>
        </div>
        <Tab className="popuptabs-list"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
        />

        <div
          className={`button-cover footer ${
            this.state.activeIndex === 3 && "between"
          }`}
        >
          {this.state.activeIndex === 3 && (
            <button
              type="submit"
              className="purple-button disabled"
              onClick={this.closeExitModal}
            >
              Save as a draft and close
            </button>
          )}
          <button
            type="submit"
            className="purple-button"
            onClick={() => this.openExitModal()}
          >
            {this.state.activeIndex === 3
              ? "Run automation and close"
              : "Save and close"}
          </button>
        </div>
        <Modal
          open={exitOpen}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          onClose={this.closeExitModal}
          className="alert-modal"
        >
          <Modal.Content>
            <div className="modal-body">
              <div className="title">Save and close</div>
              <div>
                This automation will be saved as a{" "}
                {this.renderStatus() === "running" ? "active" : "draft"}
              </div>
            </div>
            <div className="modal-footer">
              <button className="blue-button" onClick={this.closeExitModal}>
                Ok
              </button>
            </div>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.deleteModalOpen}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          onClose={this.closeDeleteModal}
          className="alert-modal"
        >
          <Modal.Content>
            <div className="modal-body">
              <div className="title">Delete rules</div>
              <div>Are you sure that you want to delete these rules?</div>
            </div>
            <div className="modal-footer">
              <button
                className="blue-inverted-button"
                onClick={() => {
                  this.closeDeleteModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="blue-button"
                onClick={() => {
                  this.closeDeleteModal(true);
                }}
              >
                Yes, Delete
              </button>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default AutomationBuilder;
