import React from 'react'

export default class CampaignSidebar extends React.Component {
  destroyCampaign(e) {
    e.preventDefault()

    fetch(this.props.campaign.campaign_url, {
      credentials: 'same-origin',
      method: 'DELETE'
    }).then(response => response.json())
      .then(response => window.location.reload())
  }

  deleteCampaign() {
    if(this.props.campaign.deletable)
      return (
        <a className="Button RedButton" onClick={(e) => this.destroyCampaign(e)}>Delete Campaign</a>
      )
  }

  render() {
    const count = this.props.campaign.recipients.reduce((a, c)=> a + 1, 0)
    let buttons
    const state = this.props.campaign.current_state
    if (state != 'Paid' && state != 'In Production' && state != 'In Transit' && state != 'Error' && state != 'Out for Delivery') {
      buttons = <div>
            <a className="Button PurpleButton" href={this.props.campaign.edit_campaign_path}>
              Resume Editing
            </a>
        {this.deleteCampaign()}
      </div>
    }
        return (
      <aside className="PageSidebar InfoSidebar Show">
        <div className="PageSidebarContents">
          <h2>
            Selected Campaign
          </h2>
          {buttons && <h3> Actions </h3>}
          {buttons}
          <div className="Clear"></div>
          <h3>About</h3>
          <div className="Metadata">
            <div className="MetadataLabel">
              Campaign Name
            </div>
            <p>
              {this.props.campaign.name}
            </p>
          </div>
          <div className="Metadata">
            <div className="MetadataLabel">
              Status
            </div>
            <p>
              {this.props.campaign.current_state}
              <span>
                {this.props.campaign.state_description}
              </span>
            </p>
          </div>
          {this.props.campaign.submitted && <div className="Metadata">
            <div className="MetadataLabel">
              Mailing List
            </div>
            <p>
              {this.props.campaign.recipients_count} Recipients
            </p>
          </div>}
          {count != 0 && <div className="Metadata">
            <div className="MetadataLabel">
              Impact
            </div>
            <p>
              Planting {count / 10} new trees
              <span>
                Approx {count / 1000} days employment
              </span>
            </p>
          </div>}
        </div>
      </aside>
    );
  }
}
