import React from 'react';

const SubscriptionTable = () => {
    return (
        <div className='premiumtable-wrapper'>
            <table className="table-premium">
                <tbody><tr className="table-premium-tr">
                    <th className="table-premium-th">Available options</th>
                    <th className="table-premium-th">Basic Plan</th>
                    <th className="table-premium-th">Premium Plan</th>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Design Builder</td>
                    <td className="table-premium-td green">✔</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Basic Integrations</td>
                    <td className="table-premium-td green">✔</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Contact Management</td>
                    <td className="table-premium-td green">✔</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">50% Per Send Discount</td>
                    <td className="table-premium-td red">-</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Advanced Integrations</td>
                    <td className="table-premium-td red">-</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Automation</td>
                    <td className="table-premium-td red">-</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Prospecting</td>
                    <td className="table-premium-td red">-</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Dedicated Manager</td>
                    <td className="table-premium-td red">-</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Training and Onboarding</td>
                    <td className="table-premium-td red">-</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                <tr className="table-premium-tr">
                    <td className="table-premium-td">Done htmlFor You Designs</td>
                    <td className="table-premium-td red">-</td>
                    <td className="table-premium-td green">✔</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SubscriptionTable;
