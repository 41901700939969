import React from 'react';

class MailingListsTable extends React.Component {
  constructor(props) {
    super(props)

    this.checkboxClicked = this.checkboxClicked.bind(this)
    this.renderMailingLists = this.renderMailingLists.bind(this)
  }

  selectAllChanged = (e) => {
    if(e.target.checked == true) {

      let mailingListIds = this.props.mailingLists.map((mailingList) =>
        mailingList.guid
      )

      this.props.selectedMailingLists(mailingListIds)
    } else
      this.props.selectedMailingLists([])
  }

  checkboxClicked(e) {
    let id = e.target.value

    if(e.target.checked == true)
      this.props.selectedMailingLists([id])
    else
      this.props.deselectMailingList(id)
  }

  isSelected(id) {
    return _.includes(this.props.selectedIds, id)
  }

  isAllSelected() {
    return this.props.selectedIds.length == this.props.mailingLists.length && this.props.mailingLists.length > 0
  }

  renderMailingLists() {
    return this.props.mailingLists.map((mailingList) =>
      <tr key={'mailing_list_tr_' + mailingList.id}>
        <td className="ToggleContainer">
          <input value={mailingList.guid} type="checkbox" onChange={(e) => this.checkboxClicked(e)} checked={this.isSelected(mailingList.guid)} name={'mailing_list_' + mailingList.id} id={'mailing_list_' + mailingList.id} />
          <label className="CheckboxButton" htmlFor={'mailing_list_' + mailingList.id}></label>
        </td>
        <td className="LinkedName">
          <a href="/lists/">{mailingList.name}</a>
        </td>
        <td>
          {mailingList.no_of_recipients}
        </td>
        <td>
          {mailingList.last_update}
        </td>
      </tr>
    )
  }

  render() {
    return (
      <table className="InternalTable RecipientLists Hoverable">
        <tbody>
          <tr key="mailing_list_tr_th">
            <th className="ToggleContainer">
              <input
                checked={this.isAllSelected()}
                ref="selectAll"
                type="checkbox"
                onChange={(e) => this.selectAllChanged(e)}
                name="SelectAll"
                id="SelectAll"
                className="MailingList" />
              <label className="CheckboxButton" htmlFor="SelectAll"></label>
            </th>
            <th> List Name </th>
            <th> List Size </th>
            <th> Last Updated </th>
          </tr>
          {this.renderMailingLists()}
        </tbody>
      </table>
    );
  }
}

export default MailingListsTable