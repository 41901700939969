import React from "react";
import RuleCard from "./RuleCard";
import AutomationBuilder from "./AutomationBuilder";
import { Modal } from "semantic-ui-react";
import { getData, postData } from "../../lib/fetches";


class AutomationsPremium extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      modalIsOpen: false,
      selectedAutomation: undefined,
      automations: this.props.automations,
      exitModalOpen: false,
      activeTab: 0,
    };
  }

  componentDidMount() {
    const { hash } = window.location;
    if (hash) {
      const parts = hash.split("#");
      const ruleGuid = parts[1];
      if (parts[2]) {
        this.setState({
          activeTab: 1,
        });
      }
      this.openModal(ruleGuid);
    }
  }

  handleExitModalOpen = (type) => {
    this.setState({ exitModalOpen: type });
  };

  componentDidUpdate() {
    if (!this.state.modalIsOpen) {
      window.location.hash = "";
    }
  }

  openModal = (automationId = undefined) => {
    if (automationId) {
      let automation = this.state.automations.find(
        (item) => item.guid == automationId
      );
      if (automation){
        this.setState({ selectedAutomation: automation, modalIsOpen: true });
        window.location.hash = automationId;
      } else {
        window.location.hash = '';
      }
    } else {
      this.createRule();
    }
  };

  closeModal = (automationId = undefined) => {
    if (automationId) {
      this.setState({
        automations: this.state.automations.filter(
          (item) => item.guid !== automationId
        ),
        modalIsOpen: false,
        activeTab: 0,
      });
    } else {
      this.updateAutomationsList();
    }
  };

  updateAutomationStatus = (guid, status) => {
    let automationIndex = this.state.automations.findIndex(
      (item) => item.guid == guid
    );
    let newAutomations = this.state.automations;
    newAutomations[automationIndex].status = status;
    this.setState({
      automations: newAutomations,
    });
  };

  updateAutomationsList = () => {
    if(this.state.selectedAutomation == null) return
    getData(
      `/automations/${this.state.selectedAutomation.guid}`,
      this.props.authToken,
      (res) => {
        let automations = this.state.automations;
        let changed = false;
        let newAutomations = automations.map((i) => {
          if (i.id == res.id) {
            changed = true;
            return res;
          } else {
            return i;
          }
        });
        if (!changed) {
          newAutomations.unshift(res);
        }
        this.setState({
          automations: newAutomations,
          selectedAutomation: undefined,
          activeTab: 0,
        }, () => {
          setTimeout(() => {
            this.setState({ modalIsOpen: false });
          }, 1500);
        });
      }
    );
  };

  createRule = () => {
    postData(
      "/automations",
      this.props.authToken,
      {},
      (res) => {
        this.setState({ selectedAutomation: res, modalIsOpen: true });
      }
    );
  };

  render() {
    const { openSkillIndex, closeOnEscape, closeOnDimmerClick } = this.state;

    // count will be from props this.props.count
    const count = 100;

    return (
      <div>
        <div className="Content PageContent FlexContent">
          <main className="Automation">
            <header className="PageHeader">
              <div className="iconhedaer">
                <span className="icon">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.8125 3.45312H7.91406C8.26562 3.45312 8.53906 3.72656 8.53906 4.07812V8.17969C8.53906 8.72656 7.875 9.03906 7.48438 8.60938L6.27344 7.4375C5.33594 8.53125 4.78906 9.9375 4.78906 11.5C4.78906 11.9297 4.82812 12.3594 4.90625 12.75H2.36719C2.32812 12.3594 2.28906 11.9297 2.28906 11.5C2.28906 9.27344 3.10938 7.20312 4.51562 5.67969L3.34375 4.50781C2.95312 4.11719 3.22656 3.45312 3.8125 3.45312ZM14.5938 14.5078L15.7266 15.6406C16.7031 14.5078 17.2891 13.0625 17.2891 11.5C17.2891 11.0703 17.25 10.6797 17.1719 10.25H19.6719C19.75 10.6797 19.7891 11.0703 19.7891 11.5C19.7891 13.7656 18.8906 15.8359 17.4844 17.3984L18.6953 18.6094C19.0859 19 18.8125 19.6641 18.2656 19.6641H14.125C13.8125 19.6641 13.5 19.3906 13.5 19.0391V14.9375C13.5 14.3906 14.2031 14.1172 14.5938 14.5078Z" fill="#051237" fill-opacity="0.6"/>
                    <path d="M2.36719 12.75H4.90625H2.36719ZM11.0391 5.25C10.5703 5.25 10.1406 5.28906 9.75 5.36719V2.82812C10.1406 2.78906 10.5703 2.75 11.0391 2.75C13.3047 2.75 15.375 3.60938 16.9375 5.05469L18.0703 3.88281C18.4609 3.49219 19.125 3.76562 19.125 4.35156V8.45312C19.125 8.80469 18.8516 9.07812 18.5 9.07812H14.3984C13.8516 9.07812 13.5781 8.41406 13.9688 8.02344L15.1406 6.8125C14.0469 5.83594 12.6016 5.25 11.0391 5.25ZM8.10938 15.1328L6.97656 16.2656C8.07031 17.1641 9.47656 17.75 11.0391 17.75C11.4297 17.75 11.8594 17.7109 12.25 17.6328V20.1719C11.8594 20.2109 11.4297 20.25 11.0391 20.25C8.77344 20.25 6.74219 19.3906 5.17969 18.0234L3.96875 19.2344C3.57812 19.625 2.91406 19.3516 2.91406 18.8047V14.6641C2.91406 14.3516 3.1875 14.0391 3.53906 14.0391H7.64062C8.22656 14.0391 8.5 14.7422 8.10938 15.1328Z" fill="#051237" fill-opacity="0.24"/>
                  </svg>
                </span>
                <div className="headerheading">
                  <div className="AutomationHeader">
                    <h1>Automation</h1>
                    <span>Premium</span>
                  </div>
                  <div className="Clear"></div>
                  <div className="AutomationDescription premium-automation-description">
                    Mailfold stays in sync with your connected marketplace or CRM
                    and automatically sends mail when new customers match rules
                    you’ve set up. To get you started we’ve added 2 popular
                    automations you can enable or customize.{" "}
                    {/* <a className="black-link">Learn more</a> */}
                  </div>
                </div>
              </div>
            </header>
            <div className="Clear"></div>
            <div className="automation-rules-list">            
              {this.state.automations.map((automation) => {
                return (
                  <RuleCard
                    openAutomationModal={this.openModal}
                    automation={automation}
                    authToken={this.props.authToken}
                    updateStatus={this.updateAutomationStatus}
                  />
                );
              })}
              {/* <div className="automation-card">
                <div className="automation-card-header">
                  <h4>Untitled Automation</h4>
                </div>
                <div className="automation-card-body">
                  <div className="automation-card-img-wrapper">
                    <div className="automation-card-img">
                      <span>Front image</span>
                    </div>
                    <div className="automation-card-img">
                      <span>Back image</span>
                    </div>
                  </div>
                  <div className="automation-card-toggle">
                    <span>This rule is disabled</span>
                    <label class="switch">
                      <input type="checkbox"></input>
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div className="automation-card-textarea">
                    <textarea placeholder="Mail to:"></textarea>
                  </div>
                  <div className="automation-card-button">
                    <button>View rules</button>
                  </div>
                </div>
              </div>
              <div className="automation-card active">
                <div className="automation-card-header">
                  <h4>NIIT Technologies Limited</h4>
                </div>
                <div className="automation-card-body">
                  <div className="automation-card-img-wrapper">
                    <div className="automation-card-img">
                      <span>Front image</span>
                    </div>
                    <div className="automation-card-img">
                      <span>Back image</span>
                    </div>
                  </div>
                  <div className="automation-card-toggle">
                    <span>This rule is disabled</span>
                    <label class="switch">
                      <input type="checkbox" checked></input>
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div className="automation-card-textarea">
                    <textarea placeholder="Mail to:"></textarea>
                  </div>
                  <div className="automation-card-button">
                    <button>View rules</button>
                  </div>
                </div>
              </div>
              <div className="automation-card">
                <div className="automation-card-header">
                  <h4>Untitled Automation</h4>
                </div>
                <div className="automation-card-body">
                  <div className="automation-card-img-wrapper">
                    <div className="automation-card-img">
                      <span>Front image</span>
                    </div>
                    <div className="automation-card-img">
                      <span>Back image</span>
                    </div>
                  </div>
                  <div className="automation-card-toggle">
                    <span>This rule is disabled</span>
                    <label class="switch">
                      <input type="checkbox"></input>
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div className="automation-card-textarea">
                    <textarea placeholder="Mail to:"></textarea>
                  </div>
                  <div className="automation-card-button">
                    <button>View rules</button>
                  </div>
                </div>
              </div> */}
            </div>
          </main>
          <div className="AutomationSidebar">
            <div className="SidebarBody">
              <button
                className="green-button margin-bottom-s"
                onClick={() => {
                  this.openModal();
                }}
              >
                Create New Rule
              </button>
              <div className="balance-block">
                <p>Your account balance:</p>
                <div className="balance-value">
                  <span className="icon-Mailfold-coin-full-currency"></span>
                  {` ${
                    this.props.user.balance === 0
                      ? "0.00"
                      : parseFloat(this.props.user.balance).toFixed(2)
                  }`}
                </div>
                <div className="margin-top-xs">
                  Based on the automation rules you have provided,
                  <b>
                    {" "}
                    {this.props.scheduled_activities.amount} postcards
                  </b>{" "}
                  were scheduled this week.{" "}
                  {this.props.scheduled_activities.price >
                  this.props.user.balance
                    ? "It’s not enough money on your account balance to continue it"
                    : ""}
                </div>
                <button
                  className="to-replenish-link green-bordered-button margin-top-s"
                  onClick={() => {
                     window.location.href = "settings/?tab=balance";
                  }}
                >
                  Replenish my balance
                </button>
               
              </div>
            </div>
            <div className="AutomationSidebarBottom automations">
              <p>
                Your business is responsible for planting {count / 10} new trees
                with {count / 1000} days worth of employment.
                <div className="Clear"></div>
                {/* <a href="#">Learn more</a> */}
              </p>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.modalIsOpen}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.setState({ exitModalOpen: true })}
          className="builder-modal"
        >
          <Modal.Content>
            <AutomationBuilder
              automation={this.state.selectedAutomation}
              closeAutomationModal={this.closeModal}
              countries={this.props.countries}
              states={this.props.states}
              userLists={this.props.user_lists}
              authToken={this.props.authToken}
              activeTab={this.state.activeTab}
              exitModalOpen={this.state.exitModalOpen}
              handleExitModalOpen={this.handleExitModalOpen}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default AutomationsPremium;
