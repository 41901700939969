import React from 'react'

export default class ThankYou extends React.Component {
  state = {
    visible: true
  }

  onClose() {
    this.setState({ visible: false })
  }

  render() {
    return (
      <If condition={this.state.visible}>
        <div className="Modal">
          <div className="ModalWindow">
            <div className="Illustration" id="Thanks">
              <div className="GreenTick"></div>
            </div>
            <div className="ModalContent">
              <div className="Title">
                Great Job.
              </div>
              <div className="Body">
                Your campaign was created and is ready to be processed. We will email you when your postcards are out for delivery.
              </div>
            </div>
            <div className="ModalActions">
              <div className="Buttons">
                <div onClick={this.onClose.bind(this)} className="Button PrimaryAction">
                  Okay
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ModalBackground"></div>
      </If>
    )
  }
}
