import React, { Fragment } from "react";

import { Modal } from "semantic-ui-react";

function ListInUseModal(props) {
  const names = props.campaigns.map(campaign =>  campaign.name).join(", ").split(", ");
  const price = props.campaigns.reduce((total, obj) => obj.postcard_price + total,0)

  return (
    <Fragment>
    <div className="contacts-segment-container contacts-segment-container-campaigns_list">
      <div className="contacts-segment-body list-modal">
          <h3 className="segment-modal-title">
            The selected list is already in use for some campaigns
          </h3>
          <h4 className="segment-modal-small-subtitle segment-modal-small-subtitle-list">
            The list that you selected is currently in use for the following In Progress Campaigns:
            <ul className="modal-campaigns-list">
              {names.map((item) => {
                return <li className="modal-campaigns-list-item"> {item} </li>
              })}
            </ul>
            <p>
              { (props.user.balance < price) ?
                `Your current balance is $${props.user.balance.toFixed(2)}, but the cost of sending the postcards from active campaigns is $${price.toFixed(2)}.
                Please replenish your balance to add this contact to the list.`
                :
              `If you wish to send current mails to this contact, we will charge $${price.toFixed(2)} from your balance (the cost of sending the postcards).`
              }
            </p>
            { (props.user.balance < price) &&
              <a href="/settings?tab=balance" className="green-button">Go to Replenish balance page</a>
            }
          </h4>
          <div className="contacts-segment-modal-footer" >
              <div className="grid grid-2 segment-modal-buttons">
                <div className="grid-item">
                  <button
                    className="gray-button"
                    onClick={() => props.handleCloseModal()}
                  >
                    Select another list
                  </button>
                </div>
                <div className="grid-item">
                  <button className="blue-button"
                    onClick={() => props.acceptAdding()}>
                      { (props.user.balance < price) ? "Replenish and Accept" : "Accept"}
                  </button>
                </div>
              </div>
          </div>
      </div>
    </div>
    </Fragment>
  );
}

export default ListInUseModal;
