import React, { useState, useRef } from 'react';
import { Tab } from 'semantic-ui-react'
import ProspectingMap from "./ProspectingMap";
import ProspectingDemography from "./ProspectingDemography";
import { useEffect } from 'react';

const initialIndividuals = {
  HouseholdIncome: {},
  ContactAge: {},
  OwnerRenter: {},
  MartialStatus: {},
  LengthOfResidency: {},
  AgeCode: {},
  NumberOfAllPeople: {},
  NumberOfAdults: {},
  NumberOfChildren: {},
  CreditCard: {},
  Mail: {},
  NetWorth: {},
  HomeValuation: {},
  Gender: {
    "M": "Male",
    "F": "Female"
  }
};

const initialBusiness = {
  NumberOfEmployees: {},
  AnnualSales: {}
}

const ProspectingTabs = (props) => {
  const [targetValue, setTargetValue] = useState(props.targetValue);

  // const [individualsSubValue, setIndividualsSubValue] = useState("");

  const [IndividualsDemographic, setIndividualsDemographic] = useState(initialIndividuals)

  const [BusinessesDemographic, setBusinessesDemographic] = useState(initialBusiness)

  const [DisableSendindBtn, setDisableSendindBtn] = useState(true);

  useEffect(() => {

    if (Object.keys(props.IndividualsDemographic).length) {
      setIndividualsDemographic(props.IndividualsDemographic);
    } else {
      setIndividualsDemographic(initialIndividuals);
    }
    if (Object.keys(props.BusinessesDemographic).length) {
      setBusinessesDemographic(props.BusinessesDemographic)
    } else {
      setBusinessesDemographic(initialBusiness);
    }

  }, [props.IndividualsDemographic, props.BusinessesDemographic]);

  function setDemographyFilter (e, option) {

    let tagetValue = props.targetValue === "individuals" ? "IndividualsDemographic" : "BusinessesDemographic";
    let valuesObj = tagetValue === "IndividualsDemographic" ? IndividualsDemographic[e.target.name] : BusinessesDemographic[e.target.name];

    if(e.target.name === "Gender") {
      if(e.target.checked){
          setIndividualsDemographic({...IndividualsDemographic, [e.target.name]: {...IndividualsDemographic[e.target.name],
            [e.target.value] : e.target.id
          }})
      } else {
        if(Object.keys(IndividualsDemographic[e.target.name]).length > 1) {
          delete valuesObj[e.target.value]
          setIndividualsDemographic({...IndividualsDemographic, [e.target.name]: valuesObj});
        }
      }
    } else {

      if(valuesObj[e.target.value] !== undefined) {
        let newObj = valuesObj;
        delete newObj[e.target.value]
        tagetValue === "IndividualsDemographic" ? setIndividualsDemographic({...IndividualsDemographic, [e.target.name]: newObj}) : setBusinessesDemographic({...BusinessesDemographic, [e.target.name]: newObj})
      } else {
        tagetValue === "IndividualsDemographic" ? setIndividualsDemographic({...IndividualsDemographic, [e.target.name]: {...IndividualsDemographic[e.target.name],
          [e.target.value] : option
        }}) : setBusinessesDemographic({...BusinessesDemographic, [e.target.name]: {...BusinessesDemographic[e.target.name],
          [e.target.value] : option
        }});
      }
    }
    changeDisabledBtn();
  }

  function addDemographyFilter () {
    props.addDemographyFilter(IndividualsDemographic, BusinessesDemographic);
  }

  function changeTargetValue(value) {
    setTargetValue(value);
    props.targetChoice(value);
  }

  // function changeSubIndividualValue(value) {
  //   setIndividualsSubValue(value);
  //   props.individualsSubChoice(value);
  // }

  function changeDisabledBtn () {
    let defaultIndividualsDemographic = {
      HouseholdIncome: [],
      ContactAge: [],
      OwnerRenter: [],
      MartialStatus: [],
      LengthOfResidency: [],
      AgeCode: [],
      NumberOfAllPeople: [],
      NumberOfAdults: [],
      NumberOfChildren: [],
      CreditCard: [],
      Mail: [],
      NetWorth: [],
      HomeValuation: [],
      Gender: ["M", "F"]
     };

     JSON.stringify(defaultIndividualsDemographic) !== JSON.stringify(IndividualsDemographic) ? setDisableSendindBtn(false) : setDisableSendindBtn(true);
  }

  const panes = [

    {
      menuItem: 'Mailing list',
      render: () => <Tab.Pane>
        <div className="prospecting-tabs-content">
          <span className="title">Who do you want to target?</span>
          <div className="prospecting-tabs-content-option">

            <label className="container" onClick={() => { changeTargetValue("individuals")
             props.deleteBusinessesItems()}} htmlFor="individuals">
              Individuals
              <input type="radio" value="individuals" checked={targetValue === "individuals"} name="amount" id="individuals"></input>
              <span className="checkmark"></span>
            </label>
{/* 
            {targetValue === "individuals" &&
               <div className="prospecting-tabs-content-option-list">

               <label className="container" htmlFor="allIndividuals">
                 All individuals
                 <input type="radio" value="allIndividuals" checked={props.individualsSubValue === "allIndividuals"} name="sublist" id="allIndividuals" onClick={() => changeSubIndividualValue("allIndividuals")}></input>
                 <span className="checkmark"></span>
               </label>

               <label className="container" htmlFor="indNewHhomeowners">
                 Only new homeowners
                 <input type="radio" value="indNewHhomeowners" checked={props.individualsSubValue === "indNewHhomeowners"} name="sublist" id="indNewHhomeowners" onClick={() => changeSubIndividualValue("indNewHhomeowners")}></input>
                 <span className="checkmark"></span>
               </label>

               <label className="container" htmlFor="indNewMovers">
                 Only new movers
                 <input type="radio" value="indNewMovers" checked={props.individualsSubValue === "indNewMovers"} name="sublist" id="indNewMovers" onClick={() => changeSubIndividualValue("indNewMovers")}></input>
                 <span className="checkmark"></span>
               </label>

             </div>
            } */}

          </div>

            <div className="prospecting-tabs-content-option">

              <label className="container" onClick={() => {changeTargetValue("businesses")
              props.deleteIndividualItems()}} htmlFor="businesses">
                Businesses
                <input type="radio" checked={targetValue === "businesses"} value="businesses" name="amount" id="businesses"></input>
                <span className="checkmark"></span>
              </label>

            </div>
        </div>
      </Tab.Pane>,
    },
    {
      menuItem: 'Geography',
      render: () => <Tab.Pane>
        <ProspectingMap
          updateLocationValue={props.updateLocationValue}
          locationValue={props.locationValue}
          updateRadiusValue={props.updateRadiusValue}
          updateRecordsAmount={props.updateRecordsAmount}
          recipiensNumber={props.recipiensNumber}
          showRecordsInput={props.showRecordsInput}
          showRadiusInput={props.showRadiusInput}
          loading={props.loading}
          viewport={props.viewport}
          handleViewportChange={props.handleViewportChange}
          clearGeocoder={props.clearGeocoder}
        />
      </Tab.Pane>,
    },
    {
      menuItem: 'Demography',
      render: () => <Tab.Pane>
        <ProspectingDemography addDemographyFilter={addDemographyFilter} targetValue={props.targetValue} setDemographyFilter={setDemographyFilter} IndividualsDemographic={IndividualsDemographic} BusinessesDemographic={BusinessesDemographic} DisableSendindBtn={DisableSendindBtn}/>
      </Tab.Pane>,
    },
]

    return (
        <div className="prospecting-tabs">
            <Tab menu={{ secondary: false }} panes={panes} />
        </div>
    );
};

export default ProspectingTabs;
