import React from "react";
import PropTypes from "prop-types";

export default class CardOption extends React.Component {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    size: PropTypes.instanceOf(Option).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  size() {
    return this.props.size.id();
  }

  id() {
    return "card" + this.size();
  }

  width() {
    return this.props.size.widthInInches;
  }

  height() {
    return this.props.size.heightInInches;
  }

  price() {
    return this.props.size.price;
  }

  sizeStr(w) {
    if (w == 6) return "Small";
    else if (w == 9) return "Medium";
    return "Large";
  }

  render() {
    return (
      <div className="RadioRow">
        <div className="label-cover">
          <input
            id={this.id()}
            type="radio"
            value={this.size()}
            checked={this.props.isSelected}
            name="campaign[postcard_size]"
            onChange={this.props.onChange}
          />
          <label className="RadioButton" htmlFor={this.id()}></label>

          <label htmlFor={this.id()}>
            {this.height() === 8 ? 8.5 : this.height()}&prime; × {this.width()}
            &prime; {this.size() === "8.5x11" ? "Letter" : "Postcard"}
            <span className="Description">
              This will be <span className="icon-Mailfold-coin"></span>
              {this.price()} per{" "}
              {this.size() === "8.5x11" ? "Letter" : "Postcard"}
            </span>
          </label>
        </div>
      </div>
    );
  }
}
