import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import closeIcon from "images/blue-close-icon.svg";
import ProspectingSelections from "./ProspectingSelections"

const prospectingPrice = props => {

    const [price, setPrice] = useState(props.price);

    const [records, setRecords] = useState(props.records);

    const [numberValid, setNumberValid] = useState(true);

    useEffect(()=> {
      setPrice(props.price);
      setRecords(props.records)
    }, [props.price, props.records]);

    return (
        <div className="prospecting-price-container">
            <div className="prospecting-price-top-block">

                <div className="prospecting-price-top-block-row prospecting-price-top-block-row-records">
                    <span className="option"># Records:</span>
                    <span className="number">{records}</span>
                </div>

                <div className="prospecting-price-top-block-row prospecting-price-top-block-row-price">
                    <span className="option">Calc price:</span>
                    <span className="price">${price}</span>
                </div>

            </div>

            <div className="prospecting-selects">
                <span className="title">Your selections</span>
                <ProspectingSelections stateInfo={props.stateInfo} deleteSingleItem={props.deleteSingleItem} clearLocationValue={props.clearLocationValue} targetValue={props.targetValue}/>
            </div>
            <button className="clear-btn" onClick={() => {props.deleteAllItems()}}>
                <span><img src={closeIcon}></img></span>
                Clear All Selections
            </button>
        </div>
    );
};
export default prospectingPrice;
