const scaleUp = true;
const DPI = scaleUp ? 150 : 300;
const MULTIPLIER = 2;
const svgFonts = " <style type='text/css'>@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Arvo:400,700|Barlow|Bitter|Bree+Serif|Catamaran|Domine|Hind+Siliguri|Homemade+Apple|Kalam|Lobster|Lora|Montserrat:400,700|Mr+Dafoe|Pacifico|Patua+One|Permanent+Marker|Poppins|Questrial|Sacramento|Yantramanav|Zilla+Slab|Lato:400,700|Assistant:400,700|Arimo:400,700|Vollkorn:400,700|Yrsa:400,700|Ultra|Germania+One|Philosopher:400,700|Work+Sans:400,700|Viga:400,700|Alegreya+Sans:400,700|Eczar:400,700|Neuton:400,700')</style>";
export class QRDesignUtils {

   static isLetter(cardSize) {
      return cardSize === "8.5x11";
   }

   static getValueFromStorage(prop, defaultValue) {
      const showBleed =
          JSON.parse(localStorage.getItem(prop)) == null
              ? defaultValue
              : JSON.parse(localStorage.getItem(prop));
      return showBleed
   }

}

export class QRCanvasUtils {

   static getConstants() {
      return {
         scaleUp,
         DPI,
         MULTIPLIER,
         svgFonts
      }
   }

   static getDerivedValuesFromProps({ cardSize: size, cardType: type, mailer: mail, templateSide: tmplSide }) {
      const isLetter = QRDesignUtils.isLetter(size);
      const cardSize = size || "4x6";
      const cardType = type || "";
      const mailer = mail || {};
      const templateSide = tmplSide || "front";
      const bleedSize = 0.25;
      const height =
          (parseFloat(cardSize.split("x")[isLetter ? 1 : 0]) +
              (isLetter ? 0 : bleedSize)) *
          (isLetter ? 96 : DPI);
      const width =
          (parseFloat(cardSize.split("x")[isLetter ? 0 : 1]) +
              (isLetter ? 0 : bleedSize)) *
          (isLetter ? 96 : DPI);

      const showInkFreeZone = QRDesignUtils.getValueFromStorage("showInkFreeZone", true);

      return {
         height,
         width,
         showInkFreeZone,
         isLetter,
         cardSize,
         cardType,
         mailer,
         templateSide,
      }
   }
}

const xml_special_to_escaped_one_map = {
   '&': '&amp;',
   '"': '&quot;',
   '<': '&lt;',
   '>': '&gt;'
};

const escaped_one_to_xml_special_map = {
   '&amp;': '&',
   '&quot;': '"',
   '&lt;': '<',
   '&gt;': '>'
};

export function encodeXml(string) {
   return string.replace(/([\&"<>])/g, function (str, item) {

      if (item !== "&") {
         return item;
      }
      return xml_special_to_escaped_one_map[item];
   });
};

export function decodeXml(string) {
   return string.replace(/(&quot;|&lt;|&gt;|&amp;)/g,
       function (str, item) {
          return escaped_one_to_xml_special_map[item];
       });
}

export function isQRcodeObject(object) {
   return object?.sourcePath === "qrcode.svg";
}

export const getBase64FromUrl = async (url) => {
   const data = await fetch(url);
   const blob = await data.blob();
   return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
         const base64data = reader.result;
         resolve(base64data);
      }
   });
}


export function contrast(hex1, hex2) {
   const rgb1 = hexToRgb(hex1);
   const rgb2 = hexToRgb(hex2);
   var lum1 = luminance(rgb1.r, rgb1.g, rgb1.b);
   var lum2 = luminance(rgb2.r, rgb2.g, rgb2.b);
   var brightest = Math.max(lum1, lum2);
   var darkest = Math.min(lum1, lum2);
   return (brightest + 0.05)
       / (darkest + 0.05);
}

function luminance(r, g, b) {
   var a = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928
          ? v / 12.92
          : Math.pow((v + 0.055) / 1.055, 2.4);
   });
   return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function hexToRgb(hex) {
   // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
   var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
   hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
   });

   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
   return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
   } : null;
}

// contrast([255, 255, 255], [255, 255, 0]); // 1.074 for yellow
// contrast([255, 255, 255], [0, 0, 255]); // 8.592 for blue
// // minimal recommended contrast ratio is 4.5, or 3 for larger font-sizes