import React from "react";
import { Dropdown, Modal } from 'semantic-ui-react'
import TrashIcon from "../../../assets/images/trash-icon.svg";
import PropTypes from "prop-types";
import TrayImages from "./TrayImages";
import { putData } from "../../lib/fetches";
import QRGenerator from "./QRGenerator";

class TollTrayModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Modal
           open={this.props.showModal}
           closeOnEscape={false}
           closeOnDimmerClick={true}
           className="designer-segment-modal"
         >
           <div className="design-modal">
             <h2 className="agency-blog-modal-title">Delete template?</h2>
             <p className="agency-blog-modal-text">Are you sure that you want to delete the template?</p>

            <div className="design-modal-bottom">
              <button onClick={() => this.props.handleModal(false)} className="green-bordered-button max-wd-unset">Cancel</button>
              <button className="green-bordered-button max-wd-unset" onClick={()=> this.props.fetchDeleteTemplate()}>Yes, Delete</button>
            </div>
           </div>
         </Modal>
    )
  }
}
class ToolTray extends React.Component {
  constructor(props) {
    super(props);
    const dropdownOptions = Object.keys(this.props.templates).map((i) => {
      if(i == ""){
        return { key: "", text: "Other", value: "" }
      } else {
        return { key: i, text: i, value: i }
      }
    });
    if(dropdownOptions.length) {
      dropdownOptions.sort((a, b) => {
        if(a.text < b.text) { return -1; }
        if(a.text > b.text) { return 1; }
        return 0;
      })
    };
    const selectedDropdownCategory = dropdownOptions?.length > 0 ? dropdownOptions[0].value : "";
    
    this.state = {
      handleModal: false,
      selectedPersonalization: null,
      templates: this.props.templates,
      selectedTemplateId: null,
      dropdownOptions,
      selectedDropdownCategory,
    };
    this.setDropdownCategory = this.setDropdownCategory.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleModal(value, id=null) {
    this.setState({
      selectedTemplateId: id,
      handleModal: value
    })
  }

  templates() {
    let categoryTemplates = this.state.templates[this.state.selectedDropdownCategory]
    if (!categoryTemplates) return [];
    return categoryTemplates.map((template) => {
      let imgUrl = encodeURI(
        template.preview_url +
          "&w=540&h=" +
          (this.props.parentState.cardSize == "6x11" ? "294" : "360") +
          "&fit=crop"
      );
      let cssClasses = `TrayOption TemplateOption Size${this.props.parentState.cardSize}`;
      return (
        <div
          className={cssClasses}
          key={template.id}
          onClick={() => {
            this.props.fromJson(template.json_data);
          }}
          title={template.name}
        >
          {imgUrl.indexOf("svg") !== -1 ? (
            <object
              data={imgUrl}
              className={cssClasses}
              width={162}
              type="image/svg+xml"
              style={{ pointerEvents: "none" }}
            ></object>
          ) : (
            <img src={imgUrl} alt="template-image" />
          )}
          {template.private &&
            <button onClick={() => this.handleModal(true, template.id)} className="TrayOption-delete"><img src={TrashIcon}></img></button>
          }
        </div>
      );
    }, this);
  }

  graphics() {
    return this.props.graphics.map((graphic) => {
      let objects = JSON.parse(graphic.json_data);
      let imgUrl = encodeURI(graphic.preview_url + "&w=125&h=125");

      return (
        <div
          className="TrayOption ImageOption"
          key={graphic.id}
          style={{
            backgroundImage: "url(" + imgUrl + ")",
            backgroundSize: "cover",
          }}
          onClick={() => {
            this.props.loadObjects(objects.objects);
          }}
          title={graphic.name}
        ></div>
      );
    }, this);
  }

  texts() {
    return this.props.texts.map((text) => {
      let objects = JSON.parse(text.json_data);
      let imgUrl = encodeURI(text.preview_url + "&w=125&h=125");

      return (
        <div
          className="TrayOption ImageOption"
          key={text.id}
          style={{
            backgroundImage: "url(" + imgUrl + ")",
            backgroundSize: "cover",
          }}
          onClick={() => {
            this.props.loadObjects(objects.objects);
          }}
          title={text.name}
        ></div>
      );
    }, this);
  }

  isActive(tray) {
    return this.props.activeTray == tray;
  }

  setDropdownCategory(value) {
    this.setState({selectedDropdownCategory: value})
  }

  fetchDeleteTemplate = () => {
    if(this.state.selectedTemplateId){
      let newTemplates = this.state.templates;
      Object.keys(newTemplates).forEach((key) => {
        newTemplates[key] = newTemplates[key].filter(i => i.id != this.state.selectedTemplateId)
      });
      putData(`/design_elements/${this.state.selectedTemplateId}/delete_saved_template`, this.props.authToken,
              {}, (res) => {
                this.setState({
                  templates: newTemplates,
                  handleModal: false,
                  selectedTemplateId: null
                })
              });
    }
  }

  render() {
    return (
      <aside className="ToolTray">
        <div className="TrayContents">
          <If condition={this.isActive("Template")}>
            <h2>Templates</h2>
            <p>
              Start with a blank canvas or customize one of our templates.
              Careful, this replaces what you have on the canvas.
            </p>
            <div className="canvas-dropdown">
              <Dropdown
                placeholder='Select Category'
                fluid
                selection
                value={this.state.selectedDropdownCategory}
                onChange={(e, item)=> this.setDropdownCategory(item.value)}
                options={this.state.dropdownOptions}
              />
            </div>
            <h3>Designs</h3>
            <div className="TrayOptions">
              <div
                className="TrayOption TemplateOption ClearBackground"
                key="blackTemplate"
                onClick={() => {
                  this.props.clearScreen();
                }}
                title="Black Template"
              ></div>
              {this.templates()}
            </div>
          </If>

          <If condition={this.isActive("Image")}>
            <TrayImages
              key="trayPhotos"
              type="Photo"
              uploadImage={this.props.uploadImage}
              images={this.props.photos}
              setter={this.props.addImage}
            />
          </If>

          <If condition={this.isActive("Background")}>
            <TrayImages
              key="trayBackgrounds"
              type="Background"
              uploadImage={this.props.uploadImage}
              images={this.props.backgrounds}
              setter={this.props.setBackground}
            />
          </If>

          <If condition={this.isActive("Shape")}>
            <div>
              <h2>Shapes</h2>
              <p>Select a shape to drop into your canvas.</p>
              <h3>Basic Shapes</h3>
              <div className="TrayOptions">
                <div
                  onClick={() => {
                    this.props.addShape("line");
                  }}
                  className="TrayOption LineShape"
                ></div>
                <div
                  onClick={() => {
                    this.props.addShape("square");
                  }}
                  className="TrayOption RectShape"
                ></div>
                <div
                  onClick={() => {
                    this.props.addShape("circle");
                  }}
                  className="TrayOption OvalShape"
                ></div>
                <div
                  onClick={() => {
                    this.props.addShape("starpolygon");
                  }}
                  className="TrayOption StarShape"
                ></div>
                <div
                  onClick={() => {
                    this.props.addShape("triangle");
                  }}
                  className="TrayOption TriangleShape"
                ></div>
                <div
                  onClick={() => {
                    this.props.addShape("polygon");
                  }}
                  className="TrayOption PolygonShape"
                ></div>
                {this.graphics()}
              </div>
            </div>
          </If>

          <If condition={this.isActive("Text")}>{this.texts()}</If>
          <If condition={this.isActive("Personalize")}>
            <div>
              <h2>Personalize</h2>
              <p>
                You can add custom field and apply to each your customer,
                sticking to your list of customers
              </p>
              <div className="TrayOptions">
                <select
                  onChange={(e) => {
                    this.setState({ selectedPersonalization: e.target.value });
                  }}
                >
                  <option value="" selected disabled hidden>
                    Choose field
                  </option>
                  <option value="firstName">First Name</option>
                  <option value="lastName">Last Name</option>
                  <option value="fullName">Full Name</option>
                  <option value="city">City</option>
                  <option value="state">State</option>
                  {this.props.custom_fields &&
                    this.props.custom_fields.map((field) => {
                      return <option value={field.name}>{field.name}</option>;
                    })}
                </select>
              </div>
              {this.state.selectedPersonalization && (
                <>
                  <p>
                    Paste generated code into your text or add personalization
                    object
                  </p>
                  <input
                    type="text"
                    value={`{{${this.state.selectedPersonalization}}}`}
                    id="myInput"
                  />
                  <button
                    className="green-bordered-button margin-top-s"
                    onClick={() =>
                      this.props.addText(
                        `{{${this.state.selectedPersonalization}}}`
                      )
                    }
                  >
                    + Add Personalization
                  </button>
                </>
              )}
            </div>
          </If>

        {/*  !Test */}
{/*
          <If condition={this.isActive("Add QR")}>
            <QRGenerator onQRTrigger={ this.props.addDragNDropQRCode.bind(this) } test={4}/>
          </If> */}

        </div>
        <TollTrayModal
          showModal={this.state.handleModal}
          fetchDeleteTemplate={this.fetchDeleteTemplate}
          handleModal={this.handleModal}
        />
      </aside>
    );
  }
}

ToolTray.propTypes = {
  templates: PropTypes.array,
  photos: PropTypes.array,
  backgrounds: PropTypes.array,
  graphics: PropTypes.array,
  texts: PropTypes.array,
  activeTray: PropTypes.string,
  clearScreen: PropTypes.func,
  resetBackground: PropTypes.func,
};

export default ToolTray;
