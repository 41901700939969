import { isQRcodeObject } from "./QRUtils"

const canvases = { front: null, back: null };

// get ready to handle touch events
let isTouchDevice = false;
window.addEventListener("touchstart", function () {
  isTouchDevice = true;
});

const migrateItem = function (fromCanvas, toCanvas, pendingImage) {
  fromCanvas.remove(pendingImage);

  const pendingTransform = fromCanvas._currentTransform;
  fromCanvas._currentTransform = null;

  const removeListener = fabric.util.removeListener;
  const addListener = fabric.util.addListener;
  {
    removeListener(fabric.document, 'mouseup', fromCanvas._onMouseUp);
    removeListener(fabric.document, 'touchend', fromCanvas._onMouseUp);

    removeListener(fabric.document, 'mousemove', fromCanvas._onMouseMove);
    removeListener(fabric.document, 'touchmove', fromCanvas._onMouseMove);

    addListener(fromCanvas.upperCanvasEl, 'mousemove', fromCanvas._onMouseMove);
    addListener(fromCanvas.upperCanvasEl, 'touchmove', fromCanvas._onMouseMove, {
      passive: false
    });

    if (isTouchDevice) {
      // Wait 500ms before rebinding mousedown to prevent double triggers
      // from touch devices
      let _this = fromCanvas;
      setTimeout(function () {
        addListener(_this.upperCanvasEl, 'mousedown', _this._onMouseDown);
      }, 500);
    }
  }
  {
    addListener(fabric.document, 'touchend', toCanvas._onMouseUp, {
      passive: false
    });
    addListener(fabric.document, 'touchmove', toCanvas._onMouseMove, {
      passive: false
    });

    removeListener(toCanvas.upperCanvasEl, 'mousemove', toCanvas._onMouseMove);
    removeListener(toCanvas.upperCanvasEl, 'touchmove', toCanvas._onMouseMove);

    if (isTouchDevice) {
      // Unbind mousedown to prevent double triggers from touch devices
      removeListener(toCanvas.upperCanvasEl, 'mousedown', toCanvas._onMouseDown);
    } else {
      addListener(fabric.document, 'mouseup', toCanvas._onMouseUp);
      addListener(fabric.document, 'mousemove', toCanvas._onMouseMove);
    }
  }

  setTimeout(function () {
    pendingImage.scaleY *= -1;
    pendingImage.canvas = toCanvas;
    pendingImage.migrated = true;
    toCanvas.add(pendingImage);

    toCanvas._currentTransform = pendingTransform;
    toCanvas._currentTransform.scaleY *= -1;
    toCanvas._currentTransform.original.scaleY *= -1;
    toCanvas.setActiveObject(pendingImage);
  }, 10);
};

export const controlAllBoundaries = (obj, canvas) => {

  if (!isQRcodeObject(obj)) {
    return;
  }

  // if object is too big ignore
  if (obj.height > canvas.height || obj.width > canvas.width) {
    return;
  }

  obj.setCoords();
  const boundaries = obj.getBoundingRect();
  // top-left corner
  if (boundaries.top < 0 || boundaries.left < 0) {
    obj.top = Math.max(obj.top, obj.top - boundaries.top);
    obj.left = Math.max(obj.left, obj.left - boundaries.left);
  }
  // bottom-right corner
  if (boundaries.top + boundaries.height > canvas.height || boundaries.left + boundaries.width > canvas.width) {
    obj.top = Math.min(obj.top, canvas.height - boundaries.height + obj.top - boundaries.top);
    obj.left = Math.min(obj.left, canvas.width - boundaries.width + obj.left - boundaries.left);
  }

}

const controlBoundaries = ({ type, target: obj }) => {

  // if object is too big ignore
  if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
    return;
  }

  if (!isQRcodeObject(obj)) {
    return;
  }

  obj.setCoords();
  const boundaries = obj.getBoundingRect();

  if (type === "front") {
    // top-left corner
    if (boundaries.top < 0 || boundaries.left < 0) {
      obj.top = Math.max(obj.top, obj.top - boundaries.top);
      obj.left = Math.max(obj.left, obj.left - boundaries.left);
    }
    // right corner
    if (boundaries.left + boundaries.width > obj.canvas.width) {
      obj.left = Math.min(obj.left, obj.canvas.width - boundaries.width + obj.left - boundaries.left);
    }
  }
  if (type === "back") {
    // left corner
    if (boundaries.left < 0) {
      obj.left = Math.max(obj.left, obj.left - boundaries.left);
    }
    // bot-right corner
    if (boundaries.top + boundaries.height > obj.canvas.height || boundaries.left + boundaries.width > obj.canvas.width) {
      obj.top = Math.min(obj.top, obj.canvas.height - boundaries.height + obj.top - boundaries.top);
      obj.left = Math.min(obj.left, obj.canvas.width - boundaries.width + obj.left - boundaries.left);
    }
  }
}

const canvasSetup = {
  init(canvas, context, width, height, updatedWidth, updatedHeight) {
    const newHeight = `${updatedHeight}px`
    const newWidth = `${updatedWidth}px`

    canvas.preserveObjectStacking = true
    canvas.setWidth(width)
    canvas.setHeight(height)
    canvas.upperCanvasEl.style.setProperty('height', newHeight)
    canvas.upperCanvasEl.style.setProperty('width', newWidth)
    canvas.lowerCanvasEl.style.setProperty('height', newHeight)
    canvas.lowerCanvasEl.style.setProperty('width', newWidth)


    canvases[context.props.cardType] = canvas;


    const onObjectMoving = function (p) {
      const viewport = p.target.canvas.calcViewportBoundaries();

      const { front, back } = canvases;


      if (front && front === p.target.canvas) {
        controlBoundaries({ type: "front", target: p.target });
      }

      if (back && back === p.target.canvas) {
        controlBoundaries({ type: "back", target: p.target });
      }
      if (!front || !back) {
        return;
      }

      if (p.target.canvas === front) {
        if (p.target.top > viewport.br.y) {
          console.log("Migrate: top -> bottom");
          migrateItem(front, back, p.target);
          return;
        }
      }
      if (p.target.canvas === back) {
        if (p.target.top < viewport.tl.y) {
          console.log("Migrate: bottom -> top");
          migrateItem(back, front, p.target);
          return;
        }
      }
    };

    canvas.on({
      'selection:created': (e) => {
        let selectedObject = e.target
        context.updateToolsSelection(selectedObject)
        //context.setState({ showBgColorButton: false })
        if (isQRcodeObject(e.target)) {
          context?.setActiveQRcode(true);
        }
      },

      'selection:updated': (e) => {
        let selectedObject = e.target
        context.updateToolsSelection(selectedObject)
      },

      'selection:cleared': () => {

        context.resetPanels();
        //context.setState({ showBgColorButton: true })

      },

      'object:added': (e) => {
        if (e.target.excludeFromExport)
          return

        context.updateCanvasConstraints()
        if (isQRcodeObject(e.target)) {
          if (context?.setQRcode) {
            context?.setQRcode(e.target);
          }
        }
      },
      'object:removed': (e) => {

        if (isQRcodeObject(e.target)) {
          if (context?.setQRcode) {
            context?.setQRcode(null);
          }
        }

      },

      'object:modified': (e) => {

        if (e.target.excludeFromExport)
          return

        context.getCommonObjectAttributes()
        context.updateCanvasConstraints()
        context.saveState()
      },

      'object:rotated': (e) => {
        context.getCommonObjectAttributes()
        context.saveState()
      },

      'before:selection:cleared': () => {
        const clearedObject = canvas.getActiveObject()

        if (typeof (clearedObject) !== 'undefined') {
          if (clearedObject.type == 'textbox' && clearedObject.text == '') {
            canvas.remove(clearedObject)
          }
        }

        if (isQRcodeObject(clearedObject)) {
          context?.setActiveQRcode(false);
        }
      },

      'text:editing:entered': () => {
        context.setState({
          oldText: context.activeObject().get('text')
        })
      },

      'text:editing:exited': () => {
        let newText = context.activeObject().get('text')

        if (context.state.oldText != newText)
          context.saveState()
      },
      "object:moving": onObjectMoving,
      "object:scaling": (e) => {
        const obj = e.target;
        const minScale = 0.5;
        const maxScale = 2.5;
        if (!isQRcodeObject(obj)) {
          return;
        }
        if(obj.scaleX < minScale) {
          obj.scaleX = minScale;
          obj.left = obj.lastGoodLeft;
          obj.top = obj.lastGoodTop;
        }
        if(obj.scaleY < minScale) {
          obj.scaleY = minScale;
          obj.left = obj.lastGoodLeft;
          obj.top = obj.lastGoodTop;
        }
        if(obj.scaleX > maxScale) {
          obj.scaleX = maxScale;
          obj.left = obj.lastGoodLeft;
          obj.top = obj.lastGoodTop;
        }
        if(obj.scaleY > maxScale) {
          obj.scaleY = maxScale;
          obj.left = obj.lastGoodLeft;
          obj.top = obj.lastGoodTop;
        }
        obj.lastGoodTop = obj.top;
        obj.lastGoodLeft = obj.left;
      }
    })
  }
}

export default canvasSetup
