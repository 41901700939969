import React, { useRef, useState } from 'react';
import Warning from './Warning';

const initialState = {
    current_password: "",
    password: "",
    password_confirmation: "",
}

const styles = {
    error: {
        border: "1px solid rgba(256, 0, 0, 0.8)",
    },
}

const PasswordForm = () => {
    const form = useRef(null)
    const [error, setError] = useState('')
    const [fields, setFields] = useState(() => ({...initialState}))

    const getWarning = (data) => {
        if (Array.isArray(data)) {
            return data.join('. ');
        }

        if (data) {
            return data;
        }
        return "";
    }
    const handleChange = ({ target: { id, value } }) => {
        const field = id.replace("user_", "")
        setFields(prev => ({ ...prev, [field]: value }))
    }

    const authToken = () => { return $('meta[name="csrf-token"]').attr("content"); }

    const onSubmit = (event) => {

        event.preventDefault();
        const formData = new FormData(form.current);
        setError("")
        fetch(`/update_password`, {
            body: formData,
            method: "POST",
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error("Not success");
                }

                return response.json();
            })
            .then(({error = ""}) => {
                setError(error)
            })
            .catch(console.debug);
    }
    return (

        <form
            className="edit_user"
            id="edit_user"
            action="/update_password"
            acceptCharset="UTF-8"
            method="post"
            ref={form}
            onSubmit={onSubmit}
        >
            <input
                type="hidden"
                name="authenticity_token"
                value={authToken()}
            />
            <p>
                Change your password by entering your current and new password. If you don't want to change your password, just leave these fields blank.
            </p>
            <div className='passward-wrapper'>
                <div className='paswardwrapper-input'>
                    <label htmlFor="user_current_password">Current Password</label>
                    <input
                        autoComplete="off"
                        type="password"
                        name="user[current_password]"
                        id="user_current_password"
                        value={fields.current_password}
                        onChange={handleChange}
                        style={error ? styles.error : {}}
                    />
                </div>
                <div className='paswardwrapper-input'>
                    <label htmlFor="user_password">New Password</label>
                    <input
                        autoComplete="off"
                        type="password"
                        name="user[password]"
                        id="user_password"
                        value={fields.password}
                        onChange={handleChange}
                        style={error ? styles.error : {}}
                    />
                </div>
                <div className='paswardwrapper-input'>
                    <label
                        htmlFor="user_password_confirmation">Confirm New Password</label>
                    <input
                        autoComplete="off"
                        type="password"
                        name="user[password_confirmation]"
                        id="user_password_confirmation"
                        value={fields.password_confirmation}
                        onChange={handleChange}
                        style={error ? styles.error : {}}
                    />
                    <Warning data={getWarning(error)} />
                </div>
            </div>
            <div className="SubmitContainer">
                <input
                    type="submit"
                    name="commit"
                    value="Update"
                    className="Button PurpleSubmit"
                    // data-disable-with="Update"
                />
            </div>
        </form>

    );
};

export default PasswordForm;
