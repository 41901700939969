import React from 'react'
import _ from 'lodash'

import MailingListsTable from './MailingListsTable'

class MailingLists extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIds: [],
      deleteDisabled: true,
      editDisabled: true
    }

    this.selectedMailingLists = this.selectedMailingLists.bind(this)
    this.deselectMailingList = this.deselectMailingList.bind(this)
    this.delete = this.delete.bind(this)
    this.edit = this.edit.bind(this)

  }

  selectedMailingLists(ids) {
    let selectedIds = this.state.selectedIds

    if(ids.length > 0)
      selectedIds.push(ids)
    else
      selectedIds = []

    selectedIds = _.uniq(_.flatten(selectedIds))

    this.setState({
      selectedIds: selectedIds
    }, () => {
      this.updateButtonsState()
    })
  }

  deselectMailingList(id) {
    let selectedIds = this.state.selectedIds

    if(id)
      _.remove(selectedIds, item => item == id)

    this.setState({
      selectedIds: selectedIds
    }, () => {
      this.updateButtonsState()
    })
  }

  updateButtonsState() {
    let selectedIds = this.state.selectedIds
    let deleteDisabled = selectedIds.length < 1
    let editDisabled = selectedIds.length != 1

    this.setState({
      deleteDisabled: deleteDisabled,
      editDisabled: editDisabled
    })
  }

  delete() {

    let selectedIds = this.state.selectedIds.join(",")

    if (window.confirm('Are you sure you wish to delete?')) {
      fetch('/lists/' + selectedIds, {
        credentials: 'same-origin',
        method: 'DELETE'
      }).then(response => response.json())
        .then(response => window.location.reload())
    }
  }

  edit() {
    let guid = _.head(this.state.selectedIds)
    window.location = '/lists/' + guid
  }

  render() {
    return (
      <div className="Content PageContent">
        <main>
          <header className="PageHeader">
            <h1>
              Mailing Lists
            </h1>
            <div className="Actions">
              <button ref="edit" onClick={this.edit} id="EditListButton" disabled={this.state.editDisabled}>Edit</button>
              <button ref="delete" onClick={this.delete} id="DeleteListButton" disabled={this.state.deleteDisabled}>Delete</button>
            </div>
          </header>
          <MailingListsTable
            selectedIds={this.state.selectedIds}
            selectedMailingLists={this.selectedMailingLists}
            deselectMailingList={this.deselectMailingList}
            mailingLists={this.props.mailingLists} />
        </main>
      </div>
    )
  }
}

export default MailingLists