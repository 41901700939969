import React, { Fragment } from "react";

import { Dropdown, Input, Modal } from "semantic-ui-react";

import IntegrationDots from "../../../../assets/images/integrationDots.svg";
import IntegrationIcon from "../../../../assets/images/integrationIcon.svg";

function IntegrationModals(props) {
  const {
    handleCloseModal,
    integrationUpload,
    openedListId,
    openListRequest,
  } = props;

  const goToList = () => {
    handleCloseModal();
    openListRequest(openedListId);
  };

  return (
    <Fragment>
      <div className="contacts-segment-container small">
        <div className="contacts-segment-body">
          <h3
            className={`segment-modal-small-title ${
              integrationUpload && "integration"
            }`}
          >
            {integrationUpload
              ? " Getting Your Customer List from Etsy"
              : "Woo hoo!"}
          </h3>
          <h4 className="segment-modal-small-subtitle">
            {integrationUpload ? (
              <>
                Mailfold is downloading your Etsy orders. This could take a few
                minutes
              </>
            ) : (
              `You have successfully uploaded contacts from Etsy! `
            )}
          </h4>
          {integrationUpload && (
            <div className="segment-modal-spinner">
              <img
                src={IntegrationDots}
                alt="spinner dots"
                className="spinner"
              />
              <img src={IntegrationIcon} alt="spinner dots" />
            </div>
          )}
        </div>
        <div
          className={`segment-modal-footer ${
            integrationUpload && "integration"
          }`}
        >
          {!integrationUpload && (
            <div className="grid grid-2 segment-modal-buttons">
              <div className="grid-item">
                <button
                  className="gray-button"
                  onClick={() => handleCloseModal()}
                >
                  Exit
                </button>
              </div>
              <div className="grid-item">
                <button className="blue-button" onClick={() => goToList()}>
                  Go to list
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default IntegrationModals;
