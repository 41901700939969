import React, { Component } from 'react';
import DemographyBar from "./DemographyBar";
import OptionsContent from "./OptionsContent";

class ProspectingDemography extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeOption: this.props.targetValue === "individuals" ? "HouseholdIncome": "NumberOfEmployees"
        };
    }

    showActiveContent = (activeOption) => {
        this.setState({
            activeOption: activeOption
        })
    }


    render() {
        return (
            <div className="prospecting-tabs-demography">
                <div className="demography-bar">
                    <DemographyBar 
                    showActiveContent={this.showActiveContent} 
                    activeOption={this.state.activeOption} 
                    targetValue={this.props.targetValue}/>
                </div>            
                <OptionsContent 
                    activeOption={this.state.activeOption} 
                    setDemographyFilter={this.props.setDemographyFilter} 
                    addDemographyFilter={this.props.addDemographyFilter} 
                    targetValue={this.props.targetValue} 
                    IndividualsDemographic={this.props.IndividualsDemographic} 
                    BusinessesDemographic={this.props.BusinessesDemographic} 
                    DisableSendindBtn={this.props.DisableSendindBtn}
                />            
            </div>
        )
    }
}

export default ProspectingDemography;