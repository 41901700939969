import React from "react";
import { Input } from "semantic-ui-react";

class SetupTab extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      hideState: true,
      formChanged: false,
    };
    this.countries = [];
    this.states = [];
    for (const [name, key] of props.countries) {
      this.countries.push({ key, name });
    }

    for (const [name, key] of props.states) {
      this.states.push({ key, name });
    }

    if (this.props.country === "US") {
      this.state.hideState = false;
    }
    this.onCountryChange = this.onCountryChange.bind(this);
  }

  updateFieldValue = (name, val) => {
    this.props.updateField(name, val, "setupChanged");
  };

  onCountryChange(event) {
    const country = event.target.value;

    if (country == "US") {
      this.setState({ hideState: false });
    } else {
      this.setState({ hideState: true });
      this.updateFieldValue("state", "");
    }
    this.props.updateField("country", country, "setupChanged");
  }

  render() {
    return (
      <div className="automation-tab setup">
        <div className="field-group">
          <div className="title">Automation Setup</div>
          <div className="field">
            <label className="recipients-label">Automation Name</label>
            <Input
              fluid
              value={this.props.title}
              placeholder="Untitled Automation"
              onChange={(event, data) => {
                this.updateFieldValue("title", data.value);
              }}
            />
          </div>
        </div>
        <div className="field-group">
          <div className="title">Return Address</div>
          <div className="field">
            <label className="recipients-label">Business Name</label>
            <Input
              fluid
              value={this.props.businessName}
              placeholder="Fiddle Leaf Fig Company"
              onChange={(event, data) => {
                this.updateFieldValue("businessName", data.value);
              }}
            />
          </div>
          <div className="fields-block grid-2 mg-grid-20">
            <div className="field">
              <label className="recipients-label">Address Line 1</label>
              <Input
                value={this.props.addressLine1}
                placeholder="345 Oak St."
                onChange={(event, data) => {
                  this.updateFieldValue("addressLine1", data.value);
                }}
              />
            </div>
            <div className="field">
              <label className="recipients-label">Address Line 2</label>
              <Input
                value={this.props.addressLine2}
                onChange={(event, data) => {
                  this.updateFieldValue("addressLine2", data.value);
                }}
              />
            </div>
          </div>
          <div className="fields-block grid-2 mg-grid-20">
            <div className="field">
              <label className="recipients-label">City</label>
              <Input
                value={this.props.city}
                placeholder="Maplewood"
                onChange={(event, data) => {
                  this.updateFieldValue("city", data.value);
                }}
              />
            </div>
            <div className="field">
              <label className="recipients-label">Country</label>
              <select
                className="CountryField"
                id="country"
                field="country"
                // onChange={this.onCountryChange}
                placeholder="US"
                value="US"
                disabled
              >
                <option>US</option>
                {/* <For each="country" of={this.countries}>
                  <option key={country.key} value={country.key}>
                    {country.name}
                  </option>
                </For> */}
              </select>
            </div>
          </div>
          <div className="fields-block grid-2 mg-grid-20">
            <div className="field">
              <label className="recipients-label">State/Province/Region</label>
              <select
                className="CountryField"
                id="state"
                field="state"
                disabled={this.state.hideState}
                onChange={(event, data) => {
                  this.updateFieldValue("state", event.target.value);
                }}
                placeholder="Alaska"
                value={this.props.state ? this.props.state : "Alaska"}
              >
                <For each="state" of={this.states}>
                  <option key={state.key} value={state.key}>
                    {state.name}
                  </option>
                </For>
              </select>
            </div>
            <div className="field postal-code">
              <label className="recipients-label">Postal Code</label>
              <Input
                value={this.props.postalCode}
                className="short-input postal-code"
                placeholder="00000"
                onChange={(event, data) => {
                  this.updateFieldValue("postalCode", data.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SetupTab;
