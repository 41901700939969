export const  setCotactFilterOptions = () =>{
  return [
    { key: "all", value: "all", text: "Show the list of all contacts" },
    {
      key: "segment",
      value: "segment",
      text: "Show all contacts by segments",
    },
    { key: "list", value: "list", text: "Show all contacts by lists" },
    {
      key: "allListsSegments",
      value: "allListsSegments",
      text: "Show all lists and all segments",
    },
  ]
}
