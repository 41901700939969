import React from "react";
import PropTypes from "prop-types";
import { Modal, Dropdown } from "semantic-ui-react";

class TrayImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      externalImages: [],
      activeSearchImage: true,
      activeUploadImage: false,
      showLoadMore: false,
      uploading: false,
    };
  }

  externalImages() {
    return this.state.externalImages.map((photo) => {
      let imgUrl = encodeURI(photo.preview_url + "&w=125&h=125");
      return (
        <div
          className="TrayOption ImageOption"
          key={photo.id}
          style={{
            backgroundImage: "url(" + imgUrl + ")",
            backgroundSize: "cover",
          }}
          onClick={() => {
            this.props.setter(photo.base_url);
          }}
          title={photo.name}
        ></div>
      );
    }, this);
  }

  images() {
    return this.props.images.map((photo) => {
      let imgUrl = encodeURI(photo.preview_url + "&w=125&h=125");

      return (
        <div
          className="TrayOption ImageOption"
          key={photo.id}
          style={{
            backgroundImage: "url(" + imgUrl + ")",
            backgroundSize: "cover",
          }}
          onClick={() => {
            this.props.setter(photo.base_url || photo.url);
          }}
          title={photo.name}
        ></div>
      );
    }, this);
  }

  uploadImage = () => {
    let context = this;

    context.setState({ uploading: true });
    console.log("uploading:");
    this.props
      .uploadImage(this.props.type, this.refs.image.files[0])
      .then(() => {
        context.setState({ uploading: false });
        console.log("uploading done:");
      });
  };

  searchUnsplash(query) {
    fetch(`/design_elements/external_images.json?query=${query}`)
      .then((response) => response.json())
      .then((externalImages) => {
        console.log(externalImages);
        this.setState({ externalImages: externalImages.urls });
      });
  }

  toggleTab = () => {
    let activeSearchImage = !this.state.activeSearchImage;
    let activeUploadImage = !this.state.activeUploadImage;

    this.setState({
      activeSearchImage,
      activeUploadImage,
    });
  };

  render() {
    const uploadButtonClass = this.state.uploading
      ? "Button PurpleButton UploadButton Disabled Loading"
      : "Button PurpleButton UploadButton";
    const searchImageClass = this.state.activeSearchImage
      ? "SegmentOption Selected"
      : "SegmentOption";
    const uploadImageClass = this.state.activeUploadImage
      ? "SegmentOption Selected"
      : "SegmentOption";

    return (
      <div>
        <If condition={this.props.type == "Photo"}>
          <h2>Images</h2>
          <p>
            Search for an image or upload one of your own. The image will be
            dropped into your canvas.
          </p>
        </If>
        <If condition={this.props.type == "Background"}>
          <h2>Backgrounds</h2>
          <p>
            Search for a background or upload one of your own. The background
            will be dropped into your canvas.
          </p>
        </If>
        <div className="SegmentedControl Tabs ToolTraySegment">
          <label
            className={searchImageClass}
            onClick={() => {
              this.toggleTab();
            }}
          >
            <span>
              Search {this.props.type == "Photo" ? "Images" : "Backgrounds"}
            </span>
          </label>
          <label
            className={uploadImageClass}
            onClick={() => {
              this.toggleTab();
            }}
          >
            <span>Upload {this.props.type == "Photo" ? "Image" : ""}</span>
          </label>
        </div>

        <If condition={this.state.activeSearchImage}>
          <div className="SearchImage">
            <div className="Clear"></div>
            <div className="SearchForm">
              <input
                type="text"
                className="SearchInput"
                ref="query"
                placeholder="Enter a search term..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.searchUnsplash(this.refs.query.value);
                  }
                }}
              />
              <button
                className="SearchButton"
                onClick={() => {
                  this.searchUnsplash(this.refs.query.value);
                }}
              />
            </div>
            <div className="Clear"></div>
            <div className="TrayOptions">{this.externalImages()}</div>
            <If condition={this.state.showLoadMore}>
              <button className="LoadMoreButton">Load 30 More Images</button>
            </If>
          </div>
        </If>
        <If condition={this.state.activeUploadImage}>
          <div className="UploadImage">
            <input
              id="ImageUpload"
              placeholder="upload image"
              className="TwoThirds"
              type="file"
              ref="image"
              onChange={() => {
                this.uploadImage();
              }}
              accept="image/x-png,image/gif,image/jpeg,image/svg+xml"
            />
            <label
              htmlFor="ImageUpload"
              className={`${uploadButtonClass} canvas-upload`}
            >
              Select File to Upload
            </label>
            <div className="TrayOptions">{this.images()}</div>
          </div>
        </If>
      </div>
    );
  }
}

TrayImages.propTypes = {
  images: PropTypes.array,
  setter: PropTypes.func,
  uploadImage: PropTypes.func,
};

export default TrayImages;
