const s3UploaderReact = {
  constructor() {
    this._S3_BASE = 'https://' + document.querySelector('meta[name="s3-bucket"]').content + '.s3.amazonaws.com/'
    this._S3_ACCESS_KEY = document.querySelector('meta[name="s3-key"]').content
    this._S3_POLICY = document.querySelector('meta[name="s3-policy"]').content
    this._S3_SIGNATURE = document.querySelector('meta[name="s3-signature"]').content
  },

  generateNewFileName(currentFileName, id, format, isHtml) {
    let
      randomString = Math.random().toString(36).substring(2, 15),
      yearAndId = (new Date()).getFullYear() + `/` + id + `/`

    if(typeof(currentFileName) == 'undefined')
      return `${yearAndId}${randomString}-img.${isHtml ? 'html' : format || 'png'}`
    else
      return `${yearAndId}${randomString}-${this.sanitizeWord(currentFileName)}`
  },

  sanitizeWord(text) {
    if(text)
      return text.replace(/[\W_]+/g, "_")
  },

  originalFileName(generatedName) {
    return generatedName.split('/').pop().split(/-(.+)/)[1]
  },

  upload(file, name, id, format, htmlName, HTMLfile, callback) {
    this.constructor();
    let
      s3Data = new FormData(),
      s3HTMLData = new FormData(),
      fileName = this.generateNewFileName(file.name || name, id, format, false),
      htmlFileName = ''

    s3Data.append("key", fileName)
    s3Data.append("AWSAccessKeyId", this._S3_ACCESS_KEY)
    s3Data.append("acl", "public-read")
    s3Data.append("policy", this._S3_POLICY)
    s3Data.append("signature", this._S3_SIGNATURE)
    s3Data.append("Content-Type", file.type)
    s3Data.append("file", file)

    // Set HTML file form
    if(htmlName) {
      htmlFileName = this.generateNewFileName(HTMLfile.name || htmlName, id, format, true)
      s3HTMLData.append("key", htmlFileName)
      s3HTMLData.append("AWSAccessKeyId", this._S3_ACCESS_KEY)
      s3HTMLData.append("acl", "public-read")
      s3HTMLData.append("policy", this._S3_POLICY)
      s3HTMLData.append("signature", this._S3_SIGNATURE)
      s3HTMLData.append("Content-Type", HTMLfile.type)
      s3HTMLData.append("file", HTMLfile)
    }



    return fetch(this._S3_BASE, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      body: s3Data,
    })
    .then((response) => {
      if (response.status > 204) {
        callback('');
      } else {
        if(htmlName){
          fetch(this._S3_BASE, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            body: s3HTMLData,
          })
          .then((response) => {
            callback(fileName, this._S3_BASE + fileName, htmlFileName, this._S3_BASE + htmlFileName)
          })
        }
        else {
          callback(fileName, this._S3_BASE + fileName)
        }
      }
    })
    .catch((error) => console.log(error));
  }
}

export default s3UploaderReact;
