import React, { Fragment, useState, useEffect } from "react";

import TrashIcon from "../../../../assets/images/trash-icon.svg";

export default function ClientItem({
  item,
  selectItem,
  deleteItem,
})
 {
  return (
    <>
      <tr className={item.checked ? "checked" : null}>
        <td>
          <div className="ToggleContainer">
            <input
              type="checkbox"
              onChange={() => {
                selectItem(item.id, "checkbox");
              }}
              checked={item.checked}
              name={item.email}
              id={item.email + item.id}
              value="1"
            />
            <label className="CheckboxButton" htmlFor={item.email + item.id}></label>
          </div>
          {item.email}
        </td>
        <td>{item.contacts_count}</td>
        <td>{item.campaigns_count}</td>
        <td>{item.automations_count}</td>
        <td>{item["premium?"] ? "Premium" : "Not Premium"}</td>
        <td>{item.first_name ? "Registered" : "Pending"}</td>
        <td>
          <img
            src={TrashIcon}
            alt="trash icon"
            onClick={() => deleteItem(item.id, "trash")}
          />
        </td>
      </tr>
    </>
  );
}
