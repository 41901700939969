import React, { useState, useEffect } from 'react';

const OptionsContent = props => {

    const [contactAgeText, setContactAgeText] = useState(
        ["18-20", "21-25", "26-30", "31-35", "36-40", "41-45", "46-50", "51-55", "56-60", "61-65", "66-70", "71-75", "76+"]
    )

    const [Owner_RenterText, setOwner_RenterText] = useState(
        ["Home Owner", "Renter"]
    )

    const [MartialStatusText, setMartialStatusText] = useState(
        ["Unknown", "Household contain at least 1 married person", "Household contain at least 1 single person", "Household contain both married & single persons"]
    )

    const [LengthOfResidencyText, setLengthOfResidencyText] = useState(
        ["0 - 6 months", "7 - 12 months", "1 - 2 years", "3 - 5 years", "6 - 10 years", "11 - 15 years", "16 - 20 years", "20 or more years"]
    )

    const [AgeCodeText, setAgeCodeText] = useState(
        ["18 - 24", "25 - 34", "35 - 44", "45 - 54", "55 - 64", "65 - 74", "75+"]
    )

    const [NumberOfAllPeopleText, setNumberOfAllPeopleText] = useState(
        ["1 person", "2 persons", "3 persons", "4 persons", "5 persons", "6 persons", "7 persons", "8 persons", "9+"]
    )

    const [NumberOfAdultsText, setNumberOfAdultsText] = useState(
        ["1 adult", "2 adults", "3 adults", "4 adults", "5+"]
    )

    const [NumberOfChildrenText, setNumberOfChildrenText] = useState(
        ["0 child", "1 child", "2 children", "3 children", "4 children", "5 children", "6 children", "7 children", "8 children", "9+"]
    )

    const [CreditCardText, setCreditCardText] = useState(
        ["Miscellaneous Credit Card", "Standard Retail Card", "Standard Specialty Card", "Upscale Retail Card", "Upscale Specific Retail Card", "Bank Card", "Oil-Gas Card", "Finance Company Card", "Travel/Entertainment Card"]
    )

    const [MailText, setMailText] = useState(
        ["Mail Buyer Indicator", "Mail Donor Indicator"]
    )

    const [NetWorthText, setNetWorthText] = useState(
        ["Less than $25K", "$25,000-$49,999", "$50,000-$74,999", "$75,000-$99,000", "$100,000-$149,999", "$150,000-$249,999", "$250,000-$499,999", "$500,000-$749,999", "$750,00-$999,999", "$1,000,000 or More"]
    )

    const [HomeValuationText, setHomeValuationText] = useState(
        ["$1 - 49,999", "$50,000 - 99,999", "$100,000 - 149,999", "$150,000 - 199,999", "$200,000 - 249,999", "$250,000 - 299,999", "$300,000 - 349,999", "$350,000 - 399,999", "$400,000 - 449,999", "$450,000 - 499,999", "$500,000 - 574,999", "$575,000 - 649,999", "$650,000 - 724,999", "$725,000 - 799,999", "$800,000 - 899,999", "$900,000 - 999,999", "$1,000,000 - 1,999,999", "$2,000,000+"]
    )

    const [NumberOfEmployees, setNumberOfEmployees] = useState(
        ["1 - 4", "5-9", "10 - 19", "20 - 49", "50 - 99", "100 - 249", "250 - 499", "500 - 999", "1,000 - 4,999", "5,000 - 9,999", "10,000 +"]
    )

    const [AnnualSales, setAnnualSales] = useState(
        ["$1,000-$499,999", "$500,000-$999,999", "$1,000,000-$2,499,999", "$2,500,000-$4,999,999", "$5,000,000-$9,999,999", "$10,000,000-$19,999,999", "$20,000,000-$49,999,999", "$50,000,000-$99,999,999", "$100,000,000-$499,999,999", "$500,000,000-$999,999,999", "Over $1 billion"]
    )

    const [Mchecked, setMChecked] = useState(true);
    const [Fchecked, setFChecked] = useState(true);

    useEffect(() => {
        ["M"] in props.IndividualsDemographic["Gender"] ? setMChecked(true) : setMChecked(false);
        ["F"] in props.IndividualsDemographic["Gender"] ? setFChecked(true) : setFChecked(false);
    }, [props.IndividualsDemographic, props.BusinessesDemographic])

    return (
        <div className="demography-content">

            <div className={`demography-content-general-household demography-content-items ${props.activeOption === "HouseholdIncome" ? "" : "demography-content-hidden"}`}>

                 <h2 className="demography-content-items-title">Household Income</h2>

                 <label className="container option-check" for="HouseholdIncome1">
                     $0 - 14,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["1"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$0 - 14,999")} value="1" id="HouseholdIncome1"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome2">
                     $15,000 - 19,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["2"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$15,000 - 19,999")} value="2" id="HouseholdIncome2"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome3">
                     $20,000 - 29,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["3"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$20,000 - 29,999")} value="3" id="HouseholdIncome3"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome4">
                     $30,000 - 39,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["4"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$30,000 - 39,999")} value="4" id="HouseholdIncome4"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome5">
                     $40,000 - 49,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["5"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$40,000 - 49,999")} value="5" id="HouseholdIncome5"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome6">
                     $50,000 - 74,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["6"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$50,000 - 74,999")} value="6" id="HouseholdIncome6"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome7">
                     $75,000 - 99,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["7"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$75,000 - 99,999")} value="7" id="HouseholdIncome7"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome8">
                     $100,000 - 124,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["8"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$100,000 - 124,999")} value="8" id="HouseholdIncome8"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome9">
                     $125,000 - 149,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["9"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$125,000 - 149,999")} value="9" id="HouseholdIncome9"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome10">
                     $150,000 - 174,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["10"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$150,000 - 174,999")} value="10" id="HouseholdIncome10"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome11">
                     $175,000 - 199,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["11"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$175,000 - 199,999")} value="11" id="HouseholdIncome11"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome12">
                     $200,000 - 249,999
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["12"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$200,000 - 249,999")} value="12" id="HouseholdIncome12"></input>
                     <span className="checkmark"></span>
                 </label>

                 <label className="container option-check" for="HouseholdIncome13">
                     $250,000+
                     <input type="checkbox" checked={props.IndividualsDemographic.HouseholdIncome["13"] !== undefined} name="HouseholdIncome" onClick={(e) => props.setDemographyFilter(e, "$250,000+")} value="13" id="HouseholdIncome13"></input>
                     <span className="checkmark"></span>
                 </label>

             </div>

              <div className={`demography-content-general-contactAge demography-content-items ${props.activeOption === "ContactAge" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Contact Age</h2>
                    {
                        contactAgeText.map((i, index) =>
                            <label className="container option-check" for={`ContactAge${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.ContactAge[(index + 1).toString()] !== undefined} name={`ContactAge`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`ContactAge${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-OwnerRenter demography-content-items ${props.activeOption === "OwnerRenter" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Owner/Renter</h2>
                    {
                        Owner_RenterText.map((i, index) =>
                            <label className="container option-check" for={`OwnerRenter${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.OwnerRenter[(index + 1).toString()] !== undefined} name={`OwnerRenter`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`OwnerRenter${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-MartialStatus demography-content-items ${props.activeOption === "MartialStatus" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Martial Status</h2>
                    {
                        MartialStatusText.map((i, index) =>
                            <label className="container option-check" for={`MartialStatus${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.MartialStatus[(index + 1).toString()] !== undefined} name={`MartialStatus`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`MartialStatus${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-LengthOfResidency demography-content-items ${props.activeOption === "LengthOfResidency" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Length Of Residency</h2>
                    {
                        LengthOfResidencyText.map((i, index) =>
                            <label className="container option-check" for={`LengthOfResidency${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.LengthOfResidency[(index + 1).toString()] !== undefined} name={`LengthOfResidency`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`LengthOfResidency${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-AgeCode demography-content-items ${props.activeOption === "AgeCode" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Age Code</h2>
                    {
                        AgeCodeText.map((i, index) =>
                            <label className="container option-check" for={`AgeCode${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.AgeCode[(index + 1).toString()] !== undefined} name={`AgeCode`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`AgeCode${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-NumberOfAllPeople demography-content-items ${props.activeOption === "NumberOfAllPeople" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Number Of All People</h2>
                    {
                        NumberOfAllPeopleText.map((i, index) =>
                            <label className="container option-check" for={`NumberOfAllPeople${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.NumberOfAllPeople[(index + 1).toString()] !== undefined} name={`NumberOfAllPeople`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`NumberOfAllPeople${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-NumberOfAdults demography-content-items ${props.activeOption === "NumberOfAdults" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Number Of Adults</h2>
                    {
                        NumberOfAdultsText.map((i, index) =>
                            <label className="container option-check" for={`NumberOfAdults${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.NumberOfAdults[(index + 1).toString()] !== undefined} name={`NumberOfAdults`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`NumberOfAdults${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-NumberOfChildren demography-content-items ${props.activeOption === "NumberOfChildren" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Number Of Children</h2>
                    {
                        NumberOfChildrenText.map((i, index) =>
                            <label className="container option-check" for={`NumberOfChildren${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.NumberOfChildren[(index + 1).toString()] !== undefined} name={"NumberOfChildren"} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`NumberOfChildren${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-CreditCard demography-content-items ${props.activeOption === "CreditCard" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Credit Card</h2>
                    {
                        CreditCardText.map((i, index) =>
                            <label className="container option-check" for={`CreditCard${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.CreditCard[(index + 1).toString()] !== undefined} name={`CreditCard`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`CreditCard${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-Mail demography-content-items ${props.activeOption === "Mail" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Mail</h2>
                    {
                        MailText.map((i, index) =>
                            <label className="container option-check" for={`Mail${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.Mail[(index + 1).toString()] !== undefined} name={`Mail`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`Mail${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-NetWorth demography-content-items ${props.activeOption === "NetWorth" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Net Worth</h2>
                    {
                        NetWorthText.map((i, index) =>
                            <label className="container option-check" for={`NetWorth${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.NetWorth[(index + 1).toString()] !== undefined} name={`NetWorth`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`NetWorth${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-HomeValuation demography-content-items ${props.activeOption === "HomeValuation" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Home Valuation</h2>
                    {
                        HomeValuationText.map((i, index) =>
                            <label className="container option-check" for={`HomeValuation${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.IndividualsDemographic.HomeValuation[(index + 1).toString()] !== undefined} name={`HomeValuation`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`HomeValuation${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-general-Gender demography-content-items ${props.activeOption === "Gender" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Gender</h2>
                    {/* {
                        GenderText.map((i, index) =>
                            <label className="container option-check" for={`Gender${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={index === 0 ? Mchecked : Fchecked} name={`Gender`} onClick={(e) => {
                                    props.setDemographyFilter(e, i)
                                    changeChecked(e)
                                }} value={index === 0 ? "M" : "F"} id={`Gender${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    } */}
                    <label className="container option-check" for="Male">
                        Male
                        <input type="checkbox" checked={Mchecked} name="Gender" onClick={(e) => { props.setDemographyFilter(e)}} value="M" id="Male"></input>
                        <span className="checkmark"></span>
                    </label>

                    <label className="container option-check" for="Female">
                        Female
                        <input type="checkbox" checked={Fchecked} name="Gender" onClick={(e) => { props.setDemographyFilter(e)}} value="F" id="Female"></input>
                        <span className="checkmark"></span>
                    </label>
              </div>

              <div className={`demography-content-businesses-NumberOfEmployees demography-content-items ${props.activeOption === "NumberOfEmployees" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Number Of Employees</h2>
                    {
                        NumberOfEmployees.map((i, index) =>
                            <label className="container option-check" for={`NumberOfEmployees${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.BusinessesDemographic.NumberOfEmployees[(index + 1).toString()] !== undefined} name={`NumberOfEmployees`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`NumberOfEmployees${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

              <div className={`demography-content-businesses-AnnualSales demography-content-items ${props.activeOption === "AnnualSales" ? "" : "demography-content-hidden"}`}>
                    <h2 className="demography-content-items-title">Annual Sales</h2>
                    {
                        AnnualSales.map((i, index) =>
                            <label className="container option-check" for={`AnnualSales${index + 1}`}>
                                {i}
                                <input type="checkbox" checked={props.BusinessesDemographic.AnnualSales[(index + 1).toString()] !== undefined} name={`AnnualSales`} onClick={(e) => props.setDemographyFilter(e, i)} value={index + 1} id={`AnnualSales${index + 1}`}></input>
                                <span className="checkmark"></span>
                            </label>
                        )
                    }
              </div>

            <div className={`sendCheckedData ${props.DisableSendindBtn && "sendCheckedDataDisabled"}`} onClick={() => props.addDemographyFilter()}>
                Aprove choices
            </div>
        </div>
    );
};

export default OptionsContent;
