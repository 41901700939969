import React, { useState, useEffect } from "react";

import DeskIcon from "../../../assets/images/desk-icon.svg";
import DiagramIcon from "../../../assets/images/diagram-icon.svg";
import TableArrowDown from "../../../assets/images/table-arrow-down.svg";
import { getData, postData, putData, deleteData } from "../../lib/fetches";

import _ from "lodash";
import Moment from "react-moment";
import { Item, Dropdown } from "semantic-ui-react";

function ContactsTables(props) {
  const {
    token,
    openedListId,
    addCustomFieldToList,
    contactsPage,
    selecteAllContacts,
    allContacts,
    tableList,
    tableRecipients,
    openListRequest,
    selectContact,
    contactsInformation,
    customDates,
    contactsRequestSort,
    contactsRequest,
    contactsInListSort,
  } = props;

  //sortOption = true - sort by alphabet, sortOption = false - revert sorting
  const [sortOption, setSortOption] = useState(true);

  const tableSorting = (key, sort, table, id, customField) => {
    table === "lists" && contactsRequestSort(key, sort);
    if (table === "contacts") {
      customField
        ? contactsInListSort(id, null, sort, customField)
        : contactsInListSort(id, key, sort, null);
    }
    setSortOption(!sort);
  };

  const customDatesArraySetting = (customArray, array) => {
    let newArray = [...customArray, ...array].map((item, index) => {
      if (item.name) {
        const typedItem = item;
        typedItem.type = "data";
        return typedItem;
      } else {
        return { name: item, type: "blank", index: index };
      }
    });
    let arrayWithIndexedItems = [];
    newArray.map((item) => {
      if (item.type === "blank") {
        let indexedItem = array.filter((elem) => {
          if (elem.name === item.name) {
            elem.index = item.index;
            return elem;
          }
        });
        if (indexedItem[0] && indexedItem[0].type === "data") {
          arrayWithIndexedItems.push(indexedItem[0]);
        }
      }
    });
    let lengthArray = [...customArray.map((item, index) => index)];
    let finalArray = lengthArray.map((item) => {
      let switchedItem = arrayWithIndexedItems.find(
        (elem) => elem.index === item
      );
      if (switchedItem && item === switchedItem.index) {
        item = switchedItem;
        return item;
      } else {
        return item;
      }
    });
    return finalArray;
  };

  const emptyCustomDatesArray = (arr) => {
    let lengthArray = [...arr.map((item, index) => index)];
    return lengthArray;
  };

  return (
    <>
      {contactsPage ? (
        <table className="ui celled collapsing very basic table contacts">
          <thead className="">
            <tr className="">
              <th className="contacts-th list-checkbox">
                <div className="ToggleContainer">
                  <input
                    type="checkbox"
                    onChange={() => {
                      selecteAllContacts();
                    }}
                    checked={allContacts}
                    name={"allContacts"}
                    id={"allContacts"}
                    value="1"
                  />
                  <label
                    className="CheckboxButton"
                    htmlFor={"allContacts"}
                  ></label>
                </div>
              </th>
              <th
                className="contacts-th date"
                onClick={() =>
                  tableSorting("created_at", sortOption, "lists", null, null)
                }
              >
                Date
                <img src={TableArrowDown} alt="table-arrow" />
              </th>
              <th
                className="contacts-th list-name"
                onClick={() =>
                  tableSorting("name", sortOption, "lists", null, null)
                }
              >
                List Name <img src={TableArrowDown} alt="table-arrow" />
              </th>
              <th
                className="contacts-th list-size"
                onClick={() =>
                  tableSorting("list_size", sortOption, "lists", null, null)
                }
              >
                List Size <img src={TableArrowDown} alt="table-arrow" />
              </th>
              <th className="contacts-th">Contacts</th>
            </tr>
          </thead>
          <tbody className="">
            {tableList.length > 0 &&
              tableList.map((item, index) => {
                return (
                  <tr
                    key={item.id}
                    className={`${item.selected ? "contacts-checked" : ""}`}
                  >
                    <td className="contacts-td">
                      <div className="ToggleContainer">
                        <input
                          type="checkbox"
                          onChange={() => selectContact(item.id, "contacts")}
                          checked={item.selected}
                          name={"contacts_" + item.id}
                          id={"contacts_" + item.id}
                          value="1"
                        />
                        <label
                          className="CheckboxButton"
                          htmlFor={"contacts_" + item.id}
                        ></label>
                      </div>
                    </td>
                    <td className="contacts-td date">
                      <Moment format="DD.MM.YYYY" withTitle>
                        {item.created_at}
                      </Moment>
                    </td>
                    <td className="contacts-td list-name">
                      <div className="contacts-list-name-cover">
                        {item.list_type === "list" ? (
                          <img src={DeskIcon} />
                        ) : (
                          <img src={DiagramIcon} />
                        )}
                        <p>{item.name}</p>
                      </div>
                      {item.selected && (
                        <div className="contacts-checked-info">
                          <span>
                            Created:
                            <strong>
                              <Moment format="DD.MM.YYYY" withTitle>
                                {item.created_at}
                              </Moment>
                            </strong>
                          </span>
                          <span>
                            Last mailing:
                            <strong>
                              {item.last_activity === "No activities" ? (
                                "No activities"
                              ) : (
                                <Moment format="DD.MM.YYYY" withTitle>
                                  {item.last_activity}
                                </Moment>
                              )}
                            </strong>
                          </span>
                          <span>
                            Mailing:{" "}
                            <strong>{item.sent_activities_amount}</strong>
                          </span>
                        </div>
                      )}
                    </td>
                    <td className="contacts-td list-size">
                      {item.addresses.length} People
                    </td>
                    <td className="contacts-td button-table">
                      <button
                        className="contacts-button"
                        onClick={() => openListRequest(item.id)}
                      >
                        Open
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="table-wrapper">
          <table className="ui celled collapsing very basic table contacts table-one">
            <thead>
              <tr>
                <th className="contacts-th">
                  <div className="ToggleContainer">
                    <input
                      type="checkbox"
                      onChange={() => {
                        selecteAllContacts("recipients");
                      }}
                      checked={allContacts}
                      name={"allContacts"}
                      id={"allContacts"}
                      value="1"
                    />
                    <label
                      className="CheckboxButton"
                      htmlFor={"allContacts"}
                    ></label>
                  </div>
                </th>
                <th
                  className="contacts-th name"
                  onClick={() =>
                    tableSorting(
                      "business_name",
                      sortOption,
                      "contacts",
                      openedListId,
                      null
                    )
                  }
                >
                  Contacts Name <img src={TableArrowDown} alt="table-arrow" />{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {tableRecipients.length > 0 &&
                tableRecipients.map((item, index) => {
                  return (
                    <tr
                      key={item.customer_id}
                      className={`${item.selected && "contacts-checked"}`}
                    >
                      <td className="contacts-td">
                        <div className="ToggleContainer">
                          <input
                            type="checkbox"
                            onChange={() =>
                              selectContact(item.customer_id, "recipients")
                            }
                            checked={item.selected}
                            name={"contacts_" + item.customer_id}
                            id={"contacts_" + item.customer_id}
                            value="1"
                          />
                          <label
                            className="CheckboxButton"
                            htmlFor={"contacts_" + item.customer_id}
                          ></label>
                        </div>
                      </td>
                      <td className="contacts-td name">{item.business_name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="table-two-container">
            <table className="ui celled collapsing very basic table contacts table-two ">
              <thead>
                  <tr>
                  <th
                    className="contacts-th city"
                    onClick={() =>
                      tableSorting(
                        "Email",
                        sortOption,
                        "contacts",
                        openedListId,
                        null
                      )
                    }
                  >
                    Email <img src={TableArrowDown} alt="table-arrow" />{" "}
                  </th>
                  <th
                    className="contacts-th city"
                    onClick={() =>
                      tableSorting(
                        "city",
                        sortOption,
                        "contacts",
                        openedListId,
                        null
                      )
                    }
                  >
                    City <img src={TableArrowDown} alt="table-arrow" />{" "}
                  </th>
                  <th
                    className="contacts-th state"
                    onClick={() =>
                      tableSorting(
                        "state",
                        sortOption,
                        "contacts",
                        openedListId,
                        null
                      )
                    }
                  >
                    State <img src={TableArrowDown} alt="table-arrow" />
                  </th>
                  <th
                    className="contacts-th zip"
                    onClick={() =>
                      tableSorting(
                        "postal_code",
                        sortOption,
                        "contacts",
                        openedListId,
                        null
                      )
                    }
                  >
                    Zip <img src={TableArrowDown} alt="table-arrow" />{" "}
                  </th>
                  {customDates !== undefined &&
                    customDates.length > 0 &&
                    customDates.map((item, index) => {
                      return (
                        <th
                          key={index}
                          className="contacts-th"
                          onClick={() =>
                            tableSorting(
                              null,
                              sortOption,
                              "contacts",
                              openedListId,
                              item
                            )
                          }
                        >
                          {item} <img src={TableArrowDown} alt="table-arrow" />
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {tableRecipients.length > 0 &&
                  tableRecipients.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${item.selected && "contacts-checked"}`}
                      > 
                        {console.log(item)}
                        <td className="contacts-td">{item.email}</td>
                        <td className="contacts-td">{item.city}</td>
                        <td className="contacts-td ">{item.state}</td>
                        <td className="contacts-td ">{item.postal_code}</td>
                        {item.custom_dates !== undefined &&
                        item.custom_dates.length > 0
                          ? customDatesArraySetting(
                              customDates,
                              item.custom_dates
                            ).map((item, index) => {
                              if (item.type === "data") {
                                const value = item.value || item.default_value;
                                return (
                                  <td key={item.id} className="contacts-td">
                                    {item.field_type === "date" && value ? (
                                      <Moment format="DD/MM/YYYY" withTitle>
                                        {value}
                                      </Moment>
                                    ) : (
                                      value
                                    )}
                                  </td>
                                );
                              } else {
                                return (
                                  <td key={index} className="contacts-td"></td>
                                );
                              }
                            })
                          : emptyCustomDatesArray(customDates).map(
                              (item, index) => {
                                return (
                                  <td key={index} className="contacts-td"></td>
                                );
                              }
                            )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <table className="ui celled collapsing very basic table contacts table-three">
            <thead>
              <tr>
                <th className="contacts-th"></th>
              </tr>
            </thead>
            <tbody>
              {tableRecipients.length > 0 &&
                tableRecipients.map((item, index) => {
                  return (
                    <tr>
                      <td className="contacts-td button-table">
                        <button
                          className="contacts-button"
                          onClick={() => contactsInformation(item.customer_id)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {contactsPage && tableList.length < 1 ? (
        <div className="contacts-empty-block">
          <h3 className="contacts-empty-title">No lists yet</h3>
          <p className="contacts-empty-text">
            Add some lists or segments, just press "New List" or "New Segment"
            on the right sidebar. Also you can import your scv files, just press
            "+Add Contacts" on the right sidebar, after that press 'Import
            contacts'
          </p>
        </div>
      ) : null}
      {!contactsPage && tableRecipients.length < 1 ? (
        <div className="contacts-empty-block">
          <h3 className="contacts-empty-title">No contacts yet</h3>
          <p className="contacts-empty-text">
            Add some contacts into your list, just press "+Add Contacts" on the
            right sidebar
          </p>
        </div>
      ) : null}
    </>
  );
}
export default ContactsTables;
