import React, { Component } from "react";
import CustomListMapping from "./CustomListMapping";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import * as Papa from "papaparse";

class CustomList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      name: "Untitled List",
      sampleData: [],
      defaultPositions: {},
      nextDisabled: true,
      fileName: "Select a .CSV or .XLSX file",
    };
  }

  fileOnSelect = (file) => {
    if (!file) return console.log("No file found!");

    console.log("File found", file);

    const fileExtension = file.name.split(".").pop();
    const context = this;

    if (fileExtension === "csv") {
      Papa.parse(file, {
        complete: (results) => {
          console.log("Addresses Loaded: ", results.data.length);
          context.setState({
            sampleData: results.data,
            fileName: file.name,
            file,
          });
        },
      });
    } else if (fileExtension === "xlsx") {
      let reader = new FileReader();
      reader.onload = (e) => {
        let binaryString = e.target.result;
        let workBook = XLSX.read(binaryString, { type: "binary" });
        let first_worksheet = workBook.Sheets[workBook.SheetNames[0]];
        let data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        console.log("Addresses Loaded: ", data.length);
        context.setState({
          sampleData: data,
          fileName: file.name,
          file,
        });
      };
      reader.readAsBinaryString(file);
    } else {
      throw new TypeError(`${file.type} File Type not supported.`, file.name);
    }
  };

  setDefaultPositions = (defaultPositions) => {
    let nextDisabled = !this.isMappingsValid();

    this.setState({
      defaultPositions,
      nextDisabled,
    });
  };

  nameChanged = (name) => {
    this.setState({ name });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    let { file, name, defaultPositions } = this.state;

    if (!this.isMappingsValid()) {
      alert("Required Data Missing!"); // Need to remove
      throw new Error("Required Data Missing!");
      return;
    }

    formData.append("file", file);
    formData.append("name", name);
    formData.append("mappings", JSON.stringify(defaultPositions));

    fetch(`/process_list`, {
      body: formData,
      method: "POST",
    })
      .then(this.handleErrors)
      .then((response) => {})
      .catch((error) => console.log(error));
  };

  isMappingsValid = () => {
    const { defaultPositions } = this.state;
    const presentKeys = Object.keys(defaultPositions);

    const requiredKeys = ["addressLine1", "city", "state", "postalCode"];
    const requiredNameKeys = ["firstName", "lastName", "businessName"];

    const gotRequiredKeys = requiredKeys.every((k) => k in defaultPositions);
    const gotRequiredNameKeys = requiredNameKeys.some(
      (v) => presentKeys.indexOf(v) !== -1
    );

    return gotRequiredKeys && gotRequiredNameKeys;
  };

  handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  acceptedFileFormats() {
    return [
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ].join(", ");
  }

  render() {
    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        <fieldset>
          <h2>Upload Mailing List</h2>
          <p>
            Provide us with a mailing list that you already have. Don't worry
            too much about how it's formatted, you'll help us figure it out in a
            moment.
          </p>

          <div>
            <label>Mailing List Name</label>
            <input
              type="text"
              key="name"
              ref="name"
              value={this.state.name}
              onChange={() => {
                this.nameChanged(this.refs.name.value);
              }}
            />
          </div>

          <div>
            <label className="import">Mailing List File</label>

            <input
              placeholder="Select a File"
              type="file"
              key="file"
              id="fileInput"
              accept={this.acceptedFileFormats()}
              onChange={(e) => {
                this.fileOnSelect(e.target.files[0]);
              }}
            />

            <div className="FilenameDisplay">{this.state.fileName}</div>

            <label className="FileInputButton mgt-20" htmlFor="fileInput">
              Choose File
            </label>
          </div>
        </fieldset>
        <fieldset className="WideListOptions campaign">
          <If condition={this.state.sampleData.length !== 0}>
            <CustomListMapping
              sampleData={this.state.sampleData}
              setDefaultPositions={this.setDefaultPositions}
              defaultPositions={this.state.defaultPositions}
            />
          </If>
        </fieldset>

        <div className="SubmitContainer">
          <div className="InnerColumnContentWrapper">
            <div className="InnerColumnContent">
              <input
                type="submit"
                name="commit"
                value="Next Step"
                className="Button BlueSubmit"
                data-disable-with="Next Step"
                disabled={this.state.nextDisabled}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

CustomList.propTypes = {
  campaign: PropTypes.object.isRequired,
};

export default CustomList;
