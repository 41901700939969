export default class {
  constructor({widthInInches, heightInInches, className, price}) {
    this.height = (heightInInches + 0.25) * 300
    this.width = (widthInInches + 0.25) * 300
    this.className = className
    this.price = price
    this.base = "https://s3.amazonaws.com/mailfold-storage"
    this.selectClass = this.selectClass.bind(this)
    this.boxClass = this.boxClass.bind(this)
    this.id = this.id.bind(this)
    this.heightInInches = heightInInches
    this.widthInInches = widthInInches
  }

  selectClass() {
    return this.className + "Selected"
  }

  boxClass() {
    return this.className + "Card"
  }

  id() {
    return `${this.heightInInches}x${this.widthInInches}`
  }

  psdTemplate() {
    return this.base + `/mailfold-${this.height}x${this.width}-template.psd`
  }

  pngTemplate() {
    return this.base + `/mailfold-${this.height}x${this.width}-template-overlay.png`
  }
}
