import  React from "react";
import { Pagination, Icon } from 'semantic-ui-react';

const headers = ["№", "Name", "Location", "Day of Scanning", "Operating System", "User ID number"];
const mockData = [{name: "Hillary Roy", location: "California", day: "22.03.2001", os: "Windows", uid: "22032001"}, {name: "Hillary Roy", location: "California", day: "22.03.2001", os: "Windows", uid: "22032001"}, {name: "Hillary Roy", location: "California", day: "22.03.2001", os: "Windows", uid: "22032001"}]
const mockStatuses = [{value: 1, label: "Status 1"}, {value: 2, label: "Status 2"}, {value: 3, label: "Status 3"}, {value: 4, label: "Status 4"}, {value: 5, label: "Status 5"}, {value: 6, label: "Status 6"}, {value: 7, label: "Status 7"}];

const ReportTable = () => {
    return(
        <section className="report-table-section">
            <div className="report-table__header">
                <h3>Scans</h3>
                
                <form className="report-table__select">
                    <span>Status:</span>
                    <select name="status-filter" id="">
                        {
                            mockStatuses.map((option, idx) => {
                                return (
                                    <option value={option.value} key={idx}>
                                        {option.label}
                                    </option>
                                )
                            })
                        }
                    </select>
                </form>
            </div>
            <div>
                <table className="ui celled collapsing very basic sortable table report">
                    <thead>
                    <tr>
                        { headers.map((h, idx) => <th key={idx}>{h}</th>) }
                    </tr>
                    </thead>
                    <tbody>
                    { mockData.map((d, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{ idx + 1 }</td>
                                <td>{ d.name }</td>
                                <td>{ d.location}</td>
                                <td>{ d.day }</td>
                                <td>{ d.os }</td>
                                <td>{ d.uid }</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <Pagination
                    className="default-pagination"
                    defaultActivePage={1}
                    siblingRange={1}
                    totalPages={190}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{ content: <Icon name='angle orange-icon left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle orange-icon right' />, icon: true }}
                />

            </div>

        </section>
    )
};

export default ReportTable;