import React from 'react'
import ThankYou from './ThankYou'

export default class Notice extends React.Component {
  showError() {
    if(this.props.notice_type == 'error') {
      return (
        <div className="Errors Visible">
          <div className="ErrorMessage">
            <div className="Icon">
              <div className="SubIcon">
                <div className="Background"></div>
              </div>
            </div>
            <div className="Heading">
              {this.noticeHeading()}
            </div>
            <div className="Body">
              {this.noticeMessage()}
            </div>
          </div>
          <div className="Clear"></div>
        </div>
      )
    }
  }

  showSuccess() {
    if(this.props.notice_type == 'success') {
      return (
        <div className="Success Visible">
          <div className="SuccessMessage">
            <div className="Icon">
              <div className="SubIcon"></div>
              <div className="Background"></div>
            </div>
            <div className="Heading">
              {this.noticeHeading()}
            </div>
            <div className="Body">
              {this.noticeMessage()}
            </div>
          </div>
          <div className="Clear"></div>
        </div>
      )
    }
  }

  noticeMessage() {
    return this.props.notice_message
  }

  noticeHeading() {
    return this.props.notice_heading
  }

  showThankYou() {
    if(this.props.notice_type == 'thank_you') {
      return <ThankYou/>
    }
  }

  render() {
    return (
      <div>
        {this.showThankYou()}
        <div className="Clear"></div>
        {this.showSuccess()}
        <div className="Clear"></div>
        {this.showError()}
        <div className="Clear"></div>
      </div>
    );
  }
}
