import React, { Component } from "react";
import { Tab } from "semantic-ui-react";

import Moment from "react-moment";

import { getData, postData, putData, deleteData } from "../../lib/fetches";

class ContactInformation extends Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      history: {},
    };
  }

  componentDidMount() {
    getData(
      `/contacts/contacts_history/${this.props.contact.customer_id}`,
      this.props.authToken,
      (res) => {
        this.setState({ history: { ...res } });
      }
    );
  }

  render() {
    const panes = [
      {
        menuItem: "Information",
        render: () => (
          <Tab.Pane attached={false} className="contacts">
            <div className="contacts-tab-cover">
              <div className="contacts-tab-item info">
                <h4 className="contacts-tab-item-title">Full Name</h4>
                <p className="contacts-tab-item-text">
                  {this.props.contact.business_name}
                </p>
              </div>
              <div className="contacts-tab-item info">
                <h4 className="contacts-tab-item-title">City</h4>
                <p className="contacts-tab-item-text">
                  {this.props.contact.city}
                </p>
              </div>

              <div className="contacts-tab-item info">
                <h4 className="contacts-tab-item-title">State</h4>
                <p className="contacts-tab-item-text">
                  {this.props.contact.state}
                </p>
              </div>
              <div className="contacts-tab-item info">
                <h4 className="contacts-tab-item-title">Address line 1</h4>
                <p className="contacts-tab-item-text">
                  {this.props.contact.address_line_1}
                </p>
              </div>
              {this.props.contact.address_line_2 && (
                <div className="contacts-tab-item info">
                  <h4 className="contacts-tab-item-title">Address line 2</h4>
                  <p className="contacts-tab-item-text">
                    {this.props.contact.address_line_2}
                  </p>
                </div>
              )}

              <div className="contacts-tab-item info">
                <h4 className="contacts-tab-item-title">Zip Code</h4>
                <p className="contacts-tab-item-text">
                  {this.props.contact.postal_code}
                </p>
              </div>
              {this.props.contact.custom_dates.length > 0 &&
                this.props.contact.custom_dates.map((item, index) => {
                  const value = item.value || item.default_value;
                  return (
                    <div key={index} className="contacts-tab-item info">
                      <h4 className="contacts-tab-item-title">{item.name}</h4>
                      <p className="contacts-tab-item-text">
                        {item.field_type === "date" && value ? (
                          <Moment format="DD/MM/YYYY" withTitle>
                            {value}
                          </Moment>
                        ) : (
                          value
                        )}
                      </p>
                    </div>
                  );
                })}
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "History",
        render: () => (
          <Tab.Pane attached={false} className="contacts">
            <div className="contacts-tab-cover">
              <div className="grid grid-2 contacts-history">
                <div className="grid-item">
                  <div className="contacts-tab-item history">
                    <h4 className="contacts-tab-item-title">Created</h4>
                    <p className="contacts-tab-item-text">
                      <Moment format="MMM DD YYYY" withTitle>
                        {this.props.contact.created_at}
                      </Moment>{" "}
                      <span className="date-separater">at</span>
                      <Moment format="h:mm a" withTitle>
                        {this.props.contact.created_at}
                      </Moment>
                      <span className="contacts-tab-item-breadcrumb">
                        <Moment fromNow>{this.props.contact.created_at}</Moment>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="contacts-tab-item history">
                    <h4 className="contacts-tab-item-title">Last Mailed</h4>
                    <p className="contacts-tab-item-text">
                      {this.state.history.last_mailed !== "No activities" ? (
                        <>
                          <Moment format="MMM DD YYYY" withTitle>
                            {this.state.history.last_mailed}
                          </Moment>
                          <span className="contacts-tab-item-breadcrumb">
                            <Moment fromNow>
                              {this.state.history.last_mailed}
                            </Moment>
                          </span>
                        </>
                      ) : (
                        <>No activities yet</>
                      )}
                      <span className="contacts-tab-item-breadcrumb">
                        Mailed {this.state.history.sent_activities_amount} time
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {this.state.history.activities && this.state.history.activities.length > 0 &&
                this.state.history.activities.map((item, index) => {
                  return (
                    <>
                      {item.object_type === "Campaign" && (
                        <div key={item.id} className="contacts-tab-item">
                          <h4 className="contacts-tab-item-title">
                            <Moment fromNow>{item.created_at}</Moment>
                          </h4>
                          <ul className="contacts-tab-list">
                            <li className="contacts-tab-item-text list">
                              {item.activity_type === "mail_sent" &&
                                "Sent from the  "}
                              {item.activity_type === "added_to_list" &&
                                "Added to the "}
                              <span>{item.campaign.name}</span> campaign
                            </li>
                          </ul>
                        </div>
                      )}
                      {item.object_type === "AutomationRule" && (
                        <div key={item.id} className="contacts-tab-item">
                          <h4 className="contacts-tab-item-title">
                            <Moment fromNow>{item.created_at}</Moment>
                          </h4>
                          <ul className="contacts-tab-list">
                            <li className="contacts-tab-item-text list">
                              {item.activity_type === "mail_sent" &&
                                "Sent from the  "}
                              {item.activity_type === "added_to_list" &&
                                "Added to the "}
                              <span>{item.automation.title} </span> automation
                            </li>
                          </ul>
                        </div>
                      )}
                      {item.object_type === "AddressBook" && (
                        <div key={item.id} className="contacts-tab-item">
                          <h4 className="contacts-tab-item-title">
                            <Moment fromNow>{item.created_at}</Moment>
                          </h4>
                          <ul className="contacts-tab-list">
                            <li className="contacts-tab-item-text list">
                              {item.activity_type === "mail_sent" &&
                                "Sent from the  "}
                              {item.activity_type === "added_to_list" &&
                                "Added to the "}
                              <span>{item.list.name} </span> list
                            </li>
                          </ul>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </Tab.Pane>
        ),
      },
    ];
    return (
      <>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          className="contacts"
        />
      </>
    );
  }
}

export default ContactInformation;
