import React, { useState } from "react";
import DatePicker from "react-datepicker";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnatChart";

const ReportCharts = () => {
    const [activeTab, setActiveTab] = useState("allTime");
    const [startDate, setStartDate] = useState(new Date());
    const [dataObject, setDataObject] = useState([{name: 'Unique', number: '426,65', percent: '-2,999%'}, {name: 'Unique', number: '426,65', percent: '+2,51%'}, {name: 'Unique', number: '426,65', percent: '-0,61%'}, {name: 'Unique', number: '426,65', percent: '+2,51%'}]);

    return (
        <section className="chart-section">
            <div className="chart-section__tabs">
                <button className={`chart-section__tab ${activeTab === 'allTime' ? 'active' : ''}`} onClick={() => setActiveTab('allTime')}>All Time</button>
                <button className={`chart-section__tab ${activeTab === 'year' ? 'active' : ''}`} onClick={() => setActiveTab('year')}>Year</button>
                <button className={`chart-section__tab ${activeTab === 'month' ? 'active' : ''}`} onClick={() => setActiveTab('month')}>Month</button>
            </div>

            <form className="chart-section__dates">
                <label htmlFor="startDate">From</label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    id="startDate"
                    dateFormat="MMM. dd/yyyy"
                    className="customized-datepicker"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />


                <label htmlFor="endDate">to</label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    id="endDate"
                    dateFormat="MMM. dd/yyyy"
                    className="customized-datepicker"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />

                <button type="submit" className="green-outlined">Apply</button>
            </form>

            <div className="data-wrapper">
                {dataObject.map((d, idx) => {
                    return (
                        <div className="data-card" key={idx}>
                            <h5 className="data-card__title">{ d.name }</h5>

                            <div>
                                <span className="data-card__value">{ d.number }</span>
                                <span className={`data-card__percentage data-card__percentage_${d.percent[0] === '-' ? 'decrease' : 'increase'}`}>{ d.percent }</span>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="chart-block__wrapper">
                <div className="chart-block__title">Scans</div>
                <LineChart />
            </div>

            <article className="report-statistic-block">
                <div className="chart-block__wrapper">
                    <div className="chart-block__title">Top Locations</div>
                    <BarChart />
                </div>
                <div className="chart-block__wrapper doughnut-chart">
                    <div className="chart-block__title">Operating System</div>
                        <DoughnutChart />
                </div>
            </article>

        </section>
    );
};

export default ReportCharts;