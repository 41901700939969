import React from "react";
import { Icon } from "semantic-ui-react";

const ContactSycnConfirmation = ({ closeSyncModal,selectedAddress,type,syncContactfromTopline,postContact,loader }) => {
  console.log(loader)
  return (
    <div
        className={`contacts-segment-container small`}
    >
          <>
            <div className="contacts-segment-body">
              <h3 className="segment-modal-small-title">
              {type == "from" ?"Sync Contacts":"Sync Contacts"}
              </h3>
              <h4 className="segment-modal-small-subtitle">
          Are you sure you want to sync all the contact?   
          </h4>
            </div>
            <div className="segment-modal-footer">
              <div className="grid grid-2 segment-modal-buttons">
                <div className="grid-item">
                  <button
                      className="gray-button"
                    onClick={() => closeSyncModal()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="grid-item">
                  <button
                      className="blue-button"
                      onClick={() => {
                        type == "from" ? syncContactfromTopline(selectedAddress) :postContact(selectedAddress)
                      }}
                  >
                Sync{"  "}
               {loader && <Icon loading name='spinner' />}
                  </button>
                </div>
              </div>
            </div>
          </>
    </div>
);
}

export default ContactSycnConfirmation;

