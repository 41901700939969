import React from "react";
import { Dropdown } from "semantic-ui-react";
import { getData } from "../../lib/fetches";
import { Modal } from "semantic-ui-react";
import { postData } from "../../lib/fetches";

import prospectingImg1 from "../../../assets/images/prospecting-page-image-1";
import prospectingImg2 from "../../../assets/images/prospecting-page-image-2";
import QRCodeIcon1 from "../../../assets/images/QR-code-builder-icon-1.jpg";
import QRCodeIcon2 from "../../../assets/images/QR-code-builder-icon-2.jpg"
import closeIcon from "../../../assets/images/close-gray.svg"
import SubscriptionPremiumTypes from "../shared/subscriptions/SubscriptionPremiumTypes";
import { premiumSubscriptionByType, premiumSubscriptionTypeByName } from "../shared/subscriptions/utils";

class Recipients extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      lists: this.props.lists.map((item) => {
        return { key: item.id, value: item.id, text: item.name };
      }),
      selectedList: this.props.campaign.address_book_id,
      infoModalOpen: false,
      upgradeModalOpen: false,
      user: this.props.current_user,
      noListError: false,
      emptyListError: false,
      premiumType: premiumSubscriptionTypeByName(this.props.current_user?.['premium?'])
    };
  }

  campaignId() {
    return this.props.campaign.guid;
  }

  setPremiumType = (premiumType) => {
    this.setState({premiumType})
  }

  handleNext = (e) => {
    e.preventDefault();
    if (!this.state.selectedList) {
      this.setState({ noListError: true })
    } else {
      window.location.href = `/campaigns/${this.campaignId()}/confirm`;
    }
  };

  redirectProspecting = (e) => {
    e.preventDefault();
    window.location.href = `/campaigns/${this.campaignId()}/prospecting`;
  };

  redirectToCcUpdate = () => {
    window.location.href = "/settings/?tab=billing";
  }

  connectList(listId) {
    getData(
        `/campaigns/${this.campaignId()}/connect_to_list?address_book_id=${listId}`,
        this.props.authToken,
        (res) => { }
    );
    let list = this.props.lists.find(el => el.id == listId)
    if (!list.addresses.length) this.setState({ emptyListError: true })
  }

  closeInfoModal() {
    this.setState({
      infoModalOpen: false,
    })
  }

  openInfoModal() {
    this.setState({
      infoModalOpen: true,
    })
  }

  setUpgradeModalOpen() {
    this.setState({
      upgradeModalOpen: true
    })
  }

  closeUpgradeModalOpen() {
    this.setState({
      upgradeModalOpen: false
    })
  }

  upgradeAccount(premiumType) {

    if (this.state.user["with_card?"]) {
      postData(
          "/set_premium",
          this.props.authToken,
          {premiumType: premiumSubscriptionByType(premiumType)},
          (res) => {
            this.setState({
              user: res,
              upgradeModalOpen: false
            })
          }
      );
    } else {
      this.redirectToCcUpdate();
    }
  }

  render() {
    return (
        <div className="mailing-prospecting">
          <div className={"InnerColumnContent"}>
            <div className="adress-wrapper">
              <fieldset className={"ListOptions"}>
                <h2>Mailing List</h2>
                <p>Choose your list to get your customers.</p>
              </fieldset>
              <div>
                <Dropdown
                    placeholder="- Choose your list -"
                    options={this.state.lists}
                    fluid
                    selection
                    value={this.state.selectedList}
                    onChange={(event, data) => {
                      this.connectList(data.value);
                      this.setState({ selectedList: data.value });
                    }}
                />
              </div>
              {!this.state.user["premium?"] &&
                  <div className="prospecting-options-wrapper">
                    <div className="prospecting-option">
                      <span>or</span>
                      <h2>Prospecting</h2>
                      <p>Find out what is Prospecting here</p>
                      <button className="prospecting-create-btn" onClick={() => this.openInfoModal('prospecting')}>Create Prospecting</button>
                    </div>

                    {this.state.infoModalOpen &&
                        <div className="create-prospecting-modal">
                    <span className="close-icon" onClick={() => { this.closeInfoModal() }}>
                      <img src={closeIcon}></img>
                    </span>

                          <React.Fragment>
                            <h2>Create Prospecting</h2>
                            <p>Create prospecting for mailing all the potential clients and take your business to the next
                              level. Creating prospecting allows
                              you extend your target audience and find people by location, demography etc.
                              Prospecting gives you a new opportunity to conquer the world with your business and attract many
                              new customers.</p>
                            <div className="ProspectingRectangle">
                              <img
                                  src={prospectingImg1}
                              />
                              <img
                                  src={prospectingImg2}
                              />
                            </div>
                          </React.Fragment>

                          <div className="prospecting-modal-premium-plan">
                            <h2>Requires Premium Plan</h2>

                            <React.Fragment>
                              <p>You’ll need to upgrade to Mailfold’s Premium Plan starting from $99 per month to start using Prospecting.
                                Once upgraded, you can set rules for sending automated mail to new customers.</p>

                              <p>With a premium plan, you have access to add more than 5000 contacts. Plan includes all postcard sizes.</p>
                            </React.Fragment>

                            <button className="premium-plan-button" onClick={() => this.setUpgradeModalOpen()}>Upgrade now</button>
                          </div>
                          <Modal
                              open={this.state.upgradeModalOpen}
                              closeOnEscape={true}
                              closeOnDimmerClick={true}
                              className="contacts-segment-modal"
                              onClose={() => this.closeUpgradeModalOpen()}
                          >
                            <div className="contacts-segment-container small">

                              <div className="contacts-segment-body">
                                <h3 className="segment-modal-small-title">
                                  Upgrade to Premium
                                </h3>
                                <h4 className="segment-modal-small-subtitle">
                                  In order to create Prospecting Campaigns you have to upgrade your account to Premium. Upgrade now?
                                </h4>
                                <h4 className="segment-modal-small-subtitle">
                                  If your CC is not connected to the account yet, you will be redirected to the Settings page.
                                </h4>
                                {this.state.user["with_card?"] && <SubscriptionPremiumTypes  premiumType={this.state.premiumType} setPremiumType={setPremiumType}/>}
                              </div>
                              <div className="segment-modal-footer">
                                <div className="grid grid-2 segment-modal-buttons">
                                  <div className="grid-item">
                                    <button
                                        className="blue-button"
                                        style={{ fontSize: "14px" }}
                                        onClick={() => this.upgradeAccount(this.state.premiumType)}
                                    >
                                      Upgrade to Premium
                                    </button>
                                  </div>
                                  <div className="grid-item">
                                    <button
                                        className="gray-button"
                                        onClick={() => this.closeUpgradeModalOpen()}
                                    >
                                      Maybe later
                                    </button>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </Modal>
                        </div>
                    }

                  </div>
              }
              <div className={"AddressProvider AddressProvider-addlist"}>
                <fieldset>
                  <h2>No lists ?</h2>
                  <p>You can easily do it by clicking on the button below.</p>
                  <div className={"ConnectSubmitContainer"}>
                    <a className="Button GreenSubmit" href="/contacts">
                      Add new list
                    </a>
                    <div className={"Note"}>
                      You’ll be taken to contacts page. We’ll see you in just a
                      minute.
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="adress-wrapper">
              <div className={"AddressProvider"}>
                <fieldset>
                  <h2>Connect Your Store</h2>
                  <p>
                    This will only need to happen once. You’ll be taken to chosen
                    website where you’ll need give Mailfold access to your customer
                    list.
                  </p>
                  <div className={"ConnectSubmitContainer"}>
                    <a className="Button GreenSubmit" href="/contacts/integrations">
                      Connect your Store
                    </a>
                    <div className={"Note"}>
                      You’ll be taken to integration page. We’ll see you in just a
                      minute.
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="SubmitContainer">
              <div className="InnerColumnContentWrapper">
                <div className="InnerColumnContent buttons">

                  <a
                      className="Button ExitButton"
                      href={`/campaigns/${this.props.campaign.guid}/qr_code`}
                  >
                    Go Back
                  </a>
                  <div>
                    <button className="Button OutlinedButton" onClick={() => window.location.href="/campaigns" }>
                      Save &amp; Exit
                    </button>
                    <button
                        className="Button PurpleSubmit"
                        onClick={(e) => {
                          this.handleNext(e);
                        }}
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.user["premium?"] &&
              <div className="prospecting-info">
                <div className="prospecting-info-create">
                  <h2>Create Prospecting</h2>
                  <p>Create prospecting for mailing all the potential clients and take your business to a new level.
                    Creating prospecting lets you to make wider your target audience and find people by location, demography etc.
                    Prospecting gives you a new opportunity to concure the world with your business and attract many new clients.</p>
                  <button className="prospecting-create-btn" onClick={(e) => this.redirectProspecting(e)}>Create Prospecting</button>
                  <div className="ProspectingRectangle">
                    <img
                        src={prospectingImg1}
                    />
                    <img
                        src={prospectingImg2}
                    />
                  </div>
                </div>
              </div>
          }
          <Modal
              open={this.state.noListError}
              closeOnEscape={true}
              closeOnDimmerClick={true}
              className="contacts-segment-modal"
              onClose={() => this.setState({ noListError: false })}
          >
            <div className="contacts-segment-container small">

              <div className="contacts-segment-body">
                <h3 className="segment-modal-small-title">
                  Please select your Mailing List
                </h3>
              </div>
              <div className="segment-modal-footer">
                <div className="grid grid-2 segment-modal-buttons">
                  <button
                      className="blue-button centered"
                      style={{ fontSize: "14px" }}
                      onClick={() => this.setState({ noListError: false })}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
              open={this.state.emptyListError}
              closeOnEscape={true}
              closeOnDimmerClick={true}
              className="contacts-segment-modal"
              onClose={() => this.setState({ emptyListError: false })}
          >
            <div className="contacts-segment-container small">

              <div className="contacts-segment-body">
                <h3 className="segment-modal-small-title">
                  The Mailing List you selected is empty. Please add addresses to the list or choose another one.
                </h3>
              </div>
              <div className="segment-modal-footer">
                <div className="grid grid-2 segment-modal-buttons">
                  <button
                      className="blue-button centered"
                      style={{ fontSize: "14px" }}
                      onClick={() => this.setState({ emptyListError: false })}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
    );
  }
}

export default Recipients;
