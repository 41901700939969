import React from 'react'
import PropTypes from 'prop-types'
import {SketchPicker} from 'react-color'

export default class ColorPicker extends React.PureComponent {
  static propTypes = {
    showColorPickerButton: PropTypes.bool,
    handleColorPickerButtonClick: PropTypes.func,
    colorPickerButtonColor: PropTypes.string,
    showColorBoard: PropTypes.bool,
    handleClose: PropTypes.func,
    changeColor: PropTypes.func
  }

  render() {
    return (
      <>
      <div className="ColorPicker">
        <div onClick={ this.props.handleColorPickerButtonClick }
             style={{backgroundColor: this.props.colorPickerButtonColor}}
             className="SelectedColor"></div>
      </div>

      <div>
        <If condition={this.props.showColorBoard}>
          <div className="popover">
            <div className="cover"
                 onClick={ this.props.handleClose }/>
            <SketchPicker color={ this.props.colorPickerButtonColor }
                          onChangeComplete={ this.props.changeColor }/>
          </div>
        </If>
      </div>
      </>
    )
  }
}
