import { isQRcodeObject } from "../qr_codes/QRUtils"

const canvasSetup = {
  init(canvas, context, width, height, updatedWidth, updatedHeight) {
    const newHeight = `${updatedHeight}px`
    const newWidth = `${updatedWidth}px`

    canvas.preserveObjectStacking = true
    canvas.setWidth(width)
    canvas.setHeight(height)
    canvas.upperCanvasEl.style.setProperty('height', newHeight)
    canvas.upperCanvasEl.style.setProperty('width', newWidth)
    canvas.lowerCanvasEl.style.setProperty('height', newHeight)
    canvas.lowerCanvasEl.style.setProperty('width', newWidth)

    canvas.on({
      'selection:created': (e) => {
        let selectedObject = e.target
        context.updateToolsSelection(selectedObject)
        context.setState({ showBgColorButton: false })
      },

      'selection:updated': (e) => {
        let selectedObject = e.target
        context.updateToolsSelection(selectedObject)
      },

      'selection:cleared': () => {

        context.resetPanels()
        context.setState({ showBgColorButton: true })
      },

      'object:added': (e) => {
        if (e.target.excludeFromExport)
          return

        context.updateCanvasConstraints()
        if (isQRcodeObject(e.target)) {
          if (context?.setQRcode) {
            context?.setQRcode(e.target);
          }
        }
      },
      'object:removed': (e) => {

        if (isQRcodeObject(e.target)) {
          if (context?.setQRcode) {
            context?.setQRcode(null);
          }
        }

      },

      'object:modified': (e) => {

        if (e.target.excludeFromExport)
          return

        context.getCommonObjectAttributes()
        context.updateCanvasConstraints()
        context.saveState()
      },

      'object:rotated': (e) => {
        context.getCommonObjectAttributes()
        context.saveState()
      },

      'before:selection:cleared': () => {
        const clearedObject = canvas.getActiveObject()

        if (typeof (clearedObject) !== 'undefined') {
          if (clearedObject.type == 'textbox' && clearedObject.text == '') {
            canvas.remove(clearedObject)
          }
        }
      },

      'text:editing:entered': () => {
        context.setState({
          oldText: context.activeObject().get('text')
        })
      },

      'text:editing:exited': () => {
        let newText = context.activeObject().get('text')

        if (context.state.oldText != newText)
          context.saveState()
      }
    })
  }
}

export default canvasSetup
