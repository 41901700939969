import React from "react";

import Help from "../../../../assets/images/sidebar-help.svg";

const HelpSidebar = (props) => {
  return(
      <aside className="HelpSidebar">
          <div className="HelpSidebar__actions">
              { props.children }
          </div>
          <div className="HelpSidebar__image-block">
              <img src={Help} alt="Help img" />
              <h2 className="HelpSidebar__title">Getting Help</h2>
              <p>Need help with something? If you need to reach someone, contact Mailfold's support by emailing <a href="#">support@mailfold.com</a></p>
          </div>
      </aside>
  )
};

export default HelpSidebar;