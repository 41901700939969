import React, { Fragment } from "react";
import Moment from "react-moment";

class ActivityTab extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.activityListArray = Object.values(this.props.activityList);
    this.activityListKeys = Object.keys(this.props.activityList);
    this.state = {
      activityList: this.props.activityList,
    };
  }

  filteredItems = (arrIndex, columnType) => {
    const array = this.activityListArray[arrIndex];
    const mail_scheduled = array.filter(
      (item) => item.activity_type === "mail_scheduled"
    );
    const mail_sent = array.filter(
      (item) => item.activity_type === "mail_sent"
    );
    if (columnType === "mail_scheduled") {
      return mail_scheduled;
    } else {
      return mail_sent;
    }
  };

  render() {
    return (
      <>
        {this.state.activityListKeys &&
        this.state.activityListKeys.length > 0 ? (
          <table className="ui celled collapsing very basic table activity">
            <thead className="">
              <tr className="activity-tr">
                <th className="activity-th pd-18">Date</th>
                <th className="activity-th styled">Mail Scheduled</th>
                <th className="activity-th">Mail Sent</th>
              </tr>
            </thead>
            <tbody className="">
              {this.activityListKeys.map((item, index) => {
                return (
                  <tr className="activity-tr">
                    <td className="first-column">
                      <Moment format="D MMM" withTitle>
                        {item}
                      </Moment>
                    </td>
                    <td className="">
                      {this.filteredItems(index, "mail_scheduled").map(
                        (listedItem, itemIndex) => {
                          return (
                            <ul className="activity-list activity-list-black">
                              <li className="activity-list-item">
                                <span className="activity-list-number">
                                  {itemIndex + 1}
                                </span>
                                <h4 className="activity-item-title styled">
                                  <Moment format="D MMM YYYY" withTitle>
                                    {listedItem.activity_date}
                                  </Moment>
                                </h4>
                                <p className="activity-item-body">
                                  {listedItem.subject.business_name}{" "}
                                  <span className="activity-gray">in</span>{" "}
                                  {listedItem.subject.city},{" "}
                                  {listedItem.subject.state}
                                </p>
                                <span className="activity-item-footer">
                                  Etsy Transaction
                                </span>
                              </li>
                            </ul>
                          );
                        }
                      )}
                    </td>
                    <td className="">
                      {this.filteredItems(index, "mail_sent").map(
                        (listedItem, itemIndex) => {
                          return (
                            <ul className="activity-list">
                              <li className="activity-list-item activity-list-item-black">
                                <span className="activity-list-number black">
                                  {itemIndex + 1}
                                </span>
                                <h4 className="activity-item-title">
                                  <Moment format="D MMM YYYY" withTitle>
                                    {listedItem.activity_date}
                                  </Moment>
                                </h4>
                                <p className="activity-item-body">
                                  {listedItem.subject.business_name}{" "}
                                  <span className="activity-gray">in</span>{" "}
                                  {listedItem.subject.city},{" "}
                                  {listedItem.subject.state}
                                </p>
                                <span className="activity-item-footer">
                                  Etsy Transaction
                                </span>
                              </li>
                            </ul>
                          );
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <span className="automation-no-activity">No activity yet</span>
        )}
      </>
    );
  }
}

export default ActivityTab;
