import React from "react";
import AgencySidebar from "./components/AgencySidebar";
import AgencyDashboard from "./components/AgencyDashboard";


class Agency extends React.Component {
  constructor(props) {
    props;
    super(props);
  }

  render() {
    return (
      <div className="Content PageContent">
        <AgencyDashboard {...this.props}/>
        <AgencySidebar agency= {this.props.agency} />
      </div>
    );
  }
}
export default Agency;
