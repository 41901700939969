import React from "react";

import LeafImage from "../../../assets/images/Ash.svg";

export default class ContactsSidebar extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      contactsDropdown: false,
    };
  }

  connectYourStore() {
    window.location.href = `/contacts/integrations`;
  }

  render() {
    // count will be from props this.props.count
    const count = 100;

    return (
      <aside className="PageSidebar InfoSidebar Show AutomationSidebar ContactsSidebar">
        <div className="PageSidebarContents contacts">
          <div className="sidebar-heading-wrapper"> 
            <h2 className="sidebar-title">Create</h2>
            <div className="add-contact-cover">
            <button
              className="add-contact-button"
              onMouseEnter={() =>
                this.setState({
                  contactsDropdown: !this.state.contactsDropdown,
                })
              }
              onClick={() =>
                this.setState({
                  contactsDropdown: !this.state.contactsDropdown,
                })
              }
            >
              + Add Contacts
            </button>
          </div>

          </div>
          <div className="grid grid-2 contacts-sidebar-buttons">
            <div className="grid-item ">
              <a
                className="Button GreenSubmit contacts"
                onClick={() => this.props.openNewSegment(true, 1)}
              >
                New List
              </a>
            </div>
            <div className="grid-item ">
              <a
                className="Button GreenSubmit contacts transparent "
                onClick={() => this.props.openNewSegment(true, 0)}
              >
                New Segment
              </a>
            </div>
          </div>
          {this.state.contactsDropdown && (
            <div
              className="add-contact-dropdown"
              onMouseLeave={() => {
                this.setState({ contactsDropdown: false });
              }}
            >
              <button onClick={() => this.props.openContactsSettings("import")}>
                Import contacts
              </button>
              <button
                onClick={() => {
                  this.props.openContactModal(true, 1);
                  this.setState({ contactsDropdown: false });
                }}
              >
                Quick add contacts
              </button>
              <button onClick={() => this.connectYourStore()}>
                Integrations
              </button>
            </div>
          )}
          <div className="Clear"></div>
        </div>

        <div className="AutomationSidebarBottom contacts">
          <div className="contact-settings-cover">
            <h3 className="contact-settings-title">Settings:</h3>
            <div className="contact-setting-button-wrapper">
              <button
                className="contact-settings-button transparent"
                onClick={() => this.props.openContactsSettings("customField")}
              >
                + Add Custom Fields
              </button>
              <button
                className="contact-settings-button"
                onClick={() => this.connectYourStore()}
              >
                Integration
              </button>
            </div>
          </div>
        </div>
          <div className="contacts-leaf-image">
            <img src={LeafImage} />
            <p>
              Your business is responsible for planting {count / 10} new trees
              with {count / 1000} days worth of employment.
              <span className="Clear"></span>
              {/* <a href="#">Learn more</a> */}
            </p>
          </div>
      </aside>
    );
  }
}
