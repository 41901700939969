import React, { Component } from 'react';
import { fabric } from "fabric";
import $ from "jquery";
import canvasHelpers from "../shared/canvas-helpers";
import canvasGuidelines from "../shared/canvas-guidelines";
import canvasSetup, { controlAllBoundaries } from "./QR-canvas-setup";
import s3UploaderReact from "../shared/s3UploaderReact";
import imgixReact from "../shared/ImgixReact";
import { encodeXml, getBase64FromUrl, isQRcodeObject, QRCanvasUtils } from './QRUtils';

const { scaleUp, DPI, MULTIPLIER, svgFonts } = QRCanvasUtils.getConstants();

class QRCanvas extends Component {
  // cardSize, cardType, mailerType, mailer, designElement, personalizedValues
  // QRImage = {Front: Image, Back: Image}, activeTray, id = front|back

  constructor(props) {
    super(props);

    const {
      height,
      width,
      showInkFreeZone,
      isLetter,
      cardSize,
      cardType,
      mailer,
      templateSide,
    } = QRCanvasUtils.getDerivedValuesFromProps(props);

    this.cache = {
      cardSize,
      isLetter,
      canvasWidth: width,
      canvasHeight: height,
      left: width / 2,
      top: height / 2,
      cardType,
      mailer,
      direction: { LEFT: 0, UP: 1, RIGHT: 2, DOWN: 3, DELETE: 4 },
      showInkFreeZone,
      templateSize: cardSize,
      templateSide,
      canvas: null,

    }
    this.state = {
      updatedWidth: "",
      updatedHeight: "",
      objectSelected: false,
      inkFreeZoneObj: "",
      bleedObj: "",
      groupSelected: false,
      // QRcode: null,

    };

    this.fromJson = this.fromJson.bind(this);
    this.addImage = this.addImage.bind(this);
    this.addQRCodePNG = this.addQRCodePNG.bind(this);
  }

  componentDidMount() {
    let context = this;
    let leftSidebarWidth = 800; // Assumptions
    let dividingFactor = this.cache.canvasWidth / leftSidebarWidth;
    let updatedWidth = this.cache.canvasWidth / dividingFactor;
    let updatedHeight = this.cache.canvasHeight / dividingFactor;

    fabric.DPI = this.cache.isLetter ? 96 : DPI;

    const canvas = new fabric.Canvas(this.props.id, {
      hoverCursor: "pointer",
      selection: true,
      selectionBorderColor: "blue",
      backgroundColor: "rgba(255, 255, 255, 1)",
    });
    canvasSetup.init(
      canvas,
      context,
      this.cache.canvasWidth,
      this.cache.canvasHeight,
      updatedWidth,
      updatedHeight
    );
    canvasGuidelines.init(canvas);

    this.cache.canvas = canvas;

    this.setState({
      updatedHeight: updatedHeight,
      updatedWidth: updatedWidth,
    });

    const json_data = this.props.designElement?.json_data;
    const back_url = this.props.mailer?.imgix_back_design_url;
    const front_url = this.props.mailer?.imgix_front_design_url;

    if (json_data && Object.keys(json_data).length > 0) {
      console.log("Mode from json ", this.props.designElement);
      try {
        this.addTemplateToCanvas(
          JSON.parse(json_data),
          canvas
        );
      } catch (error) {
        console.log(error);
      }

    } else if (this.cache.cardType === "back" && back_url && this.props.designOption === "upload") {
      this.addImage(back_url);
      console.log("Mode from pictire ", back_url);
    } else if (front_url && this.props.designOption === "upload") {
      this.addImage(front_url);
      console.log("Mode from pictire ", front_url);
    }

    this.setCanvasDefaults(canvas);

  }

  componentDidUpdate(prevProps, prevState) {
    // const {
    //   isLetter,
    //   cardSize,
    //   cardType,
    //   mailer,
    //   templateSide } = QRCanvasUtils.getDerivedValuesFromProps(this.props);

    // this.cache = {
    //   ...this.cache,
    //   cardSize,
    //   isLetter,
    //   cardType,
    //   mailer,
    //   templateSide,
    // };
    if (prevProps.showBleed !== this.props.showBleed) {
      this.toggleBleedObj();
    }

  }

  toggleBleedObj = () => {
    const options = {};
    if (!this.props.showBleed) {
      options.bleedObj = this.removeBleed(this.cache.canvas);
    } else {
      options.bleedObj = this.addBleed(this.cache.canvas);
    }
    this.setState(options);
  };

  setCanvasDefaults = (canvas) => {
    if (this.isBack() && this.cache.showInkFreeZone) {
      let inkFreeZoneObj = this.addInkFreeZone(canvas);
      this.setState({ inkFreeZoneObj: inkFreeZoneObj });
    } else if (this.cache.isLetter && this.cache.showInkFreeZone) {
      let inkFreeZoneObj = this.addInkFreeZone(canvas);
      this.setState({ inkFreeZoneObj: inkFreeZoneObj });
    }
    this.toggleBleedObj();
  };

  updateCanvasConstraints = () => {
    if (this.state.inkFreeZoneObj && this.state.inkFreeZoneObj != "")
      this.state.inkFreeZoneObj.bringToFront();

    if (this.state.bleedObj && this.state.bleedObj != "")
      this.state.bleedObj.bringToFront();
  };


  handleEscape() {
    const canvas = this.cache.canvas;
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    this.resetPanels();
  }

  handleKeydown = (e) => {
    const REGULAR_STEP = 1;
    const SHIFT_STEP = 10;
    const keyCode = e.keyCode;

    const OBJECTS = this.activeObjects();

    if (OBJECTS) {
      OBJECTS.forEach((object) => {
        if (object.isEditing) return false;

        let step = REGULAR_STEP;

        if (e.shiftKey) step = SHIFT_STEP;

        if (keyCode === 8 || keyCode === 46) {
          this.removeSelected();
        } else if (keyCode === 37) {
          this.moveObject(object, this.cache.direction.LEFT, step);
        } else if (keyCode === 38) {
          this.moveObject(object, this.cache.direction.UP, step);
        } else if (keyCode === 39) {
          this.moveObject(object, this.cache.direction.RIGHT, step);
        } else if (keyCode === 40) {
          this.moveObject(object, this.cache.direction.DOWN, step);
        }
      });
    }
  };

  moveObject(object, direction, step) {
    switch (direction) {
      case this.cache.direction.LEFT:
        object.left = object.left - step;
        break;
      case this.cache.direction.RIGHT:
        object.left = object.left + step;
        break;
      case this.cache.direction.UP:
        object.top = object.top - step;
        break;
      case this.cache.direction.DOWN:
        object.top = object.top + step;
        break;
    }

    object.setCoords();
    this.cache.canvas.renderAll();
  }

  removeSelected = () => {
    let activeObjects = this.activeObjects();

    if (activeObjects) {
      let self = this;
      activeObjects.forEach(function (object) {
        self.cache.canvas.remove(object);
      });

      self.cache.canvas.discardActiveObject();
    }
  };

  updateToolsSelection(selectedObject) {
    let objectSelected = false,
      groupSelected = false;

    if (
      selectedObject.type !== "activeSelection" &&
      selectedObject.type !== "group" &&
      selectedObject
    ) {
      objectSelected = true;

      this.setState({
        objectSelected,
        groupSelected,
      });
    } else {
      groupSelected = true;
      this.setState({ groupSelected });
    }
  }

  resetPanels() {
    this.setState({
      objectSelected: false,
      groupSelected: false,
      //Todo - handleKeydown why its needed ?
      handleKeydown: true,
    });
  }

  setActiveQRcode = (isActiveQRcode) => {
    this.props.setActiveQRcode(isActiveQRcode);
  }

  canvasData = (isPreview) => {
    let options = scaleUp ? { multiplier: MULTIPLIER } : {};
    this.removeInkFreeZone(this.cache.canvas);
    this.removeBleed(this.cache.canvas);
    let result;
    let canvasTexts = {};

    if (isPreview) {
      let personalizations = this.cache.canvas.getObjects().filter(i => i.type == "textbox" && i.text.includes("{{"))
      personalizations.forEach((item, i) => {
        this.setActiveObject(item)
        canvasTexts[item.id] = item.text;
        Object.entries(this.props.personalizedValues).map(([k, v]) => {
          item.text = item.text.replaceAll(`{{${k}}}`, v)
        })
        item.enterEditing();
        this.cache.canvas.renderAll();
      });
      result = this.cache.canvas.toSVG(options);
      personalizations.forEach((item, i) => {
        item.text = canvasTexts[item.id];
        item.selectable = true;
        item.enterEditing();
      });
    } else {
      result = this.cache.canvas.toSVG(options);
    }

    this.cache.canvas.getObjects()
      .filter(object => object.type === "image")
      .map(image => image.getSrc())
      .forEach(src => result = result.replaceAll(src, encodeXml(src)));

    const srcBack = this.cache.canvas?.backgroundImage?.getSrc();
    if (srcBack) {
      result = result.replaceAll(srcBack, encodeXml(srcBack));
    }

    let svgString = result.split('</defs>');
    let res = svgString[0] + svgFonts + "</defs>" + svgString[1];
    return res
  };

  canvasJson = () => {
    this.removeInkFreeZone(this.cache.canvas);
    this.removeBleed(this.cache.canvas);
    return this.cache.canvas.toJSON(["selectable", "crossOrigin", "sourcePath"]);
  };

  saveDesignElement(designElement) {
    return new Promise((resolve, reject) => {
      let designElementUrl = "/design_elements";
      let httpMethod = "POST";

      //let id = this.props.designElement?.id || this.cache.idUploadElement;

      if (this.props.designElement?.id) {
        designElementUrl = `/design_elements/${this.props.designElement?.id}`;
        httpMethod = "PATCH";
      }

      fetch(designElementUrl, {
        method: httpMethod,
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": this.authToken,
        },
        body: JSON.stringify({ design_element: { ...designElement, campaign_with_qr_code: true }, }),
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            resolve(response);
          } else reject(response);
        });
    });
  }

  setSelectable = () => {
    this.cache.canvas.getObjects().filter(object => !object.selectable).forEach(object => object.selectable = true);
    this.cache.canvas.renderAll();
  }

  saveTemplate = (savedTemplate = false, redirect = true) => {

    const canvasJson = this.canvasJson();
    if (!canvasJson.objects.length && !canvasJson.backgroundImage) {
      console.error("Empty Design!");
      this.setCanvasDefaults(this.cache.canvas);
      return false;
    }
    //set all elements leaves selectable ?
    this.setSelectable();

    let _this = this;
    let mailer = this.cache.mailer;
    let cardType = this.cache.cardType.toString().toLowerCase();
    let uuid = this.cache.mailer.guid || canvasHelpers.generateUUID();
    let templateName = this.cache.mailer.name || "Untitled";
    let templateSize = this.cache.templateSize;
    let templateSide = this.cache.templateSide;
    let file = canvasHelpers.svgToBlob(templateName, this.canvasData(true));
    let HTMLfile = canvasHelpers.svgToHTMLBlob(templateName, this.canvasData(false));
    let name = `${templateName} - ${cardType}.svg`;
    let htmlName = `${templateName} - ${cardType}.html`;

    return s3UploaderReact.upload(
      file,
      name,
      uuid,
      "svg",
      htmlName,
      HTMLfile,
      (path, fileUrl, htmlpath, htmlFileUrl) => {
        if (fileUrl) {
          let previewUrl = imgixReact.urlFromAwsUrl(fileUrl, "", "");
          let designElement = {
            name: templateName,
            type: "Template",
            private: true,
            json_data: JSON.stringify(this.canvasJson(), null, 2),
            url: fileUrl,
            html_url: htmlFileUrl,
            preview_url: previewUrl,
            size: templateSize,
            side: templateSide,
            template_category_name: null,
            update_qrcode: true,
            qrcode_redirect_url: this.props.qrCodeRedirectUrl,
            qrcode_options: this.props.qrOptions,
            saved_template: savedTemplate,
          };
          if (!_.isEmpty(mailer) && cardType) {
            designElement.mailer_id = mailer.id;
            designElement.mailer_type =
              this.props.mailerType == "campaigns"
                ? "Campaign"
                : "AutomationRule";
            designElement.side = cardType;
          }

          let success = false;
          this.saveDesignElement(designElement)
            .then((result) => {
              success = true;
              console.log("Card successfully saved.");
              console.log(result);
              //this.cache.idUploadElement=result?.design_element?.id;
            })
            .catch((error) => {
              console.log("Failed! Could not save Template.");
              console.log(error);
            });

          if (!_.isEmpty(mailer) && cardType) {
            _this
              .updateMailer(uuid, path, htmlpath, name, cardType)
              .then((result) => {

                if (!redirect) {
                  return
                }
                if (this.props.mailerType == "campaigns") {
                  setTimeout(() => window.location.href = `/campaigns/${uuid}/recipients`, 0);
                } else {
                  window.location = `/automations#${uuid}#design`;
                }
              });
          } else if (success && redirect) {
            window.location = `/design_elements/new`;
          }
        }
      }
    );
  };

  get authToken() {
    return $('meta[name="csrf-token"]').attr("content");
  }

  updateMailer = (guid, path, htmlpath, name, type) => {
    return new Promise((resolve, reject) => {
      let typeUrl = `/${this.props.mailerType}/${guid}/postcards/${type}`;

      $.post(typeUrl, {
        authenticity_token: this.authToken,
        "postcard[scale]": "plain",
        "postcard[file_name]": name,
        "postcard[path]": path,
        "postcard[html_path]": htmlpath,
      }).done((result) => {
        console.debug("[PATCH] Finished saving postcard");
        resolve(result);
      });
    });
  };

  setActiveObject(obj) {
    this.cache.canvas.setActiveObject(obj);
  }

  fromJson(json) {
    if (typeof json == "undefined") return false;
    this.addTemplateToCanvas(json, this.cache.canvas);
  }

  addTemplateToCanvas(template, canvas) {
    if (template.backgroundImage) {
      template.backgroundImage.crossOrigin = "Anonymous";
    }
    canvas.loadFromJSON(template, canvas.renderAll.bind(canvas), function (
      o,
      object
    ) {
      canvasHelpers.updateObjectWithDefaults(object);

      object.selectable = isQRcodeObject(object);

      controlAllBoundaries(object, canvas);

    });
  }

  activeObject = () => {
    return this.cache.canvas.getActiveObject();
  };

  activeObjects = () => {
    return this.cache.canvas.getActiveObjects();
  };

  addImage = (url) => {
    if (url) {
      fabric.Image.fromURL(
        url,
        (image) => {
          // image.set(
          //   _.extend(canvasHelpers.defaultCanvasObjProperties(), {
          //     left: this.cache.left,
          //     top: this.cache.top,
          //  })
          // );
          image.set({
            left: 0,
            top: 0,
            originX: "left",
            originY: "top"
          });

          if (
            image.height > this.cache.canvas.height ||
            image.width > this.cache.canvas.width
          ) {
            let scaleHeight = this.cache.canvas.height / image.height;
            let scaleWidth = this.cache.canvas.width / image.width;
            if (scaleWidth > scaleHeight || this.cache.isLetter)
              image.scaleToHeight(this.cache.canvas.height);
            else image.scaleToWidth(this.cache.canvas.width);
          }

          image.selectable = false;
          this.addAndRender(image);

        },
        { crossOrigin: "Anonymous" }
      );
    }
  }

  addAndRender(obj) {
    this.cache.canvas.add(obj);

    if (isQRcodeObject(obj)) {
      this.cache.canvas.setActiveObject(obj);
    }

    if (isQRcodeObject(obj) || this.cache.isLetter) {
      obj.center().setCoords();
    }

    this.cache.canvas.renderAll();
  }

  addInkFreeZone(canvas) {
    let inkFreeZoneObj = !this.cache.isLetter
      ? canvasHelpers.reservedArea(
        this.cache.canvasWidth,
        this.cache.canvasHeight
      )
      : canvasHelpers.reservedAreaLetter(
        this.cache.canvasHeight,
        this.cache.canvasWidth
      );
    canvas.add(inkFreeZoneObj);
    return inkFreeZoneObj;
  }

  removeInkFreeZone(canvas) {
    let inkFreeZoneObj = this.state.inkFreeZoneObj;
    canvas.remove(inkFreeZoneObj);
    return "";
  }

  addBleed(canvas) {
    let bleedObj = canvasHelpers.bleedArea(
      this.cache.canvasWidth,
      this.cache.canvasHeight
    );
    canvas.add(bleedObj);
    return bleedObj;
  }

  removeBleed(canvas) {
    let bleedObj = this.state.bleedObj;
    canvas.remove(bleedObj);
    return "";
  }


  mailerName = () => {
    return this.props.mailer ? this.props.mailer.name : "Design Template";
  };

  isBack() {
    return this.props.cardType == "back";
  }

  showCanvasBorder() {
    return (
      this.state.handleKeydown &&
      !this.state.objectSelected &&
      !this.state.groupSelected
    );
  }

  // empty functions needed for canvas-setup
  getCommonObjectAttributes = () => { }
  saveState = () => { }

  restoreQRCode = () => {
    this.cache.canvas.getObjects().filter(object => isQRcodeObject(object)).forEach(object => object.center().setCoords());
    this.cache.canvas.renderAll();
  }

  setQRcode = (QRcode) => {
    this.props.setQRcode(QRcode);
  }

  async addQRCodePNG(url) {

    if (!url) return;

    if (this.props.QRcode) {
      return;
    }

    try {
      const result = await getBase64FromUrl(url);
      fabric.Image.fromURL(result, (image) => {

        image.set('sourcePath', "qrcode.svg");

        image.set(
          _.extend(canvasHelpers.defaultCanvasObjProperties())
        );

        if (
          image.height > this.cache.canvas.height ||
          image.width > this.cache.canvas.width
        ) {
          let scaleHeight = this.cache.canvas.height / image.height;
          let scaleWidth = this.cache.canvas.width / image.width;

          if (scaleWidth > scaleHeight)
            image.scaleToHeight(this.cache.canvas.height);
          else image.scaleToWidth(this.cache.canvas.width);
        }
        this.addAndRender(image);
        URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log(error);
    }

  };

  updateQRCodePNG = async (url) => {

    if (!url) return;

    if (!this.props.QRcode) {
      return;
    }
    const qrcode = this.activeObject();

    if (!qrcode || !isQRcodeObject(qrcode)) {
      return;
    }
    try {
      const result = await getBase64FromUrl(url);
      qrcode.setSrc(result, (image) => {
        this.cache.canvas.renderAll();
        URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log(error);
    }

  }

  render() {
    const pagesClassName = this.showCanvasBorder() ? "Page Selected" : "Page";
    const sideType = _.capitalize(this.props.cardType);
    const [cH, cW] = this.cache.cardSize.split("x");

    return (
      <section
        className={`Canvas ${this.cache.isLetter ? "letter" : ""}`}
        style={{ marginTop: '60px', overflowY: "visible" }}>
        <div
          className={`Pages ${this.cache.isLetter ? "letter" : ""}`}
          style={this.cache.isLetter ? { marginTop: "300px" } : {}}>
          <div className="PostcardMetadata">
            <span className="Side">{sideType} Side</span>{" "}
            <span className="Division">&bull;</span> {cH}&prime; &times; {cW}
            &prime;
          </div>
          <div
            className={pagesClassName}
            style={{
              width: this.state.updatedWidth,
              height: this.state.updatedHeight,
            }}
          >
            <canvas
              id={this.props.id}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default QRCanvas;