import React from "react";
import Switch from "react-input-switch";
import { Radio } from "semantic-ui-react";

import CameraIcon from "../../../assets/images/icon-camera.svg";

class RuleCard extends React.Component {
  constructor(props) {
    props;
    super(props);
    this.state = {
      status: this.props.automation.status,
      purchase: 0,
      rule_data: this.props.automation.rule_data,
    };
  }
  componentDidMount() {
    if (this.props.automation.rule_data) {
      this.getTotalValues(this.props.automation);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.props.automation.status) {
      this.setState({ status: this.props.automation.status });
    }
    if (prevState.rule_data !== this.props.automation.rule_data) {
      this.setState({ rule_data: this.props.automation.rule_data });
    }
  }

  getTotalValues = (automation) => {
    let customDates = automation.rule_data.mailing_date || [];
    fetch(`automations/${automation.guid}/total_values`, {
      method: "POST",
      body: JSON.stringify({
        selected_dates: customDates,
        rule_option: automation.rule_option
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.authToken,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ purchase: data.price });
      })
      .catch((error) => console.log(error));
  };

  changeStatus = (e, data) => {
    fetch(`automations/${this.props.automation.guid}/change_status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.authToken,
      },
      body: JSON.stringify({
        automation: {
          status: data.checked ? "active" : "not-active",
        },
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Not success");
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ status: data.status });
        this.props.updateStatus(data.guid, data.status);
      })
      .catch((error) => console.log(error));
  };

  priceData() {
    return this.state.purchase == 0 ? "Not Set" : `$${this.state.purchase}`
  }

  imageData(side) {
    let imageFrontPath = this.props.automation.front;
    let imageBackPath = this.props.automation.back;
    if (side === "front" && imageFrontPath) {
      return `https://mailfold-campaign-src.imgix.net/${this.props.automation.front.path}`;
    } else if (side === "back" && imageBackPath) {
      return `https://mailfold-campaign-src.imgix.net/${this.props.automation.back.path}`;
    } else {
      return "";
    }
  }

  renderStatus = () => {
    if (this.state.status == "active") return "running";
    if (this.state.status == "draft" || this.state.status == "disabled")
      return "disabled";
    return "not-active";
  };

  render() {
    return (
      <div className="rule-grid-item">
        <div className="rule-card">
          <div className="rule-body">
            <div className="header">
              <div className="title">
                {this.props.automation.title
                  ? this.props.automation.title
                  : "Untitled Automation"}
              </div>
              <div
                className="details"
                onClick={() => {
                  this.props.openAutomationModal(this.props.automation.guid);
                }}
              >
                View rules
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711Z"
                    fill="#179960"
                  />
                </svg>
              </div>
            </div>
            <div className="preview">
              <div
                className={`preview-image ${
                  this.props.automation.postcard_size === "8.5x11"
                    ? "letter"
                    : ""
                }`}
              >
                {this.props.automation.front ? (
                  <>
                    {typeof this.imageData("front") === "string" &&
                    this.imageData("front").indexOf("svg") !== -1 ? (
                      <object
                        data={this.imageData("front")}
                        type="image/svg+xml"
                        className="automation_preview_image"
                      ></object>
                    ) : (
                      <img src={this.imageData("front")} />
                    )}
                  </>
                ) : (
                  <div className="preview-image-cover">
                    <span>Front</span>
                    <img src={CameraIcon} />
                  </div>
                )}
              </div>
              {this.props.automation.postcard_size === "8.5x11" ? null : (
                <div className="preview-image">
                  {this.props.automation.back ? (
                    <>
                      {typeof this.imageData("back") === "string" &&
                      this.imageData("back").indexOf("svg") !== -1 ? (
                        <object
                          data={this.imageData("back")}
                          type="image/svg+xml"
                          className="automation_preview_image"
                        ></object>
                      ) : (
                        <img src={this.imageData("back")} />
                      )}
                    </>
                  ) : (
                    <div className="preview-image-cover">
                      <span>Back</span>
                      <img src={CameraIcon} />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="settings margin-top-xs">
              <div>
                <div className="setting-key">Mail to:</div>
                <div className="setting-value">
                  {this.props.automation.address_book
                    ? this.props.automation.address_book.name
                    : "Not Set"}
                </div>
              </div>
              {this.state.rule_data && (
                <>
                  <div>
                    <div className="setting-key">Delivery timing:</div>
                    <div className="setting-value">
                    {this.props.automation.rule_option == "asap" ? "Every week in specific days":
                      this.state.rule_data.mailing_date &&
                        (this.state.rule_data.mailing_date.length > 0
                          ? this.state.rule_data.mailing_date.map(
                            (item, index) => {
                              return (index == this.state.rule_data.mailing_date.length - 1 ? item : (item + ", "))
                            }
                          )
                          : "Not Set")
                    }
                    </div>
                  </div>
                  <div className="setting-devider" />
                  <div>
                    <div className="setting-key">Purchase amount:</div>
                    <div className="setting-value">
                      {this.state.rule_data
                        ? this.priceData()
                        : "Not Set"}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="rule-footer">
            <div>
              This rule is
              <span className="rule-status"> {this.renderStatus()} </span>
            </div>
            <div className="rule-togler">
              <Radio
                className="green-toggler"
                toggle
                checked={this.state.status === "active"}
                disabled={this.state.status == "disabled"}
                onChange={this.changeStatus}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RuleCard;
