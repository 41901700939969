import React from "react";

import Help from "../../../../assets/images/sidebar-help.svg";
import StripeButton from "../../../../assets/images/blue-on-light.png";

class AgencySidebar extends React.Component {
  constructor(props) {
    props;
    super(props);
  }

  render() {
    return (
      <aside className="PageSidebar HelpSidebar AgencySidebar">   

        {this.props.agency ?
          this.props.agency.stripe_account_url ?

            <div className="stripe-connect-block">
              <a href={this.props.agency.stripe_account_url} className="agency-stripe-connect">
                <span className="stripe-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 91">
                    <g fill="none" fill-rule="evenodd">                     
                      <path fill="#FFF" d="M43.0168821,37.3455209 C43.0168821,35.6487301 44.4103217,34.9961182 46.7182061,34.9961182 C50.0276252,34.9961182 54.2079441,35.9967897 57.5173632,37.7805955 L57.5173632,27.556343 C53.9031292,26.1205969 50.3324401,25.555 46.7182061,25.555 C37.8785734,25.555 32,30.1667905 32,37.8676104 C32,49.8756686 48.5470956,47.9613404 48.5470956,53.1387279 C48.5470956,55.1400709 46.805296,55.792683 44.3667767,55.792683 C40.7525427,55.792683 36.1367739,54.3134292 32.4789949,52.3120862 L32.4789949,62.666861 C36.5286788,64.407159 40.6219077,65.146786 44.3667767,65.146786 C53.4241343,65.146786 59.6510676,60.665518 59.6510676,52.8776831 C59.6075226,39.9124609 43.0168821,42.2183561 43.0168821,37.3455209 Z"></path>
                    </g>
                  </svg>
                </span>  
                <span className="stripe-text">Go to my Stripe</span>
              </a>
              <p className="stripe-connect-block-text">Visit my Stripe account</p>
              <a className="stripe-about-link" href="https://stripe.com/about">What is Stripe?</a>
            </div>  

         : this.props.agency.stripe_setting_account_url &&

          <div className="stripe-connect-block">
            <a href={this.props.agency.stripe_setting_account_url} className="agency-stripe-connect">
              <span className="stripe-svg">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 91">
                  <g fill="none" fill-rule="evenodd">                     
                    <path fill="#FFF" d="M43.0168821,37.3455209 C43.0168821,35.6487301 44.4103217,34.9961182 46.7182061,34.9961182 C50.0276252,34.9961182 54.2079441,35.9967897 57.5173632,37.7805955 L57.5173632,27.556343 C53.9031292,26.1205969 50.3324401,25.555 46.7182061,25.555 C37.8785734,25.555 32,30.1667905 32,37.8676104 C32,49.8756686 48.5470956,47.9613404 48.5470956,53.1387279 C48.5470956,55.1400709 46.805296,55.792683 44.3667767,55.792683 C40.7525427,55.792683 36.1367739,54.3134292 32.4789949,52.3120862 L32.4789949,62.666861 C36.5286788,64.407159 40.6219077,65.146786 44.3667767,65.146786 C53.4241343,65.146786 59.6510676,60.665518 59.6510676,52.8776831 C59.6075226,39.9124609 43.0168821,42.2183561 43.0168821,37.3455209 Z"></path>
                  </g>
                </svg>
              </span>  
              <span className="stripe-text">Connect with Stripe</span>
            </a>
            <p className="stripe-connect-block-text">Complete filling of your Stripe account to get revenue from your Clients</p>
            <a className="stripe-about-link" href="https://stripe.com/about">What is Stripe?</a>
          </div>  

        : "" }
        <div className="Content PageContent FlexContent">
          <div className="AutomationSidebar">
            <div className="AutomationSidebarBottom automations">
              <img src={Help} alt="Help img" />
              <h2 className="AutomationSidebar-title">Getting Help</h2>
              <p>Need help with something? Try visiting our <a href="#">Help Center</a> for some answers. If you need to reach someone, contact Mailfold's support by emailing <a href="#">support@mailfold.com</a></p>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

export default AgencySidebar;
