import React, { Fragment, useState, useEffect } from "react";
import AgenciesGraphs from "./AgenciesGraphs";
import moment from "moment";

import icon1 from "../../../../assets/images/agency-icon-add.svg";
import icon2 from "../../../../assets/images/agency-icon-customize.svg";

const clientsStatisticsTypeTitles = {
  pending: "Pending",
  registered: "Registered",
  inactive_30: "Inactive for 30 days",
  inactive_90: "Inactive for 90 days"
}
const AgencyDashboard = (props) => {

    const optionsDonut = () =>  {
      let result = {
        datasets: [{
          data: [],
          backgroundColor: ['#FADDDC', '#8BCCAF', '#F0EFEF', '#FDC8A0'],
        }],
        options: {
          legend: {
            position: 'bottom'
          },
        },
        labels: []
      }
      Object.entries(props.clients_statistic).forEach(([key, value]) => {
        result.datasets[0].data.push(value);
        result.labels.push(clientsStatisticsTypeTitles[key])
      });
      return result
    };
    const graphTableHeader = Object.entries(props.clients_statistic).map(([key, value]) => {
      return [clientsStatisticsTypeTitles[key], value]
    });

    const dropdownOptions = [
        {
          key: 'Postcards',
          text: 'Postcards',
          value: 'Postcards',
        },
        {
          key: 'Revenue',
          text: 'Revenue',
          value: 'Revenue',
        }
      ]
    const optionsBar = (selectedItem) => {
      let labels = [];
      let datasets = [
        {
          label: selectedItem == "Revenue" ? "Earned revenue" : 'Postcards Sent',
          backgroundColor: '#9BA5D1',
          borderColor: 'rgba(0,0,0,1)',
          data: [],
          roundedBar: {
              categoryPercentage: 0.8,
              barPercentage: 0.9
          }
        }
      ];
      props.statistic.forEach((item, i) => {
        let period = moment(item.period, 'MM YYYY');
        labels.push(period.format('MMMM, YY'));
        datasets[0].data.push(selectedItem == "Revenue" ? item.revenue : item.mails_amount)
      });
      return { labels: labels, datasets: datasets }
    }

    const OptionsDonutName = "Agency Clients";
    const OptionsDonutHeaderTitle = "Agency Clients activity";
    const OptionsBarHeaderTitle = "Activity and Revenue";

    const [selectedDropdown, setSelectedDropdown] = useState("Postcards")
    const graphType = 'bar';
    return (
        <main className="agency-dashboard">
            <header className="agency-header">
                <h1 className="agency-header-title">sdfkjsd</h1>
            </header>
            <div className="dashboard-middle-container">
                <h2 className="dashboard-middle-container-title">Getting Started with the Agency Edition</h2>

                <div className="dashboard-middle-section">
                    <div className="dashboard-middle-block">
                        <h2 className="dashboard-middle-block-title">Add Clients</h2>
                        <img src={icon1}></img>
                        <a href="/clients" className="dashboard-middle-block-link">Add new client</a>
                    </div>

                    <div className="dashboard-middle-block">
                        <h2 className="dashboard-middle-block-title">Customize branding</h2>
                        <img src={icon2}></img>
                        <a href="agency_settings" className="dashboard-middle-block-link">Customize branding</a>
                    </div>
                </div>

            </div>
            <div className="dashboard-graph-block">
                <AgenciesGraphs
                  showTable={true}
                  options={optionsDonut()}
                  headerText={OptionsDonutHeaderTitle}
                  title={OptionsDonutName}
                  graphTableHeader={graphTableHeader}
                />
                <AgenciesGraphs
                  showSelect={true}
                  options={optionsBar(selectedDropdown)}
                  graphType={graphType}
                  headerText={OptionsBarHeaderTitle}
                  dropdownOptions={dropdownOptions}
                  selectedDropdown={selectedDropdown}
                  setSelectedDropdown={setSelectedDropdown}
                  title={selectedDropdown ==  "Postcards" ? " Postcards Sent" : "Revenue Amount"}
                />
            </div>
        </main>
    )
}
export default AgencyDashboard;
