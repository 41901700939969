import React, { useState } from "react";

import { Modal } from "semantic-ui-react";

import QRImg1 from "../../../assets/images/QR-code-builder-icon-1.jpg";
import QRImg2 from "../../../assets/images/QR-code-builder-icon-2.jpg";
import {postData} from "../../lib/fetches";
import SubscriptionPremiumTypes from "../shared/subscriptions/SubscriptionPremiumTypes";
import { premiumSubscriptionByType, premiumSubscriptionTypeByName } from "../shared/subscriptions/utils";


const QRCode = (props) => {

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [premiumType, setPremiumType] = useState(() => premiumSubscriptionTypeByName(props.premium_user))
    const goToBuilder = () => {
        window.location = `/qr_code_elements/new?cid=${props.campaign.guid}&card_size=${props.campaign.postcard_size}&mailer_type=campaigns`
    };

    const redirectToCcUpdate = () => {
        window.location.href = "/settings/?tab=billing";
    }

    const upgradeAccount = (premiumType) => {

        if (props.with_card) {
            postData(
                "/set_premium",
                props.authToken,
                {premiumType: premiumSubscriptionByType(premiumType)},
                () => {
                    setIsModalOpened(false);
                    goToBuilder();
                }
            );
        } else {
            redirectToCcUpdate();
        }
    }

    return (
        <div className="qr-page__container">
            <div className="qr-page__content">
                <div className="qr-page__text-block">
                    <div className="qr-page__main-info">
                        <h1>QR Code Tracking</h1>
                        <p>
                            Add a QR code to your postcard to monitor your campaigns and adjust your marketing if needed.
                        </p>
                    </div>

                    {
                        props.premium_user ?
                            (<div className="qr-page__text-block-actions">
                                <button
                                    className="Button greenSubmit"
                                    onClick={goToBuilder}
                                >
                                    {props.with_qr_code ? 'Edit ' : 'Add ' }
                                    QR code
                                </button>
                            </div>)
                            :
                            (<div className="qr-page__additional-info">
                                <h3>Requires Premium Plan</h3>

                                <p>
                                    You’ll need to upgrade to Mailfold’s Premium Plan starting from $99 per month to start using QR code tracking. Once upgraded, you can add a QR code to your postcard to monitor your campaigns.
                                </p>

                                <button className="green-bordered-button" onClick={() => setIsModalOpened(true)}>Upgrade now</button>

                                <Modal
                                    open={isModalOpened}
                                    closeOnEscape={true}
                                    closeOnDimmerClick={true}
                                    className="contacts-segment-modal"
                                    onClose={() => setIsModalOpened(false)}
                                >
                                    <div className="contacts-segment-container small">

                                        <div className="contacts-segment-body">
                                            <h3 className="segment-modal-small-title">
                                                Upgrade to Premium
                                            </h3>
                                            <h4 className="segment-modal-small-subtitle">
                                                In order to be able add QR code you have to upgrade your account to Premium. Upgrade now?
                                            </h4>
                                            <h4 className="segment-modal-small-subtitle">
                                                If your CC is not connected to the account yet, you will be redirected to the Settings page.
                                            </h4>
                                            {props.with_card && <SubscriptionPremiumTypes premiumType={premiumType} setPremiumType={setPremiumType}/>}
                                        </div>
                                        <div className="segment-modal-footer">
                                            <div className="grid grid-2 segment-modal-buttons">
                                                <div className="grid-item">
                                                    <button
                                                        className="blue-button"
                                                        style={{ fontSize: "14px" }}
                                                        onClick={() => upgradeAccount(premiumType)}
                                                    >
                                                        Upgrade to Premium
                                                    </button>
                                                </div>
                                                <div className="grid-item">
                                                    <button
                                                        className="gray-button"
                                                        onClick={() => setIsModalOpened(false)}
                                                    >
                                                        Maybe later
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Modal>
                            </div>)

                    }
                    {
                        props.with_qr_code && (
                            <button style={{width: "260px", marginTop: "10px"}} onClick={() => {window.location = `${props.qr_code_element_name}`}}>check qr code</button>
                        )
                    }
                </div>
                <div className="qr-page__image-container">
                    <img src={QRImg1} alt="QR-code image" width="320" height="200"/>
                    <img src={QRImg2} alt="QR-code image" width="320" height="200"/>
                </div>
            </div>

            <div className="InnerColumnContent">
                <form action={`/campaigns/${props.campaign.guid}/skip_qr_code`} className="SubmitContainer" method="post">
                    <div className="InnerColumnContentWrapper">
                        <div className="InnerColumnContent buttons">
                            <input
                                type="button"
                                className="Button ExitButton"
                                value="Go Back"
                                onClick={() => {
                                    window.location.href = `/campaigns/${props.campaign.guid}/design`
                                }}
                            />
                            <div>
                                <input
                                    type="button"
                                    value="Save & Exit"
                                    className="Button OutlinedButton"
                                    onClick={() => window.location.href = "/campaigns"}
                                />
                                <button
                                    className="Button PurpleSubmit"
                                    type="submit"
                                >
                                    Next Step
                                </button>
                            </div>

                        </div>

                    </div>

                </form>
            </div>

        </div>
    )
};

QRCode.propTypes = {};

export default QRCode;
