import React from "react";
import PropTypes from "prop-types";
import Option from "./CardOption";

export default class Size extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    size: PropTypes.instanceOf(Option).isRequired,
    sizes: PropTypes.objectOf(Option).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { size: props.size };
    this.onChange = this.onChange.bind(this);
  }

  // @size i.e 4x6
  isSelected(size) {
    return size == this.state.size.id();
  }

  // User selected a different postcard size
  onChange(event) {
    const id = event.target.value;
    if (!id) {
      return console.error("[Bug] No id found");
    } else {
      console.info("Got id", id);
    }
    const size = this.props.sizes[id];
    if (!size) {
      return console.error("[Bug] no size found");
    }

    this.setState({ size });
    this.props.onChange(size);
  }

  isSmall() {
    return this.state.size.boxClass() == "SmallCard";
  }

  isMedium() {
    return this.state.size.boxClass() == "MediumCard";
  }

  isLarge() {
    return this.state.size.boxClass() == "LargeCard";
  }

  isLetter() {
    return this.state.size.boxClass() == "LetterCard";
  }

  psdTemplate() {
    return this.state.size.psdTemplate();
  }

  pngTemplate() {
    return this.state.size.pngTemplate();
  }

  heightInInches() {
    return this.state.size.heightInInches;
  }

  widthInInches() {
    return this.state.size.widthInInches;
  }

  height() {
    return this.state.size.height;
  }

  width() {
    return this.state.size.width;
  }

  selectClass() {
    return this.state.size.selectClass();
  }

  options() {
    return Object.keys(this.props.sizes).map((key) => {
      return this.props.sizes[key];
    });
  }

  render() {
    const { size } = this.state;
    const verticalStyle = size.className;
    return (
      <fieldset className="ListOptions postcard-form">
        <div className="grid grid-2">
          <div className="grid-item">
          <h1> Post Card Design </h1>
          <p>
            Create a postcard design with our design tool, use our design tool to customize a
            template or provide your own design files.
          </p>

            {/* <h2>Select a Card Size</h2>
            <p>
              Postcards are printed on a thick 120lb card stock with a UV-coated
              gloss on the front and an uncoated back.
            </p> */}

            <div className="Options">
              <For each="size" of={this.options()}>
                <Option
                  size={size}
                  isSelected={this.isSelected(size.id())}
                  onChange={this.onChange}
                ></Option>
              </For>
            </div>
          </div>
          <div className="grid-item">
            <div
              className={`OptionsPreview Internal ${
                verticalStyle === "Letter" ? "visible" : null
              }`}
            >
              <h4>Preview</h4>
              <div className={this.selectClass()} id="CardsContainer">
                <div className="ForegroundCards">
                  <If condition={this.isLetter()}>
                    <div className="LetterCard">
                      <div className="ActualSize">
                        <span>
                          {this.heightInInches() === 8.5
                            ? 8.5
                            : this.heightInInches()}
                          ″ × {this.widthInInches()}″
                        </span>
                      </div>
                      <div className="LetterCard Bleed"></div>
                      <div className="LetterCard OutsideBorder"></div>
                      <div className="MediumCardVertical GhostedCard"></div>
                      <div className="SmallCardVertical GhostedCard"></div>
                    </div>
                  </If>

                  <If condition={this.isLarge()}>
                    <div className="LargeCard">
                      <div className="ActualSize">
                        <span>
                          {this.heightInInches()}″ × {this.widthInInches()}″
                        </span>
                      </div>
                      <div className="LargeCard Bleed"></div>
                      <div className="LargeCard OutsideBorder"></div>
                      <div className="MediumCard GhostedCard"></div>
                      <div className="SmallCard GhostedCard"></div>
                    </div>
                  </If>

                  <If condition={this.isMedium()}>
                    <div className="MediumCard">
                      <div className="ActualSize">
                        <span>
                          {this.heightInInches()}″ × {this.widthInInches()}″
                        </span>
                      </div>
                      <div className="MediumCard Bleed"></div>
                      <div className="MediumCard OutsideBorder"></div>
                      <div className="SmallCard GhostedCard"></div>
                    </div>
                  </If>

                  <If condition={this.isSmall()}>
                    <div className="SmallCard">
                      <div className="ActualSize">
                        <span>
                          {this.heightInInches()}″ × {this.widthInInches()}″
                        </span>
                      </div>
                      <div className="SmallCard Bleed"></div>
                      <div className="SmallCard OutsideBorder"></div>
                    </div>
                  </If>
                </div>

                {verticalStyle !== "Letter" ? (
                  <div className="BackgroundCards">
                    <div className="LargeCard GhostedCard"></div>
                    <div className="MediumCard GhostedCard"></div>
                    <div className="SmallCard GhostedCard"></div>
                  </div>
                ) : null}
              </div>
              <div className="Tip">
                <div>
                  Your files must be 6.5” × 9.5” in order to include a 0.25”
                  bleed.{" "}
                  <a href={this.psdTemplate()}>Download the 6 × 9 template.</a>
                </div>

                <div className="GeneralTip">
                  Pick a card size and we'll provide you with a link to the
                  template.
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
