import React, { Fragment } from "react";

import { Dropdown, Input, Modal } from "semantic-ui-react";

function SettingsModal(props) {
  const { modalContext, openModalPopUp, isPremiumUser } = props;

  return (
    <Fragment>
      <div className="contacts-segment-container small">
        <div className="contacts-segment-body">
          {modalContext === "importSuccess" ? (
            <>
              <h3 className="segment-modal-small-title">
                Import was successful!
              </h3>
              <h4 className="segment-modal-small-subtitle">
                Import contact information was successful! You can import more
                contacts or go to the created list.
              </h4>
            </>
          ) : modalContext === "fieldsLimit" ? (
            <>
              <h3 className="segment-modal-small-title">
                Limit in adding fields
              </h3>
              <h4 className="segment-modal-small-subtitle">
                The maximum number of fields you can add is 20. If you want to
                create new ones, you need to delete the old ones.
              </h4>
            </>
          ) : (
                <></>
              )}
        </div>
        <div className="segment-modal-footer">
          {modalContext === "fieldsLimit" ? (
            <button
              className="blue-button"
              onClick={() => openModalPopUp(false, null)}
            >
              Ok
            </button>
          ) : (
              <div className={`grid grid-2 segment-modal-buttons ${isPremiumUser ? 'one-btn' : ''}`} >
                <div className="grid-item">
                  <button
                    className="gray-button"
                    onClick={() => {
                      window.location.href = "/contacts";
                      openModalPopUp(false, null);
                    }}
                  >
                    Ok
                </button>
                </div>
                {!isPremiumUser ? (<div className="grid-item">
                  <button
                    className="blue-button"
                    onClick={() => {
                      window.location.href = "/settings?tab=account";
                      openModalPopUp(false, null);
                    }}
                  >
                    {modalContext === "importSuccess" ? "Up to Premium" : "Ok"}
                  </button>
                </div>) : <></>}
              </div>
            )}
        </div>
      </div>
    </Fragment>
  );
}

export default SettingsModal;
