import React, { Component } from 'react';
import QRGenerator from '../design_elements/QRGenerator';
import SidebarButton from "../design_elements/SidebarButton";
import QRCanvas from "./QRCanvas";
import QRCodeOptions from './QRCodeOptions';
import { getPanels } from './QRCodeOptionsPages';
import QRPropertiesBar from './QRPropertiesBar';
import QRToolTray from './QRToolTray';
import { isQRcodeObject, QRDesignUtils } from './QRUtils';

const initialQRoptions = {
  width: 270,
  height: 270,
  //type: 'svg',
  type: 'canvas',
  data: '',
  image: '',
  margin: 10,
  qrOptions: {
    typeNumber: 0,
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 20,
    crossOrigin: 'anonymous',
  },
  dotsOptions: {
    color: '#222222',
    // gradient: {
    //   type: 'linear', // 'radial'
    //   rotation: 0,
    //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
    // },
    type: 'rounded'
  },
  backgroundOptions: {
    color: '#FFFFFF',
    // gradient: {
    //   type: 'linear', // 'radial'
    //   rotation: 0,
    //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
    // },
  },
  cornersSquareOptions: {
    color: '#222222',
    type: 'extra-rounded',
    // gradient: {
    //   type: 'linear', // 'radial'
    //   rotation: 180,
    //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
    // },
  },
  cornersDotOptions: {
    color: '#222222',
    type: 'dot',
    // gradient: {
    //   type: 'linear', // 'radial'
    //   rotation: 180,
    //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
    // },
  }
};

class QRDesignCanvas extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTray: "",
      showBleed: QRDesignUtils.getValueFromStorage("showBleed", false),
      showSupportBubble: false,
      handleKeydown: false,
      savingTemplate: false,
      // TODO - add new ??
      // activeCanvas: "",
      QRoptions: { ...initialQRoptions, ...(props.qrCodeData || {}) },
      qrCodeRedirectUrl: props.qrCodeRedirectUrl,
      QRcode: null,
      isActiveQRcode: false,
    };

    this.changeActiveTray = this.changeActiveTray.bind(this);
    this.state.QRoptions.data = this.state.qrCodeRedirectUrl;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeydown, true);
    window.addEventListener("click", this.handleClick, true);
  }

  componentDidUpdate() {
    // if (this.state.QRoptions.data !== this.state.qrCodeRedirectUrl) {
    //   this.setState({ QRoptions: { ...this.state.QRoptions, data: this.state.qrCodeRedirectUrl } });
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeydown, true);
    window.removeEventListener("click", this.handleClick, true);
  }

  handleEscape() {

    this.refs.editor.classList.remove("TrayOpen");

    if (QRDesignUtils.isLetter(this.props.cardSize)) {
      this.refs.canvas_front.handleEscape();

    } else {
      this.refs.canvas_front.handleEscape();

      this.refs.canvas_back.handleEscape();
    }
  }

  handleZ(event) {
    let { ctrlKey, metaKey } = event;

    if (ctrlKey || metaKey) {
      event.preventDefault();
      this.restore();
    }
  }

  handleKeydown = (e) => {

    const keyCode = e.keyCode;

    if (keyCode === 27) this.handleEscape();
    if (keyCode === 90) this.handleZ(e);

    if (!this.state.handleKeydown) return;

    if (QRDesignUtils.isLetter(this.props.cardSize)) {
      this.refs.canvas_front.handleKeydown(e);
    } else {
      this.refs.canvas_front.handleKeydown(e);
      this.refs.canvas_back.handleKeydown(e);
    }

  };

  handleClick = (e) => {
    let handleKeydown = false;
    let activeTray = this.state.activeTray;

    if (_.includes(e.target.classList, "upper-canvas")) {
      handleKeydown = true;

      if (activeTray) {
        this.refs.editor.classList.remove("TrayOpen");
        activeTray = "";
      }
    }

    this.setState({
      handleKeydown,
      activeTray,
    });
  };


  changeActiveTray(trayType) {
    if (trayType == "Back") {
      this.refs.editor.classList.remove("TrayOpen");
      this.setState({ activeTray: "" });
    } else {
      this.refs.editor.classList.add("TrayOpen");
      this.setState({ activeTray: trayType });
    }
  }


  setLocalStorage(options) {
    _.forEach(Object.keys(options), (key) =>
      localStorage.setItem(key, options[key])
    );
  }

  toggleBleed = () => {
    let options = { showBleed: !this.state.showBleed };
    this.setLocalStorage(options);
    this.setState(options);
  };

  //TODO - move to common module
  mailerName = () => {
    return this.props.mailer ? this.props.mailer.name : "Design Template";
  };
  mailerType = () => {
    return this.props.mailerType ? this.props.mailerType : "Design Template";
  };

  saveTemplate = async () => {

    console.debug("Called onSave in QRDesignCanvas.js");
    this.setState({ savingTemplate: true });
    try {
      if (QRDesignUtils.isLetter(this.props.cardSize)) {
        await this.refs.canvas_front.saveTemplate();

      } else {
        await this.refs.canvas_front.saveTemplate(false, false);
        await this.refs.canvas_back.saveTemplate();
      }
    } catch (error) {
      console.debug("Error occurs on Save 2 canvases")
    }
    this.setState({ savingTemplate: false });
  }

  setQRoptionsByNodeNames = (names, value) => {
    const options = { ...this.state.QRoptions };
    const list = names.split(".");
    if (list.length === 1) {
      options[names] = value;
    } else if (list.length > 1) {

      let temp = options;
      const name = list.pop();
      list.forEach(item => {
        temp = temp[item]
      })
      temp[name] = value;
    }
    else {
      console.log("Invalid argument ", names);
      return;
    }

    this.setState({ QRoptions: options }, () => {
      this.updateQRCodePNG()
    })
  }

  setQRcode = (QRcode) => {
    this.setState({ QRcode });
  }

  setQRRedirectLink = (qrCodeRedirectUrl, imageUrl) => {
    this.setState({ qrCodeRedirectUrl })
    this.setState({ QRoptions: { ...this.state.QRoptions, data: qrCodeRedirectUrl } });
    this.refs.canvas_front.addQRCodePNG(imageUrl);
  }

  updateQRCodePNG = async () => {
    const imageUrl = await this.refs.generator.getQRCodeUrl();
    if (QRDesignUtils.isLetter(this.props.cardSize)) {
      this.refs.canvas_front.updateQRCodePNG(imageUrl);
    } else {
      this.refs.canvas_front.updateQRCodePNG(imageUrl);
      this.refs.canvas_back.updateQRCodePNG(imageUrl);
    }
  }

  restore = () => {

    if (QRDesignUtils.isLetter(this.props.cardSize)) {
      this.refs.canvas_front.restoreQRCode();

    } else {
      this.refs.canvas_front.restoreQRCode();
      this.refs.canvas_back.restoreQRCode();
    }

  }

  setActiveQRcode = (isActiveQRcode) => {
    this.setState({isActiveQRcode});
  }

  render() {
    const bleedButtonClass = this.state.showBleed
      ? "BleedButton On"
      : "BleedButton Off";

    const saveTemplateBtnClass = this.state.savingTemplate
      ? "Button PurpleButton Disabled Loading"
      : "Button PurpleButton";

    const qrPanels = getPanels(this.state.QRoptions, this.setQRoptionsByNodeNames);

    const {
      cardSize,
      designOption,
      mailer,
      mailerType,
      personalizedValues,
      qrCodeCustomerUrl,
      frontDesignElement,
      backDesignElement,
    } = this.props;

    //console.log({ frontDesignElement, backDesignElement });
    const commonCardProps = {
      cardSize,
      mailerType,
      mailer,
      personalizedValues,
      qrCodeRedirectUrl: this.state.qrCodeRedirectUrl,
      qrOptions: this.state.QRoptions,
      designOption,
      QRcode: this.state.QRcode,
      setQRcode: this.setQRcode,
      setActiveQRcode: this.setActiveQRcode,
    };

    return (
      <div ref="editor" className="Editor">
        <header className="GlobalHeader DesignHeader">
          <div className="Content">
            {/* <a className="Logo" href={`/${this.mailerType()}`}>
              Mailfold
            </a> */}
            <div className="CampaignName">{this.mailerName()}</div>
            <nav>
              <button className="Button ExitButton canvas-save-design" onClick={() => window.location.href = `/campaigns/${this.props.mailer.guid}/qr_code`}>Cancel</button>

              <button
                className={`${saveTemplateBtnClass} canvas-save-design `}
                disabled={this.state.savingTemplate}
                onClick={() => {
                  this.saveTemplate();
                }}
              >
                Save
              </button>

            </nav>
          </div>
        </header>
        <aside className="ButtonBar">
          <div className="CloseButtonOverlay">
            <div className="DesignerButtons">
              <SidebarButton
                changeActiveTray={this.changeActiveTray}
                label="Back"
              />
            </div>
          </div>

          <div className="DesignerButtons PrimaryButtons">
            <SidebarButton
              changeActiveTray={this.changeActiveTray}
              label="Add QR" />
          </div>
          <div className="DesignerButtons BottomButtons">
            <div className="SlimButton UndoButton">
              <span
                onClick={() => {
                  this.restore();
                }}
                className="ButtonLabel"
              >
                Undo
              </span>
            </div>
          </div>

        </aside>
        <QRToolTray>
          <QRGenerator
            ref="generator"
            options={this.state.QRoptions}
            campaignId={mailer?.guid}
            qrCodeCustomerUrl={qrCodeCustomerUrl}
            qrCodeRedirectUrl={this.state.qrCodeRedirectUrl}
            setQRRedirectLink={this.setQRRedirectLink}

          />
        </QRToolTray>

        <div onClick={this.toggleBleed} className={bleedButtonClass}>
          <span className="Icon"></span>
          <span className="HiddenLabel On">Hide Bleed</span>
          <span className="HiddenLabel Off">Show Bleed</span>
        </div>

        <main style={!QRDesignUtils.isLetter(this.props.cardSize) ? { flexDirection: "column", justifyContent: "space-between" } : {}}>

          <QRCanvas
            id="canvas_front"
            key="canvas_front"
            ref="canvas_front"
            showBleed={this.state.showBleed}
            cardType="front"
            designElement={frontDesignElement}
            {...commonCardProps}
          />

          {!QRDesignUtils.isLetter(this.props.cardSize) && (<QRCanvas
            id="canvas_back"
            key="canvas_back"
            ref="canvas_back"
            showBleed={this.state.showBleed}
            cardType="back"
            designElement={backDesignElement}
            {...commonCardProps}
          />)}

        </main>

        {this.state.isActiveQRcode && <QRPropertiesBar>
          <QRCodeOptions
            panels={qrPanels}
          />
        </QRPropertiesBar>
        }

      </div>
    );
  }
}

export default QRDesignCanvas;