import React from 'react'

const styles = {
  panel: {
    padding: "118px 20px 80px 20px",
 },
}

class QRPropertiesBar extends React.Component {

  render() {
    const showStyle = true;
    const selectedStyleClass = showStyle ? "SegmentOption Selected" : "SegmentOption";
    
    return (
      <aside className="PropertiesBar">
        <div className="StickyControl">
          <div className="SegmentedControl Tabs">
            <label
              className={selectedStyleClass}
              id="StyleControl"
              htmlFor="StyleDisplay">
              <span>Style</span>
            </label>

          </div>
        </div>
        <div className="PropertiesBarContent StyleProperties" style={styles.panel}>
          {this.props.children}
        </div>
      </aside>
    )
  }
}

QRPropertiesBar.propTypes = {}

export default QRPropertiesBar
