import React from 'react';
import { Message } from 'semantic-ui-react';

const styles = {
    section: {
        width: '100%'
    },
    text: {
        color: 'rgba(256, 0, 0, 0.8)',
        fontSize: '0.85em',
        whiteSpace: 'pre-line',
        lineHeight: '1.2',
        margin: '5px 0 0 0'
    }

}

const Warning = ({ data }) => {
    return (
        <div style={styles.section}>
            {
                data && (<div style={styles.text}>{data}</div>)
            }
        </div>
    );
};

export default Warning;
