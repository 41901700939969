import React from 'react'
import PropTypes from "prop-types"
// import QRCodeOptions from "./QRCodeOptions";

class StyleProperties extends React.Component {
  showTemplateSaveOption() {
    return _.isEmpty(this.props.parentState.mailer) && this.props.admin
  }

  backgroundHasImage() {
    if(this.props.parentState.canvas)
      return !_.isEmpty(this.props.parentState.canvas.backgroundImage)
  }

  render() {
    const {objectSelected, shapeSelected, textSelected, fontSize, fontWeightStyle,
      charSpacing, lineHeight, showBgColorButton} = this.props.parentState

    const {objectColorPicker, fontFamilyOptions, setFontSize, setFontWeightStyle,
      resetTextStyle, setUnderline, setLinethrough, textAlignment, setCharSpacing,
      setLineHeight, resetBackground, bgColorPicker, templateForm} = this.props
    return (
      <div className="PropertiesBarContent StyleProperties">
        <If condition={objectSelected}>
          {/* <QRCodeOptions/> */}
          <If condition={shapeSelected}>
            <section>
              <h3>Appearance</h3>
              <div className="SingleInputRow">
                <label>Shape Color</label>
                {objectColorPicker}
              </div>
            </section>
          </If>
        </If>

        <If condition={textSelected}>
          <section className="FontProperties">
            <h3>Font</h3>
            {fontFamilyOptions}
            <input  value={fontSize}
                    type="number"
                    className="Third" ref="fontSize"
                    placeholder="size"
                    onChange={() => {setFontSize(this.refs.fontSize.value)}} />

            <select ref="fontWeightStyle"
                    key={fontWeightStyle}
                    defaultValue={fontWeightStyle}
                    onChange={() => {setFontWeightStyle(this.refs.fontWeightStyle.value)}}
                    className="TwoThirds">
              <option value="">Regular</option>
              <option value="700">Bold</option>
              <option value="italic">Italic</option>
              <option value="700,italic">Bold Italic</option>
            </select>

            <div className="SegmentedControl TwoThirds">
              <label className="SegmentOption"
                     id="RegularTextStyleControl"
                     htmlFor="RegularTextStyle"
                     onClick={ () => {resetTextStyle()}}>Aa</label>

              <label className="SegmentOption"
                     id="UnderlineTextStyleControl"
                     htmlFor="UnderlineTextStyle"
                     onClick={ () => {setUnderline()}}>
                <u>Aa</u>
              </label>
              <label className="SegmentOption"
                     id="StrikethroughTextStyleControl"
                     htmlFor="StrikethroughTextStyle"
                     onClick={ () => {setLinethrough()}}>
                <s>Aa</s>
              </label>
            </div>

            {objectColorPicker}

            <div className="Clear"></div>
          </section>

          {textAlignment}

          <div className="Clear"></div>

          <section className="SpacingProperties">
            <h3>Text Spacing</h3>

            <div className="SingleInputRow LetterSpacing">
              <label>Letter Spacing</label>
              <input value={charSpacing}
                     type="number"
                     ref="charSpacing"
                     onChange={() => {setCharSpacing(this.refs.charSpacing.value)}}
                     className="Third" />
            </div>

            <div className="SingleInputRow LineSpacing">
              <label>Line Spacing</label>
              <input value={lineHeight}
                     type="number"
                     step="0.01"
                     min="0.01"
                     max="100"
                     ref="lineHeight"
                     className="Third"
                     onChange={ () => {setLineHeight(this.refs.lineHeight.value)}} />
            </div>
            <div className="Clear"></div>
          </section>
        </If>
        <If condition={showBgColorButton}>
          <Choose>
            <When condition={this.backgroundHasImage()}>
              <section className="TwoLineContainer">
                <div className="TwoLineButton">
                  <button onClick={() => {resetBackground()}}
                          className="FullButton DeleteBGButton" />
                  <span className="ButtonLabel">Remove Background Image</span>
                </div>
              </section>
            </When>
            <Otherwise>
              <section className="AllProperties">
                <div className="SingleInputRow">
                  <label>Background Color</label>
                  {bgColorPicker}
                </div>
              </section>
            </Otherwise>
          </Choose>
        </If>

        <If condition={this.showTemplateSaveOption()}>
          {templateForm}
        </If>
      </div>
    )
  }
}

StyleProperties.propTypes = {
  admin: PropTypes.bool,
  parentState: PropTypes.object,
  setFontSize: PropTypes.func,
  setFontWeightStyle: PropTypes.func,
  setUnderline: PropTypes.func,
  setLinethrough: PropTypes.func,
  setCharSpacing: PropTypes.func,
  setLineHeight: PropTypes.func,
  resetTextStyle: PropTypes.func,
  resetBackground: PropTypes.func,
  textAlignment: PropTypes.element,
  templateForm: PropTypes.element,
  bgColorPicker: PropTypes.element,
  objectColorPicker: PropTypes.element,
  fontFamilyOptions: PropTypes.element,
}

export default StyleProperties
