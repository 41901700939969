const subscriptions = {
    monthly_premium: 0,
    annual_premium: 1
}

function premiumSubscriptionByType(premiumType) {
    const [premium = 'monthly_premium', _] = Object.entries(subscriptions).find(([_, v]) => v === premiumType)
    return premium;
}

function premiumSubscriptionTypeByName(premium) {
    if (!premium) {
        return 0;
    }
    const result = subscriptions[premium] ?? 0;
    return result;
}

export {
    premiumSubscriptionByType,
    premiumSubscriptionTypeByName
}
