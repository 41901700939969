import React, { Component } from "react";
import MapGL from "react-map-gl";
import DeckGL, { GeoJsonLayer, ScatterplotLayer } from "deck.gl";
import Geocoder from "react-map-gl-geocoder";
import LoadingOverlay from 'react-loading-overlay';
import {DebounceInput} from 'react-debounce-input';
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibWFpbGZvbGQiLCJhIjoiY2trbW5uZmx5MHl4dTJucXRpdjVnaHU3cyJ9.viz7TxTxCd6hreBmc4UAtA";

class ProspectingMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewport: this.props.viewport,
      searchResultLayer: null,
      locationValue: this.props.locationValue,
      mapSelection: "location",
      showRadiusInput: this.props.showRadiusInput,
      showRadiusField: false,
      radiusChecked: false,
      radiusMeters: 0,
      radiusMiles: 0,
      radiusFieldIsValid: true,
      amountFieldIsValid: true,
      amount: this.props.recipiensNumber,
      loading: this.props.loading,
      showAmountRecords: this.props.showRecordsInput,
      clearGeocoder: this.props.clearGeocoder
    };
  }

  mapRef = React.createRef();

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.clearGeocoder !== this.props.clearGeocoder && this.props.clearGeocoder === true){
      this.setState({
        clearGeocoder: this.props.clearGeocode
      }, ()=> this.simulateClick());
    }

    if(prevProps.locationValue !== "usa" && this.props.locationValue == "usa"){
      this.clearLocationState();
      this.setState({
        radiusMeters: 0,
        radiusMiles: 0,
        amount: this.props.recipiensNumber,
      })
    }
    if(prevProps.showRecordsInput !== this.props.showRecordsInput){
      this.setState({
        showAmountRecords: this.props.showRecordsInput
      })
    }
    if(prevProps.showRadiusInput !== this.props.showRadiusInput){
      this.setState({
        showRadiusInput: this.props.showRadiusInput
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.handleViewportChange({
      width: '100%'
    });
  };

  handleViewportChange = viewport => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    });
  };

  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };
    this.props.handleViewportChange(viewport);

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleOnResult = event => {
    this.setState({
      searchResultLayer: new GeoJsonLayer({
        id: "search-result",
        data: event.result.geometry,
        getFillColor: [255, 0, 0, 128],
        getRadius: 1000,
        pointRadiusMinPixels: 10
      }),
      locationValue: event.result,
    });
    this.props.updateLocationValue(this.state.locationValue);
  };



  clearLocationState = () => {
    this.setState({
      viewport: {
        latitude: 37.090240,
        longitude: -95.712891,
        width: '100%',
        height: 420,
        zoom: 3,
        pitch: 0,
        bearing: 0
      },
      searchResultLayer: null,
      radiusChecked: false,
      locationValue: "usa",
      radiusMeters: 0,
    })
    this.props.updateLocationValue("usa");
  }

  changeMapSelection = (value) => {
    this.setState({
      mapSelection: value
    })
    this.clearLocationState()
  }

  setShowRadiusField = (e) => {
    this.setState({
      showRadiusField: e.target.checked,
      radiusChecked: e.target.checked? true : false,
      radiusMeters: 0,
      radiusMiles: 0
    }, () => this.props.updateRadiusValue(this.state.radiusMiles));
  }

  getRadiusMiles = (e) => {
    let val = e
    let meters = val * 1609.344;
    if(val > 25 || val <= 0) {
      this.setState({
        radiusFieldIsValid: false,
        radiusMiles: val
      })
    } else {
      this.setState({
        radiusMeters: meters,
        radiusMiles: val,
        radiusFieldIsValid: true
      },
       () => this.props.updateRadiusValue(this.state.radiusMiles));
    }
  }

  getRecordsAmount = (e) => {
    let val = parseInt(e.target.value)
    if(val > 65000 || val <= 0) {
      this.setState({
        amountFieldIsValid: false,
        amount: val,
      })
    } else {
      this.setState({
        amount: val,
        amountFieldIsValid: true
      },
      () => this.props.updateRecordsAmount(this.state.amount));
    }
  }

  setViewport = (viewport) => {
    viewport.height = 420;
    viewport.width = "100%";

    return viewport;
  }

  simulateClick = () => {
    let clickable = document.getElementsByClassName('mapboxgl-ctrl-geocoder--button')[0];
    clickable.click();
  }

  render() {
    const viewport = this.setViewport(this.state.viewport);
    return (
      <div className="prospecting-tabs-map">
        <LoadingOverlay
          active={this.props.loading}
          spinner
          text='Loading your content...'
          >
        </LoadingOverlay>
        <div className="prospecting-tabs-content">

          <div className="prospecting-tabs-content-row">
            <label className="container container-description">
              Please enter a zip, city, county, street or state in the box below to see the number of records available for this region.
            </label>
          </div>
          {this.state.mapSelection === "location" &&
            <>
            <div className="prospecting-tabs-content-row">
              <label className={`container radius-check ${this.state.showRadiusInput ? "" : "radius-check-disabled"}` } for="radius">
                Add Radius
                <input type="checkbox" disabled={false && !this.state.showRadiusInput} checked={this.state.radiusChecked} onChange={(e) => this.setShowRadiusField(e)} value="radius" id="radius"></input>
                <span className="checkmark"></span>
                <div className="radius-check-disabled-tip">
                  If you want to add Radius, please choose the location on the Map below.
                  Adding a radius is only available for addresses and indexes.
                  Default Radius is 10 miles.
                </div>
              </label>

              {this.state.showRadiusField && this.state.radiusChecked &&
                <>
                  <DebounceInput
                    minLength={1}
                    type="number"
                    min="1"
                    debounceTimeout={1000}
                    value={this.state.radiusMiles}
                    onChange={(e) => {this.getRadiusMiles(parseInt(e.target.value))}}
                    className={this.state.radiusFieldIsValid ? "" : "notValid"} />
                  <span className={this.state.radiusFieldIsValid ? "" : "notValidText"}>Maximum radius is 25 miles</span>
                </>
              }
            </div>
            <div className="prospecting-tabs-content-row prospecting-tabs-content-row-amountRecords">
              <label className={`container radius-check ${this.state.showAmountRecords ? "" : "radius-check-disabled"}` }>
                Add Records Amount
                <div className="radius-check-disabled-tip">
                  If you want to add Amount of records, please choose the location on the Map below.
                  Adding an amount of records is only available for addresses
                </div>
              </label>
              {this.state.showAmountRecords &&
                <div className="amountRecordsField">
                      <DebounceInput
                        minLength={1}
                        type="number"
                        min="0"
                        value={this.state.amount}
                        debounceTimeout={1500}
                        onChange={(e) => this.getRecordsAmount(e)}
                        className={this.state.amountFieldIsValid ? "" : "notValid"} />
                      <span className={this.state.amountFieldIsValid ? "" : "notValidText"}>Maximum amount is 65 000 records</span>
                </div>
              }
            </div>
            </>
          }

        </div>
        <div className="mapBox-wrapper">
          <MapGL
            ref={this.mapRef}
            {...viewport}
            onViewportChange={this.handleViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          >
            <>
              <Geocoder
                mapRef={this.mapRef}
                onResult={this.handleOnResult}
                onViewportChange={this.handleGeocoderViewportChange}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                countries="us"
                onClear={this.clearLocationState}
              />
              <DeckGL {...viewport} layers={[this.state.searchResultLayer]}/>
              <DeckGL
                   initialViewState={viewport}
                   controller={true}
                   width="100%"
                   height="100%"
                >
                {this.state.locationValue !== "usa" &&
                  <ScatterplotLayer
                    data={[{ position: this.state.locationValue.center }]}
                    radiusScale={this.state.radiusMeters}
                    stroked={true}
                    getFillColor={[0, 0, 0]}
                    opacity={0.1}
                  />
                }
              </DeckGL>
            </>
          </MapGL>
        </div>
      </div>
    );
  }
}

export default ProspectingMap;
