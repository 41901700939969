import React, { useState, useEffect } from 'react';
import deleteIcon from "images/rubish-delete.svg";

const ProspectingSelections = props => {

    const [mainArr, setmainArr] = useState([]);

    useEffect(() => {
        props.targetValue === "businesses" ? setmainArr(Object.entries(Object.assign([], props.stateInfo.BusinessesDemographic))) :
        setmainArr(Object.entries(Object.assign([], props.stateInfo.IndividualsDemographic, props.stateInfo.BusinessesDemographic)))
    }, [props.stateInfo]);

    const renderArr = () => {
        if (Array.isArray(mainArr)) {

            return (
                mainArr.map(function(i, index) {

                    if(Object.keys(mainArr[index][1]).length > 0) {

                        let newArr = Array.from(mainArr[index][0]);
                        for (let i = newArr.length-1; i > 0; i--) {
                            if (newArr[i] === newArr[i].toUpperCase()) {
                                newArr.splice(newArr.indexOf(newArr[i]), 0, " ");
                            }
                        }
                        let result = newArr.join('')  ;
                        let tips = Object.values(mainArr[index][1]).map((i, index) => {
                            return (
                                <span className="prospecting-selects-content-item-text-options-tip-item">
                                    {i}
                                </span>
                            )
                        })
                        return (
                            <div className="prospecting-selects-content-item prospecting-selects-content-item-filter">
                                <div className="prospecting-selects-content-item-text">
                                    <span className="prospecting-selects-content-item-text-info">{result}</span>
                                    <div className="prospecting-selects-content-item-text-options">

                                        <span className="prospecting-selects-content-item-text-options-number">
                                            +{Object.keys(mainArr[index][1]).length}
                                        </span>

                                        <div className="prospecting-selects-content-item-text-options-tip">
                                            {tips}
                                        </div>

                                    </div>
                                </div>
                                {mainArr[index][0] !== "Gender" &&
                                    <span className="close-icon" onClick={() => {props.deleteSingleItem(mainArr[index][0])}}><img src={deleteIcon}></img></span>
                                }
                            </div>
                        )
                    }
                })
            )
        }
    }

    return (
        <div className="prospecting-selects-content">
            <div className="prospecting-selects-content-item prospecting-selects-content-item-mailingList">
                <span className="prospecting-selects-content-item-text">{props.stateInfo.targetValue}</span>
            </div>
            {props.stateInfo.locationValue.place_name &&
                <div className="prospecting-selects-content-item prospecting-selects-content-item-location">
                  <span className="prospecting-selects-content-item-text">{props.stateInfo.locationValue.place_name}</span>
                  <span className="close-icon" onClick={() => props.clearLocationValue()}><img src={deleteIcon}></img></span>
              </div>
            }
            <div className="prospecting-selects-content-filtering">
            {
             renderArr()
            }
            </div>
        </div>
    );
};

export default ProspectingSelections;
